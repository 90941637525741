import AppState from "../AppState";

export const selectVariationAttributes = (state: AppState) => {
  return state.variationManagement.data || [];
};

export const selectVariationGroupIds = (state: AppState) => {
  return state.variationManagement.variationGroupIds || [];
};

export const selectVariationGroupDetails = (state: AppState) => {
  return state.variationManagement.variationGroupDetails || [];
};

export const selectIsVariationDetailsFetched = (state: AppState): boolean => {
  return state.variationManagement.isFetched;
};

export const selectIsGroupIdsFetched = (state: AppState): boolean => {
  return state.variationManagement.variationGroupIds
    ? state.variationManagement.isGroupIdsFetched
    : false;
};

export const selectIsVariationGroupUpdated = (state: AppState): boolean => {
  return state.variationManagement.isVariationGroupAdded;
};

export const selectIsVariationGroupDeleted = (state: AppState): boolean => {
  return state.variationManagement.isVariationGroupDeleted;
};

export const selectIsAllProductFetched = (state: AppState): boolean => {
  return state.variationManagement.areAllPagesFetched;
};

export const selectVariationProductList = (state: AppState) => {
  return state.variationManagement.variationGroupProductList;
};

export const selectVariationAssignedAttributeValues = (state: AppState) => {
  return state.variationManagement.assignedAttributeValues || [];
};

export const selectVariationUnAssignedAttributeValues = (state: AppState) => {
  return state.variationManagement.unAssignedAttributeValues || [];
};
