import AppState from "../AppState";
import { ProductAlertsScheduleInfo } from "./ProductAlertsScheduleType";

export const selectProductAlertsScheduleData = (
  state: AppState,
): ProductAlertsScheduleInfo => {
  return state?.productAlertsSchedule?.productAlertsSchedule;
};

export const selectIsUpdatingProductAlertSchedule = (
  state: AppState,
): boolean => {
  return state.productAlertsSchedule.isUpdatingSchedule;
};

export const selectIsFetchingProductAlertsSchedule = (
  state: AppState,
): boolean => {
  return state.productAlertsSchedule.isFetchingProductAlertsSchedule;
};
