import AppState from "../AppState";

export const selectClipboardProductCodes = (state: AppState) => {
  return state.clipBoard.productCodes;
};

export const selectIsVerifyingProductCodes = (state: AppState) => {
  return state.clipBoard.isValidatingProductCodes;
};

export const selectCurrentActionModal = (state: AppState) => {
  return state.clipBoard.currentAction;
};

export const selectClipboardInvalidProductCodes = (state: AppState) => {
  return state.clipBoard.invalidProductCodes;
};

export const selectValidProductIds = (state: AppState) => {
  return state.clipBoard.validProductIds;
};
