import type { Components } from "@mui/material";
import { DANGER_COLOR } from "../palette";

export const MuiFormHelperText: Components["MuiFormHelperText"] = {
  styleOverrides: {
    root: {
      fontWeight: 400,
      fontFamily: "Helvetica !important",
      color: `${DANGER_COLOR} !important`,
      marginTop: "0px !important",
      marginLeft: "1px !important",
    },
  },
};
