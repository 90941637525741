import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { selectGetProductIds } from "../../store/product-list/ProductListSelectors";
import { selectIsProductsFetched } from "../../store/product/ProductSelectors";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import { selectCurrentStoreId } from "../../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { loadCategoryByProductId } from "../../store/product/ProductActions";
import { OVERLAY_TABS } from "../../utils/Constants";
import useClipboard from "../../hooks/useClipBoard";

const CategoriesOverlayLabel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const productIds = useSelector(selectGetProductIds);
  const isProductDetailsFetched = useSelector(selectIsProductsFetched);
  const selectedOverlay = useSelector(selectOverlay);
  const storeId = useSelector(selectCurrentStoreId);
  const catalogId = useSelector(selectCurrentCatalogId);
  const { getClipboardProductCodes } = useClipboard();

  const isClipBoardProductsAvailableInProductIds = useMemo(() => {
    let productCount = 0;
    productIds.length &&
      productIds.forEach((id) => {
        if (getClipboardProductCodes().includes(id)) {
          productCount++;
        }
      });
    return productCount > 0
      ? productCount === getClipboardProductCodes().length
      : true;
  }, [productIds, getClipboardProductCodes]);

  useEffect(() => {
    if (
      selectedOverlay === OVERLAY_TABS.CATEGORIES_OVERLAY &&
      isProductDetailsFetched &&
      productIds.length > 0 &&
      storeId &&
      isClipBoardProductsAvailableInProductIds
    ) {
      const payload = {
        productIds,
      };
      dispatch(loadCategoryByProductId(payload));
    }
  }, [
    isProductDetailsFetched,
    catalogId,
    storeId,
    dispatch,
    selectedOverlay,
    productIds,
    isClipBoardProductsAvailableInProductIds,
  ]);
  return (
    <>
      {intl.formatMessage({
        id: "overlayTabs.categoriesLabel",
        defaultMessage: "Categories",
      })}
    </>
  );
};

export default React.memo(CategoriesOverlayLabel);
