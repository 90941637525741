import React from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MultipleStoreModal, {
  MultipleStoreModalId,
} from "../multiple-store-modal/MultipleStoreModal";
import ButtonComponent from "../../components/common/ButtonComponent";
import SettingsIcon from "@mui/icons-material/Settings";

interface Props {
  validatePartNumber: Function;
}

const PublishConfigProductsSFCC: React.FC<Props> = ({ validatePartNumber }) => {
  //const classes = useStyles();

  const openModalHandler = () => {
    validatePartNumber(MultipleStoreModalId);
  };

  return (
    <>
      <ButtonComponent
        color="tertiary"
        variant="contained"
        fullWidth={true}
        startIcon={<SettingsIcon fontSize="medium" />}
        onClick={openModalHandler}
      >
        Publish / Unpublish
      </ButtonComponent>
      <MultipleStoreModal />
    </>
  );
};

export default PublishConfigProductsSFCC;
