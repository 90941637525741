import {
  LeadingImageImagesData,
  ProductImage,
} from "../store/leading-image/LeadingImageTypes";
import { ImageListItem, styled } from "@mui/material";

interface Props {
  imageList: LeadingImageImagesData[];
  handleImageClick: (image: ProductImage) => void;
  leadingImage: string | null;
}

const ImageListItems = (props: Props) => {
  const { imageList, handleImageClick, leadingImage } = props;

  const imageListClasses = {
    imageCard: "imageCard",
    leadingImageCard: "leadingImageCard",
  };

  const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
    [`&.${imageListClasses.imageCard}`]: {
      "&:hover": {
        border: "2px solid " + theme.palette.error.main,
        cursor: "pointer",
      },
    },
    [`&.${imageListClasses.leadingImageCard}`]: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  }));

  if (!Object.keys(imageList)?.length) {
    return <></>;
  }
  return (
    <>
      {imageList?.length ? (
        imageList.map((image, index) => {
          return (
            <StyledImageListItem
              key={index}
              sx={{
                border: `medium solid ${leadingImage === image.imageId ?? image.isSelected ? "red" : "white"}`,
              }}
              onClick={() => {
                handleImageClick(image);
              }}
              className={
                leadingImage !== image.imageId && !image.isSelected
                  ? imageListClasses.imageCard
                  : imageListClasses.leadingImageCard
              }
            >
              <img src={`${image.url}`} alt={image.imageId} />
            </StyledImageListItem>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default ImageListItems;
