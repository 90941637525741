import type { Components } from "@mui/material";

import {
  FOCUS_COLOR,
  PRIMARY_COLOR,
  PRIMARY_DARK,
  TERTIARY_COLOR,
  TERTIARY_DARK,
  WHITE_COLOR,
} from "../palette";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    altmenu: true;
    menu: true;
    pickerVariant: true;
  }
  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    secondaryButtonColorCTABlue: true;
    error: true; // Added new color overrides
  }
}

export const MuiButton: Components["MuiButton"] = {
  styleOverrides: {
    root: {
      fontSize: "0.75rem",
      fontWeight: 700,
      padding: `0.75rem calc(0.75rem * 3)`,
      borderRadius: 0,
      textTransform: "uppercase",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        borderColor: "transparent",
      },
    },
  },
  variants: [
    {
      props: { variant: "primary" },
      style: {
        "&:hover": {
          background: PRIMARY_DARK,
        },
      },
    },
    {
      props: { color: "primary", variant: "contained" },
      style: {
        "&:hover": {
          background: PRIMARY_DARK,
        },
        "&.Mui-disabled": {
          color: "white",
        },
      },
    },
    {
      props: { color: "tertiary", variant: "contained" },
      style: {
        "&:hover": {
          background: TERTIARY_DARK,
        },
        "&.Mui-disabled": {
          color: "white",
        },
      },
    },
    {
      props: { color: "secondaryButtonColorCTABlue", variant: "outlined" },
      style: {
        color: FOCUS_COLOR,
        border: "none !important",
        backgroundColor: "transparent",
        "&:hover": {
          color: PRIMARY_DARK,
          backgroundColor: "transparent !important",
        },
      },
    },
    {
      props: { color: "secondary", variant: "outlined" },
      style: {
        backgroundColor: "transparent",
        border: "none !important",
        "&:hover": {
          color: PRIMARY_DARK,
          backgroundColor: "transparent !important",
        },
        "&:active": {
          color: PRIMARY_COLOR,
          backgroundColor: "transparent !important",
        },
      },
    },
    {
      props: { color: "error", variant: "outlined" },
      style: {
        backgroundColor: "transparent",
        border: "none !important",
        width: "100%",
        "&:hover": {
          // color: primaryDark,
          backgroundColor: "transparent !important",
        },
        "&:active": {
          // color: primaryColor,
          backgroundColor: "transparent !important",
        },
      },
    },
    {
      props: { variant: "secondary" },
      style: {
        "&:hover": {
          background: PRIMARY_DARK,
        },
      },
    },
    {
      props: { variant: "menu" },
      style: {
        width: "100%",
        marginBottom: 10,
        height: "auto",
        lineHeight: "normal",
        backgroundColor: TERTIARY_COLOR,
        fontSize: ".6em",
        border: "0 none",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: WHITE_COLOR,
        cursor: "pointer",
        borderRadius: 0,
        "&:hover": {
          background: TERTIARY_DARK,
        },
      },
    },
    {
      props: { variant: "menu", color: "secondary" },
      style: {
        "&:hover": {
          background: TERTIARY_DARK,
        },
      },
    },
    {
      props: { variant: "menu" },
      style: {
        width: "100%",
        marginBottom: 10,
        height: "auto",
        lineHeight: "normal",
        backgroundColor: TERTIARY_COLOR,
        fontSize: ".6em",
        border: "0 none",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: WHITE_COLOR,
        cursor: "pointer",
        borderRadius: 0,
        "&:hover": {
          background: TERTIARY_DARK,
        },
      },
    },
    {
      props: { variant: "menu", color: "secondary" },
      style: {
        width: "auto",
        marginBottom: 0,
        height: "auto",
        lineHeight: 1.2,
        backgroundColor: TERTIARY_COLOR,
        fontSize: ".7em",
        padding: "10px 20px",
        border: "0 none",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: WHITE_COLOR,
        cursor: "pointer",
        borderRadius: 0,
        "&:hover": {
          background: TERTIARY_DARK,
        },
      },
    },
    {
      props: { variant: "altmenu" },
      style: {
        width: "auto",
        height: "auto",
        lineHeight: "normal",
        backgroundColor: "#87baa9",
        fontSize: ".8em",
        border: "0 none",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "#f2f2f2",
        cursor: "pointer",
        borderRadius: 0,
        "&:hover": { background: "#658c7f" },
      },
    },
    {
      props: { variant: "pickerVariant" }, // Add customVariant here
      style: {
        width: 200,
        justifyContent: "left",
        marginLeft: 30,
        position: "relative",
        "& ul.MuiList-root.MuiList-padding": {
          width: 120,
        },
        "& .arrowIcon": {
          position: "absolute",
          right: 0,
        },
        "& .pickerButton": {
          width: 200,
          justifyContent: "left",
          marginLeft: 30,
        },
        "& .pickerPopUp": {
          position: "absolute",
          left: "calc(-100% - 50px)",
          zIndex: 3,
        },
      },
    },
  ],
};
