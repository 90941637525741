import { useState, useContext } from "react";
import { FormControl } from "@mui/material";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { ViewContext, ProductListColumn } from "../context/view-context";
import { styles } from "./ProductListViewSelect.styles";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import DropdownMenu from "./common/DropdownMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuLabel: { marginLeft: 10, marginRight: 10 },
    menuItem: {
      "&:hover": {
        backgroundColor: "rgba(241, 130, 33, 0.50)",
      },
      "&:selected": {
        backgroundColor: "#FFFFFF",
      },
    },
  }),
);

export default function ProductListViewSelect() {
  const intl = useIntl();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const viewContext = useContext(ViewContext);
  const [value, setValue] = useState<number>(viewContext.styles.column);
  const { bodyWrap } = styles();
  const changeHandler = (event) => {
    const newValue = event.target.value as ProductListColumn;
    setValue(newValue);
    viewContext.onChangeStyles(newValue);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const openHandler = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl sx={bodyWrap} variant="outlined">
        <label className={classes.menuLabel}>
          <Typography variant="subHeader">
            {intl.formatMessage({
              id: "productListViewSelect.viewLabel",
              defaultMessage: "View",
            })}
            :
          </Typography>
        </label>
        <DropdownMenu
          menuId="ProductListViewSelect"
          classes={classes}
          open={open}
          value={value}
          closeHandler={closeHandler}
          openHandler={openHandler}
          changeHandler={changeHandler}
        />
      </FormControl>
    </div>
  );
}
