import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { Divider } from "@material-ui/core";

import CatalogNavigationTabs from "./CatalogNavigationTabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      justifyContent: "center",
      gridTemplateColumns: "repeat(3,1fr)",
      backgroundColor: "#F2F3F4",
      minHeight: "fit-content",
    },
  }),
);

const PrimaryToolbarSequence = () => {
  const classes = useStyles();

  return (
    <div>
      <Toolbar className={classes.toolbar}>
        <CatalogNavigationTabs />
      </Toolbar>
      <Divider />
    </div>
  );
};

export default PrimaryToolbarSequence;
