import { makeStyles } from "hooks";

interface IPrivacyPolicyProps {
  onClick: () => void;
}

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
    color: "blue",
    textDecoration: "underline",
  },
});

export function PrivacyPolicy(props: IPrivacyPolicyProps) {
  const { onClick } = props;
  const classes = useStyles();

  const handlePrivacyPolicyClick = (event) => {
    event.preventDefault();

    onClick();
  };

  return (
    <span className={classes.root} onClick={handlePrivacyPolicyClick}>
      Privacy Policy
    </span>
  );
}
