import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useHistory, useLocation } from "react-router-dom";
import AppState from "../../store/AppState";
import { selectAllCatalogs } from "../../store/catalog/CatalogSelectors";
import { LoadAllCatalogs } from "../../store/catalog/CatalogActions";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import { setReportCatalogId } from "../../store/report/ReportActions";
import {
  selectReportStoreId,
  selectReportCatalogId,
} from "../../store/report/ReportSelectors";
import { LoadTopCategoriesByCatalogIdForReport } from "../../store/category/CategoryActions";
import { useQuery } from "../../hooks/useQueryParams";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5, 1),
  },
}));

const CatalogFilter: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const storeId = useSelector(selectReportStoreId);
  const catalogId = useSelector(selectReportCatalogId);
  const userAccountId = useSelector(selectCallerAccountId);
  const graphQLStoreRelationshipEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
    ) === "true";

  const catalogList: { name: string; id: string }[] = useSelector(
    (state: AppState) => selectAllCatalogs(state),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!graphQLStoreRelationshipEnabled) {
      dispatch(LoadAllCatalogs(storeId));
    }
  }, [graphQLStoreRelationshipEnabled, dispatch, storeId]);

  const handleCatalogChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;
    dispatch(setReportCatalogId(changeEvent.target.value));
    if (changeEvent.target.value !== "") {
      dispatch(
        LoadTopCategoriesByCatalogIdForReport(
          changeEvent.target.value,
          storeId,
        ),
      );
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&catalogId=${changeEvent.target.value}`,
      );
    } else {
      history.push(`${url}?accountId=${userAccountId}&storeId=${storeIdQuery}`);
    }
  };

  useEffect(() => {
    if (storeId !== "" && catalogList.find((e) => e.id === catalogIdQuery)) {
      if (catalogId !== catalogIdQuery) {
        dispatch(setReportCatalogId(catalogIdQuery));
        dispatch(
          LoadTopCategoriesByCatalogIdForReport(catalogIdQuery, storeId),
        );
      }
    } else {
      if (catalogId !== "") {
        dispatch(setReportCatalogId(""));
      }
    }
  }, [catalogIdQuery, catalogList, catalogId, storeId, dispatch]);

  useEffect(() => {
    if (
      storeIdQuery !== "" &&
      catalogList.length > 0 &&
      !catalogList.find((e) => e.id === catalogIdQuery)
    ) {
      history.push(`${url}?accountId=${userAccountId}&storeId=${storeIdQuery}`);
    }
  }, [catalogIdQuery, catalogList, storeIdQuery, history, url, userAccountId]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="catalog-list-label">Catalog Selection</InputLabel>
      <DropdownMenu
        menuId="InventoryOverlayLabel"
        value={catalogId}
        storeId={storeId}
        changeHandler={handleCatalogChange}
        catalogList={catalogList}
      />
    </FormControl>
  );
};

export default React.memo(CatalogFilter);
