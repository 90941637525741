export const formatDateForAnalytics = (currentTime: Date): string => {
  const year = "" + currentTime.getFullYear();
  let month = "" + (currentTime.getMonth() + 1);
  let day = "" + currentTime.getDate();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  const newDate = year + "-" + month + "-" + day;
  return newDate;
};

/**
 * Convert the SM analytics date string to date.
 * Date string format YYYYMMDD
 */
export const getDateFromString = (dateString: string): Date => {
  const currentDate = new Date();
  if (dateString.length !== 8) {
    console.log("Invalid date string. Using current date");
  } else {
    currentDate.setFullYear(parseInt(dateString.substring(0, 4)));
    currentDate.setMonth(parseInt(dateString.substring(4, 6)) - 1);
    currentDate.setDate(parseInt(dateString.substring(6)));
  }
  return currentDate;
};

/**
 * Get date string for the date ahead or behind by numOfDays
 * Provide negative value for previous days
 */
export const getDateStringByDays = (numOfDays: number): string => {
  const currentTime = new Date();
  currentTime.setDate(currentTime.getDate() + numOfDays);

  return formatDateForAnalytics(currentTime);
};

/**
 * Get date ahead or behind by numOfDays
 * Provide negative value for previous days
 */
export const getDateByDays = (numOfDays: number): Date => {
  const currentTime = new Date();
  currentTime.setDate(currentTime.getDate() + numOfDays);

  return currentTime;
};

/**
 * Get date ahead or behind by numOfMonths
 * Provide negative value for previous months
 */
export const getDateByMonths = (numOfMonths: number): Date => {
  const currentTime = new Date();
  currentTime.setMonth(currentTime.getMonth() + numOfMonths);

  return currentTime;
};
