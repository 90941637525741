import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FacetTile, { DragFacetItem } from "./FacetTile";
import { makeStyles } from "@material-ui/core";
import {
  selectCategoryFacets,
  selectSFCCFacets,
} from "../../store/facets/FacetSelectors";
import { Facet, FacetSequences } from "../../store/facets/FacetTypes";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { getFacetDisplayName } from "../../utils/FacetUtils";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import {
  updateFacetSequences,
  updateSFCCFacetSequences,
} from "../../store/facets/FacetActions";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { selectAssignedAttributeValuesMap } from "../../store/facets/FacetSelectors";
import { FACET_BUCKET_MODES } from "../../utils/Constants";
import FacetBucketTile from "./FacetBucketTile";
import AppState from "../../store/AppState";
import { AdapterInfoObject } from "../../utils/AppConfigUtils";
import { selectAdapterInfo } from "../../store/adapter-config/AdapterConfigSelectors";

const useStyles = makeStyles({
  root: {
    top: 44,
    position: "relative",
    padding: "10px 20px",
  },
});

const FacetsList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryFacets = useSelector(selectCategoryFacets);
  const categorySFCCFacets = useSelector(selectSFCCFacets);
  const [facets, setFacets] = useState<Facet[]>([]);
  const [inheritedFacets, setInheritedFacets] = useState<Facet[]>([]);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const containerRef = useRef<HTMLDivElement>(null);
  const localeCode = useSelector(selectCurrentLocale);
  const storeId = useSelector(selectCurrentStoreId);
  const categoryId = useSelector(selectCurrentCategory);
  const assignedAttributeValuesMap = useSelector(
    selectAssignedAttributeValuesMap,
  );
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const attrValSequenceLocaleSpecific =
    useSelector((state: AppState) =>
      selectConfigValue(state, "attrValSequenceLocaleSpecific"),
    ) === "true";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  useEffect(() => {
    setInheritedFacets(
      categorySFCCFacets.filter((facet) =>
        facet.categoryId ? facet.categoryId !== categoryId : false,
      ),
    );
    const remainingFacets = categorySFCCFacets.filter((facet) =>
      facet.categoryId ? facet.categoryId === categoryId : true,
    );
    setFacets([...remainingFacets, ...categoryFacets]);
  }, [categoryFacets, categorySFCCFacets, categoryId]);

  const moveTiles = useCallback(
    (dragItem: DragFacetItem) => {
      if (hoverIndex < 0) return;
      const copiedList = facets.slice();
      const dragIndex = copiedList.findIndex(
        (item) => item.facetId === dragItem.id,
      );
      if (dragIndex === hoverIndex) return;
      const draggedFacet = copiedList[dragIndex];
      copiedList.splice(dragIndex, 1);
      copiedList.splice(hoverIndex, 0, draggedFacet);
      const facetSequences: FacetSequences[] = [];
      let prevSequence = 0;
      copiedList.forEach(function (facet, index) {
        var nextIndex = index + 1;
        var thisSequence = Number(facet.sequence);

        var nextSequence =
          copiedList.length !== nextIndex
            ? Number(copiedList[nextIndex].sequence)
            : thisSequence;

        if (prevSequence >= thisSequence || thisSequence > nextSequence) {
          var rounder = Math.pow(10, 2);
          thisSequence =
            prevSequence < nextSequence
              ? (prevSequence + nextSequence) / 2
              : prevSequence + 1;
          thisSequence = Math.round(thisSequence * rounder) / rounder;

          if (prevSequence === thisSequence) {
            thisSequence = prevSequence + 1;
          }

          facetSequences.push({
            facetId: facet.facetId,
            sequence: thisSequence,
          });
        }
        prevSequence = thisSequence;
      });
      if (ecomPlatformType === "SFCC") {
        const payload = {
          facetSequences,
        };

        dispatch(updateSFCCFacetSequences(payload));
      } else {
        const payload = {
          storeId: storeId ? storeId : "",
          categoryId,
          facetSequences,
        };

        dispatch(updateFacetSequences(payload));
      }
      setFacets(copiedList);
      setHoverIndex(-1);
    },
    [facets, hoverIndex, dispatch, storeId, categoryId, ecomPlatformType],
  );

  const getFacetTileJSX = (facet: Facet, index: number) => {
    const facetDisplayName = getFacetDisplayName(
      facet,
      localeCode,
      defaultLocaleId,
    );
    if (facet.bucketMode === FACET_BUCKET_MODES.NONE) {
      const values = facet.attribute ? facet.attribute.values : [];
      switch (facet.sortMode) {
        case "VALUE_ASC":
          values.sort((value1, value2) => {
            if (value1.code < value2.code) return -1;
            else if (value1.code > value2.code) return 1;
            return 0;
          });
          break;
        case "VALUE_DESC":
          values.sort((value1, value2) => {
            if (value1.code > value2.code) return -1;
            else if (value1.code < value2.code) return 1;
            return 0;
          });
          break;
        case "SEQUENCE":
          values.sort((value1, value2) => {
            if (attrValSequenceLocaleSpecific) {
              const valDesc1 = value1.descriptions.find(
                (desc) => desc.localeCode === localeCode,
              );
              const valDesc2 = value2.descriptions.find(
                (desc) => desc.localeCode === localeCode,
              );
              if (valDesc1) value1.sequence = valDesc1.sequence;
              if (valDesc2) value2.sequence = valDesc2.sequence;
            }
            if (value1.sequence < value2.sequence) return -1;
            else if (value1.sequence > value2.sequence) return 1;
            return 0;
          });
          break;
        case "COUNT_ASC":
          values.sort((value1, value2) => {
            const count1 = assignedAttributeValuesMap.hasOwnProperty(
              value1.attributeValueId,
            )
              ? assignedAttributeValuesMap[value1.attributeValueId].length
              : 0;
            const count2 = assignedAttributeValuesMap.hasOwnProperty(
              value2.attributeValueId,
            )
              ? assignedAttributeValuesMap[value2.attributeValueId].length
              : 0;
            if (count1 < count2) return -1;
            else if (count1 > count2) return 1;
            return 0;
          });
          break;
        case "COUNT_DESC":
          values.sort((value1, value2) => {
            const count1 = assignedAttributeValuesMap.hasOwnProperty(
              value1.attributeValueId,
            )
              ? assignedAttributeValuesMap[value1.attributeValueId].length
              : 0;
            const count2 = assignedAttributeValuesMap.hasOwnProperty(
              value2.attributeValueId,
            )
              ? assignedAttributeValuesMap[value2.attributeValueId].length
              : 0;
            if (count1 > count2) return -1;
            else if (count1 < count2) return 1;
            return 0;
          });
          break;
      }
      return (
        <FacetTile
          key={facet.facetId}
          index={index}
          facetId={facet.facetId}
          facetType={facet.type}
          displayName={facetDisplayName}
          attributeId={facet.attributeId}
          facetValues={facet.attribute ? facet.attribute.values : []}
          onMove={moveTiles}
          setHoverIndex={setHoverIndex}
          isSortBySequence={facet.sortMode === "SEQUENCE"}
          facetCategoryId={facet.categoryId}
          isEnabled={facet.isEnabled}
        />
      );
    } else {
      const values = facet.buckets;
      switch (facet.sortMode) {
        case "VALUE_ASC":
          values.sort((value1, value2) => {
            if (
              facet.bucketMode === FACET_BUCKET_MODES.THRESHOLDS ||
              facet.bucketMode === FACET_BUCKET_MODES.PERIODS
            ) {
              return value1.threshold - value2.threshold;
            }
            if (value1.facetBucketId < value2.facetBucketId) return -1;
            else if (value1.facetBucketId > value2.facetBucketId) return 1;
            return 0;
          });
          break;
        case "VALUE_DESC":
          values.sort((value1, value2) => {
            if (
              facet.bucketMode === FACET_BUCKET_MODES.THRESHOLDS ||
              facet.bucketMode === FACET_BUCKET_MODES.PERIODS
            ) {
              return value2.threshold - value1.threshold;
            }
            if (value1.facetBucketId > value2.facetBucketId) return -1;
            else if (value1.facetBucketId < value2.facetBucketId) return 1;
            return 0;
          });
          break;
        case "SEQUENCE":
          values.sort((value1, value2) => {
            if (value1.sequence < value2.sequence) return -1;
            else if (value1.sequence > value2.sequence) return 1;
            return 0;
          });
          break;
      }
      return (
        <FacetBucketTile
          key={facet.facetId}
          index={index}
          facetId={facet.facetId}
          facetType={facet.type}
          displayName={facetDisplayName}
          attributeId={facet.attributeId}
          facetBuckets={values}
          onMove={moveTiles}
          setHoverIndex={setHoverIndex}
          isSortBySequence={facet.sortMode === "SEQUENCE"}
          facetCategoryId={facet.categoryId}
          isEnabled={facet.isEnabled}
        />
      );
    }
  };

  const inheritedFacetTiles = inheritedFacets.map(getFacetTileJSX);
  const facetTiles = facets.map(getFacetTileJSX);
  return (
    <div className={classes.root}>
      <div ref={containerRef}>
        {inheritedFacetTiles}
        {facetTiles}
      </div>
    </div>
  );
};

export default React.memo(FacetsList);
