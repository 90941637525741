import React, { useCallback, useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Skeleton } from "@mui/material";
import { useQuery } from "../../hooks/useQueryParams";
import {
  selectCurrentCatalogId,
  selectIsCatalogsLoadedState,
} from "../../store/catalog/CatalogSelectors";
import { useDispatch, useSelector } from "react-redux";
import {
  selectChildCategories,
  selectIsCatalogOrStoreChanged,
} from "../../store/category/CategorySelectors";
import { LoadChildCategories } from "../../store/category/CategoryActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { classicTheme } from "../../styles/themes/classic";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    overflowY: "auto",
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  rootLabel: {
    "&:focus>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#78a6be",
    },
  },
  selected: {
    "&>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
      color: classicTheme.palette.primary.main,
    },
  },
  count: {
    fontSize: ".8rem",
    marginLeft: "5px",
    marginBottom: "1px",
  },
  progress: {
    marginLeft: "5px",
  },
  skeletonLabel: {
    marginTop: 2,
    display: "flex",
    justifyContent: "center",
  },
}));

export interface TreeNode {
  id: string;
  translations?: Translations[];
  code?: string;
  name: string;
  productCount?: number;
  childCategoryCount?: number;
  children?: TreeNode[];
  categoryPath?: string | null;
  isPublished?: boolean;
  categoryIds?: string[];
}

export interface Translations {
  name: string;
  isPublished: boolean;
  localeCode: string;
}

interface TreeViewProps {
  nodes: TreeNode[] | null;
  handleToggle?: (event: React.ChangeEvent<{}>, node: TreeNode) => void;
  labelClickHandler?: (node: TreeNode, event: React.MouseEvent) => void;
  renderLabel: (node: TreeNode) => React.ReactNode;
  selectedNodes: string[];
  showInitialLoading?: boolean;
  expanded?: string[];
}

const TreeViewComponent: React.FC<TreeViewProps> = ({
  nodes,
  selectedNodes = [],
  handleToggle,
  labelClickHandler,
  renderLabel,
  showInitialLoading = false,
  expanded = [],
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const query = useQuery();
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const childCategories = useSelector(selectChildCategories);
  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const isCatalogsLoaded = useSelector(selectIsCatalogsLoadedState);
  const isCatalogOrStoreChanged = useSelector(selectIsCatalogOrStoreChanged);

  const [expandedNodes, setExpandedNodes] = useState<string[]>(expanded);

  useEffect(
    () => {
      if (expanded.length && !isCatalogOrStoreChanged) {
        setExpandedNodes(expanded);
        if (currentCatalogId) {
          const categoryIds = categoryPathQuery.split(";");
          categoryIds.forEach((id) => {
            if (!childCategories[id]) {
              dispatch(
                LoadChildCategories(
                  currentCatalogId,
                  id,
                  currentLocaleId,
                  currentStoreId,
                ),
              );
            }
          });
        }
      } else {
        setExpandedNodes([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expanded.length, currentStoreId, currentCatalogId],
  );

  const defaultRenderTree = useCallback(
    (node) => (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={renderLabel(node)}
        onLabelClick={(event) =>
          labelClickHandler && labelClickHandler(node, event)
        }
        classes={{
          root: classes.rootLabel,
          label: classes.label,
          selected: classes.selected,
        }}
        onIconClick={(event) => handleToggle?.(event, node)}
      >
        {Array.isArray(node.children)
          ? node.children.map((nodes) => {
              return defaultRenderTree(nodes);
            })
          : node.childCategoryCount > 0
            ? Array.from(Array(node.childCategoryCount)).map((childCount) => (
                <div className={classes.skeletonLabel}>
                  <Skeleton
                    variant="rectangular"
                    width={"90%"}
                    height={15}
                    animation="wave"
                  />
                </div>
              ))
            : null}
      </TreeItem>
    ),
    [
      classes.label,
      classes.rootLabel,
      classes.selected,
      classes.skeletonLabel,
      labelClickHandler,
      renderLabel,
      handleToggle,
    ],
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<RemoveIcon fontSize="small" />}
      defaultExpandIcon={<AddIcon fontSize="small" />}
      expanded={expandedNodes}
      selected={selectedNodes}
      onNodeToggle={(_, nodes) => setExpandedNodes(nodes)}
    >
      {showInitialLoading &&
      currentStoreId !== "" &&
      currentCatalogId !== "" &&
      isCatalogsLoaded ? (
        <div className={classes.skeletonLabel}>
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={15}
            animation="wave"
          />
        </div>
      ) : null}
      {nodes && nodes.length
        ? nodes.map((node) => defaultRenderTree(node))
        : null}
    </TreeView>
  );
};

export default TreeViewComponent;
