import {
  LeadingImage,
  REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  RESET_LEADING_IMAGE_LIST,
  SetLeadingImageForProductInCategoryAction,
  RemoveLeadingImageForProductInCategoryAction,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES,
  GetVariationAttributesPayload,
  GetVariantAttributesAction,
  SET_FILTER_VALUES_FOR_PRODUCT,
  FETCH_LEADING_IMAGE_IMAGES,
  RESET_FILTER_FOR_PRODUCT,
  RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT,
  RESET_VARIATION_ATTRIBUTES_STATE,
} from "./LeadingImageTypes";

export type setLeadingImageForProductInCategoryPayload = {
  productId: string;
  categoryId: string;
  image: LeadingImage;
};

export type filterPayload = {
  productId: string;
  categoryId: string;
  attributeId: string;
  filterValue: string;
};

export type getLeadingImageForProductInCategoryPayload = {
  categoryId: string;
  selectedColorId: string;
  product: leadingImageProduct;
};

export type getLeadingImagesPayload = {
  product: leadingImageProduct;
};

export interface leadingImageProduct {
  //overrides: any;
  thumbnail: any;
  name: string;
  //descriptions?: any;
  productId: string;
  code: string;
  typeCode: string;
  descriptions?: {
    isPublished: boolean;
    name: string;
    thumbnail: string;
    localeCode: string;
  }[];
  //updatedAt: string;
  isPublished: boolean;
  variationBaseId?: string;
  hasColors: boolean;
  hasManageableColors: boolean;
  colors?: {
    sequence: string;
    name: string;
    swatchImage: string;
    thumbnail: string;
    code: string;
    colorId: string;
    isPublished: boolean;
    translations: [
      {
        localeCode: string;
        name: string;
      },
    ];
    stock: number;
    price: number;
    storeSpecific: { storeId: string; isPublished: boolean | null }[];
  }[];
  overrides?: {
    isPublished: boolean | null;
    storeId: string;
  }[];
  inventories?: {
    quantity: number;
  }[];
  toChildProducts?: {
    childProduct: {};
    childProductId: string;
    sequence: number;
    typeCode: string;
  }[];
  productScore?: number;
  stock: number;
  listPrice: number;
  cachedAt?: Date;
  isRefreshing?: boolean;
  storeSpecific?: Object;
  isUpdatingPublishFlag?: boolean;
  translations?: any;
  categorySpecific: categorySpecificData[];
  imageGroups?: {
    images: {
      imageId: string;
      thumbnail: string;
    }[];
    variationAttributes: {
      attributeId: string;
      values: {
        value: string;
      }[];
    }[];
  }[];
}

export interface categorySpecificData {
  categoryId: string;
  leadingColorId?: string;
  leadingImageId?: string;
}

export const getLeadingImageForProductInCategory = (
  payload: getLeadingImageForProductInCategoryPayload,
) => {
  return {
    type: FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
    payload,
  };
};

export const getLeadingImages = (payload: getLeadingImagesPayload) => {
  return {
    type: FETCH_LEADING_IMAGE_IMAGES,
    payload,
  };
};

export const resetLeadingImageList = () => {
  return {
    type: RESET_LEADING_IMAGE_LIST,
  };
};

export const setLeadingImageForProductInCategory = (
  payload: setLeadingImageForProductInCategoryPayload,
): SetLeadingImageForProductInCategoryAction => ({
  type: SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  payload,
});

export const setSelectedFilterValueAction = (payload: filterPayload) => ({
  type: SET_FILTER_VALUES_FOR_PRODUCT,
  payload,
});

export const resetFiltersAction = () => ({
  type: RESET_FILTER_FOR_PRODUCT,
});

export const resetVariationAttributesForProduct = () => {
  return {
    type: RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT,
  };
};

export function createResetProdutctVariationAttributesAction() {
  return {
    type: RESET_VARIATION_ATTRIBUTES_STATE,
  };
}

export type removeLeadingImageForProductInCategoryPayload = {
  productId: string;
  categoryId: string;
};

export const getVariationAttributes = (
  payload: GetVariationAttributesPayload,
): GetVariantAttributesAction => {
  return {
    payload,
    type: FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES,
  };
};

export const removeLeadingImageForProductInCategory = (
  payload: removeLeadingImageForProductInCategoryPayload,
): RemoveLeadingImageForProductInCategoryAction => ({
  type: REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  payload,
});

export function createStoreSetLeadingImageAction(
  payload: setLeadingImageForProductInCategoryPayload,
) {
  return {
    type: STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
    payload,
  };
}

export function createStoreRemoveLeadingImageAction(
  payload: removeLeadingImageForProductInCategoryPayload,
) {
  return {
    type: STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
    payload,
  };
}
