import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { AutomationRulesList } from "../../../../store/businessRules-list/BusinessRulesListTypes";
import AutomationRulesDeleteModal from "../AutomationRules/AutomationRulesDeleteModal/AutomationRulesDeleteModal";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../../store/modal/ModalActions";
import {
  getAutomationRuleByJobId,
  loadSystemGeneratedRules,
  loadUnifiedSortingRulesList,
} from "../../../../store/businessRules-list/BusinessRulesListActions";
import { classicTheme } from "../../../../styles/themes/classic";
import ButtonComponent from "../../../../components/common/ButtonComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderClass: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    loaderText: {
      marginRight: 20,
    },
    editBtn: {
      color: "#337ab7 !important",
      textDecoration: "none !important",
      "&:hover": {
        textDecorationColor: "#337ab7 !important",
        textDecoration: "underline !important",
      },
    },
    deleteBtn: {
      color: "#d9534f !important",
      textDecoration: "none !important",
      "&:hover": {
        textDecorationColor: "#d9534f !important",
        textDecoration: "underline !important",
      },
    },
  }),
);

const AutomationListDataGrid = ({
  isLoading,
  automationRulesList,
  handleJobIdCallBackFn,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [jobId, setJobId] = React.useState("");
  const [name, setName] = React.useState("");

  const handleDeleteAutomationRuleByJobId = (jobId, name) => {
    dispatch(setModalState("DeleteAutomationRuleModal", true));
    setJobId(jobId);
    setName(name);
    return;
  };
  const handleGetAutomationRuleByJobId = (jobID: string) => {
    handleJobIdCallBackFn(jobID);
    dispatch(setModalState("AutomationRulesModal", true));
    dispatch(loadUnifiedSortingRulesList());
    dispatch(loadSystemGeneratedRules());
    dispatch(getAutomationRuleByJobId(jobID));
    return;
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.name",
        defaultMessage: "Name",
      }),
      width: 250,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: intl.formatMessage({
        id: "automationListDataGrid.description",
        defaultMessage: "Description",
      }),
      width: 350,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "col3",
      headerName: intl.formatMessage({
        id: "automationListDataGrid.edit",
        defaultMessage: "Edit",
      }),
      width: 200,
      renderCell: (data) => {
        return (
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            padding="8px 0px"
            onClick={() => handleGetAutomationRuleByJobId(data.row.jobId)}
          >
            {intl.formatMessage({
              id: "dataGridUI.edit",
              defaultMessage: "Edit",
            })}
          </ButtonComponent>
        );
      },
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "col4",
      headerName: intl.formatMessage({
        id: "automationListDataGrid.delete",
        defaultMessage: "Delete",
      }),
      width: 200,
      renderCell: (data) => {
        return (
          <ButtonComponent
            color="error"
            variant="outlined"
            padding="8px 0px"
            onClick={() =>
              handleDeleteAutomationRuleByJobId(data.row.jobId, data.row.name)
            }
          >
            {intl.formatMessage({
              id: "dataGridUI.delete",
              defaultMessage: "Delete",
            })}
          </ButtonComponent>
        );
      },
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  return (
    <div>
      {isLoading ? (
        <Box className={classes.loaderClass}>
          <Box className={classes.loaderText}>
            {intl.formatMessage({
              id: "automationListDataGrid.loading",
              defaultMessage: "Loading Data...",
            })}
          </Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        automationRulesList.length === 0 && (
          <Box className={classes.loaderClass}>
            <Typography variant="h5">
              <b>
                {intl.formatMessage({
                  id: "automationRules.noAutomationRulesFoundText",
                  defaultMessage: "No schedules found",
                })}
              </b>
            </Typography>
          </Box>
        )
      )}
      {automationRulesList && automationRulesList.length > 0 && !isLoading && (
        <DataGrid
          getRowId={(row: AutomationRulesList) => row.jobId ?? ""}
          rows={automationRulesList}
          columns={columns}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: classicTheme.palette.primary.main,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
              fontWeight: "bold",
            },
          }}
        />
      )}
      <AutomationRulesDeleteModal jobId={jobId} name={name} />
    </div>
  );
};

export default AutomationListDataGrid;
