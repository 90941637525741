import {
  ADD_CLIPBOARD_PRODUCT_CODES,
  AddClipBoardProductCodesAction,
  AddClipBoardProductCodesPayload,
  CLEAR_CLIPBOARD,
  ClearClipBoard,
  SET_CLIPBOARD_PRODUCT_CODES,
  SetClipBoardProductCodesAction,
  SetClipBoardProductCodesPayload,
} from "./ClipBoardTypes";

// setclipboard action
export const setClipboardPCodes = (
  payload: SetClipBoardProductCodesPayload,
): SetClipBoardProductCodesAction => {
  return {
    payload,
    type: SET_CLIPBOARD_PRODUCT_CODES,
  };
};

// addclipboard action
export const addClipboardPCodes = (
  payload: AddClipBoardProductCodesPayload,
): AddClipBoardProductCodesAction => {
  return {
    payload,
    type: ADD_CLIPBOARD_PRODUCT_CODES,
  };
};

// clear clipboard action
export const clearClipBoardProductCodes = (): ClearClipBoard => {
  return {
    type: CLEAR_CLIPBOARD,
  };
};
