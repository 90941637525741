import {
  AddGlobalAlertPayload,
  RemoveGlobalAlertPayload,
  GlobalAlertActionsTypes,
} from "./GlobalAlertTypes";

export const addGlobalAlertState = (
  payload: AddGlobalAlertPayload,
): GlobalAlertActionsTypes => ({
  type: "ADD_GLOBAL_ALERT_STATE",
  payload,
});

export const removeGlobalAlertState = (
  payload: RemoveGlobalAlertPayload,
): GlobalAlertActionsTypes => ({
  type: "REMOVE_GLOBAL_ALERT_STATE",
  payload,
});
