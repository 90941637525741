import type { IUserStyles, TCSSProperties } from "./index.d";
import { useContext, useMemo } from "react";

import { CustomThemeContext } from "themeContext";

import { getStyleSheet, getUniqueClassName, muiStylesToCSS } from "./utils";

export const makeStyles = <T extends string>(userStyles: IUserStyles<T>) => {
  const insertedClasses = {};

  return () => {
    const { muiTheme: theme } = useContext(CustomThemeContext);

    const useStyles = useMemo(() => {
      const stylesheet = getStyleSheet();
      const classes = {} as Record<T, string>;

      const _userStyles =
        typeof userStyles === "function" ? userStyles(theme) : userStyles;

      Object.entries(_userStyles).forEach(([key, styles]) => {
        const cssProperties: TCSSProperties =
          typeof styles === "function" ? styles(theme) : styles;

        const css = muiStylesToCSS(cssProperties);
        const className = getUniqueClassName(key, css);

        if (!insertedClasses[className]) {
          const rule = `.${className} { ${css} }`;
          stylesheet.insertRule(rule, stylesheet.cssRules.length);

          insertedClasses[className] = true;
        }

        classes[key] = className;
      });

      return classes;
    }, [theme]);

    return useStyles;
  };
};
