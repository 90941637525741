import React from "react";
import { useIntl } from "react-intl";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import DataGridUI from "../DataGrid/DataGridUI";
import ButtonComponent from "../../../../components/common/ButtonComponent";
import UnifiedSortingRuleModal from "./UnifiedSortingRuleModal/UnifiedSortingRuleModal";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../../../store/modal/ModalActions";
import {
  selectBusinessRulesListFetched,
  selectIsBusinessRulesFetched,
} from "../../../../store/businessRules-list/BusinessRuleslistSelectors";
import { selectIsLoading } from "../../../../store/loader/LoaderSelectors";
import AppState from "../../../../store/AppState";
import { setLoaderState } from "../../../../store/loader/LoaderActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      minHeight: "45px",
      backgroundColor: "#F2F3F4",
      "& .MuiTab-root": {
        padding: 0,
        minHeight: "35px",
      },
    },
    select: {
      marginLeft: "0.5rem",
      Width: "100px",
    },
    selectRule: {
      width: "200px",
    },
    selectAreaItem: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    selectAreaChild: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "75%",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
    dataGrid: {
      width: "80%",
      margin: "30px auto",
    },
  }),
);

const SortingRules = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const businessRulesList = useSelector(selectBusinessRulesListFetched);
  const isBusinessRulesFetched = useSelector(selectIsBusinessRulesFetched);

  const isLoading = useSelector((state: AppState) =>
    selectIsLoading(state, "LIST_BUSINESS_RULES_LOADER"),
  );
  React.useEffect(() => {
    if (
      (businessRulesList && businessRulesList.length) ||
      isBusinessRulesFetched
    ) {
      dispatch(setLoaderState("LIST_BUSINESS_RULES_LOADER", false));
      return;
    }
  }, [dispatch, businessRulesList, isBusinessRulesFetched]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(setModalState("AttributeSortingRuleModal", true));
  };
  return (
    <div>
      <Box>
        <Box className={classes.selectAreaChild}>
          <Box className={classes.selectAreaItem}>
            <ButtonComponent
              color="primary"
              variant="contained"
              padding="10px 15px"
              justifyContent="center"
              onClick={handleClick}
            >
              {intl.formatMessage({
                id: "sortingRules.create",
                defaultMessage: "Create New Rule",
              })}
            </ButtonComponent>
          </Box>
        </Box>
      </Box>
      <Box className={classes.dataGrid}>
        <DataGridUI
          businessRulesList={businessRulesList}
          isLoading={isLoading}
        />
      </Box>
      <UnifiedSortingRuleModal />
    </div>
  );
};

export default SortingRules;
