import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEditSequenceList,
  selectNumberOfPinnedProducts,
  selectSortingRule,
} from "../store/product-list/ProductListSelectors";
import {
  selectAllProductAnalytics,
  selectIsProductAnalyticsFetched,
  selectDateRange,
} from "../store/product/ProductSelectors";
import {
  updateProductListInState,
  updateProductListSequenceMode,
} from "../store/product-list/ProductListActions";
import { SEQUENCE_MODE_TYPE } from "../utils/Constants";

const AnalyticsSorting = () => {
  const dispatch = useDispatch();
  const [sorted, setSorted] = useState(false);
  const products = useSelector(selectEditSequenceList);
  const allAnalytics = useSelector(selectAllProductAnalytics);
  const numberOfPinnedProducts = useSelector(selectNumberOfPinnedProducts);
  const rule = useSelector(selectSortingRule);
  const isAnalyticsFetched = useSelector(selectIsProductAnalyticsFetched);
  const selectedDateRange = useSelector(selectDateRange);

  useEffect(() => {
    setSorted(false);
  }, [rule, selectedDateRange]);

  useEffect(() => {
    if (isAnalyticsFetched && !sorted && rule) {
      const pinnedProducts = products.slice(0, numberOfPinnedProducts);
      const remainingProducts = products.slice(numberOfPinnedProducts);
      // .sort((p1, p2) => {
      //   const p1Analytics = allAnalytics[p1.productId];
      //   const p2Analytics = allAnalytics[p2.productId];
      //   const index1 = p1Analytics.findIndex(
      //     (analytic) => analytic.name === rule,
      //   );
      //   const index2 = p2Analytics.findIndex(
      //     (analytic) => analytic.name === rule,
      //   );
      //   if (index1 < 0 && index2 < 0) return -1;
      //   if (index1 < 0 && index2 >= 0) return 1;
      //   if (index1 >= 0 && index2 < 0) return -1;
      //   const value1 = parseFloat(p1Analytics[index1].value.toString());
      //   const value2 = parseFloat(p2Analytics[index2].value.toString());
      //   if (value1 === value2) return 0;
      //   return value1 < value2 ? 1 : -1;
      // });
      const payload = {
        products: [...pinnedProducts, ...remainingProducts],
        isSaveAction: false,
      };
      dispatch(updateProductListInState(payload));
      dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
      setSorted(true);
    }
  }, [
    products,
    allAnalytics,
    numberOfPinnedProducts,
    rule,
    isAnalyticsFetched,
    sorted,
    dispatch,
  ]);
  return <></>;
};

export default React.memo(AnalyticsSorting);
