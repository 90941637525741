import {
  DELETE_BUSINESS_RULE,
  FETCH_ATTRIBUTES_LIST,
  GET_BUSINESS_RULES_DETAILS_BY_ID,
  LOAD_ATTRIBUTES_LIST,
  LOAD_JOBS_LIST,
  LOAD_AUTOMATION_LIST,
  LOAD_UNIFIED_SORTING_RULES_LIST,
  LoadAutomationRulesListAction,
  SAVE_UNIFIED_BUSINESS_RULES,
  UPDATE_BUSINESS_RULES,
  attributesListPayload,
  loadAttributesListAction,
  loadAttributeslistAction,
  loadJobsListAction,
  loadUnifiedSortingRuleslistAction,
  saveUnifiedBusinessRulesAction,
  saveUnifiedBusinessRulesPayload,
  DELETE_AUTOMATION_RULE,
  AutomationRulesList,
  saveAutomationRuleAction,
  SAVE_AUTOMATION_RULES,
  GET_AUTOMATION_RULES_DETAILS_BY_ID,
  UPDATE_AUTOMATION_RULES,
  FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS,
  RESET_SELECTED_CATEGORIES_DATA,
  RESET_JOBS_LIST,
  SET_SORTING_RULE_TYPE,
  RESET_AUTOMATION_JOB_CREATION_STATE,
  LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST,
  LoadSystemGeneratedSortingRulesListAction,
  RESET_BUSINESS_RULES_DETAILS,
  SET_IS_ANALYTIC,
  DeleteSchedulePayload,
  DeleteScheduleAction,
  RESET_AUTOMATION_JOB,
  attributesPayload,
  RESET_ATTRIBUTES_LOADED_FLAG,
  RESET_ATTRIBUTES_LIST,
  FETCH_ATTRIBUTES,
  SET_SEARCH_VALUE,
  fetchAttributesAction,
  searchValueAction,
  STORE_ALL_ATTRIBUTES,
} from "./BusinessRulesListTypes";

export type BusinessRulesListActionTypes =
  | saveUnifiedBusinessRulesAction
  | loadAttributesListAction;
export const loadUnifiedSortingRulesList =
  (): loadUnifiedSortingRuleslistAction => {
    return {
      type: LOAD_UNIFIED_SORTING_RULES_LIST,
    };
  };
export const loadAttributesList = (): loadAttributeslistAction => {
  return {
    type: LOAD_ATTRIBUTES_LIST,
  };
};
export const saveUnifiedBusinessRules = (
  payload: saveUnifiedBusinessRulesPayload,
): saveUnifiedBusinessRulesAction => {
  return {
    type: SAVE_UNIFIED_BUSINESS_RULES,
    payload,
  };
};

export const loadAttributesListPayload = (
  payload: attributesListPayload,
): loadAttributesListAction => {
  return {
    type: FETCH_ATTRIBUTES_LIST,
    payload,
  };
};

export const getBusinessRulesDetailsById = (payload) => {
  return {
    type: GET_BUSINESS_RULES_DETAILS_BY_ID,
    payload,
  };
};

export const updateUnifiedBusinessRules = (payload) => {
  return {
    type: UPDATE_BUSINESS_RULES,
    payload,
  };
};
export const deleteUnifiedBusinessRuleByRuleId = (ruleId) => {
  return {
    type: DELETE_BUSINESS_RULE,
    payload: ruleId,
  };
};

export const loadJobsList = (payload?: string): loadJobsListAction => {
  return {
    type: LOAD_JOBS_LIST,
    payload,
  };
};
export const loadAutomationRulesList = (): LoadAutomationRulesListAction => {
  return {
    type: LOAD_AUTOMATION_LIST,
  };
};

export const deleteAutomationRuleByJobId = (
  payload: DeleteSchedulePayload,
): DeleteScheduleAction => {
  return {
    type: DELETE_AUTOMATION_RULE,
    payload,
  };
};

export const saveAutomationRule = (
  payload: AutomationRulesList,
): saveAutomationRuleAction => {
  return {
    type: SAVE_AUTOMATION_RULES,
    payload,
  };
};

export const getAutomationRuleByJobId = (payload: string) => {
  return {
    type: GET_AUTOMATION_RULES_DETAILS_BY_ID,
    payload,
  };
};
export const updateAutomationRule = (payload) => {
  return {
    type: UPDATE_AUTOMATION_RULES,
    payload,
  };
};

export const selectedCategoriesId = (payload) => {
  return {
    type: FETCH_CATEGORY_DETAILS_FOR_MULTIPLE_IDS,
    payload,
  };
};
export const resetSelectedCategoriesData = () => {
  return {
    type: RESET_SELECTED_CATEGORIES_DATA,
  };
};
export const resetJobsList = () => {
  return {
    type: RESET_JOBS_LIST,
  };
};

export const setSortingRuleType = (sortRule: string) => {
  return {
    type: SET_SORTING_RULE_TYPE,
    sortRule,
  };
};

export const resetAutomationJobCreationState = () => {
  return {
    type: RESET_AUTOMATION_JOB_CREATION_STATE,
  };
};

export const loadSystemGeneratedRules =
  (): LoadSystemGeneratedSortingRulesListAction => {
    return {
      type: LOAD_SYSTEM_GENERATED_SORTING_RULES_LIST,
    };
  };

export const resetBusinessRulesDetails = () => {
  return {
    type: RESET_BUSINESS_RULES_DETAILS,
  };
};

export const setIsAnalytic = (isAnalytic: boolean) => {
  return {
    type: SET_IS_ANALYTIC,
    isAnalytic,
  };
};

export const resetAutomationJob = () => {
  return {
    type: RESET_AUTOMATION_JOB,
  };
};

export const resetAttributesFlag = () => {
  return {
    type: RESET_ATTRIBUTES_LOADED_FLAG,
  };
};

export const resetAttributesList = () => {
  return {
    type: RESET_ATTRIBUTES_LIST,
  };
};

export const fetchAttributesList = (
  payload: attributesPayload,
): fetchAttributesAction => {
  return {
    type: FETCH_ATTRIBUTES,
    payload,
  };
};

export const setSearchValue = (searchValue: string): searchValueAction => {
  return {
    type: SET_SEARCH_VALUE,
    searchValue,
  };
};

export function storeAllAttributes(payload: attributesListPayload) {
  return {
    type: STORE_ALL_ATTRIBUTES,
    payload,
  };
}
