import AppState from "../AppState";

export const selectOverlay = (state: AppState): string => {
  return state.overlay && state.overlay.overlay
    ? state.overlay.overlay
    : "products";
};

export const selectInventoryAttribute = (state: AppState): string => {
  return state.overlay && state.overlay.inventoryAttribute
    ? state.overlay.inventoryAttribute
    : "";
};

export const selectAttribute = (state: AppState): string => {
  return state.overlay && state.overlay.attribute
    ? state.overlay.attribute
    : "";
};

export const selectIsOverlaySelectedByType = (
  state: AppState,
  type: string,
): boolean => {
  return state.overlay && state.overlay.overlay
    ? state.overlay.overlay === type
    : false;
};
