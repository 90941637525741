export const UPDATE_SELECTED_OVERLAY = "UPDATE_SELECTED_OVERLAY";
export const UPDATE_SELECTED_INVENTORY_ATTRIBUTE =
  "UPDATE_SELECTED_INVENTORY_ATTRIBUTE";
export const UPDATE_SELECTED_ATTRIBUTE = "UPDATE_SELECTED_ATTRIBUTE";

export interface updateSelectedOverlayAction {
  overlay: string;
  type: typeof UPDATE_SELECTED_OVERLAY;
}

export interface updateSelectedInventoryAttributeAction {
  invAttribute: string;
  type: typeof UPDATE_SELECTED_INVENTORY_ATTRIBUTE;
}

export interface updateSelectedAttributeAction {
  attribute: string;
  type: typeof UPDATE_SELECTED_ATTRIBUTE;
}

export type OverlayActions =
  | updateSelectedOverlayAction
  | updateSelectedInventoryAttributeAction
  | updateSelectedAttributeAction;
