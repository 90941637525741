export const storeListQuery = `query StoreList {
  Store {
    storeId
  }
  StoreToCurrency {
    currencyCode
    storeId
  }
  StoreToFulfillmentCenter {
    fulfillmentCenterId
    storeId
  }
  StoreToPriceList {
    priceListId
    storeId
    typeCode
  }
  StoreToLocale {
    localeCode
    storeId
    currencyCode
  }
  StoreDefaults {
    catalogId
    localeCode
    storeId
    currencyCode
  }
}`;

export const fetchAllAttributesQueryGenerator = (
  storeId: string | undefined,
) => `subscription FetchAllAttributes${storeId ? `($storeId: String!)` : ``} {
  Attribute(where: {isEnum: {_eq: true}${storeId ? `, toStores: {storeId: {_eq: $storeId}}` : ``}}) {
    isMultiValue
    attributeId
    code
    descriptions {
      displayName
      localeCode
    }
    values(where: {productId: {_is_null: true}}) {
      attributeValueId
      code
      descriptions {
        displayValue
        localeCode
      }
    }
    isStoreSpecific
    isLocalized
  }
}`;
