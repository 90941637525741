import { uuid } from "uuidv4";
import { GlobalAlertActionsTypes, AlertProps } from "./GlobalAlertTypes";

export interface AlertOptions extends AlertProps {
  id: string;
}
export interface GlobalAlertState {
  alertsProps: AlertOptions[];
}

const initialState: GlobalAlertState = {
  alertsProps: [],
};

export const globalAlertReducer = (
  state = initialState,
  action: GlobalAlertActionsTypes,
): GlobalAlertState => {
  switch (action.type) {
    case "ADD_GLOBAL_ALERT_STATE":
      const alertOptions = action.payload.alertsProps.reduce(
        (acc, alert) => {
          alert.variant = alert?.variant ?? "standard";
          acc.push({
            ...alert,
            id: uuid(),
          });
          return acc;
        },
        [...state.alertsProps],
      );

      return {
        alertsProps: alertOptions,
      };

    case "REMOVE_GLOBAL_ALERT_STATE":
      const filteredState = state.alertsProps.filter(
        (alert) => alert.id !== action.payload.id,
      );

      return {
        ...state,
        alertsProps: filteredState,
      };

    default:
      return state;
  }
};
