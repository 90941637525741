import type { Components } from "@mui/material";
import { BLACK } from "../palette";

export const MuiAppBar: Components["MuiAppBar"] = {
  styleOverrides: {
    colorPrimary: {
      backgroundColor: "#123456",
    },
  },
  variants: [
    {
      props: { color: "primary" },
      style: { backgroundColor: "#ccc", color: BLACK },
    },
  ],
};
