import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import { Typography } from "@mui/material";

interface BreadcrumbsComponentProps {
  breadcrumbPath?: string[];
  data?: { [key: string]: string };
  children?: JSX.Element;
}

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = (props) => {
  const { breadcrumbPath, data, children } = props;

  switch (true) {
    default:
      if (breadcrumbPath)
        return (
          <>
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbPath.map((category, i) => (
                <Typography
                  variant={
                    i === breadcrumbPath.length - 1
                      ? "highlightedText"
                      : "body2"
                  }
                  key={category + i}
                >
                  {category}
                </Typography>
              ))}
            </Breadcrumbs>
          </>
        );
      if (data)
        return (
          <Breadcrumbs aria-label="breadcrumb">
            {Object.values(data).map((e, i) => {
              return (
                <Typography
                  variant={
                    i === Object.values(data).length - 1
                      ? "highlightedText"
                      : "body2"
                  }
                  key={e + i}
                >
                  {e}
                </Typography>
              );
            })}
          </Breadcrumbs>
        );
      if (children)
        return <Breadcrumbs aria-label="breadcrumb">{children}</Breadcrumbs>;
      return <></>;
  }
};

export default BreadcrumbsComponent;
