import {
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../store/AppState";
import { selectProductFetched } from "../../store/product/ProductSelectors";
import { selectModalVariable } from "../../store/modal/ModalSelectors";
import { VariationManagementModalId } from "./VariationManagement";
import { useEffect, useState } from "react";
import {
  selectVariationAttributes,
  selectVariationGroupDetails,
  selectIsVariationDetailsFetched,
  selectIsVariationGroupDeleted,
  selectIsVariationGroupUpdated,
  selectVariationAssignedAttributeValues,
  selectVariationUnAssignedAttributeValues,
} from "../../store/variation-management/VariationManagementSelector";
import {
  deleteVariationGroup,
  getVariationGroupDetails,
  getVariationGroupId,
  resetVariationAddState,
  resetVariationDeleteState,
  resetVariationDetailsByGroupId,
  updateVariationGroup,
  addVariationGroup,
} from "../../store/variation-management/VariationManagementActions";
import {
  componentClasses,
  StyledContainer,
  StyledDiv,
} from "./VariationManagementModalStyles";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "../../store/category/CategorySelectors";
import DropdownMenu from "../common/DropdownMenu";
import ButtonComponent from "../common/ButtonComponent";

interface Props {
  isVariationFormOpen: boolean;
  isEditForm: boolean;
  isDeleteAction: boolean;
  isAddForm: boolean;
  isCancelAction: boolean;
  setIsDeleteAction: (isDelete: boolean) => void;
  setIsEditForm: (isEdit: boolean) => void;
  setIsVariationFormOpen: (isOpen: boolean) => void;
  setIsCancelAction: (isCancel: boolean) => void;
  setIsAddForm: (isOpen: boolean) => void;
  setVariationGroupId: (groupId: string) => void;
  variationGroupId: string;
}
type OptionType = "Add" | "Edit" | null;

export const VariationForm: React.FC<Props> = ({
  setIsVariationFormOpen,
  setVariationGroupId,
  setIsDeleteAction,
  setIsAddForm,
  setIsEditForm,
  isAddForm,
  isVariationFormOpen,
  variationGroupId,
  isCancelAction,
  setIsCancelAction,
  isEditForm,
  isDeleteAction,
}) => {
  const dispatch = useDispatch();
  const categoryId = useSelector(selectCurrentCategory) ?? "";
  const topCategories = useSelector(selectTopCategories);
  const childCategories = useSelector(selectChildCategories);
  const assignedAttributeValues = useSelector(
    selectVariationAssignedAttributeValues,
  );
  const unAssignedAttributeValues = useSelector(
    selectVariationUnAssignedAttributeValues,
  );
  const findTopCategory = topCategories.find(
    (e) => e.categoryId === categoryId,
  );
  const filteredChildCategory = Object.entries(childCategories).find((e) =>
    e[1].childCategories.find((el) => el.categoryId === categoryId),
  );
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, VariationManagementModalId, "variationBaseId"),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const isVariationDetailsFetched = useSelector((state: AppState) =>
    selectIsVariationDetailsFetched(state),
  );
  const vGroupDetails = useSelector((state: AppState) =>
    selectVariationGroupDetails(state),
  );
  const isVariationGroupUpdated = useSelector((state: AppState) =>
    selectIsVariationGroupUpdated(state),
  );
  const isVariationGroupDeleted = useSelector((state: AppState) =>
    selectIsVariationGroupDeleted(state),
  );
  const vAttributes = useSelector((state: AppState) =>
    selectVariationAttributes(state),
  );
  const [progress, setProgress] = useState(false);
  const [isAddError, setIsAddError] = useState(false);
  const [addOption, setAddOption] = useState<OptionType>(null);

  const baseProductId = productId || fetchedProduct?.code;
  const [selectedAttributes, setSelectedAttributes] = useState<{
    [key: string]: string;
  }>({});
  const [initialAttributes, setInitialAttributes] = useState<{
    [key: string]: string;
  }>({});
  const isModified = Object.values(selectedAttributes).some(
    (value) => value !== "-None-",
  );
  const condition = variationGroupId !== "";
  const handleDeleteVariationGroup = () => {
    setIsDeleteAction(true);
    setIsAddForm(false);
    setIsEditForm(false);
    setIsVariationFormOpen(false);
    setIsCancelAction(true);
    setVariationGroupId("");
    setSelectedAttributes({});
    setInitialAttributes({});
    const payload = {
      baseProductId,
      variationGroupId,
      parentId: !findTopCategory ? filteredChildCategory?.[0] : null,
    };
    dispatch(deleteVariationGroup(payload));
    dispatch(resetVariationDetailsByGroupId());
    const defaultAttrs: { [key: string]: string } = {};
    vAttributes.variationAttributes.forEach((attr) => {
      defaultAttrs[attr.attributeId] = "-None-";
    });
    setSelectedAttributes(defaultAttrs);
  };

  const handleCancelForm = () => {
    dispatch(resetVariationDetailsByGroupId());
    const defaultAttrs: { [key: string]: string } = {};
    vAttributes.variationAttributes.forEach((attr) => {
      defaultAttrs[attr.attributeId] = "-None-";
    });
    setSelectedAttributes(defaultAttrs);
    setVariationGroupId("");
    setIsDeleteAction(false);
    setIsAddForm(false);
    setIsEditForm(false);
    setIsVariationFormOpen(false);
    setIsAddError(false);
  };

  const isEditButtonEnabled = () => {
    return Object.keys(selectedAttributes).some((attributeId) => {
      const selectedValue = selectedAttributes[attributeId];
      const actualValue = initialAttributes[attributeId];
      const isModifiedEdit = Object.values(selectedAttributes).every(
        (value) => value === "-None-",
      );
      return selectedValue !== actualValue && !isModifiedEdit;
    });
  };

  const handleAttributeValueChange = (attributeId: string, value: string) => {
    setSelectedAttributes({
      ...selectedAttributes,
      [attributeId]: value,
    });
  };

  const handleGroupIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariationGroupId(event.target.value as string);
  };
  const handleAddVariationGroup = (option) => {
    setProgress(true);
    setIsVariationFormOpen(true);
    setAddOption(option); // Store the option
    setIsDeleteAction(false);
    const filteredAttributes = Object.fromEntries(
      Object.entries(selectedAttributes).map(([key, value]) => [
        key,
        value === "-None-" ? null : value,
      ]),
    );

    const payload = {
      baseProductId,
      variationGroupId,
      variationValues: filteredAttributes,
      isUpdate: option === "Update" ? true : false,
    };
    if (option === "Update") {
      dispatch(updateVariationGroup(payload)); // Wait for dispatch completion
    } else {
      dispatch(addVariationGroup(payload));
    }
  };

  useEffect(() => {
    if (isVariationDetailsFetched) {
      const allAttributes: { [key: string]: string } = {};
      // Populate initialAttributes with all variation attributes
      vAttributes.variationAttributes.forEach((attr) => {
        allAttributes[attr.attributeId] = "-None-";
      });

      if (vGroupDetails.variationValues) {
        // Update initialAttributes with values from vGroupDetails.variationValues
        Object.keys(vGroupDetails.variationValues).forEach((key) => {
          allAttributes[key] = vGroupDetails.variationValues[key];
        });
      }
      setSelectedAttributes(allAttributes);
      setInitialAttributes(allAttributes);
    }
  }, [isVariationDetailsFetched, vGroupDetails, vAttributes]);

  useEffect(() => {
    if (isVariationGroupDeleted) {
      dispatch(resetVariationDeleteState());
      dispatch(getVariationGroupId({ baseProductId }));
      setIsDeleteAction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationGroupDeleted]);

  useEffect(() => {
    if (isVariationGroupUpdated && !isAddError) {
      setProgress(false);
      dispatch(resetVariationAddState());
      dispatch(getVariationGroupId({ baseProductId }));
      dispatch(getVariationGroupDetails({ baseProductId, variationGroupId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationGroupUpdated, isAddError]);

  useEffect(() => {
    if (addOption === "Add" && isVariationGroupUpdated !== null) {
      if (isVariationGroupUpdated === false) {
        setIsAddError(true);
        setIsVariationFormOpen(false);
        setProgress(false);
      }
      setIsAddError(false);

      setAddOption(null);
      // Reset the option after handling
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVariationGroupUpdated, addOption]); // Run when these values change

  useEffect(() => {
    if (isAddError === true) {
      setIsAddForm(true);
      setIsEditForm(false);
    } else {
      setIsAddForm(false);
      setIsEditForm(true);
      setVariationGroupId("");
      handleCancelForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddError]);

  return (
    <div>
      {!isVariationGroupDeleted && isDeleteAction && (
        <StyledDiv className={componentClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      {!isVariationGroupUpdated && progress && !isDeleteAction && (
        <StyledDiv className={componentClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      {(isEditForm || isAddForm) && !isCancelAction && (
        <StyledContainer className={componentClasses.formContainer}>
          <FormControl fullWidth margin="normal">
            <InputLabel required htmlFor="variationGroupId">
              ID
            </InputLabel>
            <Input
              id="variationGroupId"
              disabled={isVariationFormOpen || isEditForm}
              value={variationGroupId}
              onChange={handleGroupIdChange}
            />
          </FormControl>

          {!isVariationDetailsFetched && isEditForm && (
            <StyledDiv className={componentClasses.overlay}>
              <CircularProgress />
            </StyledDiv>
          )}
          {vAttributes?.variationAttributes &&
            vAttributes.variationAttributes.map((attribute) => (
              <FormControl
                fullWidth
                margin="normal"
                key={attribute.attributeId}
              >
                <InputLabel htmlFor={attribute.attributeId}>
                  {attribute.name}
                </InputLabel>
                <DropdownMenu
                  menuId="VariationForm"
                  selectedAttributes={selectedAttributes}
                  attribute={attribute}
                  assignedAttributeValues={assignedAttributeValues}
                  unAssignedAttributeValues={unAssignedAttributeValues}
                  handleAttributeValueChange={handleAttributeValueChange}
                />
              </FormControl>
            ))}
          <StyledDiv className={componentClasses.editForm}>
            {(isEditForm || isVariationFormOpen) && !isAddError && (
              <ButtonComponent
                color="secondaryButtonColorCTABlue"
                variant="outlined"
                justifyContent="start"
                padding="10px 0px"
                onClick={handleDeleteVariationGroup}
              >
                Delete
              </ButtonComponent>
            )}
            <ButtonComponent
              color="secondaryButtonColorCTABlue"
              variant="outlined"
              justifyContent="start"
              padding="10px 0px"
              onClick={handleCancelForm}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              color="primary"
              variant="contained"
              justifyContent="center"
              padding="10px 0px"
              onClick={() =>
                handleAddVariationGroup(isEditForm ? "Update" : "Add")
              }
              disabled={
                isAddForm ? !isModified || !condition : !isEditButtonEnabled()
              }
            >
              {isEditForm && !isAddError ? "Update" : "Add"}
            </ButtonComponent>
          </StyledDiv>
        </StyledContainer>
      )}
      {!isAddForm && !isEditForm && (
        <StyledDiv className={componentClasses.addVariationBtnDiv}>
          <ButtonComponent
            color="tertiary"
            variant="contained"
            onClick={() => {
              setIsAddForm(true);
              setIsCancelAction(false);
            }}
          >
            Add Variation Group
          </ButtonComponent>
        </StyledDiv>
      )}
    </div>
  );
};
