export const updateAttributeValueDescriptionQuery = `mutation UpdateAttributeValueDescription($attributeValueId: String!, $localeCode: String!, $displayValue: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_AttributeValueDescription_by_pk(pk_columns: {attributeValueId: $attributeValueId, localeCode: $localeCode}, _set: {displayValue: $displayValue}) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateAttributeValueCodeQuery = `mutation UpdateAttributeValueCode($attributeValueId: String!, $displayValue: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_AttributeValue_by_pk(pk_columns: {attributeValueId: $attributeValueId}, _set: {code: $displayValue}) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const addAttributeValueQuery = `mutation AddAttributeValue($attributeValueObject: [AttributeValue_insert_input!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_AttributeValue(objects: $attributeValueObject, on_conflict: {constraint: AttributeValue_pkey, update_columns: code}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const getAttributeByIdQuery = `query GetAttributeById($attributeId: String!) {
  Attribute(where: {attributeId: {_eq: $attributeId}}) {
    isMultiValue
    attributeId
    code
    isEnum
    descriptions {
      displayName
      localeCode
    }
    values(where: {productId: {_is_null: true}}) {
      attributeValueId
      code
      descriptions {
        displayValue
        localeCode
      }
    }
    isStoreSpecific
    isLocalized
  }
}`;
