import { takeEvery, put, call, select } from "redux-saga/effects";
import { callApi } from "../../utils/SagaUtils";
import {
  LoadStoreListAction,
  LOAD_STORE_LIST,
  FETCH_STORE_LIST,
  LoadAllAttributesAction,
  LOAD_ALL_ATTRIBUTES,
  FETCH_ALL_ATTRIBUTES,
} from "./StoreListTypes";
import { fetchAllAttributesQueryGenerator } from "./StoreListSchema";
import { getData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { CLOSE_SUBSCRIPTION, CREATE_SUBSCRIPTION } from "../Subscription";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../AppState";

export function* watchLoadStoreList() {
  yield takeEvery(LOAD_STORE_LIST, loadStoreList);
}

export function* watchLoadAllAttributes() {
  yield takeEvery(LOAD_ALL_ATTRIBUTES, loadAllAttributes);
}

function* loadStoreList(action: LoadStoreListAction) {
  const actionType = FETCH_STORE_LIST;
  const constName = Object.keys({ FETCH_STORE_LIST })[0].toString();
  const headersObj = {
    "x-locale-code": "default",
    "x-currency-code": "USD",
    "x-store-id": "my-store",
    "x-catalog-id": "my-catalog",
  };
  try {
    const endpoint = acquireEndpoint(constName);
    yield call(callApi, actionType, getData, null, endpoint, headersObj);
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_STORE_LIST.FAILURE, message: e.message });
  }
}

function* loadAllAttributes(action: LoadAllAttributesAction) {
  try {
    const isGraphQLStoreRelationshipEnabled = yield select((state: AppState) =>
      selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
    );
    const storeId =
      isGraphQLStoreRelationshipEnabled === "true"
        ? action.payload.storeId
        : undefined;

    const payload = {
      query: fetchAllAttributesQueryGenerator(storeId),
      variables: {
        storeId,
      },
    };
    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_ALL_ATTRIBUTES.REQUEST,
      },
    });
    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: FETCH_ALL_ATTRIBUTES.REQUEST,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield { type: FETCH_ALL_ATTRIBUTES.FAILURE, message: e.message };
  }
}
