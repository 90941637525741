import { ProductSequenceParams } from "./ProductListTypes";

export const productsByCategoryQuery = `subscription ProductList($catalogId: String, $categoryId: String, $localeId: String, $storeId: String) {
  CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, product: {type: {isBrowsable: {_eq: true}}}}, order_by: {sequence: asc}) {
    sequence
    productId
    product {
      descriptions(where: {localeCode: {_eq: $localeId}}) {
        isPublished
      }
      isPublished
      overrides(where: {storeId: {_eq: $storeId}}) {
        isPublished
      }
    }
  }
}`;

export const updateCategoryProductSequenceQueryGenerator = (
  productSequences: ProductSequenceParams[],
) => `mutation UpdateProductSequence($catalogId: String, $categoryId: String, $syncLogInserts: [SyncLog_insert_input!]!) {
  ${productSequences.map(
    (
      productSequence,
      index,
    ) => `product${index} : update_CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, productId: {_eq: "${productSequence.productId}"}}, _set: {sequence: ${productSequence.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateCategoryPinnedProductsQueryGenerator = (
  productIds: string[],
) => `mutation UpdatePinnedProducts($storeId: String, $catalogId: String, $categoryId: String, $syncLogInserts: [SyncLog_insert_input!]!) {
  
  delete_PinnedProduct(where: {storeId: {_eq: $storeId}, catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}) {
    affected_rows
  }

  insert_PinnedProduct(objects: [
    ${productIds.map((productId, index) => `{storeId: $storeId, catalogId: $catalogId, categoryId: $categoryId, position: ${index}, productId: "${productId}"}`)}]
    ) {
    affected_rows
  }

  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const pinnedProductsQuery = `subscription PinnedProducts($catalogId: String, $categoryId: String, $storeId: String) {
  PinnedProduct(where: {storeId: {_eq: $storeId}, catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}, order_by: {position: asc}) {
    productId
  }
}`;
