import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactGA from "react-ga";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Zoom from "@material-ui/core/Zoom";

import ItemManagementTablePagination from "../item-management/ItemManagementTablePagination";
import { ItemManagementSFCCData } from "../../store/product/ProductTypes";
import AppState from "../../store/AppState";
import {
  updateProductPublishByDefaultState,
  updateProductPublishStateByStore,
  updateSingleProductDeleteState,
} from "../../store/product/ProductActions";
import { selectModalVariable } from "../../store/modal/ModalSelectors";
import ConfirmationActionBox from "./ConfirmationActionBox";
import ItemManagementToolbar from "./ItemManagementToolbar";
import { Order } from "../../utils/SortTableUtils";
import { selectItemManagementSFCCDataByProductId } from "../../store/product/ProductSelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import TableComponent from "../common/Table";
import CheckboxComponent from "../common/Checkbox";
import DropdownMenu from "../common/DropdownMenu";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    container: {
      maxHeight: "50vh",
    },
    table: {
      minWidth: 750,
    },
    toolBar: {
      backgroundColor: theme.palette.grey[200],
      minHeight: "48px",
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      marginRight: 0,
      marginLeft: "auto !important",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    formControl: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      alignItems: "baseline",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "&  .MuiInput-formControl": {
        marginTop: 0,
      },
      "& label": {
        marginRight: "7px",
      },
    },
    confirmMsg: {
      backgroundColor: theme.palette.grey[200],
      paddingRight: theme.spacing(2),
      "& span": {
        fontSize: "16px",
      },
    },
    selectInput: {
      width: "110px",
      "& .MuiSelect-selectMenu": {
        textAlign: "left",
      },
    },
  }),
);

interface ItemManagementTableProps {
  parentId: string;
}

export const ItemManagementSFCCTableId = "ItemManagementSFCCTable";

export default function ItemManagementSFCCTable(
  props: ItemManagementTableProps,
) {
  const { parentId } = props;
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState<ItemManagementSFCCData[]>([]);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [useSearch, setUseSearch] = useState(false);

  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const items = useSelector((state: AppState) =>
    selectItemManagementSFCCDataByProductId(state, parentId, defaultLocaleId),
  );

  const successDeleteId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "successDeleteId"),
  );
  const successCheckId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "successCheckId"),
  );
  const successSelectId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "successSelectId"),
  );

  const loadingDeleteId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "loadingDeleteId"),
  );

  const loadingCheckId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "loadingCheckId"),
  );
  const loadingSelectId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "loadingSelectId"),
  );

  const failureCheckId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "failureCheckId"),
  );
  const failureSelectId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "failureSelectId"),
  );
  const failureDeleteId = useSelector((state: AppState) =>
    selectModalVariable(state, ItemManagementSFCCTableId, "failureDeleteId"),
  );

  const headerLabels = useMemo(
    () => (items[0] ? Object.keys(items[0]) : []),
    [items],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setRows(items);
  }, [items]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      ReactGA.event({
        category: "Item Management",
        action: `Sort ${property} In ${isAsc ? "desc" : "asc"} Order Action`,
      });
    },
    [order, orderBy],
  );

  const publishByDefaultChangeHandler = (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => {
    const index = rows.findIndex((row) => row.SKU === SKU);
    const prevPublishStatus = rows[index][key];

    dispatch(
      updateProductPublishByDefaultState({
        parentId,
        childId: SKU,
        isPublishedByDefault: !prevPublishStatus,
      }),
    );
  };

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length) {
      let newRows = [...items];
      newRows = newRows.filter((data) => {
        return Object.keys(data).some((key) => {
          if (typeof data[key] === "string") {
            const value = data[key] as string;
            return value
              .toLowerCase()
              .includes(event.target.value.toLowerCase());
          } else {
            return false;
          }
        });
      });
      setRows(newRows);
      setPage(0);
      setUseSearch(true);
      if (!useSearch) {
        ReactGA.event({
          category: "Item Management",
          action: "Search Action",
        });
      }
    } else {
      setRows(items);
      setUseSearch(false);
    }
  };

  const publishedStoreChangeHandler = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ) => {
    interface Options {
      "Use Default": null;
      Publish: boolean;
      Unpublish: boolean;
    }

    const options: Options = {
      "Use Default": null,
      Publish: true,
      Unpublish: false,
    };
    const productId = event.target.name ?? "";
    const value = event.target.value as keyof Options;

    dispatch(
      updateProductPublishStateByStore({
        childId: productId,
        parentId,
        storeId: selectedStoreId,
        isPublishedByStore: options[value],
      }),
    );
  };

  const actionHandler = (event: React.MouseEvent, sku: string) => {
    setDeleteId(sku);
  };

  const deleteHandler = useCallback(
    (sku: string) => {
      setDeleteId(null);

      dispatch(
        updateSingleProductDeleteState({
          childId: sku,
          parentId,
          isDeleted: true,
        }),
      );
    },
    [dispatch, parentId],
  );

  const generatePublishedDefaultColumn = (
    key: string,
    data: ItemManagementSFCCData,
  ) => {
    switch (data["SKU"]) {
      case loadingCheckId:
        return <CircularProgress thickness={2} color="secondary" />;

      case successCheckId:
        return (
          <Zoom in={true}>
            <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
          </Zoom>
        );

      case failureCheckId:
        return (
          <Zoom in={true}>
            <Icons iconId="AlertIcon" type="active" />
          </Zoom>
        );

      default:
        return (
          <CheckboxComponent
            checkboxId="ItemManagementTable"
            checked={data["published"]}
            handleCheck={publishByDefaultChangeHandler}
            handleParam={[data["SKU"], key]}
          />
        );
    }
  };

  const generateStorePublishColumn = (data: ItemManagementSFCCData) => {
    switch (data["SKU"]) {
      case loadingSelectId:
        return <CircularProgress thickness={2} color="secondary" />;

      case successSelectId:
        return (
          <Zoom in={true}>
            <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
          </Zoom>
        );

      case failureSelectId:
        return (
          <Zoom in={true}>
            <Icons iconId="AlertIcon" type="active" />
          </Zoom>
        );

      default:
        return (
          <DropdownMenu
            menuId="MultipleStoreModal"
            classes={classes}
            changeHandler={publishedStoreChangeHandler}
            data={data}
          />
        );
    }
  };
  const generateColumns = (key: string, data: ItemManagementSFCCData) => {
    switch (key) {
      case "published":
        return generatePublishedDefaultColumn(key, data);
      case "publishedStore":
        return generateStorePublishColumn(data);
      case "action":
        return (
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={(e) => actionHandler(e, data["SKU"])}
          >
            <Icons iconId="TrashIcon" />
          </IconButton>
        );
      default:
        return data[key];
    }
  };
  const generateRows = (data: ItemManagementSFCCData) => {
    switch (data["SKU"]) {
      case deleteId:
        return (
          <TableRow key={data["SKU"]} style={{ height: "46px" }}>
            <TableCell
              colSpan={12}
              padding="none"
              align="right"
              className={classes.confirmMsg}
            >
              <ConfirmationActionBox
                onDone={deleteHandler}
                OnClose={() => setDeleteId(null)}
                id={data["SKU"]}
              />
            </TableCell>
          </TableRow>
        );
      case loadingDeleteId:
        return (
          <TableRow key={data["SKU"]} style={{ height: "46px" }}>
            <TableCell
              colSpan={12}
              padding="none"
              align="center"
              className={classes.confirmMsg}
            >
              <CircularProgress thickness={2} color="secondary" />
            </TableCell>
          </TableRow>
        );
      case successDeleteId:
        return (
          <TableRow key={data["SKU"]} style={{ height: "46px" }}>
            <TableCell
              colSpan={12}
              padding="checkbox"
              align="center"
              className={classes.confirmMsg}
            >
              <Zoom in={true}>
                <CheckCircleIcon
                  fontSize="large"
                  style={{ color: green[500] }}
                />
              </Zoom>
            </TableCell>
          </TableRow>
        );
      case failureDeleteId:
        return (
          <TableRow key={data["SKU"]} style={{ height: "46px" }}>
            <TableCell
              colSpan={12}
              padding="checkbox"
              align="center"
              className={classes.confirmMsg}
            >
              <Zoom in={true}>
                <Icons iconId="AlertIcon" type="active" />
              </Zoom>
            </TableCell>
          </TableRow>
        );
      default:
        return (
          <TableRow
            hover
            tabIndex={-1}
            key={data["SKU"]}
            style={{ height: "46px" }}
          >
            {Object.keys(rows[0]).map((key: string) => (
              <React.Fragment key={key}>
                <TableCell align={"center"} padding="none">
                  {generateColumns(key, data)}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        );
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ItemManagementToolbar
          onSearch={searchHandler}
          onSelectedStoreId={setSelectedStoreId}
          selectedStoreId={selectedStoreId}
        />
        <TableComponent
          tableId="ItemManagementSFCCTable"
          classes={classes}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          headerLabels={headerLabels}
          rowsSFCC={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          generateRows={generateRows}
        />
        <ItemManagementTablePagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </div>
  );
}
