import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import AppState from "../../store/AppState";
import {
  selectStoreInfoById,
  selectCurrentStoreId,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import { makeStyles } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { setCurrentLocale } from "../../store/store-list/StoreListActions";
import { useQuery } from "../../hooks/useQueryParams";
import { Typography } from "@mui/material";
import { selectCallerAccountId } from "../../store/app-config/AppConfigSelectors";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 1),
  },
}));

const LanguageSelection = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const localeCodeQuery = query.get("localeCode") ?? "";
  const catalogIdQueryStr =
    catalogIdQuery !== "" ? `&catalogId=${catalogIdQuery}` : "";
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const categoryPathQueryStr =
    categoryPathQuery !== "" ? `&categoryPath=${categoryPathQuery}` : "";

  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const storeInfo = useSelector((state: AppState) =>
    selectStoreInfoById(state, currentStoreId),
  );
  const currentLocaleId = useSelector(selectCurrentLocale);
  const userAccountId = useSelector(selectCallerAccountId);

  const handleLocaleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;
    dispatch(setCurrentLocale(changeEvent.target.value));
    if (changeEvent.target.value !== "") {
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&localeCode=${changeEvent.target.value}${catalogIdQueryStr}${categoryPathQueryStr}`,
      );
    } else {
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${catalogIdQueryStr}${categoryPathQueryStr}`,
      );
    }
  };

  useEffect(() => {
    if (
      currentStoreId !== "" &&
      storeInfo &&
      storeInfo.localeCodes.find((e) => e.localeCode === localeCodeQuery)
    ) {
      if (currentLocaleId !== localeCodeQuery) {
        dispatch(setCurrentLocale(localeCodeQuery));
      }
    } else {
      if (currentLocaleId !== "") {
        dispatch(setCurrentLocale(""));
      }
    }
  }, [localeCodeQuery, storeInfo, currentLocaleId, currentStoreId, dispatch]);

  useEffect(() => {
    if (
      storeIdQuery !== "" &&
      storeInfo &&
      storeInfo.localeCodes.length > 0 &&
      !storeInfo.localeCodes.find((e) => e.localeCode === localeCodeQuery)
    ) {
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${catalogIdQueryStr}${categoryPathQueryStr}`,
      );
    }
  }, [
    categoryPathQueryStr,
    catalogIdQueryStr,
    localeCodeQuery,
    storeInfo,
    storeIdQuery,
    history,
    url,
    userAccountId,
  ]);

  if (!storeInfo || (storeInfo && storeInfo.localeCodes.length === 0))
    return <React.Fragment />;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="locale-list-label">
        <Typography variant="sidebarTitle">
          {intl.formatMessage({
            id: "languageSelection.label",
            defaultMessage: "Locale Selection",
          })}
        </Typography>
      </InputLabel>
      <DropdownMenu
        menuId="LanguageSelection"
        value={currentLocaleId}
        changeHandler={handleLocaleChange}
        storeInfo={storeInfo}
      />
    </FormControl>
  );
};

export default React.memo(LanguageSelection);
