import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../store/AppState";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import { setModalState } from "../../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { Box, Typography } from "@material-ui/core";
import CustomDialog from "../../common/DialogContainer";
import { styled } from "@mui/material";

const styleClasses = {
  listParent: "listParent",
  listChild: "listChild",
  label: "label",
};
const StyledUl = styled("ul")(({ theme }) => ({
  [`&.${styleClasses.listParent}`]: {
    //display: "table",
    listStyle: "none",
    padding: 0,
  },
}));

const StyledLi = styled("li")(({ theme }) => ({
  [`&.${styleClasses.listChild}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    borderBottom: "1px solid #E8E8E8",
    fontSize: 16,
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  [`&.${styleClasses.label}`]: {
    fontWeight: 600,
  },
}));

type JobDetails = {
  startTime: string;
  endTime: string;
  description?: string;
};
interface Props {
  data: JobDetails;
}
const JobListDetailsModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "JobListDetails"),
  );

  const jobListDetailsModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "jobListDetailsModal.header",
          defaultMessage: "Job Details",
        })}
      </Typography>
    );
  };

  const jobListDetailsModalContent = () => {
    return (
      <>
        <Box>
          <StyledUl className={styleClasses.listParent}>
            <StyledLi className={styleClasses.listChild}>
              <StyledLabel className={styleClasses.label}>
                {intl.formatMessage({
                  id: "jobListDetailsModal.description",
                  defaultMessage: "Description",
                })}
              </StyledLabel>
              <span>
                {props.data.description !== ""
                  ? props.data.description
                  : intl.formatMessage({
                      id: "jobListDetailsModal.notApplicable",
                      defaultMessage: "NA",
                    })}
              </span>
            </StyledLi>
            <StyledLi className={styleClasses.listChild}>
              <StyledLabel className={styleClasses.label}>
                {intl.formatMessage({
                  id: "jobListDetailsModal.actualStartTime",
                  defaultMessage: "Start Time",
                })}
              </StyledLabel>
              <span>{props.data.startTime}</span>
            </StyledLi>
            <StyledLi className={styleClasses.listChild}>
              <StyledLabel className={styleClasses.label}>
                {intl.formatMessage({
                  id: "jobListDetailsModal.endTime",
                  defaultMessage: "End Time",
                })}
              </StyledLabel>
              <span>
                {props.data.endTime !== ""
                  ? props.data.endTime
                  : intl.formatMessage({
                      id: "jobListDetailsModal.notApplicable",
                      defaultMessage: "NA",
                    })}
              </span>
            </StyledLi>
          </StyledUl>
        </Box>
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => dispatch(setModalState("JobListDetails", false))}
      title={jobListDetailsModalTitle()}
      fullWidth
      maxWidth={"sm"}
    >
      {jobListDetailsModalContent()}
    </CustomDialog>
  );
};

export default JobListDetailsModal;
