import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import ButtonComponent from "components/common/ButtonComponent";

interface IPrivacyPolicyContentProps {
  open: boolean;
  onClose: () => void;
}

export function PrivacyPolicyContent(props: IPrivacyPolicyContentProps) {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      keepMounted
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
          Effective date: May 20, 2024
        </Typography>
        <Box py={4}>
          <List component="ol">
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="1. Introduction"
                secondary={
                  <>
                    Welcome to Smart Merchandiser. We are committed to
                    protecting your personal information and your right to
                    privacy. If you have any questions or concerns about our
                    policy, or our practices with regards to your personal
                    information, please contact us at{" "}
                    <Link href="mailto:support@smartmerchandiser.com">
                      support@smartmerchandiser.com
                    </Link>
                    .
                  </>
                }
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="2. What Information Do We Collect?"
                secondary={
                  <>
                    We collect personal information that you voluntarily provide
                    to us when registering on the Site. The personal information
                    that we collect depends on the context of your interactions
                    with us and the Site, the choices you make, and the products
                    and features you use. The personal information we collect
                    can include the following:
                    <ul>
                      <li>Name and Contact</li>
                    </ul>
                  </>
                }
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="3. How Do We Use Your Information?"
                secondary={
                  <>
                    We use personal information collected via our Site for a
                    variety of business purposes described below:
                    <ul>
                      <li>To facilitate account creation and logon process</li>
                      <li>To send administrative information to you</li>
                    </ul>
                  </>
                }
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="4. Will Your Information Be Shared With Anyone?"
                secondary="We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="5. Do We Use Cookies and Other Tracking Technologies?"
                secondary="We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="6. What Is Our Stance on Third-party Websites?"
                secondary="Our Site does not contain advertisements from third parties that are not affiliated with us and which may link to other 
              websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="7. How Long Do We Keep Your Information?"
                secondary="We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="8. How Do We Keep Your Information Safe?"
                secondary="We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="9. Do We Collect Information from Minors?"
                secondary="We do not knowingly solicit data from or market to children under 18 years of age."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="10. What Are Your Privacy Rights?"
                secondary="You may review, change, or terminate your account at any time."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="11. Controls for Do-Not-Track Features"
                secondary="Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="12. Do California Residents Have Specific Privacy Rights?"
                secondary="Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="13. Updates to Our Privacy Policy"
                secondary="We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and 
              the updated version will be effective as soon as it is accessible."
              />
            </ListItem>

            <ListItem alignItems="flex-start">
              <ListItemText
                primary="14. How Can You Contact Us About This Policy?"
                secondary={
                  <>
                    If you have questions or comments about this policy, you may
                    email us at{" "}
                    <Link href="mailto:support@smartmerchandiser.com">
                      support@smartmerchandiser.com
                    </Link>{" "}
                    or by post to:
                    <br /> <br />
                    Zobrist Software Group, Inc.
                    <br />
                    1611 Folkestone Terrace Rd
                    <br />
                    Westlake Village, CA 91361
                    <br />
                    USA
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonComponent variant="contained" onClick={onClose}>
          Close
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
}
