import { useSelector, useDispatch } from "react-redux";
import { selectClipboardProductCodes } from "../store/clipboard/ClipBoardSelectors";
import {
  addClipboardPCodes,
  clearClipBoardProductCodes,
  setClipboardPCodes,
} from "../store/clipboard/ClipBoardActions";
import { useEffect } from "react";

function useClipboard() {
  const dispatch = useDispatch();
  const productCodes = useSelector(selectClipboardProductCodes);

  useEffect(() => {
    const storedItems = sessionStorage.getItem("clipboard");
    if (storedItems) {
      dispatch(setClipboardPCodes({ productCodes: JSON.parse(storedItems) }));
    }
  }, [dispatch]);

  const getClipboardProductCodes = () => {
    return productCodes;
  };

  const setClipboardProductCodes = (newProductCodes) => {
    dispatch(setClipboardPCodes({ productCodes: newProductCodes }));
    sessionStorage.setItem("clipboard", JSON.stringify(newProductCodes));
  };

  const addClipboardProductCodes = (additionalProducts) => {
    const items = new Set([
      ...productCodes,
      ...additionalProducts.productCodes,
    ]);
    const uniqueCodes = Array.from(items);
    dispatch(addClipboardPCodes({ productCodes: uniqueCodes }));
    sessionStorage.setItem("clipboard", JSON.stringify(uniqueCodes));
  };

  const clearClipboard = () => {
    dispatch(clearClipBoardProductCodes());
    sessionStorage.removeItem("clipboard");
  };

  return {
    getClipboardProductCodes,
    setClipboardProductCodes,
    addClipboardProductCodes,
    clearClipboard,
  };
}

export default useClipboard;
