import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

type Order = "asc" | "desc";

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rows: { [key: string]: string | number }[];
  selectedFields: string[];
  allFields: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableSortLabel: {
      width: "120px",
    },
    tableHeadText: {
      maxWidth: "100%",
      wordWrap: "break-word",
      padding: "12px 0 12px 24px",
    },
    leftAlign: {
      paddingLeft: "12px",
    },
  }),
);

export default function ItemManagementTableHead(props: Props) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, rows, selectedFields, allFields } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const defaultFields = rows[0]
    ? Object.keys(rows[0]).filter((key) => !allFields.includes(key))
    : [];
  const analyticsFields = rows[0]
    ? Object.keys(rows[0]).filter((key) => selectedFields.includes(key))
    : [];

  const row = [...defaultFields, ...analyticsFields];

  return (
    <TableHead>
      {selectedFields.length > 0 && (
        <TableRow>
          {row.map((key, index) => {
            return (
              <TableCell
                key={key}
                sortDirection={orderBy === key ? order : false}
                align="center"
                padding="none"
              >
                <TableSortLabel
                  active={orderBy === key}
                  direction={orderBy === key ? order : "asc"}
                  onClick={createSortHandler(key)}
                  className={classes.tableSortLabel}
                >
                  <div
                    className={clsx(classes.tableHeadText, {
                      [classes.leftAlign]: index < 2,
                    })}
                  >
                    {key}
                  </div>
                  {orderBy === key ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </TableHead>
  );
}
