import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputBase from "@material-ui/core/InputBase";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import CustomDialog from "../common/DialogContainer";
import CheckboxComponent from "../common/Checkbox";
import DropdownMenu from "../common/DropdownMenu";
import ButtonComponent from "../common/ButtonComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minHeight: "40vh",
      maxHeight: "60vh",
      minWidth: "40vw",
      overflow: "hidden",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      padding: "0 0 0 24px",
    },
    dialogContent: {
      padding: "0 24px",
      overflow: "auto",
      height: "100%",
    },
    formControl: {
      margin: "0 24px",
    },
    scrollableList: {
      marginTop: theme.spacing(1),
      height: "200px",
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(1),
    },
    select: {
      marginLeft: "0.5rem",
      minWidth: "100px",
    },
    rowFormControl: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "24px",
      "&  .MuiInput-formControl": {
        marginTop: 0,
      },
    },
    inputRoot: {
      color: "inherit",
      height: "80%",
      marginLeft: theme.spacing(1),
      border: "2px solid #F2F3F4;",
    },
    inputInput: {
      padding: theme.spacing(1),
      width: "100%",
    },
    dailogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

interface Props {}

const CoreSizesModal: React.FC<Props> = () => {
  // const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [type, setType] = useState("");
  const [threshold, setThreshold] = useState("");
  // const variantAttributes = useSelector(selectVariantAttributesAndValuesByCategory);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "CoreSizesModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("CoreSizesModal", false));
  };

  const handleSave = () => {
    dispatch(setModalState("CoreSizesModal", false));
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({
      ...selected,
      [e.target.name]: !selected[e.target.name],
    });
  };

  const handleSelect = (e: any) => {
    setType(e.target.value);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThreshold(event.target.value);
  };

  const coreSizeModalTitle = () => {
    return <Typography variant="inherit">Manage Core Sizes</Typography>;
  };

  const coreSizeModalContent = () => {
    return (
      <>
        <FormControl className={classes.rowFormControl}>
          <label id={"select-product-type-label"}>Product Type</label>
          <DropdownMenu
            menuId="CoreSizesModal"
            value={type}
            classes={classes}
            handleSelect={handleSelect}
          />
        </FormControl>
        {/* {variantAttributes && Object.keys(variantAttributes).length > 0 && Object.keys(variantAttributes).map((attributeCode) => {
              const descriptions = variantAttributes[attributeCode].descriptions
              const values = variantAttributes[attributeCode].values
              const index = descriptions.findIndex((description: any) => description.localeCode === 'x-default');
              const attributeName = descriptions[index].displayName
              return (
                <FormControl key={attributeCode} className={classes.formControl}>
                  <FormLabel required={false}>{attributeName}</FormLabel>
                  <div className={classes.scrollableList}>
                    <FormGroup>
                      {values && Object.keys(values).length > 0 && Object.keys(values).map((attributeValueCode) => {
                        const valuesDescriptions = values[attributeValueCode].descriptions
                        const valueIndex = valuesDescriptions.findIndex((description: any) => description.localeCode === 'x-default');
                        const attributeValueName = valuesDescriptions[valueIndex].displayValue
                        return (
                          <FormControlLabel
                            control={<Checkbox checked={selected[attributeValueCode] || false} onChange={handleCheck} name={attributeValueCode} />}
                            label={attributeValueName}
                            key={attributeValueCode}
                          />
                        )
                      })}
                    </FormGroup>
                  </div>
                </FormControl>
              )
            })} */}
        <FormControl key={"Size"} className={classes.formControl}>
          <FormLabel required={false}>Size</FormLabel>
          <div className={classes.scrollableList}>
            <FormGroup>
              {[
                "4",
                "6",
                "8",
                "10",
                "12",
                "14",
                "16",
                "XS",
                "S",
                "M",
                "L",
                "XL",
              ].map((attributeValueCode) => {
                return (
                  <FormControlLabel
                    control={
                      <CheckboxComponent
                        checkboxId="CoreSizesModal"
                        checked={selected[attributeValueCode] || false}
                        onChange={handleCheck}
                        value={attributeValueCode}
                      />
                    }
                    label={attributeValueCode}
                    key={attributeValueCode}
                  />
                );
              })}
            </FormGroup>
          </div>
        </FormControl>
        <FormControl className={classes.rowFormControl}>
          <label id={"inventory-threshold-label"}>Inventory Threshold</label>
          <InputBase
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleSearch}
            inputProps={{ "aria-label": "inventory threshold" }}
            value={threshold}
          />
        </FormControl>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dailogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleSave}
          // disabled={isSubmitting}
        >
          Save
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={coreSizeModalTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
    >
      {coreSizeModalContent()}
    </CustomDialog>
  );
};

export default CoreSizesModal;
