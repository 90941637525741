import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEditSequenceList } from "../../store/product-list/ProductListSelectors";
import {
  selectIsProductChildrenFetched,
  selectChildrenPage,
  selectIsFetchedBySearch,
  selectProductIdsBySearch,
  selectChildProductIds,
  selectChildProductAttributeIsFetching,
  selectChildProductAttributeIsFetched,
} from "../../store/product/ProductSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { loadChildProductAttributes } from "../../store/product/ProductActions";
import { PAGINATION_CHUNK_SIZE } from "../../utils/Constants";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";

const ChildProductionAttributeAction = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectEditSequenceList);
  const productsBySearch = useSelector(selectProductIdsBySearch);
  const isProductChildrenFetched = useSelector(selectIsProductChildrenFetched);
  const localeId = useSelector(selectCurrentLocale);
  const storeId = useSelector(selectCurrentStoreId);
  const childrenPage = useSelector(selectChildrenPage);
  const isFetchedBySearch = useSelector(selectIsFetchedBySearch);
  const childProductIds = useSelector(selectChildProductIds);
  const isChildProductAttributeFetching = useSelector(
    selectChildProductAttributeIsFetching,
  );
  const isChildProductAttributeFetched = useSelector(
    selectChildProductAttributeIsFetched,
  );
  const showProductCount =
    useSelector((state: AppState) =>
      selectConfigValue(state, "showProductCountForFacets"),
    ) === "true";

  useEffect(() => {
    let productIds: string[];
    if (isFetchedBySearch) {
      productIds = productsBySearch;
    } else {
      productIds = products.map((product) => product.productId);
    }
    const totalPages = Math.ceil(productIds.length / PAGINATION_CHUNK_SIZE);
    if (
      isProductChildrenFetched &&
      childrenPage === totalPages &&
      !isChildProductAttributeFetching &&
      !isChildProductAttributeFetched &&
      showProductCount
    ) {
      const payload = {
        productIds: childProductIds,
        storeId: storeId ? storeId : "",
        localeCode: localeId ? localeId : "",
      };
      dispatch(loadChildProductAttributes(payload));
    }
  }, [
    isFetchedBySearch,
    productsBySearch,
    products,
    isProductChildrenFetched,
    childrenPage,
    childProductIds,
    storeId,
    localeId,
    dispatch,
    isChildProductAttributeFetching,
    isChildProductAttributeFetched,
    showProductCount,
  ]);
  return <React.Fragment />;
};

export default React.memo(ChildProductionAttributeAction);
