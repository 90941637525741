import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { callApi } from "../../utils/SagaUtils";
import {
  LoadTopCategoriesAction,
  LoadAttributesAction,
  LoadChildCategoriesAction,
  LoadProductCountAction,
  CloseAllCategorySubscriptionsAction,
  LOAD_TOP_CATEGORIES,
  FETCH_TOP_CATEGORIES,
  LOAD_CHILD_CATEGORIES,
  LOAD_PARENT_CHILD_CATEGORIES,
  FETCH_CHILD_CATEGORIES,
  FETCH_PARENT_CHILD_CATEGORIES,
  FETCH_PRODUCT_COUNT,
  LOAD_PRODUCT_COUNT,
  RESET_CATEGORY,
  CLOSE_ALL_CATEGORY_SUBSCRIPTIONS,
  FETCH_PRODUCT_ATTRIBUTES_BY_CATEGORY,
  LOAD_PRODUCT_ATTRIBUTES_BY_CATEGORY,
  LoadVariantAttributesAndValuesAction,
  FETCH_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY,
  LOAD_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY,
  LOAD_TOP_CATEGORIES_FOR_REPORT,
  LoadTopCategoriesForReportAction,
  FETCH_TOP_CATEGORIES_FOR_REPORT,
  LoadChildCategoriesForReportAction,
  LOAD_CHILD_CATEGORIES_FOR_REPORT,
  FETCH_CHILD_CATEGORIES_FOR_REPORT,
  RESET_TOP_CATEGORIES_LOADING_STATE,
  RESET_TOP_CATEGORIES_LOADING_STATE_VALUE,
  UPDATE_TOP_CATEGORIES_LOADING_STATE,
  UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE,
  FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  LoadTopCategoriesForFindProducts,
  LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
  FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
  LoadChildCategoriesActionForFindProducts,
} from "./CategoryTypes";
import {
  productCountQuery,
  productAttributesByCategoryQueryGenerator,
  variantAttributesAndValuesByCategoryQuery,
} from "./CategorySchema";
import { getData } from "../../services/ApiService";
import { CREATE_SUBSCRIPTION, CLOSE_SUBSCRIPTION } from "../Subscription";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { selectCurrentLocale } from "../store-list/StoreListSelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../AppState";

export function* watchLoadTopCategories() {
  yield takeEvery(LOAD_TOP_CATEGORIES, loadTopCategories);
}

export function* watchLoadTopCategoriesForReport() {
  yield takeEvery(LOAD_TOP_CATEGORIES_FOR_REPORT, loadTopCategoriesForReport);
}

export function* watchLoadChildCategories() {
  yield takeEvery(LOAD_CHILD_CATEGORIES, loadChildCategories);
}
export function* watchLoadParentChildCategories() {
  yield takeEvery(LOAD_PARENT_CHILD_CATEGORIES, loadChildCategories);
}
export function* watchLoadChildCategoriesForReport() {
  yield takeEvery(
    LOAD_CHILD_CATEGORIES_FOR_REPORT,
    loadChildCategoriesForReport,
  );
}

export function* watchCreateProductCountSubscription() {
  yield takeEvery(LOAD_PRODUCT_COUNT, createProductCountSubscription);
}

export function* watchCloseAllCategorySubscriptions() {
  yield takeEvery(
    CLOSE_ALL_CATEGORY_SUBSCRIPTIONS,
    closeAllCategorySubscriptions,
  );
}

export function* watchLoadProductAttributesByCategory() {
  yield takeEvery(
    LOAD_PRODUCT_ATTRIBUTES_BY_CATEGORY,
    loadProductAttributesByCategory,
  );
}

export function* watchLoadVariantAttributesAndValuesByCategory() {
  yield takeEvery(
    LOAD_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY,
    loadVariantAttributesAndValuesByCategory,
  );
}
export function* watchResetTopCategoriesState() {
  yield takeEvery(
    RESET_TOP_CATEGORIES_LOADING_STATE,
    watchResetTopCategoriesLoadingState,
  );
}

export function* watchUpdateTopCategoriesLoadingState() {
  yield takeEvery(
    UPDATE_TOP_CATEGORIES_LOADING_STATE,
    updateTopCategoriesLoadingState,
  );
}

export function* watchLoadTopCategoriesForFindProducts() {
  yield takeEvery(
    LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
    loadTopCategoriesForFindProducts,
  );
}

export function* watchLoadChildCategoriesForFindProducts() {
  yield takeEvery(
    LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
    loadChildCategoriesForFindProducts,
  );
}

function* watchResetTopCategoriesLoadingState() {
  try {
    yield put({ type: RESET_TOP_CATEGORIES_LOADING_STATE_VALUE });
  } catch (e: any) {
    console.log(e);
  }
}
function* updateTopCategoriesLoadingState() {
  try {
    yield put({ type: UPDATE_TOP_CATEGORIES_LOADING_STATE_VALUE });
  } catch (e: any) {
    console.log(e);
  }
}

function* closeAllCategorySubscriptions(
  action: CloseAllCategorySubscriptionsAction,
) {
  try {
    yield put({ type: RESET_CATEGORY });
  } catch (e: any) {
    console.error(e);
  }
}

function* loadTopCategories(action: LoadTopCategoriesAction) {
  const actionType = FETCH_TOP_CATEGORIES;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);

  const constName = Object.keys({ FETCH_TOP_CATEGORIES })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, catalogId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-locale-code": localeCode || "default",
    };
    const response = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (response?.payload) {
      yield all(response.payload.results);
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_TOP_CATEGORIES.FAILURE, message: e.message });
  }
}

function* loadTopCategoriesForReport(action: LoadTopCategoriesForReportAction) {
  const actionType = FETCH_TOP_CATEGORIES_FOR_REPORT;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({
    FETCH_TOP_CATEGORIES_FOR_REPORT,
  })[0].toString();
  const headersObj = {
    "x-currency-code": "USD",
    "x-locale-code": localeCode,
    "x-store-id": storeId,
  };
  try {
    const endpoint = acquireEndpoint(constName, catalogId);
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_TOP_CATEGORIES_FOR_REPORT.FAILURE,
      message: e.message,
    });
  }
}

function* loadProductAttributesByCategory(action: LoadAttributesAction) {
  const catalogId = action.payload.catalogId;
  const categoryId = action.payload.categoryId;
  const isGraphQLStoreRelationshipEnabled = yield select((state: AppState) =>
    selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
  );
  const storeId =
    isGraphQLStoreRelationshipEnabled === "true"
      ? action.payload.storeId
      : undefined;

  const payload = {
    operationName: "ProductAttributesByCategory",
    query: productAttributesByCategoryQueryGenerator(storeId),
    variables: {
      catalogId,
      categoryId,
      storeId,
    },
  };
  try {
    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_PRODUCT_ATTRIBUTES_BY_CATEGORY.REQUEST,
      },
    });
    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: FETCH_PRODUCT_ATTRIBUTES_BY_CATEGORY.REQUEST,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_PRODUCT_ATTRIBUTES_BY_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

function* loadChildCategories(action: LoadChildCategoriesAction) {
  const actionType =
    action.type === "LOAD_CHILD_CATEGORIES"
      ? FETCH_CHILD_CATEGORIES
      : FETCH_PARENT_CHILD_CATEGORIES;
  const categoryId = action.payload.categoryId;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({ FETCH_CHILD_CATEGORIES })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };
    const response = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    if (response?.payload) {
      yield all(response.payload.results);
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_CHILD_CATEGORIES.FAILURE, message: e.message });
  }
}

function* loadChildCategoriesForReport(
  action: LoadChildCategoriesForReportAction,
) {
  const actionType = FETCH_CHILD_CATEGORIES_FOR_REPORT;
  const categoryId = action.payload.categoryId;
  const storeId = action.payload.storeId;
  const catalogId = action.payload.catalogId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({
    FETCH_CHILD_CATEGORIES_FOR_REPORT,
  })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_CHILD_CATEGORIES_FOR_REPORT.FAILURE,
      message: e.message,
    });
  }
}

function* createProductCountSubscription(action: LoadProductCountAction) {
  try {
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;
    const storeId = action.payload.storeId;
    const localeId = action.payload.localeId;

    const payload = {
      operationName: "ProductCount",
      query: productCountQuery,
      variables: {
        catalogId,
        categoryId,
        storeId,
        localeId,
      },
    };

    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: `${FETCH_PRODUCT_COUNT.REQUEST}__catalogId__${catalogId}__categoryId__${categoryId}`,
      },
    });
    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: `${FETCH_PRODUCT_COUNT.REQUEST}__catalogId__${catalogId}__categoryId__${categoryId}`,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield put({ type: FETCH_PRODUCT_COUNT.FAILURE, message: e.message });
  }
}

function* loadVariantAttributesAndValuesByCategory(
  action: LoadVariantAttributesAndValuesAction,
) {
  const categoryId = action.payload.categoryId;

  const payload = {
    operationName: "VariantAttributesAndValuesByCategory",
    query: variantAttributesAndValuesByCategoryQuery,
    variables: {
      categoryId,
    },
  };
  try {
    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY.REQUEST,
      },
    });
    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: FETCH_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY.REQUEST,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

function* loadTopCategoriesForFindProducts(
  action: LoadTopCategoriesForFindProducts,
) {
  const actionType = FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({
    FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  })[0].toString();
  const headersObj = {
    "x-currency-code": "USD",
    "x-locale-code": localeCode,
    "x-store-id": storeId,
  };
  try {
    const endpoint = acquireEndpoint(constName, catalogId);
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_TOP_CATEGORIES_FOR_FIND_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* loadChildCategoriesForFindProducts(
  action: LoadChildCategoriesActionForFindProducts,
) {
  const actionType = FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS;
  const categoryId = action.payload.categoryId;
  const catalogId = action.payload.catalogId;
  const storeId = action.payload.storeId;
  const localeCode = yield select(selectCurrentLocale);
  const constName = Object.keys({
    FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
  })[0].toString();
  try {
    const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
    };
    const response = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    yield all(response.payload.results);
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_CHILD_CATEGORIES_FOR_FIND_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}
