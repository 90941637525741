import { Theme, styled, FormControl, InputBase } from "@mui/material";

export const styleClasses = {
  contentContainer: "contentContainer",
  leftPanel: "leftPanel",
  main: "main",
  utilsBar: "utilsBar",
  searchError: "searchError",
  search: "search",
  searchIcon: "searchIcon",
  errorIcon: "errorIcon",
  inputRoot: "inputRoot",
  inputInput: "inputInput",
  sorting: "sorting",
  formControl: "formControl",
  productList: "productList",
  btnClose: "btnClose",
  catagoryProductList: "catagoryProductList",
};

export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.contentContainer}`]: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  [`&.${styleClasses.leftPanel}`]: {
    minWidth: "200px",
    height: "100%",
    // margin: "10px",
    overflow: "hidden",
    borderRadius: "9px", // Added border-radius
    border: "1px solid" + theme.palette.borderColor.main,
    // overflowY: "auto",
    margin: theme.spacing(1.25, 1.25, 1.25, 0),
    padding: theme.spacing(0, 1.25, 0, 1.25),
  },
  [`&.${styleClasses.main}`]: {
    height: "100%",
    width: "100%",
  },
  [`&.${styleClasses.utilsBar}`]: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    alignItems: "center",
    marginLeft: "10px",
  },
  [`&.${styleClasses.searchError}`]: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.error.main}`,
    marginLeft: 0,
    minWidth: "300px",
  },
  [`&.${styleClasses.search}`]: {
    position: "relative",
    margin: "20px 0px",
  },
  [`&.${styleClasses.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`&.${styleClasses.errorIcon}`]: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
    color: theme.palette.error.main,
  },
  [`&.${styleClasses.sorting}`]: {
    display: "flex",
    justifyContent: "space-around",
    padding: "5px 0",
  },
  [`&.${styleClasses.btnClose}`]: {
    textAlign: "right",
    margin: "20px",
    width: "100%",
  },
  [`&.${styleClasses.catagoryProductList}`]: {
    display: "flex",
  },
  [`&.${styleClasses.productList}`]: {
    margin: "0 auto",
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${styleClasses.formControl}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    "&  .MuiInput-formControl": {
      marginTop: 0,
    },
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  [`&.${styleClasses.inputRoot}`]: {
    color: "inherit",
    width: "100%",
    height: "80%",
  },
  [`&.${styleClasses.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const useStyles = (theme: Theme) => {
  return {
    dialogPaper: {
      height: "80vh",
    },
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(0, 0, 3),
      width: "90%",
      height: "80%",
      maxWidth: "1240px",
      position: "relative",
    },
    sortingLink: {
      cursor: "pointer",
      margin: "0 5px",
    },
    select: {
      marginLeft: "0.5rem",
      minWidth: "100px",
    },
    productList: {
      marginTop: "10px",
      height: "100%",
    },
  };
};

export default useStyles;
