import AppState from "../AppState";

export const selectIsModalOpen = (
  state: AppState,
  modalId: string,
): boolean => {
  return state.modal[modalId] ? state.modal[modalId]["isOpen"] : false;
};

export const selectModalVariable = (
  state: AppState,
  modalId: string,
  variable: string,
): any => {
  return state.modal[modalId] ? state.modal[modalId][variable] : null;
};
