/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormikContext } from "formik";
import Select, { components } from "react-select";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@mui/material/styles";
import { SelectType } from "../../../store/businessRules-list/BusinessRulesListTypes";
import { useIntl } from "react-intl";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    selectField: {
      marginRight: 5,
    },
  }),
);

const customStyle = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    "&:focus-within": {
      border: "1.5px solid #3F51B5",
      boxShadow: "0 0 0 1px #3F51B5",
    },
    border: "1px solid #BDBDBD",
    borderRadius: 4,
    width: 324,
    minHeight: 50.4,
    "&:hover": {
      borderColor: "#000000",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    "&:hover": {
      backgroundColor: "#F18221 !important",
      opacity: 0.5,
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      width: "158px",
      color: "#6F7784",
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
    };
  },
};
const MultiSelectWrapper = ({ name, isMulti, options, defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();
  const intl = useIntl();
  const [defaultData, setDefaultData] = React.useState<SelectType[]>([]);
  React.useEffect(() => {
    if (defaultValue.length) {
      const defaultPayload: SelectType[] = [];
      defaultValue.forEach((value) => {
        options.forEach((data) => {
          if (data.value === value) {
            defaultPayload.push(data);
          }
        });
      });
      setDefaultData(defaultPayload);
      setFieldValue(name, defaultPayload);
      return;
    }
  }, [name, setFieldValue, defaultValue]);

  const handleChange = (selectedItems) => {
    setFieldValue(name, selectedItems);
    setDefaultData(selectedItems);
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icons iconId="TriangleDownIcon" />
        </components.DropdownIndicator>
      )
    );
  };
  return (
    <Select
      styles={customStyle}
      placeholder={intl.formatMessage({
        id: "unifiedSortingRuleModal.multiSelectWeekday",
        defaultMessage: "Select Weekday(s) *",
      })}
      menuPlacement="top"
      className={classes.selectField}
      name={name}
      isMulti={isMulti}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      options={[
        {
          label: intl.formatMessage({
            id: "Monday",
            defaultMessage: "Monday",
          }),
          value: "MON",
        },
        {
          label: intl.formatMessage({
            id: "Tuesday",
            defaultMessage: "Tuesday",
          }),
          value: "TUE",
        },
        {
          label: intl.formatMessage({
            id: "Wednesday",
            defaultMessage: "Wednesday",
          }),
          value: "WED",
        },
        {
          label: intl.formatMessage({
            id: "Thursday",
            defaultMessage: "Thursday",
          }),
          value: "THU",
        },
        {
          label: intl.formatMessage({
            id: "Friday",
            defaultMessage: "Friday",
          }),
          value: "FRI",
        },
        {
          label: intl.formatMessage({
            id: "Saturday",
            defaultMessage: "Saturday",
          }),
          value: "SAT",
        },
      ]}
      onChange={handleChange}
      required
      value={defaultData}
    />
  );
};

export default MultiSelectWrapper;
