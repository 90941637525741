export const LOAD_CATALOG_LIST = "LOAD_CATALOG_LIST";
export const SET_CATALOG_ID = "SET_CATALOG_ID";
export const FETCH_CATALOG_LIST = {
  REQUEST: "FETCH_CATALOG_LIST_REQUEST",
  SUCCESS: "FETCH_CATALOG_LIST_SUCCESS",
  FAILURE: "FETCH_CATALOG_LIST_FAILURE",
};
export const LOAD_ALL_CATALOGS = "LOAD_ALL_CATALOGS";
export const FETCH_ALL_CATALOGS = {
  REQUEST: "FETCH_ALL_CATALOGS_REQUEST",
  SUCCESS: "FETCH_ALL_CATALOGS_SUCCESS",
  FAILURE: "FETCH_ALL_CATALOGS_FAILURE",
};
export const LOAD_PROGRESS = "LOAD_PROGRESS";

export interface CatalogList {
  [key: string]: string[];
}
export interface AllCatalog {
  id: string;
  name: string;
  code: string;
}

export interface LoadCatalogListAction {
  payload: Store;
  type: string;
}

export interface LoadCatalogListResponseAction {
  payload: GetCatalogListResponsePayload;
  endpoint: string;
  type: string;
}

export interface Store {
  storeId: string;
}

export interface Catalog {
  catalogId: string;
  name: string;
}

export interface LoadCatalogPayload {
  storeId: string;
  localeId?: string;
}

export interface GetCatalogListResponsePayload {
  count: number;
  total: number;
  storeId: string;
  results: Catalog[];
}

// SET CATALOG ID TYPES
export interface SetCurrentCatalogIdAction {
  payload: {
    catalogId: string;
  };
  type: typeof SET_CATALOG_ID;
}

export interface LoadAllCatalogsAction {
  payload: Store;
  type: string;
}

export interface LoadAllCatalogsResponseAction {
  payload: GetAllCatalogsResponsePayload;
  type: string;
}

export interface GetAllCatalogsResponsePayload {
  count: number;
  total: number;
  results: Catalog[];
}
