/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsModalOpen } from "../../../../../store/modal/ModalSelectors";
import AppState from "../../../../../store/AppState";
import { FormControl } from "@material-ui/core";
import { Typography, Box, CircularProgress, useTheme } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { classes, StyledDiv, StyledSpan, styles } from "./Styles";
import { setModalState } from "../../../../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { Form, Formik } from "formik";
import { useUtils } from "./useUtils";
import TextfieldWrapper from "../../../Textfield/TextFieldWrapper";
import {
  repeats,
  repeatDays,
  retries,
  IAutomationRepeats,
  IAutomationEndDuration,
  analyticMetrics,
} from "../../../../../utils/Constants";
import SelectWrapper from "../../../Select/SelectWrapper";
import moment from "moment-timezone";
import {
  AutomationRulesList,
  CategoryData,
  SelectType,
} from "../../../../../store/businessRules-list/BusinessRulesListTypes";
import ButtonComponent from "../../../../common/ButtonComponent";
import CategoryTreeModal from "../CategoryTreeModal/CategoryTreeModal";
import { selectStoreListIds } from "../../../../../store/store-list/StoreListSelectors";
import { Tooltip } from "@mui/material";
import AlertIcon from "@mui/icons-material/Warning";
import { LoadStoreList } from "../../../../../store/store-list/StoreListActions";
import { selectAppliedRulesList } from "../../../../../store/product-list/ProductListSelectors";
import MultiSelectWrapper from "../../../MultiSelect/MultiSelect";
import { setLoaderState } from "../../../../../store/loader/LoaderActions";
import {
  loadAutomationRulesList,
  resetSelectedCategoriesData,
  saveAutomationRule,
  selectedCategoriesId,
  updateAutomationRule,
  resetAutomationJobCreationState,
  setIsAnalytic,
  resetAutomationJob,
} from "../../../../../store/businessRules-list/BusinessRulesListActions";
import {
  selectAutomationRuleCategories,
  selectAutomationRulesByJobId,
  selectIsAutomationRuleSaved,
  selectIsFetchedByJobId,
  selectIsAutomationRulesJobFailed,
  selectSystemGeneratedRules,
  selectIsAnalytic,
  selectFetchFailedAutomationRulesCategoryDetails,
} from "../../../../../store/businessRules-list/BusinessRuleslistSelectors";
import { selectIsLoading } from "../../../../../store/loader/LoaderSelectors";
import { StoreNew } from "../../../../../store/store-list/StoreListTypes";
import { periodTypes } from "../../../../../utils/Constants";
import CustomDialog from "../../../../common/DialogContainer";
import RadioComponent from "../../../../common/Radio";

const today = dayjs();

const AutomationRulesModal = ({ jobId, isEditMode }) => {
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "AutomationRulesModal"),
  );
  const storesList = useSelector(selectStoreListIds);
  const sortingRulesList = useSelector(selectAppliedRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);
  const isDetailsSaved = useSelector(selectIsAutomationRuleSaved);
  const isAutomationJobCreationFailed = useSelector(
    selectIsAutomationRulesJobFailed,
  );
  const isLoading = useSelector((state: AppState) =>
    selectIsLoading(state, "SAVE_AUTOMATION_RULE"),
  );
  const isFetchedByJobId = useSelector(selectIsFetchedByJobId);
  const automationRuleDetailsObj = useSelector(selectAutomationRulesByJobId);
  const selectedCategoriesList = useSelector(selectAutomationRuleCategories);
  const fetchFailedAutomationRulesCategoryDetails = useSelector(
    selectFetchFailedAutomationRulesCategoryDetails,
  );
  const isAnalyticMetric = useSelector(selectIsAnalytic);
  let timezones1 = moment.tz.names();
  let currentUserTimezone = moment.tz.guess();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { INITIAL_STATE, FORM_VALIDATION, FORM_VALIDATION_NEW } = useUtils();
  const [initialState, setInitialState] = useState({});
  const [timezones, setTimezones] = useState<SelectType[]>([]);
  const [value, setValue] = useState<string>("One Time");
  const [minEndTime, setMinEndTime] = useState<string>("");
  const [endValue, setEndValue] = useState<string>(
    IAutomationEndDuration.NEVER,
  );
  const [storesListState, setStoresListState] = useState<StoreNew[]>([]);
  const [appliedRulesList, setAppliedRulesList] = useState<SelectType[]>([]);
  const [savedCategories, setSavedCategories] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const [repeatArr, setRepeatArr] = useState<string[]>([]);
  const [parentData, setParentData] = useState<StoreNew[]>([]);
  const [categoriesLookUpData, setCategoriesLookUpData] = useState<
    CategoryData[]
  >([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState<boolean>(false);
  const [isFetchingDetails, setIsFetchingDetails] = useState<boolean>(false);
  const [showCategoryError, setShowCategoryError] = useState<boolean>(false);
  const [showCategoryLookUpError, setShowCategoryLookUpError] =
    useState<boolean>(false);
  const theme = useTheme();
  const { datePicker } = styles(theme);

  const handleCallbackFn = (inputFromChild, formik) => {
    const newArr: any[] = [];
    inputFromChild.forEach((data) => newArr.push(data));
    formik.setFieldValue("scope", newArr);
    setSavedCategories(newArr);
    if (newArr.length) setShowCategoryError(false);
  };

  const handleCallBackForStores = (inputDataFromChild) => {
    setParentData(inputDataFromChild);
  };

  useEffect(() => {
    if (isLoading && isDetailsSaved) {
      dispatch(setLoaderState("SAVE_AUTOMATION_RULE", false));
      dispatch(setLoaderState("LIST_AUTOMATION_RULES_LOADER", true));
      dispatch(loadAutomationRulesList());
      handleClose();
    } else if (isLoading && isAutomationJobCreationFailed) {
      dispatch(setLoaderState("SAVE_AUTOMATION_RULE", false));
      dispatch(resetAutomationJobCreationState());
    }
  }, [isLoading, isDetailsSaved, dispatch, isAutomationJobCreationFailed]);
  useEffect(() => {
    const timezoneArr: SelectType[] = [];
    timezones1.map((result) =>
      timezoneArr.push({ label: result, value: result }),
    );
    setTimezones(timezoneArr);
  }, []);

  useEffect(() => {
    if (automationRuleDetailsObj.name !== "" && isFetchedByJobId) {
      setIsFetchingDetails(false);
      setCategoriesLoaded(true);
      const loadedObjData = handleAutomationRulesPayload(
        automationRuleDetailsObj,
      );
      setInitialState(loadedObjData);
      dispatch(LoadStoreList());
      if (automationRuleDetailsObj.endAt !== "") {
        handleEndTime(automationRuleDetailsObj.startAt);
        setEndValue(IAutomationEndDuration.ON);
      } else {
        setEndValue(IAutomationEndDuration.NEVER);
      }
    } else {
      setInitialState(INITIAL_STATE);
      if (isAnalyticMetric) {
        dispatch(setIsAnalytic(false));
      }
    }
  }, [automationRuleDetailsObj, isFetchedByJobId]);

  useEffect(() => {
    if (
      !savedCategories?.length &&
      automationRuleDetailsObj.name !== "" &&
      isFetchedByJobId
    ) {
      setShowCategoryError(false);
      setShowCategoryLookUpError(false);
      setCategoriesLoaded(true);
    }
    if (fetchFailedAutomationRulesCategoryDetails) {
      setShowCategoryError(true);
      setShowCategoryLookUpError(true);
      setCategoriesLoaded(false);
    }
  }, [
    savedCategories,
    automationRuleDetailsObj,
    isFetchedByJobId,
    fetchFailedAutomationRulesCategoryDetails,
  ]);

  useEffect(() => {
    if (storesList.length) {
      setStoresListState(storesList);
    }
  }, [storesList]);

  useEffect(() => {
    if (jobId !== "") {
      setIsFetchingDetails(true);
    }
  }, [jobId]);

  useEffect(() => {
    const appliedRules: SelectType[] = [];
    const allSortingRulesList = [
      ...systemGeneratedRulesList,
      ...sortingRulesList,
    ];
    if (allSortingRulesList.length) {
      allSortingRulesList.forEach((sortingRule) => {
        const isAnalytic = sortingRule.formula?.some((formula) =>
          analyticMetrics.includes(formula.metric),
        );
        appliedRules.push({
          label: sortingRule.name,
          value: sortingRule.ruleId,
          type: sortingRule.ruleType,
          isAnalyticMetric: isAnalytic,
        });
      });
      setAppliedRulesList(appliedRules);
      return;
    }
  }, [sortingRulesList, systemGeneratedRulesList]);

  useEffect(() => {
    setCategoriesLookUpData(selectedCategoriesList);
  }, [selectedCategoriesList]);

  useEffect(() => {
    if (categoriesLookUpData.length === savedCategories.length) {
      setCategoriesLoaded(true);
      const savedCategoriesCopy = [...savedCategories];
      const categoriesLookUpDataCopy = [...categoriesLookUpData];
      const matchedCategoriesArr: any[] = [];
      savedCategoriesCopy.forEach((savedData) => {
        const matchingObj = categoriesLookUpDataCopy.find(
          (data) => data.categoryId === savedData.categoryId,
        );
        const combinedObj = { ...savedData, code: matchingObj?.code };
        matchedCategoriesArr.push(combinedObj);
      });
      setSavedCategories(matchedCategoriesArr);
      if (matchedCategoriesArr.length) {
        setShowCategoryError(false);
      }
      setCategoriesLoaded(false);
    }
  }, [categoriesLookUpData]);

  const handleAutomationRulesPayload = (payload: AutomationRulesList) => {
    if (payload?.repeats !== "") {
      setValue("Repeat");
      setRepeatArr(payload?.repeatDays as string[]);
    }
    if (payload?.parameters.scope && payload.parameters.scope[0].dateRange) {
      dispatch(setIsAnalytic(true));
    }
    setSavedCategories(payload.parameters.scope);
    setShowCategoryError(false);
    dispatch(selectedCategoriesId(payload.parameters.scope));

    const updatedPayload = {
      name: payload.name,
      description: payload.description,
      scope: appliedRulesList.find(
        (e) => e.value === payload.parameters.scope?.[0].ruleId,
      )
        ? payload.parameters.scope
        : [],
      appliedRule: appliedRulesList.find(
        (e) => e.value === payload.parameters.scope?.[0].ruleId,
      )?.value,
      start: payload.startAt,
      ends: payload.endAt,
      timezone: payload.timezone,
      retries: payload.retries,
      repeats:
        payload.repeats === "W"
          ? IAutomationRepeats.WEEKLY
          : payload.repeats === "M"
            ? IAutomationRepeats.MONTHLY
            : payload.repeats === "D"
              ? IAutomationRepeats.DAILY
              : "",
      repeatsEvery: payload.repeatsEvery,
      repeatDays: payload.repeatDays,
      dateRange: payload.parameters.scope?.[0].dateRange || "",
    };
    return updatedPayload;
  };
  const handleClose = () => {
    setShowCategoryError(false);
    setShowCategoryLookUpError(false);
    dispatch(setModalState("AutomationRulesModal", false));
    dispatch(resetSelectedCategoriesData());
    setInitialState(INITIAL_STATE);
    setEndValue(IAutomationEndDuration.NEVER);
    setRepeatArr([]);
    setSavedCategories([]);
    setValue("One Time");
    if (isAnalyticMetric) {
      dispatch(setIsAnalytic(false));
    }
    setSelectedId("");
    dispatch(resetAutomationJob());
    return;
  };

  const handleEndTime = (startTime) => {
    const currentDate = new Date(startTime);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    setMinEndTime(nextDate.toISOString());
  };

  const handleAddCategories = () => {
    dispatch(setModalState("CategoryModal", true));
    if (!storesListState.length) {
      dispatch(LoadStoreList());
    }

    return;
  };

  const handleSelectData = (selectedId) => {
    setSelectedId(selectedId);
  };

  const handleRemoveCategory = (id, formik) => {
    const updatedList = [...savedCategories];
    const newArr = updatedList.filter((data) => {
      if (data.categoryId === id || data.id === id) {
        // eslint-disable-next-line array-callback-return
        return;
      } else {
        return data;
      }
    });
    formik.setFieldValue("scope", newArr);
    setSavedCategories(newArr);
    setShowCategoryError(false);
  };

  const handleSaveAutomationRule = (data) => {
    if (!data.scope.length) {
      setShowCategoryError(true);
      return;
    } else if (!data.ends.length && endValue === IAutomationEndDuration.ON) {
      setShowCategoryError(true);
      return;
    } else {
      setShowCategoryError(false);
      const structuredScope: {
        categoryId: string;
        catalogId: string;
        storeId: string;
        localeCode: string;
        currencyCode: string;
        ruleId: string;
        dateRange: string;
        timezone: string;
      }[] = [];
      let userTimeZone: string = "";
      if (isAnalyticMetric) {
        userTimeZone = data.timezone;
      }
      data?.scope?.forEach((scope) =>
        structuredScope.push({
          categoryId: scope.categoryId,
          catalogId: scope.catalogId,
          storeId: scope.storeId,
          localeCode: "default",
          currencyCode: "USD",
          ruleId: data.appliedRule,
          dateRange: data.dateRange,
          timezone: userTimeZone,
        }),
      );
      dispatch(setLoaderState("SAVE_AUTOMATION_RULE", true));
      const structuredRepeatDays: string[] = [];
      if (data.repeatDays.length)
        data.repeatDays.forEach((day) => structuredRepeatDays.push(day.value));

      const payload: AutomationRulesList = {
        name: data.name,
        description: data.description,
        startAt: data?.start || data?.startAt,
        endAt:
          endValue === IAutomationEndDuration.ON ? data.ends || data.endAt : "",
        timezone: data.timezone,
        retries: data.retries,
        repeatsEvery:
          value !== "One Time" && data.repeats !== IAutomationRepeats.WEEKLY
            ? data.repeatsEvery
            : 1,
        repeats:
          value === "One Time"
            ? ""
            : data.repeats === IAutomationRepeats.DAILY
              ? "D"
              : data.repeats === IAutomationRepeats.WEEKLY
                ? "W"
                : data.repeats === IAutomationRepeats.MONTHLY
                  ? "M"
                  : "",
        ruleId: data.appliedRule,
        repeatDays:
          value !== "One Time" && data.repeats === IAutomationRepeats.WEEKLY
            ? structuredRepeatDays
            : [],
        parameters: {
          scope: structuredScope,
        },
        jobType: "SORT",
        active: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      dispatch(resetSelectedCategoriesData());
      setCategoriesLookUpData([]);
      if (automationRuleDetailsObj.jobId !== "") {
        payload.jobId = automationRuleDetailsObj.jobId;
        dispatch(updateAutomationRule(payload));
      } else {
        dispatch(saveAutomationRule(payload));
      }
    }
  };

  const getAutomationRuleModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "automationRules.modalTitle",
          defaultMessage: "Schedules",
        })}
      </Typography>
    );
  };

  const getAutomationRuleModalContent = () => {
    return (
      <>
        {isLoading || isFetchingDetails ? (
          <StyledDiv className={classes.dialogChildClass}>
            <StyledSpan className={classes.saveTextClass}>
              {isLoading ? (
                <Typography variant="subHeader">
                  {intl.formatMessage({
                    id: "unifiedSortingRuleModal.loaderMessage",
                    defaultMessage: "Saving Details...",
                  })}
                </Typography>
              ) : (
                <Typography variant="subHeader">
                  {intl.formatMessage({
                    id: "unifiedSortingRuleModal.fetchMessage",
                    defaultMessage: " Fetching Details...",
                  })}
                </Typography>
              )}
            </StyledSpan>
            <CircularProgress />
          </StyledDiv>
        ) : null}
        <StyledDiv
          className={`${
            isLoading || isFetchingDetails
              ? classes.dialogBlurClass
              : classes.formWrapper
          }`}
        >
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={
              value === "One Time" ? FORM_VALIDATION_NEW : FORM_VALIDATION
            }
            onSubmit={(values) => {
              handleSaveAutomationRule(values);
            }}
          >
            {(formik) => {
              return (
                <div>
                  <Form>
                    <StyledDiv className={classes.automationSection}>
                      <div>
                        <div>
                          <TextfieldWrapper
                            name="name"
                            label="Name"
                            type="text"
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <br></br>
                        <div>
                          {" "}
                          <TextfieldWrapper
                            name="description"
                            type="text"
                            label="Description"
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <br></br>
                        <div>
                          {" "}
                          <SelectWrapper
                            defaultValue={
                              appliedRulesList?.find(
                                (e) =>
                                  automationRuleDetailsObj?.parameters?.scope?.find(
                                    (el) => el.ruleId === e.value,
                                  ) ||
                                  e.label === automationRuleDetailsObj?.name,
                              )?.label ?? ""
                            }
                            name="appliedRule"
                            label="Applied Rule *"
                            options={appliedRulesList}
                            currentModalValue={""}
                            variant="outlined"
                            size="small"
                            InputProps={
                              isFetchedByJobId &&
                              !appliedRulesList?.find(
                                (e) =>
                                  (automationRuleDetailsObj.parameters.scope
                                    ?.length > 0 &&
                                    automationRuleDetailsObj.parameters.scope[0]
                                      .ruleId === e.value) ||
                                  e.label === automationRuleDetailsObj.name,
                              ) && {
                                endAdornment: (
                                  <Tooltip title="The selected rule was found invalid, please update">
                                    <AlertIcon
                                      style={{
                                        color: "#d32f2f",
                                        cursor: "pointer",
                                        marginRight: "1rem",
                                      }}
                                    />
                                  </Tooltip>
                                ),
                              }
                            }
                          />
                        </div>
                        {isAnalyticMetric && (
                          <div>
                            <br></br>{" "}
                            <SelectWrapper
                              defaultValue={""}
                              name="dateRange"
                              label={intl.formatMessage({
                                id: "automationRulesModal.sorting",
                                defaultMessage: "Sorting Period",
                              })}
                              options={periodTypes}
                              currentModalValue={""}
                              variant="outlined"
                              size="small"
                            />
                          </div>
                        )}
                        <StyledDiv className={classes.spanSection}>
                          {" "}
                          <SelectWrapper
                            defaultValue={""}
                            name="retries"
                            label={intl.formatMessage({
                              id: "automationRulesModal.numberOfRetries",
                              defaultMessage: "Number of Retries *",
                            })}
                            options={retries}
                            currentModalValue={""}
                            variant="outlined"
                            size="small"
                          />
                        </StyledDiv>
                        <br></br>
                        <div>
                          {" "}
                          <SelectWrapper
                            defaultValue={currentUserTimezone}
                            name="timezone"
                            label="Timezone"
                            options={timezones}
                            currentModalValue=""
                            variant="outlined"
                            size="small"
                          />
                        </div>
                        <br></br>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error:
                                    formik.getFieldMeta("start").touched &&
                                    !formik.getFieldProps("start").value,
                                  helperText:
                                    formik.getFieldMeta("start").touched &&
                                    !formik.getFieldProps("start").value
                                      ? "Required"
                                      : "",
                                  color:
                                    formik.getFieldProps("start").value &&
                                    formik.getFieldMeta("start").touched
                                      ? "error"
                                      : "primary",
                                },
                              }}
                              minDate={today}
                              value={
                                isEditMode && automationRuleDetailsObj.startAt
                                  ? dayjs(automationRuleDetailsObj.startAt)
                                  : null
                              }
                              label={intl.formatMessage({
                                id: "automationRulesModal.startTime",
                                defaultMessage: "Start Time *",
                              })}
                              onChange={(value) => {
                                handleEndTime(value?.toDate().toISOString());
                                formik.setFieldValue(
                                  "start",
                                  value?.toDate().toISOString(),
                                );
                              }}
                              sx={datePicker}
                            />
                          </LocalizationProvider>
                        </div>
                        <StyledDiv className={classes.radioGroup}>
                          <FormControl>
                            <RadioComponent
                              radioId="AutomationRulesModal"
                              classes={classes}
                              setValue={setValue}
                              value={value}
                            />
                          </FormControl>
                        </StyledDiv>

                        {value === "Repeat" ? (
                          <div>
                            <div>
                              {" "}
                              <SelectWrapper
                                defaultValue={""}
                                name="repeats"
                                label={intl.formatMessage({
                                  id: "automationRulesModal.repeats",
                                  defaultMessage: "Repeats *",
                                })}
                                options={repeats}
                                currentModalValue={""}
                                variant="outlined"
                                size="small"
                              />
                            </div>
                            {formik.getFieldMeta("repeats").value ===
                              IAutomationRepeats.WEEKLY && (
                              <StyledDiv className={classes.spanSection}>
                                {" "}
                                <MultiSelectWrapper
                                  name="repeatDays"
                                  isMulti={true}
                                  options={repeatDays}
                                  defaultValue={repeatArr}
                                />
                              </StyledDiv>
                            )}

                            {formik.getFieldMeta("repeats").value !==
                              IAutomationRepeats.WEEKLY &&
                              formik.getFieldProps("repeats").value !== "" && (
                                <StyledDiv className={classes.spanSection}>
                                  <TextfieldWrapper
                                    name="repeatsEvery"
                                    type="number"
                                    label={intl.formatMessage({
                                      id: "automationRulesModal.repeatFrequency",
                                      defaultMessage: "Repeat Frequency",
                                    })}
                                    variant="outlined"
                                    size="small"
                                  />
                                </StyledDiv>
                              )}
                            <FormControl>
                              <RadioComponent
                                radioId="AutomationRulesModalRepeat"
                                setEndValue={setEndValue}
                                formik={formik}
                                endValue={endValue}
                                IAutomationEndDuration={IAutomationEndDuration}
                                classes={classes}
                              />
                            </FormControl>
                            <div>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDateTimePicker
                                  sx={datePicker}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      error:
                                        endValue !==
                                          IAutomationEndDuration.NEVER &&
                                        formik.getFieldMeta("ends").touched &&
                                        !formik.getFieldMeta("ends").value,
                                      helperText:
                                        endValue !==
                                          IAutomationEndDuration.NEVER &&
                                        formik.getFieldMeta("ends").touched &&
                                        !formik.getFieldMeta("ends").value
                                          ? "Required"
                                          : "",
                                      color:
                                        endValue !==
                                          IAutomationEndDuration.NEVER &&
                                        formik.getFieldProps("ends").value &&
                                        formik.getFieldMeta("ends").touched
                                          ? "error"
                                          : "primary",
                                    },
                                  }}
                                  disabled={
                                    endValue === IAutomationEndDuration.NEVER
                                  }
                                  value={
                                    isEditMode && automationRuleDetailsObj.endAt
                                      ? dayjs(automationRuleDetailsObj.endAt)
                                      : null
                                  }
                                  minDate={dayjs(minEndTime)}
                                  label={intl.formatMessage({
                                    id: "automationRulesModal.endTime",
                                    defaultMessage: "End Time",
                                  })}
                                  onChange={(value) => {
                                    formik.setFieldValue(
                                      "ends",
                                      value?.toDate().toISOString(),
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        {showCategoryError ? (
                          <p
                            style={{
                              margin: "0px 0px 5px 0px",
                              color: "#d32f2f",
                            }}
                          >
                            {showCategoryLookUpError
                              ? intl.formatMessage({
                                  id: "unifiedSortingRuleModal.categoryLookUpError",
                                  defaultMessage:
                                    "The selected category list is invalid, please update",
                                })
                              : intl.formatMessage({
                                  id: "unifiedSortingRuleModal.selectCategory",
                                  defaultMessage:
                                    "Please select atleast one target category",
                                })}
                          </p>
                        ) : null}
                        <StyledDiv
                          style={{
                            border: showCategoryError
                              ? "1px solid #d32f2f"
                              : "",
                          }}
                          className={classes.categoriesSection}
                        >
                          {categoriesLoaded ? (
                            <Box>
                              {intl.formatMessage({
                                id: "automationRulesModal.loadingCategories",
                                defaultMessage: "Loading Categories...",
                              })}
                            </Box>
                          ) : savedCategories?.length ? (
                            savedCategories.map((data) => (
                              <Box
                                onClick={() =>
                                  handleSelectData(data?.id || data?.categoryId)
                                }
                              >
                                <StyledDiv
                                  className={`${
                                    data.categoryId === selectedId ||
                                    data.id === selectedId
                                      ? classes.addBackground
                                      : classes.listSpace
                                  }`}
                                >
                                  {data.name || data.code}
                                </StyledDiv>
                              </Box>
                            ))
                          ) : (
                            <Box>
                              {intl.formatMessage({
                                id: "automationRulesModal.noSelection",
                                defaultMessage: "No Categories Selected",
                              })}
                            </Box>
                          )}
                        </StyledDiv>
                        <StyledDiv className={classes.categoryBtnGrp}>
                          <ButtonComponent
                            color="secondaryButtonColorCTABlue"
                            variant="outlined"
                            onClick={handleAddCategories}
                          >
                            {intl.formatMessage({
                              id: "automationRulesModal.addCategories",
                              defaultMessage: "Add Categories",
                            })}
                          </ButtonComponent>
                          <ButtonComponent
                            color="primary"
                            variant="contained"
                            disabled={selectedId === "" ? true : false}
                            onClick={() => {
                              if (selectedId !== "") {
                                handleRemoveCategory(selectedId, formik);
                                setSelectedId("");
                              }
                            }}
                            style={{
                              background: selectedId === "" ? "lightgray" : "",
                            }}
                          >
                            {intl.formatMessage({
                              id: "automationRulesModal.remove",
                              defaultMessage: "Remove",
                            })}
                          </ButtonComponent>
                        </StyledDiv>
                      </div>
                    </StyledDiv>

                    <CategoryTreeModal
                      handleCallbackFn={(inputValues) =>
                        handleCallbackFn(inputValues, formik)
                      }
                      handleCallBackForStores={handleCallBackForStores}
                      storesList={storesListState}
                      savedCategories={savedCategories}
                      automationRulesList={automationRuleDetailsObj}
                      parentData={parentData}
                    />
                    <StyledDiv className={classes.btnGroup}>
                      <ButtonComponent
                        color="secondaryButtonColorCTABlue"
                        variant="outlined"
                        onClick={() => handleClose()}
                      >
                        {intl.formatMessage({
                          id: "unifiedSortingRuleModal.cancel",
                          defaultMessage: "Cancel",
                        })}
                      </ButtonComponent>
                      <ButtonComponent
                        color="primary"
                        variant="contained"
                        justifyContent="centerS"
                        type="submit"
                      >
                        {isEditMode && automationRuleDetailsObj.name !== ""
                          ? intl.formatMessage({
                              id: "unifiedSortingRuleModal.update",
                              defaultMessage: "Update",
                            })
                          : intl.formatMessage({
                              id: "unifiedSortingRuleModal.save",
                              defaultMessage: "Save",
                            })}
                      </ButtonComponent>
                    </StyledDiv>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </StyledDiv>
      </>
    );
  };

  return (
    <Box>
      <CustomDialog
        open={isModalOpen}
        onClose={handleClose}
        title={getAutomationRuleModalTitle()}
        fullWidth
        maxWidth={"md"}
      >
        {getAutomationRuleModalContent()}
      </CustomDialog>
    </Box>
  );
};

export default AutomationRulesModal;
