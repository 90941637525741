import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  RefreshIcon,
  CheckListIcon,
  ChartTreeIcon,
  ColorPaletteIcon,
  ImageIcon,
  InfoIcon,
  HelpIcon,
  StarIcon,
  TrashIcon,
  MoreVerticalIcon,
  FormatLineIcon,
  AddCircleIcon,
  AddCategoryIcon,
  CopyIcon,
  GridIcon,
  AlertIcon,
  CogIcon,
  SearchIcon,
  FilterIcon,
  MoreHorizontalIcon,
  EditIcon,
  SignoutIcon,
  RightArrowSmallIcon,
  LeftArrowSmallIcon,
  DoubleLeftArrowIcon,
  DoubleRightArrowIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  TriangleLeftIcon,
  TriangleRightIcon,
} from "../../assets";

interface IconsProps {
  iconId: string;
  type?: string;
  onClick?: (event: any) => void;
  classes?: string;
  tooltip?: string;
  disabled?: boolean;
}

const Icons: React.FC<IconsProps> = (props) => {
  const { iconId, type, onClick, classes, disabled } = props;
  const useStyles = makeStyles((theme) =>
    createStyles({
      iconContainer: {},
      icon: {
        backgroundColor: "transparent",
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#f18221",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polygon": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
      },
      iconRegular: {
        backgroundColor: "transparent",
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#f18221",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#f18221",
          strokeWidth: 1.5,
        },
        "& polygon": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
      },
      iconBold: {
        backgroundColor: "transparent",
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "inherit",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "inherit",
          strokeWidth: 2,
        },
        "& polygon": {
          stroke: "inherit",
          strokeWidth: 2,
        },
        "& circle": {
          stroke: "inherit",
          strokeWidth: 2,
        },
        "& rect": {
          stroke: "inherit",
          strokeWidth: 2,
        },
        "& polyline": {
          stroke: "inherit",
          strokeWidth: 2,
        },
        "&:hover": {
          stroke: "inherit",
          strokeWidth: 2,
        },
      },
      iconSolid: {
        backgroundColor: "transparent",
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#f18221",
          fill: "#f18221",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polygon": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
      },
      iconBackground: {
        backgroundColor: "#78a6be",
        borderRadius: 3,
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeWidth: 1,
        },
        "& polygon": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
      },
      iconBackgroundHover: {
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeWidth: 0.25,
        },
        "& polygon": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#FFFFFF",
          fill: "#FFFFFF",
          strokeWidth: 0.25,
        },
        "& rect": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
      },
      imageIconBackgroundHover: {
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#FFFFFF",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#FFFFFF",
          strokeWidth: 1,
        },
      },
      iconActive: {
        backgroundColor: "#ffd8ca",
        borderRadius: 3,
        border: 2,
        borderColor: "#e94d25",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#e94d25",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#e94d25",
          strokeWidth: 1,
        },
        "& polygon": {
          stroke: "#e94d25",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#e94d25",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#e94d25",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#e94d25",
          strokeWidth: 1,
        },
      },
      iconHover: {
        backgroundColor: "transparent",
        margin: "2px",
        "& svg": {
          viewBox: "0 0 24.00 24.00",
          stroke: "#f18221",
          strokeLinecap: "butt",
        },
        "& path": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polygon": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& circle": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& rect": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "& polyline": {
          stroke: "#f18221",
          strokeWidth: 1,
        },
        "&:hover": {
          backgroundColor: "#f18221",
          borderRadius: 3,
          "& path": {
            stroke: "#FFFFFF",
            strokeWidth: 1,
          },
        },
      },
    }),
  );
  const iconclass = useStyles();

  const getStyle = (style: string) => {
    switch (style) {
      case "regular":
        return iconclass.iconRegular;
      case "bold":
        return iconclass.iconBold;
      case "solid":
        return iconclass.iconSolid;
      case "background":
        return iconclass.iconBackground;
      case "backgroundhover":
        return iconclass.iconBackgroundHover;
      case "imagebackgroundhover":
        return iconclass.imageIconBackgroundHover;
      case "active":
        return iconclass.iconActive;
      case "hover":
        return iconclass.iconHover;
      default:
        return iconclass.icon;
    }
  };

  switch (iconId) {
    case "RefreshIcon":
      if (onClick) {
        return (
          <>
            <RefreshIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
              style={{ cursor: disabled ? "not-allowed" : "pointer" }}
            ></RefreshIcon>
          </>
        );
      } else
        return (
          <>
            <RefreshIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "CheckListIcon":
      if (onClick) {
        return (
          <>
            <CheckListIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <CheckListIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "ChartTreeIcon":
      if (onClick) {
        return (
          <>
            <ChartTreeIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <ChartTreeIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "ColorPaletteIcon":
      if (onClick) {
        return (
          <>
            <ColorPaletteIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <ColorPaletteIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "ImageIcon":
      if (onClick) {
        return (
          <>
            <ImageIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <ImageIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "InfoIcon":
      if (onClick) {
        return (
          <>
            <InfoIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <InfoIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "HelpIcon":
      if (onClick) {
        return (
          <>
            <HelpIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <HelpIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "StarIcon":
      if (onClick) {
        return (
          <>
            <StarIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <StarIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "TrashIcon":
      if (onClick) {
        return (
          <>
            <TrashIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <TrashIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "MoreVerticalIcon":
      if (onClick) {
        return (
          <>
            <MoreVerticalIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <MoreVerticalIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "FormatLineIcon":
      if (onClick) {
        return (
          <>
            <FormatLineIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <FormatLineIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "AddCircleIcon":
      if (onClick) {
        return (
          <>
            <AddCircleIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <AddCircleIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "AddCategoryIcon":
      if (onClick) {
        return (
          <>
            <AddCategoryIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <AddCategoryIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "CopyIcon":
      if (onClick) {
        return (
          <>
            <CopyIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <CopyIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "GridIcon":
      if (onClick) {
        return (
          <>
            <GridIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <GridIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "AlertIcon":
      if (onClick) {
        return (
          <>
            <AlertIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <AlertIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "CogIcon":
      if (onClick) {
        return (
          <>
            <CogIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <CogIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "SearchIcon":
      if (onClick) {
        return (
          <>
            <SearchIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <SearchIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "FilterIcon":
      if (onClick) {
        return (
          <>
            <FilterIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <FilterIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "MoreHorizontalIcon":
      if (onClick) {
        return (
          <>
            <MoreHorizontalIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <MoreHorizontalIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "EditIcon":
      if (onClick) {
        return (
          <>
            <EditIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <EditIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "SignoutIcon":
      if (onClick) {
        return (
          <>
            <SignoutIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <SignoutIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "RightArrowSmallIcon":
      if (onClick) {
        return (
          <>
            <RightArrowSmallIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <RightArrowSmallIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "LeftArrowSmallIcon":
      if (onClick) {
        return (
          <>
            <LeftArrowSmallIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <LeftArrowSmallIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "DoubleLeftArrowIcon":
      if (onClick) {
        return (
          <>
            <DoubleLeftArrowIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <DoubleLeftArrowIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "DoubleRightArrowIcon":
      if (onClick) {
        return (
          <>
            <DoubleRightArrowIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <DoubleRightArrowIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "TriangleDownIcon":
      if (onClick) {
        return (
          <>
            <TriangleDownIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <TriangleDownIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "TriangleUpIcon":
      if (onClick) {
        return (
          <>
            <TriangleUpIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <TriangleUpIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "TriangleLeftIcon":
      if (onClick) {
        return (
          <>
            <TriangleLeftIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <TriangleLeftIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    case "TriangleRightIcon":
      if (onClick) {
        return (
          <>
            <TriangleRightIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
              onChange={onClick}
            />
          </>
        );
      } else
        return (
          <>
            <TriangleRightIcon
              className={
                classes
                  ? `${classes} ${getStyle(type ?? "")}`
                  : getStyle(type ?? "")
              }
            />
          </>
        );
    default:
      if (iconId) return <></>;
      break;
  }
  return <></>;
};

export default Icons;
