import {
  SET_USER_LOCALE,
  RESET_USER_STATE,
  SetUserLocaleAction,
  UserReducersActions,
} from "./UserTypes";

export interface UserState {
  locale: string;
}

const initialState: UserState = {
  locale: window.localStorage.getItem("userLocalePreference") || "en-US",
};

export const UserReducer = (
  state = initialState,
  action: UserReducersActions,
) => {
  switch (action.type) {
    case SET_USER_LOCALE: {
      const userLocaleAction = action as SetUserLocaleAction;
      return {
        ...state,
        locale: userLocaleAction.payload.locale,
      };
    }
    case RESET_USER_STATE:
      return initialState;
    default:
      return state;
  }
};
