import { UpdatedAt, AffectedRows } from "../product/ProductTypes";
import { AttributeValueDescription } from "../category/CategoryTypes";

export const CHANGE_ATTRIBUTE_VALUE_DESCRIPTION =
  "CHANGE_ATTRIBUTE_VALUE_DESCRIPTION";
export const UPDATE_ATTRIBUTE_VALUE_DESCRIPTION = {
  REQUEST: "UPDATE_ATTRIBUTE_VALUE_DESCRIPTION_REQUEST",
  SUCCESS: "UPDATE_ATTRIBUTE_VALUE_DESCRIPTION_SUCCESS",
  FAILURE: "UPDATE_ATTRIBUTE_VALUE_DESCRIPTION_FAILURE",
};

export interface UpdateAttributeValueDescriptionPayload {
  attributeValueId: string;
  localeCode: string;
  attributeValueDisplayValue: string;
}

export interface UpdateAttributeValueDescriptionAction {
  payload: UpdateAttributeValueDescriptionPayload;
  type: typeof CHANGE_ATTRIBUTE_VALUE_DESCRIPTION;
}

export interface UpdateAttributeValueDescriptionResponse {
  type: string;
  payload: UpdateAttributeValueDescriptionResponsePayload;
  error: string;
}

export interface UpdateAttributeValueDescriptionResponsePayload {
  update_AttributeValueDescription_by_pk: UpdatedAt;
  insert_SyncLog: AffectedRows;
}

/**
 * Update attribute code
 */
export const CHANGE_ATTRIBUTE_VALUE_CODE = "CHANGE_ATTRIBUTE_VALUE_CODE";
export const UPDATE_ATTRIBUTE_VALUE_CODE = {
  REQUEST: "UPDATE_ATTRIBUTE_VALUE_CODE_REQUEST",
  SUCCESS: "UPDATE_ATTRIBUTE_VALUE_CODE_SUCCESS",
  FAILURE: "UPDATE_ATTRIBUTE_VALUE_CODE_FAILURE",
};

export interface UpdateAttributeValueCodePayload {
  attributeValueId: string;
  attributeValueDisplayValue: string;
}

export interface UpdateAttributeValueCodeAction {
  payload: UpdateAttributeValueCodePayload;
  type: typeof CHANGE_ATTRIBUTE_VALUE_CODE;
}

export interface UpdateAttributeValueCodeResponse {
  type: string;
  payload: UpdateAttributeValueCodeResponsePayload;
  error: string;
}

export interface UpdateAttributeValueCodeResponsePayload {
  update_AttributeValue_by_pk: UpdatedAt;
  insert_SyncLog: AffectedRows;
}

/**
 * Update attribute code
 */
export const ADD_ATTRIBUTE_VALUE = "ADD_ATTRIBUTE_VALUE";
export const INSERT_ATTRIBUTE_VALUE = {
  REQUEST: "INSERT_ATTRIBUTE_VALUE_REQUEST",
  SUCCESS: "INSERT_ATTRIBUTE_VALUE_SUCCESS",
  FAILURE: "INSERT_ATTRIBUTE_VALUE_FAILURE",
};

export interface AddAttributeValuePayload {
  attributeValueObject: InsertAttributeValueObject;
}

export interface InsertAttributeValueObject {
  attributeValueId: string;
  code: string;
  attributeId: string;
  sequence: number;
  descriptions: {
    data: AttributeValueDescription;
  } | null;
}

export interface AddAttributeValueAction {
  payload: AddAttributeValuePayload;
  type: typeof ADD_ATTRIBUTE_VALUE;
}

export interface AddAttributeValueResponse {
  type: string;
  payload: AddAttributeValueResponsePayload;
  error: string;
}

export interface AddAttributeValueResponsePayload {
  insert_AttributeValue: AffectedRows;
  insert_SyncLog: AffectedRows;
}

/**
 * Get attribute by id types
 */
export const GET_ATTRIBUTE_DATA_BY_ID = "GET_ATTRIBUTE_DATA_BY_ID";
export const GET_ATTRIBUTE_BY_ID = {
  REQUEST: "GET_ATTRIBUTE_BY_ID_REQUEST",
  SUCCESS: "GET_ATTRIBUTE_BY_ID_SUCCESS",
  FAILURE: "GET_ATTRIBUTE_BY_ID_FAILURE",
};

export interface GetAttributeByIdPayload {
  attributeId: string;
}

export interface GetAttributeByIdAction {
  payload: GetAttributeByIdPayload;
  type: typeof GET_ATTRIBUTE_DATA_BY_ID;
}
