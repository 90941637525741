export const styles = () => {
  return {
    bodyWrap: {
      m: 1,
      minWidth: 120,
      height: "32px",
      maxHeight: "32px",
      display: "contents",
      marginLeft: 20,
      marginRight: 10,
    },
    select: {
      minWidth: 200,
      maxHeight: "32px",
      margin: "10px",
      border: "black",
    },
    skeleton: {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      padding: "10px",
    },
    muiListSubheader: {
      backgroundColor: "white",
      fontWeight: "bold",
      padding: "8px 16px",
      zIndex: 1,
    },
    muiMenuItem: {
      padding: "8px 16px",
    },
  };
};
