import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useIntl } from "react-intl";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { AlertOptions } from "../../store/global-alert/GlobalAlertReducers";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { classicTheme } from "styles/themes/classic";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} {...props} />;
}

interface AlertMessageProps {
  alertMessageId: string;
  classes?: ClassNameMap;
  handleClose?: (
    id: string,
    event?: React.SyntheticEvent,
    reason?: string,
  ) => void;
  alerts?: AlertOptions[];
  open?: boolean;
  onClose?: () => void;
  severity?: "error" | "warning" | "info" | "success";
  message?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = (props) => {
  const { classes, alerts, handleClose, onClose, open, severity, message } =
    props;
  const intl = useIntl();
  const useStyles = makeStyles(() =>
    createStyles({
      alertButton: {
        color: classicTheme.palette.primary.main,
      },
    }),
  );
  const alertclass = useStyles();

  const action = (
    <>
      <Button color="secondary" size="small">
        CANCEL
      </Button>
      <Button className={alertclass.alertButton} color="primary" size="small">
        OK
      </Button>
    </>
  );

  switch (props.alertMessageId) {
    case "GlobalAlertMessage":
      if (classes && alerts && handleClose) {
        return (
          <Snackbar
            open={!!alerts.length}
            classes={{ root: classes.snackbar }}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <>
              {alerts.map((alert, i) => {
                return (
                  <Alert
                    key={
                      alert.descriptor
                        ? alert.descriptor.id + i
                        : alert.message
                          ? alert.message + i
                          : i
                    }
                    onClose={() => handleClose(alert.id)}
                    severity={alert.severity}
                    variant={alert.variant}
                    classes={{ root: classes.alert }}
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                  >
                    <Typography className={classes.alertMessage}>
                      {alert.descriptor
                        ? alert.variables
                          ? intl.formatMessage(
                              alert.descriptor,
                              alert.variables,
                            )
                          : intl.formatMessage(alert.descriptor)
                        : alert.message}
                    </Typography>
                  </Alert>
                );
              })}
            </>
          </Snackbar>
        );
      }
      break;
    default:
      if (onClose && severity && message)
        return (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={open ?? false}
            autoHideDuration={6000}
            onClose={onClose}
            action={action}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={onClose}
              severity={severity}
            >
              Alert Message
              <div>{message}</div>
            </MuiAlert>
          </Snackbar>
        );
      break;
  }
  return <></>;
};

export default AlertMessage;
