import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../../store/modal/ModalActions";
import {
  loadCallerAccounts,
  loadAllAccountDetails,
} from "../../../store/app-config/AppConfigActions";
import {
  selectCallerAccountId,
  selectCallerAccounts,
  selectIsCallerAccountsInvoked,
  selectIsFetchingCallerAccountsFailed,
} from "../../../store/app-config/AppConfigSelectors";
import { CircularProgress } from "@material-ui/core";
import { setCallerAccount } from "../../../utils/SmartMerchandiserAPI";
import { useQuery } from "../../../hooks/useQueryParams";
import { useLocation } from "react-router";
import { useStyles } from "./CallerAccountModalStyled";
import CallerAccountsErrorModal from "./CallerAccountsErrorModal";
import { userLoggedOut } from "../../../services/Auth0UserService";

const AccountSelection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const callerAccounts = useSelector(selectCallerAccounts);
  const isCallerAccountsInvoked = useSelector(selectIsCallerAccountsInvoked);
  const isFetchingFailed = useSelector(selectIsFetchingCallerAccountsFailed);
  const query = useQuery();
  const userAccountIdQuery = query.get("accountId") ?? "";
  const selectedCallerAccountId = useSelector(selectCallerAccountId);
  const pathName = useLocation().pathname;
  const isUserLoggedOut = userLoggedOut();

  useEffect(() => {
    if (selectedCallerAccountId === "" && !isUserLoggedOut) {
      dispatch(loadCallerAccounts());
    }
  }, [dispatch, selectedCallerAccountId, isUserLoggedOut]);

  useEffect(() => {
    if (callerAccounts.accounts?.length === 0 && isCallerAccountsInvoked) {
      dispatch(setModalState("ErrorAccountModalId", true));
    } else if (
      callerAccounts.accounts?.length === 1 &&
      selectedCallerAccountId === ""
    ) {
      const accountId = callerAccounts.accounts[0].accountId;
      if (userAccountIdQuery === "") {
        setCallerAccount(accountId);
      }
      dispatch(loadAllAccountDetails({ accountId }));
    } else if (
      callerAccounts.accounts?.length > 1 &&
      selectedCallerAccountId === ""
    ) {
      if (userAccountIdQuery === "") {
        dispatch(setModalState("AccountModalId", true));
      } else if (userAccountIdQuery !== "") {
        dispatch(loadAllAccountDetails({ accountId: userAccountIdQuery }));
      }
    }
  }, [
    dispatch,
    callerAccounts,
    isCallerAccountsInvoked,
    userAccountIdQuery,
    selectedCallerAccountId,
  ]);

  useEffect(() => {
    if (selectedCallerAccountId === "" && userAccountIdQuery !== "") {
      setCallerAccount(userAccountIdQuery);
    }
  }, [selectedCallerAccountId, userAccountIdQuery]);

  return (
    <>
      {callerAccounts.accounts?.length === 0 &&
      !isCallerAccountsInvoked &&
      !isFetchingFailed &&
      pathName.includes("/catalog") ? (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      ) : (
        callerAccounts.accounts?.length === 0 &&
        isCallerAccountsInvoked && <CallerAccountsErrorModal />
      )}
    </>
  );
};

export default AccountSelection;
