import type { Components } from "@mui/material";

export const MuiDialogTitle: Components["MuiDialogTitle"] = {
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F18221 !important",
      padding: "30px 23px 30px 23px",
      marginBottom: 2,
      gap: 10,
      maxHeight: 52,
    },
  },
};
