import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  BUSINESS_RULES_NAVIGATION_TABS,
  CATALOG_NAVIGATION_TABS,
  REPORT_TYPE_NAVIGATION_TABS,
  SEQUENCE_MODE_TYPE,
} from "../utils/Constants";
import { updateProductListSequenceMode } from "../store/product-list/ProductListActions";
import { selectProductSequenceMode } from "../store/product-list/ProductListSelectors";
import ConfirmationActionSequence from "./ConfirmationActionSequence";
import { selectCallerAccountId } from "../store/app-config/AppConfigSelectors";
import TabsComponent from "./common/Tabs";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "75%",
        width: "100%",
        backgroundColor: "rgb(112, 155, 177)",
      },
    },
    tab: {
      minWidth: "100px",
      whiteSpace: "nowrap",
    },
  }),
);

const showUnimplemented =
  window.localStorage.getItem("showUnimplemented") === "true";

const routes = [
  {
    name: { id: "tabsMenu.catalog", defaultMessage: "Catalog" },
    link: `/catalog/${CATALOG_NAVIGATION_TABS.PRODUCTS}`,
    activeIndex: 0,
  },
  {
    name: { id: "tabsMenu.sortingRules", defaultMessage: "Sorting Rules" },
    link: `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES}`,
    activeIndex: 1,
  },
  {
    name: {
      id: "businessRulesTabs.schedules",
      defaultMessage: "Schedules",
    },
    link: `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES}`,
    activeIndex: 1,
  },
];

if (showUnimplemented)
  routes.push({
    name: { id: "tabsMenu.reports", defaultMessage: "Reports" },
    link: `/reports/${REPORT_TYPE_NAVIGATION_TABS.PRODUCT}`,
    activeIndex: 1,
  });

const TabsMenu = () => {
  const location = useLocation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const currentTab =
    routes.find((route) => route.link.includes(location.pathname))
      ?.activeIndex ?? 0;
  const [value, setValue] = useState(currentTab);
  const history = useHistory();
  const isSequenceUpdated = useSelector(selectProductSequenceMode);
  const [isOpen, setIsOpen] = useState(false);
  const userAccountId = useSelector(selectCallerAccountId);

  const handleConfirmation = (checked?) => {
    if (checked && isSequenceUpdated) {
      setIsOpen(true);
    } else {
      const tab = routes.findIndex(
        (route) => route.name.defaultMessage === "Sorting Rules",
      );
      const link = routes[tab].link + `?accountId=${userAccountId}`;
      setValue(tab);
      setIsOpen(false);
      history.push(link);
      dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.VIEW));
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (!isSequenceUpdated && value !== newValue) {
      // close all websocket subscriptions
      //dispatch({ type: CLOSE_ALL_SUBSCRIPTIONS });
      //dispatch(resetCategory());
      //dispatch(resetProductList());
      //dispatch(resetProducts());
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (location.pathname === "/catalog/products" && location.search !== "") {
      localStorage.setItem("catalogPath", location.search);
    }
  }, [location]);

  useEffect(() => {
    const link = location.pathname.split("/")[1];
    routes.forEach((route) => {
      if (route.name.defaultMessage === "Catalog") {
        const categoryPath = localStorage.getItem("catalogPath");
        if (
          location.pathname ===
            `/catalog/${CATALOG_NAVIGATION_TABS.PRODUCTS}` &&
          location.search === ""
        ) {
          localStorage.setItem("catalogPath", "");
          route.link = `/catalog/${CATALOG_NAVIGATION_TABS.PRODUCTS}`;
        } else {
          route.link = `/catalog/${CATALOG_NAVIGATION_TABS.PRODUCTS}${categoryPath !== null ? categoryPath : ""}`;
        }
      }
      if (`/${link}` === route.link) {
        setValue(route.activeIndex);
      }
    });
  }, [location]);

  return (
    <TabsComponent
      tabsId="TabsMenu"
      classes={classes}
      value={value}
      routes={routes}
      userAccountId={userAccountId}
      handleChange={handleChange}
      handleConfirmation={handleConfirmation}
    >
      <ConfirmationActionSequence
        isModalOpen={isOpen}
        setIsModalOpen={setIsOpen}
        handleChange={() => handleConfirmation()}
      />
    </TabsComponent>
  );
};

export default TabsMenu;
