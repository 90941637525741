import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

type Order = "asc" | "desc";

interface ItemManagementSFCCTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headerLabels: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }),
);

function ItemManagementSFCCTableHead(props: ItemManagementSFCCTableHeadProps) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, headerLabels } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headerLabels.map((key, index) => {
          if (
            key === "published" ||
            key === "publishedStore" ||
            key === "action"
          ) {
            return (
              <TableCell
                align="center"
                key={`${key}_${index}`}
                padding="none"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {(key === "published"
                  ? "Published by Default"
                  : key === "publishedStore"
                    ? "Store Publish"
                    : "Delete"
                ).toLocaleUpperCase()}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={key}
                sortDirection={orderBy === key ? order : false}
                align="center"
                padding="none"
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <TableSortLabel
                  active={orderBy === key}
                  direction={orderBy === key ? order : "asc"}
                  onClick={createSortHandler(key)}
                >
                  {key.toLocaleUpperCase()}
                  {orderBy === key ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

export default React.memo(ItemManagementSFCCTableHead);
