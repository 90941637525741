import {
  FETCH_CATALOG_LIST,
  LoadCatalogListResponseAction,
  SetCurrentCatalogIdAction,
  CatalogList,
  AllCatalog,
  SET_CATALOG_ID,
  FETCH_ALL_CATALOGS,
  LoadAllCatalogsResponseAction,
  LOAD_PROGRESS,
} from "./CatalogTypes";

export interface CatalogState {
  catalogList: CatalogList;
  allCatalogs: AllCatalog[];
  currentCatalogId: string;
  isCatalogsLoaded: boolean;
  isProgessing: boolean;
}

const initialState: CatalogState = {
  catalogList: {},
  allCatalogs: [],
  currentCatalogId: "",
  isCatalogsLoaded: false,
  isProgessing: false,
};

export const CatalogListReducer = (
  state = initialState,
  action:
    | LoadCatalogListResponseAction
    | SetCurrentCatalogIdAction
    | LoadAllCatalogsResponseAction,
): CatalogState => {
  switch (action.type) {
    case FETCH_CATALOG_LIST.FAILURE:
      return {
        ...state,
        isCatalogsLoaded: true,
        isProgessing: false,
      };
    case FETCH_CATALOG_LIST.SUCCESS:
      const fetchAction = action as LoadCatalogListResponseAction;
      return {
        ...state,
        catalogList: {
          ...state.catalogList,
          [fetchAction.payload.storeId]: fetchAction.payload.results.map(
            (data) => data.catalogId,
          ),
        },
        isCatalogsLoaded: true,
        isProgessing: false,
      };
    case FETCH_ALL_CATALOGS.SUCCESS: {
      const fetchAction = action as LoadAllCatalogsResponseAction;
      return {
        ...state,
        allCatalogs: fetchAction.payload.results.map((data) => {
          return {
            id: data.catalogId,
            name: data.name,
            code: data.name,
          };
        }),
      };
    }
    case SET_CATALOG_ID:
      const {
        payload: { catalogId },
      } = action as SetCurrentCatalogIdAction;
      if (catalogId === state.currentCatalogId) {
        return state;
      } else {
        return {
          ...state,
          currentCatalogId: catalogId,
        };
      }
    case LOAD_PROGRESS:
      return {
        ...state,
        isProgessing: true,
      };
    default:
      return state;
  }
};
