import { put, takeEvery } from "redux-saga/effects";
import { setModalStateAction, SET_MODAL_STATE } from "./ModalTypes";
import { resetColorManagementProductList } from "../color-management/ColorManagementActions";
import { resetLeadingImageList } from "../leading-image/LeadingImageActions";

function* resetModalList(action: setModalStateAction) {
  const { modalId, ...restPayload } = action.payload;
  if (modalId === "ColorManagementModal" && !restPayload.isOpen) {
    yield put(resetColorManagementProductList());
  }
  if (modalId === "LeadingImageModal" && !restPayload.isOpen) {
    yield put(resetLeadingImageList());
  }
}

export function* watchResetColorManagementList() {
  yield takeEvery(SET_MODAL_STATE, resetModalList);
}
