import { SET_LOADER_STATE, setLoaderStateAction } from "./LoaderTypes";

export interface LoaderState {
  //loaderType: string;
  //isLoading: boolean;
  [key: string]: { isLoading: boolean; [key: string]: any };
}

const initialState: LoaderState = {};

export const LoaderReducer = (
  state = initialState,
  action: setLoaderStateAction,
) => {
  switch (action.type) {
    case SET_LOADER_STATE:
      //const { loaderType, isLoading } = action.payload;
      const { loaderType, ...restPayload } = action.payload;
      return {
        ...state,
        [action.payload.loaderType]: restPayload,
      };
    default:
      return state;
  }
};
