import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  InputLabel,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Link, Typography, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../../store/modal/ModalActions";
import JobListDetailsModal from "../../jobs-list-modal/JobListDetailsModal";
import {
  JobDetails,
  JobListPayload,
  jobsList,
  pendingJobsList,
} from "../../../../store/businessRules-list/BusinessRulesListTypes";
import { useIntl } from "react-intl";
import moment from "moment-timezone";
import DropdownMenu from "../../../common/DropdownMenu";

const options = [{ label: "Automation", value: "Automation" }];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderClass: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    loaderText: {
      marginRight: 20,
    },
    msgClass: {
      display: "flex",
      justifyContent: "center",
      fontSize: 20,
      fontWeight: 600,
    },
  }),
);

interface Props {
  jobsList: jobsList[];
  isLoading: boolean;
  isJobListFetched: boolean;
  pendingJobsList: pendingJobsList[];
}
const JobListDataGridUI: React.FC<Props> = ({
  jobsList,
  isLoading,
  isJobListFetched,
  pendingJobsList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const [jobData, setJobData] = React.useState<JobListPayload[]>([]);
  const [individualJobDetails, setIndividualJobDetails] =
    React.useState<JobDetails>({} as JobDetails);

  const handleDetails = (data: JobDetails) => {
    dispatch(setModalState("JobListDetails", true));
    setIndividualJobDetails(data);
    return;
  };

  React.useEffect(() => {
    if (jobsList.length) {
      const groupedArray = {};

      jobsList.forEach((item) => {
        const jobExecutionId = item.jobExecutionId;

        if (!groupedArray[jobExecutionId]) {
          groupedArray[jobExecutionId] = [];
        }

        groupedArray[jobExecutionId].push(item);
      });
      const newArrData: JobListPayload[] = [];
      for (let key in groupedArray) {
        let totalSteps = groupedArray[key].find((result) => {
          return result.totalSteps;
        });
        let steps = groupedArray[key].filter((result) =>
          result.logType.startsWith("STEP_"),
        );
        let isInitPresent = groupedArray[key].findIndex((result) =>
          result.logType.startsWith("INIT"),
        );
        const newJobObj = {
          jobExecutionId: "",
          jobId: "",
          status: "",
          name: "",
          startTime: "",
          endTime: "",
          progress: 0,
          logTime: "",
          logType: "",
          description: "",
        };

        groupedArray[key].forEach((jobData: JobListPayload) => {
          if (jobData.logType === "INIT") {
            newJobObj.jobExecutionId = jobData.jobExecutionId;
            newJobObj.jobId = jobData?.jobId ?? "";
            newJobObj.status = jobData.logType;
            newJobObj.name = jobData.name;
            newJobObj.startTime = moment(jobData.logTime).format(
              "MM/DD/YYYY HH:mm A",
            );
            newJobObj.progress = (steps.length / totalSteps.totalSteps) * 100;
            newJobObj.description = jobData.hasOwnProperty("description")
              ? jobData.description
              : "";
            return newJobObj;
          }
          if (jobData.logType === "DONE") {
            newJobObj.jobExecutionId = jobData.jobExecutionId;
            newJobObj.status = jobData.logType;
            newJobObj.endTime = moment(jobData.logTime).format(
              "MM/DD/YYYY HH:mm A",
            );
            return newJobObj;
          }
          if (jobData.logType === "FAILED") {
            newJobObj.jobExecutionId = jobData.jobExecutionId;
            newJobObj.status = jobData.logType;
            newJobObj.endTime = moment(jobData.logTime).format(
              "MM/DD/YYYY HH:mm A",
            );
            return newJobObj;
          }
        });
        if (isInitPresent !== -1) {
          newArrData.push(newJobObj);
        }
      }
      if (pendingJobsList.length > 0) {
        pendingJobsList.forEach((item) => {
          const search = newArrData.find((e) => e.jobId === item.jobId);
          if (
            (search && item.repeats && item.nextRunAt) ||
            search === undefined
          ) {
            const jobStartTime = item.repeats ? item.nextRunAt : item.startAt;
            const pendingJobObj = {
              jobExecutionId: item.jobId,
              status: "Pending",
              name: item.name,
              startTime:
                jobStartTime &&
                moment(jobStartTime).format("MM/DD/YYYY HH:mm A"),
              endTime: "",
              logTime: "",
              logType: "",
              description: "",
            };
            newArrData.push(pendingJobObj);
          }
        });
      }
      setJobData(newArrData);
      return;
    } else {
      setJobData([]);
      return;
    }
  }, [jobsList, pendingJobsList]);

  const columns: GridColDef[] = [
    {
      field: "startTime",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.startTime",
        defaultMessage: "Start Time",
      }),
      width: 200,
      disableColumnMenu: true,
      sortComparator: (d1, d2) => {
        const date1 = moment(d1, "MM/DD/YYYY hh:mm A").toDate();
        const date2 = moment(d2, "MM/DD/YYYY hh:mm A").toDate();
        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "endTime",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.endTime",
        defaultMessage: "End Time",
      }),
      width: 200,
      disableColumnMenu: true,
      sortComparator: (v1, v2) => {
        const date1 = moment(v1, "MM/DD/YYYY hh:mm A").toDate();
        const date2 = moment(v2, "MM/DD/YYYY hh:mm A").toDate();
        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.name",
        defaultMessage: "Name",
      }),
      width: 300,

      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "progress",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.progress",
        defaultMessage: "Progress",
      }),
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "status",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.status",
        defaultMessage: "Status",
      }),
      width: 200,
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <div>
            {row.row.status === "INIT"
              ? "In Progress"
              : row.row.status === "Pending"
                ? "Pending"
                : row.row.status === "FAILED"
                  ? "Failed"
                  : "Done"}
          </div>
        );
      },
    },
    {
      field: "col6",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.action",
        defaultMessage: "Action",
      }),
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <div>
            <Link
              style={{
                color: "#337ab7",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => handleDetails(row.row)}
            >
              {intl.formatMessage({
                id: "jobsListDataGridUI.details",
                defaultMessage: "Details",
              })}
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div style={{ marginBottom: 20, display: "flex", alignItems: "center" }}>
        <InputLabel style={{ marginRight: 10 }}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "jobsListDataGridUI.jobType",
              defaultMessage: "Job Type",
            })}
          </Typography>
        </InputLabel>
        <DropdownMenu
          menuId="JobsListDataGridUI"
          value={options[0].value}
          options={options}
        />
      </div>
      {isLoading && !isJobListFetched && (
        <Box className={classes.loaderClass}>
          <Box className={classes.loaderText}>Loading data...</Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
      {jobData.length > 0 && (
        <div>
          <DataGrid
            getRowId={(row: jobsList) => row.jobExecutionId}
            rows={jobData}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [
                  { field: "startTime", sort: "desc" },
                  { field: "endTime", sort: "desc" },
                ],
              },
            }}
          />
        </div>
      )}
      {isLoading && !jobData.length && isJobListFetched && (
        <div className={classes.msgClass}>No Data Found</div>
      )}

      {individualJobDetails !== null ? (
        <JobListDetailsModal data={individualJobDetails} />
      ) : null}
    </div>
  );
};

export default JobListDataGridUI;
