import { styled, Theme } from "@mui/material";

export const styleClasses = {
  overlay: "overlay",
  btnCloseDiv: "btnCloseDiv",
};

export const StyledDiv = styled("div")(() => ({
  [`&.${styleClasses.overlay}`]: {
    position: "absolute",
    top: "50%",
    left: 50,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99999,
  },
  [`&.${styleClasses.btnCloseDiv}`]: {
    textAlign: "end",
    width: "100%",
    margin: "20px",
  },
}));

const useStyles = (theme: Theme) => {
  return {
    dialogPaper: {
      height: "80vh",
      minWidth: "80vw",
      overflow: "hidden",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.grey[200],
      marginBottom: "24px",
      padding: "0 0 0 24px",
      lineHeight: "1",
    },
    rmButton: {
      "&:disabled": {
        background: "#cfcfcf!important",
      },
    },
    closeIcon: {
      width: 21,
      height: 21,
      border: 2,
      color: theme.palette.text.primary,
    },
  };
};

export default useStyles;
