import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import AppState from "../../store/AppState";
import {
  selectCurrentStoreId,
  selectStoreListWithLocalCode,
  selectStoreInfoById,
  selectUniqueLocaleCodes,
} from "../../store/store-list/StoreListSelectors";
import TabsComponent from "../common/Tabs";
import CheckboxComponent from "../common/Checkbox";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  affected: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  onSelectedLocaleCode: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function MultipleStoreLanguageTabs(props: Props) {
  const { onSelectedLocaleCode } = props;
  const classes = useStyles();

  const storeList = useSelector(selectStoreListWithLocalCode);
  const currentStoreId = useSelector(selectCurrentStoreId);
  const currentStore = useSelector((state: AppState) =>
    selectStoreInfoById(state, currentStoreId ?? ""),
  );
  const uniqueLocaleCodes = useSelector(selectUniqueLocaleCodes);

  const [storesData, setStoresData] = useState<
    {
      checked: boolean;
      storeId: string;
      localeCodes: string[];
      affected?: boolean;
    }[]
  >([]);

  const test: { [key: string]: string[] } = {};
  uniqueLocaleCodes.forEach((LocaleCode) => {
    test[LocaleCode] = [];
    storesData.forEach((store) => {
      if (store.localeCodes.includes(LocaleCode)) {
        test[LocaleCode].push(store.storeId);
      }
    });
  });

  const [value, setValue] = useState(0);
  const [selectedLangCodes, setSelectedLangCodes] = useState<
    { localeCode: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    if (value === 0) {
      const storesData = storeList.map((store) => ({
        storeId: store.storeId,
        localeCodes: store?.localeCodes ?? [],
        checked: true,
      }));
      setStoresData(storesData);
    }
  }, [storeList, value]);

  useEffect(() => {
    if (value === 0) {
      const AllSelectedLocaleCodes = storesData.reduce(
        (acc: string[], store) => {
          if (store.checked) {
            acc.push(...store.localeCodes);
          }
          return acc;
        },
        [],
      );

      const uniqueLocaleCode = [...new Set(AllSelectedLocaleCodes)];

      onSelectedLocaleCode(uniqueLocaleCode);
    }
  }, [onSelectedLocaleCode, storesData, value]);

  useEffect(() => {
    if (value === 1) {
      const AllSelectedLocaleCodes = storesData.reduce(
        (acc: string[], store) => {
          acc.push(...store.localeCodes);
          return acc;
        },
        [],
      );

      const uniqueLocaleCode = [...new Set(AllSelectedLocaleCodes)];
      const localeCodes = uniqueLocaleCode.map((localeCode) => ({
        localeCode,
        checked: true,
      }));

      setSelectedLangCodes(localeCodes);
    }
  }, [currentStore, storesData, value]);

  useEffect(() => {
    const k: string[] = [];
    selectedLangCodes.forEach((langCode) => {
      if (langCode.checked) {
        k.push(langCode.localeCode);
      }
    });

    onSelectedLocaleCode(k);
  }, [currentStore, onSelectedLocaleCode, selectedLangCodes]);

  const tabChangeHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const storeCheckHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentRow = event.target.name;
    const AllSelectedLocaleCodes = storesData.reduce((acc: string[], store) => {
      if (store.checked && store.storeId !== currentRow) {
        acc.push(...store.localeCodes);
      }
      return acc;
    }, []);

    const uniqueLocaleCodeByStore = [...new Set(AllSelectedLocaleCodes)];

    const currentRowIndex = storesData.findIndex(
      (store) => store.storeId === currentRow,
    );
    const newStoresData = [...storesData];
    const currentStore = newStoresData[currentRowIndex];
    if (currentStore.checked) {
      const affected = currentStore.localeCodes.some((localeCode) =>
        uniqueLocaleCodeByStore.includes(localeCode),
      );
      currentStore.affected = affected;
    } else {
      currentStore.affected = false;
    }
    currentStore.checked = !currentStore.checked;
    setStoresData(newStoresData);
  };

  const handleSelectAllStores = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newStoreList = storesData.map((store) => {
      store.checked = true;
      store.affected = false;
      return store;
    });
    setStoresData(newStoreList);
  };

  const handleUnselectAllStores = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const currentRowIndex = storesData.findIndex(
      (store) => store.storeId === currentStoreId,
    );
    const newStoresData = [...storesData];
    const currentStore = newStoresData[currentRowIndex];
    const newStoreList = storesData.map((store) => {
      if (store.storeId === currentStoreId) {
        store.checked = true;
      } else {
        store.checked = false;
        store.affected = store.localeCodes.some((localeCode) =>
          currentStore.localeCodes.includes(localeCode),
        );
      }
      return store;
    });
    setStoresData(newStoreList);
  };

  const handleLocaleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLangCodes = selectedLangCodes.map((langCode) => {
      if (langCode.localeCode === event.target.name) {
        langCode.checked = !langCode.checked;
      }
      return langCode;
    });
    setSelectedLangCodes(newLangCodes);
  };

  const handleSelectAllLocales = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newLangCodes = selectedLangCodes.map((langCode) => {
      langCode.checked = true;
      return langCode;
    });
    setSelectedLangCodes(newLangCodes);
  };

  const handleUnselectAllLocales = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const newLangCodes = selectedLangCodes.map((langCode) => {
      if (
        currentStore?.localeCodes.find(
          (e) => e.localeCode === langCode.localeCode,
        )
      ) {
        langCode.checked = true;
      } else {
        langCode.checked = false;
      }
      return langCode;
    });
    setSelectedLangCodes(newLangCodes);
  };

  return (
    <div className={classes.root}>
      <TabsComponent
        tabsId="MultipleStoreLanguageTabs"
        value={value}
        handleChange={tabChangeHandler}
      ></TabsComponent>
      <TabPanel value={value} index={0}>
        <FormControl>
          <FormLabel>
            <Grid container justifyContent="space-between">
              <Grid item>
                <span style={{ fontSize: "0.875rem" }}>
                  Select Stores you want to apply the changes to:
                </span>
              </Grid>
              <Grid item>
                <Link
                  href="#"
                  onClick={handleSelectAllStores}
                  color="primary"
                  underline="none"
                >
                  <span style={{ fontSize: "0.875rem", margin: "8px" }}>
                    Select All
                  </span>
                </Link>
                /
                <Link
                  href="#"
                  onClick={handleUnselectAllStores}
                  color="secondary"
                  underline="none"
                >
                  <span style={{ fontSize: "0.875rem", margin: "8px" }}>
                    Unselect All
                  </span>
                </Link>
              </Grid>
            </Grid>
          </FormLabel>
          <FormGroup>
            {storesData.map((store, index) => (
              <FormControlLabel
                key={`${store.storeId}_${index}`}
                control={
                  <CheckboxComponent
                    checkboxId="MultipleStoreLanguageTabs"
                    checked={store.checked}
                    disabled={store.storeId === currentStoreId}
                    value={store.storeId}
                    onChange={storeCheckHandler}
                  />
                }
                label={
                  <>
                    <span
                      style={{ fontSize: "0.875rem", whiteSpace: "normal" }}
                    >
                      {`${store.storeId} (Supported languages: ${
                        store.localeCodes?.join(", ") ?? ""
                      })`}
                    </span>
                    {store.affected && (
                      <small className={classes.affected}>
                        Will be affected
                      </small>
                    )}
                  </>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Grid container justifyContent="space-between">
              <Grid item>
                <span style={{ fontSize: "0.875rem" }}>
                  Select languages you want to apply the changes to:
                </span>
              </Grid>
              <Grid item>
                <Link href="#" onClick={handleSelectAllLocales} color="primary">
                  <span style={{ fontSize: "0.875rem", margin: "8px" }}>
                    Select All
                  </span>
                </Link>
                /
                <Link
                  href="#"
                  onClick={handleUnselectAllLocales}
                  color="secondary"
                >
                  <span style={{ fontSize: "0.875rem", margin: "8px" }}>
                    Unselect All
                  </span>
                </Link>
              </Grid>
            </Grid>
          </FormLabel>
          <FormGroup>
            {selectedLangCodes.map((langCode, index) => (
              <React.Fragment key={`${langCode.localeCode}_${index}`}>
                <FormControlLabel
                  control={
                    <CheckboxComponent
                      checkboxId="MultipleStoreLanguageTabs"
                      checked={langCode.checked}
                      // disabled={currentStore?.localeCodes.find(
                      //   e => e.localeCode ===
                      //     langCode.localeCode
                      // )}
                      value={langCode.localeCode}
                      onChange={handleLocaleCheck}
                    />
                  }
                  label={
                    <span style={{ fontSize: "0.875rem" }}>
                      {`${langCode.localeCode} (${langCode.localeCode})`}
                    </span>
                  }
                />
                <small style={{ marginTop: "-10px" }}>
                  Affecting stores: {test[langCode.localeCode].join(", ")}
                </small>
              </React.Fragment>
            ))}
          </FormGroup>
        </FormControl>
      </TabPanel>
    </div>
  );
}
