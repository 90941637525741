import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../store/AppState";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import { setModalState } from "../../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { Box, Typography } from "@material-ui/core";
import JobListDataGridUI from "../BusinessRulesContainer/DataGrid/JobsListDataGridUI";
import {
  selectIsJobListFetched,
  selectJobsListFetched,
  selectPendingJobsListFetched,
} from "../../../store/businessRules-list/BusinessRuleslistSelectors";
import { selectIsLoading } from "../../../store/loader/LoaderSelectors";
import { setLoaderState } from "../../../store/loader/LoaderActions";
import CustomDialog from "../../common/DialogContainer";

const JobListModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "JobListModal"),
  );
  const jobsList = useSelector(selectJobsListFetched);
  const pendingJobsList = useSelector(selectPendingJobsListFetched);
  const isLoading = useSelector((state: AppState) =>
    selectIsLoading(state, "JOB_LIST_LOADER"),
  );
  const isJobListFetched = useSelector(selectIsJobListFetched);
  React.useEffect(() => {
    if (jobsList.length) {
      dispatch(setLoaderState("JOB_LIST_LOADER", false));
      return;
    }
  }, [dispatch, jobsList]);

  const jobListModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "header.jobList",
          defaultMessage: "Job List",
        })}
      </Typography>
    );
  };

  const jobListModalContent = () => {
    return (
      <>
        <Box>
          <JobListDataGridUI
            jobsList={jobsList}
            isLoading={isLoading}
            isJobListFetched={isJobListFetched}
            pendingJobsList={pendingJobsList}
          />
        </Box>
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => dispatch(setModalState("JobListModal", false))}
      title={jobListModalTitle()}
      fullWidth
      maxWidth={"lg"}
    >
      {jobListModalContent()}
    </CustomDialog>
  );
};

export default JobListModal;
