import AppState from "../AppState";

export const selectLeadingImageUrlForProductInCategory = (
  state: AppState,
  productId: string,
  categoryId: string,
) =>
  state.leadingImage?.imageIdByProductIdByCategoryId[productId]?.[categoryId]
    ?.url;

export const selectLeadingImageIdForProductInCategory = (
  state: AppState,
  productId: string,
  categoryId: string,
) =>
  state.leadingImage?.imageIdByProductIdByCategoryId[productId]?.[categoryId]
    ?.imageId;

export const selectIsRefreshing = (state: AppState) =>
  state.leadingImage?.isRefreshing;

export const selectProductImages = (state: AppState) => {
  return state.leadingImage.images;
};

export const selectVariationAttributes = (state: AppState) => {
  return state.leadingImage.variationAttributes;
};

export const selectFilterValue = (
  state: AppState,
  productId: string,
  categoryId: string,
) => {
  return state.leadingImage.selectedFilterValue[productId]?.[categoryId];
};

export const selectIsRefreshingLeadingImageModal = (state: AppState) => {
  return state.leadingImage.isRefreshingLeadingImageModal;
};

export const selectIsVariationAttributesCallInvoked = (state: AppState) => {
  return state.leadingImage.isVariationAttributesCallInvoked;
};

export const selectIsSettingLeadingImage = (state: AppState) => {
  return state.leadingImage.isSettingLeadingImage;
};
