import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import ColorManagementProductItem from "./ColorManagementProductItem";
import { ViewContext } from "../../context/view-context";
import { ColorData } from "../../store/product/ProductTypes";

const StyledItemsDragPreview = styled.div<{ fontSize: string }>`
  position: relative;
  font-size: ${({ fontSize }) => fontSize};
`;

const StyledDraggedItemsContainer = styled.div`
  position: absolute;
  transform-origin: bottom left;
`;

const StyledProductSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
`;

const StyledDraggedItem = styled.div<{ width: number; height: number }>`
  display: grid;
  grid-template-rows: auto 1fr;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  border-radius: 5px;
  background: ${(props) => props.theme.palette.background.paper};
  color: black;
  padding: 0.8em;
  position: relative;
  & > div {
    margin: 0;
  }
`;
interface Props {
  item: ColorData;
  width: number;
  height: number;
}
const ColorItemDragPreview: React.FC<Props> = ({ item, width, height }) => {
  const { productListFZ } = useContext(ViewContext).styles;
  const fetchedProduct = useMemo(() => {
    return item;
  }, [item]);

  if (!fetchedProduct) return null;
  return (
    <StyledItemsDragPreview fontSize={productListFZ}>
      <StyledDraggedItemsContainer>
        <StyledDraggedItem
          width={width}
          height={height}
          key={fetchedProduct?.colorId}
        >
          <StyledProductSelectedOverlay />
          <ColorManagementProductItem
            product={fetchedProduct}
            code={fetchedProduct?.code}
            isColumn={true}
            selectedOverlay="true"
          />
        </StyledDraggedItem>
      </StyledDraggedItemsContainer>
    </StyledItemsDragPreview>
  );
};
export default ColorItemDragPreview;
