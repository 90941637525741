import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import ProductSwatch from "./ProductSwatch";
import { ProductSwatchType } from "../store/product/ProductTypes";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import { selectCurrentLocale } from "../store/store-list/StoreListSelectors";
import {
  getProductSwatchColorSFCC,
  getProductSwatchImageSFCC,
  getProductSwatchChildProductImageSFCC,
} from "../utils/ProductUtil";
import AppState from "../store/AppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productSwatches: {
      display: "flex",
      justifySelf: "center",
      overflowY: "auto",
      overflowX: "hidden",
      height: "37px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }),
);

interface Props {
  productId: string;
  swatches: ProductSwatchType[];
  selectedSwatch: string;
  onSwatchClick: (image: string, attrValId: string) => void;
}

const ProductSwatches: React.FC<Props> = (props) => {
  const { productId, swatches, selectedSwatch, onSwatchClick } = props;
  const classes = useStyles();

  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const publishedSwatches = swatches.filter((swatch) => swatch.isPublished);
  const productSwatches = publishedSwatches.map((swatch) => {
    const color = getProductSwatchColorSFCC(
      swatch,
      currentLocaleId,
      defaultLocaleId,
    );
    const swatchImage = getProductSwatchImageSFCC(
      swatch,
      currentLocaleId,
      defaultLocaleId,
    );
    const productImage =
      getProductSwatchChildProductImageSFCC(
        swatch,
        currentLocaleId,
        defaultLocaleId,
      ) || "";
    if (swatchImage) {
      const props = {
        color,
        swatchImage,
        productImage,
        attributeValueId: swatch.attributeValueId,
        isSelected: selectedSwatch === swatch.attributeValueId,
        onSwatchClick: onSwatchClick,
      };
      return (
        <ProductSwatch
          key={`${swatch.attributeValueId}-${productId}`}
          {...props}
        />
      );
    } else {
      return <React.Fragment key={`${swatch.attributeValueId}-${productId}`} />;
    }
  });
  return <div className={classes.productSwatches}>{productSwatches}</div>;
};

export default ProductSwatches;
