export const isErrorIgnored = (
  errorMessage: string,
  ignoredErrorMessages: string[],
) => {
  for (const ignoredErrorMessage of ignoredErrorMessages) {
    if (errorMessage.includes(ignoredErrorMessage)) {
      return true;
    }
  }
  return false;
};
