import { Attribute } from "../category/CategoryTypes";

export const LOAD_STORE_LIST = "LOAD_STORE_LIST";
export const FETCH_STORE_LIST_REQUEST = "FETCH_STORE_LIST_REQUEST";
export const FETCH_STORE_LIST_SUCCESS = "FETCH_STORE_LIST_SUCCESS";
export const FETCH_STORE_LIST_FAILURE = "FETCH_STORE_LIST_FAILURE";
export const FETCH_STORE_LIST = {
  REQUEST: FETCH_STORE_LIST_REQUEST,
  SUCCESS: FETCH_STORE_LIST_SUCCESS,
  FAILURE: FETCH_STORE_LIST_FAILURE,
};
export const SET_CURRENT_STORE = "SET_CURRENT_STORE";
export const SET_CURRENT_LOCALE = "SET_CURRENT_LOCALE";
export const RESET_STORE_STATE = "RESET_STORE_STATE";

export type StoreListActionTypes =
  | LoadStoreListResponseAction
  | SetCurrentStoreAction
  | SetCurrentLocaleAction
  | LoadAllAttributesResponseAction;

export interface LoadStoreListAction {
  type: typeof LOAD_STORE_LIST;
  payload?: SetCurrentSFCCStoreActionPayload;
}

export interface SetCurrentStoreAction {
  type: typeof SET_CURRENT_STORE;
  payload: SetCurrentStoreActionPayload;
}

export interface SetCurrentStoreActionPayload {
  storeId: string;
}
export interface SetCurrentSFCCStoreActionPayload {
  siteId: string;
}
export interface SetSelectedStoreActionPayload {
  storeId: string;
}

export interface SetCurrentLocaleAction {
  type: typeof SET_CURRENT_LOCALE;
  payload: SetCurrentLocaleActionPayload;
}

export interface SetCurrentLocaleActionPayload {
  localeCode: string;
}

export interface LoadStoreListResponseAction {
  payload: GetStoreListResponsePayload;
  type: typeof FETCH_STORE_LIST_SUCCESS;
}

export interface Store {
  storeId: string;
  name: string;
  code: string;
}
export interface StoreNew {
  storeId: string;
  name: string;
  code: string;
  catalogs?: any[];
}
export interface StoreToCurrency {
  currencyCode: string;
  storeId: string;
}

export interface StoreToFulfillmentCenter {
  fulfillmentCenterId: string;
  storeId: string;
}

export interface StoreToPriceList {
  priceListId: string;
  storeId: string;
  typeCode: string;
}

export interface StoreToLocale {
  localeCode: string;
  storeId: string;
  currencyCode?: string;
}

export interface StoreDefaults {
  catalogId: string;
  storeId: string;
  localeCode: string;
  currencyCode: string;
}

export interface StoreListData {
  Store: Store[];
  StoreToCurrency: StoreToCurrency[];
  StoreToFulfillmentCenter: StoreToFulfillmentCenter[];
  StoreToPriceList: StoreToPriceList[];
  StoreToLocale: StoreToLocale[];
  StoreDefaults: StoreDefaults[];
}

export interface LocaleData {
  localeCode: string;
  localeName: string;
}

export interface StoreData {
  storeId: string;
  code: string;
  name: string;
  locales: LocaleData[];
  defaults: {
    catalogId: string;
    localeCode: string;
  };
}

export interface GetStoreListResponsePayload {
  count: number;
  total: number;
  results: StoreData[];
}

export interface StoreInfoNode {
  [name: string]: StoreInfo;
}

export interface StoreInfo {
  currencyCodes: string[];
  fulfillmentCenterIds: string[];
  localeCodes: LocaleData[];
  priceLists: PriceList[];
  storeDefaults: StoreDefault;
  localeToCurrency: {
    [key: string]: string;
  };
}

export interface PriceList {
  priceListId: string;
  typeCode: string;
}

export interface StoreDefault {
  catalogId: string;
  localeCode: string;
  currencyCode: string;
}

export interface StoreWithInfo {
  storeId: string;
  currencyCodes?: string[];
  fulfillmentCenterIds?: string[];
  localeCodes?: LocaleData[];
  priceLists?: PriceList[];
  storeDefaults?: StoreDefault;
}

export interface LocaleToStoresMap {
  [key: string]: string[];
}

export interface StoreWithLocaleCode {
  storeId: string;
  localeCodes: string[] | undefined;
}

/**
 * Get all attribute types
 */
export const LOAD_ALL_ATTRIBUTES = "LOAD_ALL_ATTRIBUTES";
export const FETCH_ALL_ATTRIBUTES = {
  REQUEST: "FETCH_ALL_ATTRIBUTES_REQUEST",
  SUCCESS: "FETCH_ALL_ATTRIBUTES_SUCCESS",
  FAILURE: "FETCH_ALL_ATTRIBUTES_FAILURE",
};

export interface GetAllAttributesPayload {
  storeId: string;
}

export interface LoadAllAttributesAction {
  type: typeof LOAD_ALL_ATTRIBUTES;
  payload: GetAllAttributesPayload;
}

export interface LoadAllAttributesResponseAction {
  payload: GetAllAttributesResponsePayLoad;
  type: string;
}

export interface GetAllAttributesResponsePayLoad {
  data: Attribute;
}
