import type { ReactNode } from "react";

import { Box } from "@mui/material";

import { Footer } from "common";
import { makeStyles } from "hooks";

interface DefaultLayoutProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    overflowY: "hidden",
    position: "relative",
  },
}));

export function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box component="main">{children}</Box>
      <Footer />
    </Box>
  );
}
