import type { Components } from "@mui/material";

export const MuiGrid: Components["MuiGrid"] = {
  defaultProps: {
    spacing: 2,
    rowSpacing: 2,
    columnSpacing: 2,
    sx: {
      width: "65px",
      "@media (max-width: 1024px)": {
        width: "50px",
      },
    },
  },
};
