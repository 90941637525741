import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { setModalState } from "../../../../store/modal/ModalActions";
import AutomationRulesModal from "./AutomationRulesModal/AutomationRulesModal";
import { useDispatch, useSelector } from "react-redux";
import AutomationListDataGrid from "../DataGrid/AutomationListDataGridUI";
import {
  loadSystemGeneratedRules,
  loadUnifiedSortingRulesList,
} from "../../../../store/businessRules-list/BusinessRulesListActions";
import AppState from "../../../../store/AppState";
import { selectIsLoading } from "../../../../store/loader/LoaderSelectors";
import {
  selectAutomationRulesListFetched,
  selectIsAutomationRulesFetched,
} from "../../../../store/businessRules-list/BusinessRuleslistSelectors";
import { setLoaderState } from "../../../../store/loader/LoaderActions";
import { classicTheme } from "../../../../styles/themes/classic";
import ButtonComponent from "../../../common/ButtonComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnStyle: {
      backgroundColor: classicTheme.palette.primary.main,
      color: "#fff",
    },
    btnSection: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 20,
    },
    tableSection: {
      marginTop: 20,
    },
    selectRule: {
      width: "200px",
    },
    selectAreaItem: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    selectAreaChild: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "75%",
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
    dataGrid: {
      width: "80%",
      margin: "30px auto",
    },
    tabs: {
      minHeight: "45px",
      backgroundColor: "#F2F3F4",
      "& .MuiTab-root": {
        padding: 0,
        minHeight: "35px",
      },
    },
    select: {
      marginLeft: "0.5rem",
      Width: "100px",
    },
  }),
);

const AutomationRules = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const automationRulesList = useSelector(selectAutomationRulesListFetched);
  const isAutomationRulesFetched = useSelector(selectIsAutomationRulesFetched);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isLoading = useSelector((state: AppState) =>
    selectIsLoading(state, "LIST_AUTOMATION_RULES_LOADER"),
  );
  const [jobId, setJobId] = useState<string>("");

  useEffect(() => {
    dispatch(loadUnifiedSortingRulesList());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(setModalState("AutomationRulesModal", true));
    setIsEditMode(false);
    dispatch(loadUnifiedSortingRulesList());
    dispatch(loadSystemGeneratedRules());
  };

  const handleJobIdCallBackFn = useCallback(
    (jobId: string) => {
      setJobId(jobId);
      setIsEditMode(true);
    },
    [setJobId],
  );

  useEffect(() => {
    if (
      (automationRulesList && automationRulesList.length) ||
      isAutomationRulesFetched
    ) {
      dispatch(setLoaderState("LIST_AUTOMATION_RULES_LOADER", false));
      return;
    }
  }, [dispatch, automationRulesList, isAutomationRulesFetched]);
  return (
    <div>
      <Box>
        <Box className={classes.btnSection}>
          <ButtonComponent
            color="primary"
            variant="contained"
            padding="10px 15px"
            justifyContent="center"
            onClick={handleClick}
          >
            {intl.formatMessage({
              id: "automationRules.create",
              defaultMessage: "Create New Schedule",
            })}
          </ButtonComponent>
        </Box>
        <Box className={classes.tableSection}>
          <AutomationListDataGrid
            handleJobIdCallBackFn={handleJobIdCallBackFn}
            isLoading={isLoading}
            automationRulesList={automationRulesList}
          />
        </Box>
      </Box>
      <AutomationRulesModal jobId={jobId} isEditMode={isEditMode} />
    </div>
  );
};

export default AutomationRules;
