import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
  isSecurityEnabled,
  initUserService,
  isLoggedIn,
  login,
} from "./services/UserService";

const renderApp = () =>
  ReactDOM.render(<App />, document.getElementById("root"));

(window as any).showUnimplemented = () => {
  window.localStorage.setItem("showUnimplemented", "true");
};
(window as any).hideUnimplemented = () => {
  window.localStorage.setItem("showUnimplemented", "false");
};

if (isSecurityEnabled()) {
  initUserService().then(() => {
    if (!isLoggedIn()) {
      login();
    } else {
      renderApp();
    }
  });
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
