import React, { useState } from "react";
import ButtonComponent from "../common/ButtonComponent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateProductPublishState } from "../../store/product/ProductActions";
import { MultipleStoreLanguageModalId } from "../store-language-modal/MultipleStoreLanguageModal";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import useClipboard from "../../hooks/useClipBoard";
import AppState from "../../store/AppState";
import CustomDialog from "../common/DialogContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Btn: {
      fontSize: "0.6rem",
      marginTop: "10px",
      height: "30px",
      fontWeight: 700,
      width: "100%",
      padding: 0,
      "& .MuiButton-label": {
        justifyContent: "flex-start",
      },
      "& i": {
        fontSize: "0.7rem !important",
        marginBottom: "3px",
        marginLeft: "14px",
      },
    },
    paperWidthSm: { background: theme.palette.grey[300] },
    dialogContentText: {
      color: theme.palette.text.primary,
    },
    divMessage: {
      marginTop: "5px",
    },
  }),
);

interface Props {
  publish: boolean;
}

const PublishConfigProducts: React.FC<Props> = ({ publish }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const clipBoardHook = useClipboard();
  const selectedCodes = clipBoardHook.getClipboardProductCodes();
  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true";
  const isPublished = publish ? "Publish" : "Unpublish";

  const dispatch = useDispatch();

  const clickOpenHandler = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const publishHandler = () => {
    if (bulkUpdateEnabled) {
      const payload = {
        publishedProductIds: publish ? selectedCodes : [],
        unpublishedProductIds: publish ? [] : selectedCodes,
      };
      dispatch(setModalState(MultipleStoreLanguageModalId, true, { payload }));
    } else {
      const payload = {
        publishedProductIds: publish ? selectedCodes : [],
        unpublishedProductIds: publish ? [] : selectedCodes,
      };
      dispatch(updateProductPublishState(payload));
    }
    closeHandler();
  };

  const publishConfigProductsTitle = () => {
    return <strong>Confirm your action</strong>;
  };

  const publishConfigProductsContent = () => {
    return (
      <DialogContentText
        id="alert-dialog-description"
        className={classes.dialogContentText}
      >
        <div className={classes.divMessage}>
          Are you sure you want to <strong>{isPublished}</strong> the products
          currently in clipboard?
        </div>
      </DialogContentText>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={closeHandler}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={publishHandler}
          autoFocus
        >
          {isPublished}
        </ButtonComponent>
      </>
    );
  };

  return (
    <div>
      <ButtonComponent
        color="secondaryButtonColorCTABlue"
        variant="outlined"
        startIcon={
          <i className={`fas ${publish ? "fa-eye" : "fa-eye-slash"}`} />
        }
        onClick={clickOpenHandler}
      >
        {isPublished} Products
      </ButtonComponent>
      <CustomDialog
        open={open}
        onClose={closeHandler}
        title={publishConfigProductsTitle()}
        actions={dialogActions()}
        maxWidth={"md"}
      >
        {publishConfigProductsContent()}
      </CustomDialog>
    </div>
  );
};

export default PublishConfigProducts;
