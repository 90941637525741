import { Skeleton } from "@mui/material";
import { PRODUCT_TILE_HEIGHTS_SKELETON } from "utils/Constants";
import { StyledDiv, styleClasses } from "../ProductListStyles";
import { ViewContext } from "../../../context/view-context";
import { useContext } from "react";

const ProductListLoading = () => {
  const viewContext = useContext(ViewContext);

  const skeletonHeight =
    PRODUCT_TILE_HEIGHTS_SKELETON.get(viewContext.styles.column) ?? 400; // Adjust according to your product tile height

  return (
    <StyledDiv className={styleClasses.progressDiv}>
      <Skeleton
        // key={idx}
        variant="rectangular"
        width="100%"
        height={skeletonHeight}
      />
    </StyledDiv>
  );
};

export default ProductListLoading;
