import type { Components } from "@mui/material";
import { WHITE_COLOR } from "../palette";

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    menu: true;
  }
}

export const MuiIconButton: Components["MuiIconButton"] = {
  variants: [
    {
      props: { color: "menu" },
      style: {
        color: WHITE_COLOR,
        background: "rgb(112, 155, 177)",
        "&:hover": {
          background: "#f18308",
        },
      },
    },
  ],
};
