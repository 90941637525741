import AppState from "../AppState";

export const selectProductCategories = (state: AppState) => {
  return state.productCategories.categories;
};

export const selectIsProductDeleted = (state: AppState) => {
  return state.productCategories.isProductDeleted;
};

export const selectIsProgressStopped = (state: AppState) => {
  return state.productCategories.isProgressStopped;
};
