import type { Components } from "@mui/material";

export const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      "&&& $input": {
        padding: "none",
      },
    },
  },
};
