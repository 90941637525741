import {
  UPDATE_SELECTED_OVERLAY,
  updateSelectedOverlayAction,
  UPDATE_SELECTED_INVENTORY_ATTRIBUTE,
  updateSelectedInventoryAttributeAction,
  UPDATE_SELECTED_ATTRIBUTE,
  updateSelectedAttributeAction,
} from "./OverlayTypes";

export const updateSelectedOverlay = (
  overlay: string,
): updateSelectedOverlayAction => {
  return {
    overlay,
    type: UPDATE_SELECTED_OVERLAY,
  };
};

export const updateSelectedInventoryAttribute = (
  invAttribute: string,
): updateSelectedInventoryAttributeAction => {
  return {
    invAttribute,
    type: UPDATE_SELECTED_INVENTORY_ATTRIBUTE,
  };
};

export const updateSelectedAttribute = (
  attribute: string,
): updateSelectedAttributeAction => {
  return {
    attribute,
    type: UPDATE_SELECTED_ATTRIBUTE,
  };
};
