import { Facet, FacetBucket } from "../store/facets/FacetTypes";

export const getFacetDisplayName = (
  facet: Facet,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    facet.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    facet.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const facetDisplayName = desc ? desc.displayName : facet.facetId;

  return facetDisplayName;
};

export const getFacetBucketDisplayName = (
  facetBucket: FacetBucket,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    facetBucket.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    facetBucket.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const facetBucketDisplayName = desc
    ? desc.displayValue
    : facetBucket.facetBucketId;

  return facetBucketDisplayName;
};
