import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Theme, Typography, useTheme } from "@mui/material";
import { TransitionProps } from "@material-ui/core/transitions";

interface Props {
  tooltipTitle: string;
  children: React.ReactElement;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  TransitionComponent?: React.JSXElementConstructor<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    }
  >;
  disableHoverListener?: boolean;
  interactive?: boolean;
  enterDelay?: number;
  onClick?: (event) => void;
  style?: React.CSSProperties;
  colorMgmt?: string;
  open?: boolean;
}

const useStyles = (theme: Theme) => {
  return {
    leadingColor: {
      backgroundColor: "transparent",
      marginBottom: "0px !important",
      "& .MuiTypography-root": {
        fontFamily: "Helvetica",
        color: theme.palette.primary.main,
        fontSize: 11,
      },
    },
    tooltip: {
      height: 23,
      borderRadius: "5px !important",
      border: 0.5,
      gap: 10,
      padding: "3px 12px 4px 12px",
      marginLeft: "8px !important",
      "& .MuiTypography-root": {
        fontFamily: "Helvetica",
        color: theme.palette.background.default,
        fontSize: 10,
      },
    },
    leadingColorArrow: {
      display: "none",
    },
    arrow: {
      display: "block",
    },
  };
};
const TooltipComponent = ({
  tooltipTitle,
  children,
  colorMgmt,
  disableHoverListener = false,
  style = {},
  onClick = () => {},
  ...otherProps
}: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: colorMgmt !== undefined ? classes.leadingColor : classes.tooltip,
        },
        arrow: {
          sx:
            colorMgmt !== undefined ? classes.leadingColorArrow : classes.arrow,
        },
      }}
      title={
        <Typography style={style} onClick={onClick}>
          {tooltipTitle}
        </Typography>
      }
      {...otherProps}
      disableHoverListener={disableHoverListener}
      arrow
    >
      <span style={style} onClick={onClick}>
        {children}
      </span>
    </Tooltip>
  );
};

export default TooltipComponent;
