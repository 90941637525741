import type { Components } from "@mui/material";
import { BORDER_COLOR } from "../palette";

export const MuiInputBase: Components["MuiInputBase"] = {
  styleOverrides: {
    root: {
      borderColor: BORDER_COLOR,
      height: "50.4px !important",
      borderRadius: 4,
      border: 1,
    },
  },
};
