import React, { useRef, useState, useCallback } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useDrop, useDrag, DropTargetMonitor, XYCoord } from "react-dnd";
import { DragFacetItem } from "./FacetTile";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import LanguageIcon from "@material-ui/icons/Language";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../store/modal/ModalActions";
import { editAttributeValuesModalId } from "./EditAttributeValuesModal";
import { selectAssignedAttributeValuesMap } from "../../store/facets/FacetSelectors";
import {
  removeAttributeValueFacetFilter,
  addAttributeValueFacetFilter,
} from "../../store/facets/FacetActions";
import { editFacetBucketModalId } from "./EditFacetBucketModal";
import { FacetBucketValue } from "../../store/facets/FacetTypes";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { useIntl } from "react-intl";
import AppState from "../../store/AppState";
import Icons from "components/common/Icons";

const useStyles = makeStyles({
  facetValue: {
    borderRadius: 4,
    padding: "2px 1rem",
    position: "relative",
    margin: "3px 5px",
    width: "calc(100% - 10px)",
  },
  dragIcon: {
    fontSize: "1rem",
    left: 0,
    position: "absolute",
    top: 4,
    color: "rgba(0, 0, 0, 0.5)",
  },
  editIcon: {
    cursor: "pointer",
    fontSize: "0.875rem",
    position: "absolute",
    right: 2,
    top: 5,
  },
  textarea: {
    borderRadius: 4,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: "2px 5px",
    width: "100%",
    resize: "none",
    fontSize: "0.875rem",
  },
  valueText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  selectedIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: "1px solid #D50000",
    borderRadius: 4,
  },
  languageIcon: {
    position: "absolute",
    fontSize: "1rem",
    top: 4,
    right: 18,
    cursor: "pointer",
  },
  valueContainer: {
    "& .facet-value-icon": {
      display: "none",
    },
    "&:hover .facet-value-icon": {
      display: "block",
    },
  },
  countText: {
    fontSize: "0.75rem",
  },
});

interface Props {
  index: number;
  facetId: string;
  facetBucketId: string;
  attributeId: string;
  attributeValueId: string;
  displayName: string;
  onMove: (dragItem: DragFacetItem) => void;
  setHoverIndex: React.Dispatch<React.SetStateAction<number>>;
  updateFacetValues: (newValue: string, index: number) => void;
  isSortBySequence: boolean;
  isFacetBucket: boolean;
  bucketValues: FacetBucketValue[];
}

const FacetValue = ({
  index,
  facetId,
  facetBucketId,
  attributeId,
  attributeValueId,
  displayName,
  onMove,
  setHoverIndex,
  updateFacetValues,
  isSortBySequence,
  isFacetBucket,
  bucketValues,
}: Props) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValue, setEditValue] = useState(displayName);
  const [selected, setSelected] = useState(false);
  const assignedAttributeValuesMap = useSelector(
    selectAssignedAttributeValuesMap,
  );
  const showProductCount =
    useSelector((state: AppState) =>
      selectConfigValue(state, "showProductCountForFacets"),
    ) === "true";

  let count = 0;
  if (isFacetBucket) {
    bucketValues.forEach((value) => {
      const attrVal = Object.keys(assignedAttributeValuesMap).find((key) =>
        key.includes(value.bucketValue),
      );
      count +=
        attrVal && assignedAttributeValuesMap[attrVal]
          ? assignedAttributeValuesMap[attrVal].length
          : 0;
    });
  } else {
    count = assignedAttributeValuesMap[attributeValueId]
      ? assignedAttributeValuesMap[attributeValueId].length
      : 0;
  }
  const classes = useStyles();
  const [, drop] = useDrop({
    accept: "FacetValues",
    hover(item: DragFacetItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      setHoverIndex(hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: "FacetValues", id: attributeValueId, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      onMove(monitor.getItem());
    },
  });

  const opacity = isDragging ? 0 : 1;
  if (isSortBySequence && !isEditOpen) drag(drop(ref));

  const updateDisplayValue = useCallback(() => {
    if (editValue !== "" && editValue !== displayName) {
      updateFacetValues(editValue, index);
    }
    setEditValue("");
    setIsEditOpen(false);
  }, [index, editValue, updateFacetValues, displayName]);

  const blurHandler = () => {
    updateDisplayValue();
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.persist();
    if (event.keyCode === 13) {
      updateDisplayValue();
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditValue(event.target.value.trim());
  };

  const onEditIconClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setIsEditOpen(true);
  };

  const clickHandler = () => {
    if (count === 0) return;
    setSelected(!selected);
    const attributeValueIds: string[] = [];
    if (isFacetBucket) {
      bucketValues.forEach((value) => {
        attributeValueIds.push(value.bucketValue);
      });
    } else {
      attributeValueIds.push(attributeValueId);
    }
    if (selected) {
      dispatch(removeAttributeValueFacetFilter(attributeValueIds));
    } else {
      dispatch(addAttributeValueFacetFilter(attributeValueIds));
    }
  };

  const openEditAttributeValueModal = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.preventDefault();
    if (isFacetBucket) {
      dispatch(
        setModalState(editFacetBucketModalId, true, { facetId, facetBucketId }),
      );
    } else {
      dispatch(
        setModalState(editAttributeValuesModalId, true, {
          attributeId,
          attributeValueId,
        }),
      );
    }
  };

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className={classes.facetValue}
      onClick={() => {
        if (showProductCount) clickHandler();
      }}
    >
      {isEditOpen && (
        <textarea
          className={classes.textarea}
          aria-label="Edit facet value"
          defaultValue={displayName}
          onBlur={blurHandler}
          autoFocus={true}
          onKeyDown={keyDownHandler}
          onChange={changeHandler}
        />
      )}
      {!isEditOpen && (
        <div className={classes.valueContainer}>
          {selected && <div className={classes.selectedIndicator} />}
          {isSortBySequence && (
            <DragIndicatorIcon
              className={`${classes.dragIcon} facet-value-icon`}
            />
          )}
          <Tooltip title={displayName} interactive enterDelay={500}>
            <div className={classes.valueText}>
              {displayName}
              {showProductCount && (
                <span className={classes.countText}> ({count})</span>
              )}
            </div>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: "facetValue.editMultipleLocalesTooltipText",
              defaultMessage: "Edit value for multiple locales",
            })}
            interactive
            enterDelay={500}
          >
            <LanguageIcon
              className={`${classes.languageIcon} facet-value-icon`}
              onClick={openEditAttributeValueModal}
            />
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({
              id: "facetValue.editValueTooltipText",
              defaultMessage: "Edit value",
            })}
            interactive
            enterDelay={500}
          >
            <Icons
              iconId="EditIcon"
              classes={`${classes.editIcon} facet-value-icon`}
              onClick={onEditIconClick}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default FacetValue;
