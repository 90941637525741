export const RESET_USER_STATE = "RESET_USER_STATE";
export const SET_USER_LOCALE = "SET_USER_LOCALE";

export type UserReducersActions = SetUserLocaleAction;

export interface SetUserLocaleAction {
  payload: Locale;
  type: string;
}

interface Locale {
  locale: string;
}
