import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ButtonComponent from "../common/ButtonComponent";
import { useQuery } from "../../hooks/useQueryParams";
import { selectAnalyticsDataFieldsForReport } from "../../store/product/ProductSelectors";
import { setReportFields } from "../../store/report/ReportActions";
import { selectReportFields } from "../../store/report/ReportSelectors";
import { selectCallerAccountId } from "../../store/app-config/AppConfigSelectors";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
    },
    arrowIcon: {
      position: "absolute",
      right: 0,
    },
    pickerButton: {
      width: 200,
      justifyContent: "left",
    },
    menuPopper: {
      width: 200,
      zIndex: 3,
    },
    listItem: {
      paddingLeft: 20,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  }),
);

interface Props {}
const FieldsFilter: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const reportFieldsQuery = query.get("reportFields") ?? "";
  const dateRangeQuery = query.get("dateRange") ?? "";
  const allFields = useSelector(selectAnalyticsDataFieldsForReport);
  const selectedFields = useSelector(selectReportFields);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const userAccountId = useSelector(selectCallerAccountId);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleFieldsChange = useCallback(
    (value: string) => {
      const checked = selectedFields.includes(value);
      const updatedValues = checked
        ? selectedFields.filter((field) => field !== value)
        : [...selectedFields, value];
      dispatch(setReportFields(updatedValues));
      const reportFieldsStr = allFields
        .filter((field) => updatedValues.includes(field))
        .join(",");
      const newDateRangeQuery =
        dateRangeQuery.length > 0
          ? `&dateRange=${encodeURIComponent(dateRangeQuery)}`
          : "";
      const newReportFieldsQuery =
        reportFieldsStr.length > 0
          ? `&reportFields=${encodeURIComponent(reportFieldsStr)}`
          : "";
      history.replace(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&catalogId=${catalogIdQuery}&categoryPath=${categoryPathQuery}${newReportFieldsQuery}${newDateRangeQuery}`,
      );
    },
    [
      dispatch,
      allFields,
      selectedFields,
      catalogIdQuery,
      categoryPathQuery,
      dateRangeQuery,
      history,
      storeIdQuery,
      url,
      userAccountId,
    ],
  );

  useEffect(() => {
    if (selectedFields.length === 0) {
      const queryFields = reportFieldsQuery.split(",");
      const selectedFields = allFields.filter((field) =>
        queryFields.includes(field),
      );
      if (selectedFields.length > 0) {
        dispatch(setReportFields(selectedFields));
      }
    }
  }, [allFields, dispatch, reportFieldsQuery, selectedFields.length]);

  return (
    <div>
      <ButtonComponent
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={toggle}
      >
        Fields
        <Icons iconId="TriangleDownIcon" classes={classes.arrowIcon} />
      </ButtonComponent>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuPopper}
        placement={"bottom"}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  {allFields.map((field, index) => (
                    <ListItem
                      className={classes.listItem}
                      onClick={() => {
                        handleFieldsChange(field);
                      }}
                      key={index}
                    >
                      <Checkbox checked={selectedFields.includes(field)} />
                      <div
                        style={{
                          width: "120px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {field}
                      </div>
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FieldsFilter;
