import { styled } from "@mui/material";

export const styleClasses = {
  overlay: "overlay",
  actiondiv: "actiondiv",
  spanSelect: "spanSelect",
  spanUnselect: "spanUnselect",
  message: "message",
  options: "options",
};

export const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
  [`&.${styleClasses.actiondiv}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px 20px 10px",
  },
  [`&.${styleClasses.message}`]: {
    marginTop: "5px",
  },
  [`&.${styleClasses.options}`]: {
    display: "flex",
    justifyContent: "flex-end",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    alignItems: "center",
  },
}));

export const StyledtextSelect = styled("p")(({ theme }) => ({
  fontSize: "0.875rem",
  margin: "8px",
  color: theme.palette.unifiedSortingRuleModalMenuItem.main,
  cursor: "pointer",
}));
export const StyledtextUnSelect = styled("p")(({ theme }) => ({
  fontSize: "0.875rem",
  margin: "8px",
  color: theme.palette.unSelectTextColor.main,
  cursor: "pointer",
}));
