import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { LoadStoreList } from "../../store/store-list/StoreListActions";
import {
  selectStoreListIds,
  selectAllStoreInfo,
} from "../../store/store-list/StoreListSelectors";
import {
  setReportStoreId,
  resetReportState,
} from "../../store/report/ReportActions";
import { selectReportStoreId } from "../../store/report/ReportSelectors";
import { LoadCatalogList } from "../../store/catalog/CatalogActions";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import { useQuery } from "../../hooks/useQueryParams";
import AppState from "../../store/AppState";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5, 1),
  },
}));

const StoreFilter: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const storeIdQuery = query.get("storeId") ?? "";
  const location = useLocation();
  const url = location.pathname;
  const storeListIds = useSelector(selectStoreListIds);
  const storeId = useSelector(selectReportStoreId);
  const allStoreInfo = useSelector(selectAllStoreInfo);
  const userAccountId = useSelector(selectCallerAccountId);
  const graphQLStoreRelationshipEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
    ) === "true";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LoadStoreList());
    return () => {
      dispatch(resetReportState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (storeListIds.find((e) => e.storeId === storeIdQuery)) {
      if (storeIdQuery !== storeId) {
        dispatch(setReportStoreId(storeIdQuery));
        if (graphQLStoreRelationshipEnabled) {
          dispatch(LoadCatalogList(storeIdQuery));
        }
      }
    } else {
      if (storeId !== "") {
        dispatch(setReportStoreId(""));
      }
    }
  }, [
    storeId,
    dispatch,
    storeIdQuery,
    storeListIds,
    graphQLStoreRelationshipEnabled,
  ]);

  useEffect(() => {
    if (
      storeListIds.length > 0 &&
      !storeListIds.find((e) => e.storeId === storeIdQuery)
    ) {
      history.replace(`${url}?accountId=${userAccountId}`);
    }
  }, [history, storeIdQuery, storeListIds, url, userAccountId]);

  const handleStoreChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;

    dispatch(setReportStoreId(changeEvent.target.value));
    if (changeEvent.target.value !== "") {
      if (graphQLStoreRelationshipEnabled) {
        dispatch(LoadCatalogList(changeEvent.target.value));
      }
    }
    const storeInfo = allStoreInfo[changeEvent.target.value];
    if (storeInfo) {
      history.replace(
        `${url}?accountId=${userAccountId}&storeId=${changeEvent.target.value}&catalogId=${storeInfo.storeDefaults.catalogId}`,
      );
    } else {
      history.replace(
        `${url}?accountId=${userAccountId}&storeId=${changeEvent.target.value}`,
      );
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="store-list-label">Store Selection</InputLabel>
      <DropdownMenu
        menuId="StoreFilter"
        storeId={storeId}
        changeHandler={handleStoreChange}
        storeListIds={storeListIds}
      />
    </FormControl>
  );
};

export default React.memo(StoreFilter);
