import AppState from "../AppState";

export const selectTreeDataSelector = (state: AppState) => {
  return state.treeData.data;
};

export const selectIsProductAdded = (state: AppState) => {
  return state.treeData.isProductAdded;
};

export const selectIsProgressing = (state: AppState) => {
  return state.treeData.isProgressing;
};

export const selectAreAllProductsAdded = (state: AppState) => {
  return state.treeData.areAllProductsAdded;
};
