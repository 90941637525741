import axios from "axios";
import { getToken } from "../services/UserService";
import {
  AppConfigDetails,
  storeAndAccountDetails,
} from "../store/app-config/AppConfigTypes";

export interface AppStoreConfiguration {
  storeId: string;
  configId: string;
  value: any;
}

export interface AppConfigObject {
  [name: string]: {
    [name: string]: any;
  };
}

/*export const getConfiguration = async (): Promise<
  AppConfigResponse | undefined
> => {
  // TODO Implement account config service
  return {
    data: {
      Config: [
        {
          configId: "productImagePrefix",
          value:
            "https://sm-demo-static-data.s3.us-east.cloud-object-storage.appdomain.cloud/",
        },
        {
          configId: "graphQLStoreRelationshipEnabled",
          value: "false",
        },
        {
          configId: "bulkUpdateEnabled",
          value: "false",
        },
        {
          configId: "displaySequences",
          value: "false",
        },
        {
          configId: "inventoryThreshold",
          value: "10",
        },
        {
          configId: "showProductCountForFacets",
          value: "false",
        },
        {
          configId: "defaultLocaleId",
          value: "x-default",
        },
        {
          configId: "ecomPlatformType",
          value: "SFCC",
        },
        {
          configId: "analyticsEndpoint",
          value: "https://sm4all.smartmerchandiser.com/api/analytics",
        },
      ],
      StoreConfig: [],
    },
  };
};*/

export const getConfigValue = (
  configs: storeAndAccountDetails,
  storeId: string | null,
  key: string,
  platformDefaultValue: string,
  platformIndependentValue: string,
): string => {
  if (
    storeId &&
    configs.hasOwnProperty(storeId) &&
    configs[storeId].hasOwnProperty(key)
  ) {
    return configs[storeId][key];
  } else if (
    configs.hasOwnProperty("defaults") &&
    configs.defaults.hasOwnProperty(key)
  ) {
    return configs.defaults[key];
  } else if (platformDefaultValue !== "") {
    return platformDefaultValue;
  }
  return platformIndependentValue;
};

export interface AdapterInfoObject {
  adapterId: string;
  operations: string[];
  capabilities: string[];
}

export const fetchAdapterInfo = async () => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...tokenHeader,
    "Content-Type": "application/json",
  };
  return axios.get(
    `${process.env.REACT_APP_SM_HTTP_ENDPOINT}/v1/smdemo/adapter-info`,
    { headers: headers },
  );
};

export interface CallerAccountInfoObject {
  accounts: accountDetails[];
}

export interface accountDetails {
  accountId: string;
  roles: string[];
  configs?: AppConfigDetails;
}

export const fetchCallersAccounts = async () => {
  const token = await getToken();
  const tokenHeader = token
    ? {
        Authorization: "Bearer " + token,
      }
    : {};

  const headers = {
    ...tokenHeader,
    "Content-Type": "application/json",
  };
  return axios.get(
    `${process.env.REACT_APP_CONFIG_SERVICE_URL}/app/v1/my-accounts?include=configs&status=active`,
    { headers: headers },
  );
};
