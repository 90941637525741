const ENV_PREFIX = "REACT_APP_";
const ENV_PREFIX_LENGTH = ENV_PREFIX.length;

(global as any)._SM_Env_Context = Object.fromEntries(
  Object.keys(process.env)
    .filter((k) => k.startsWith(ENV_PREFIX))
    .map((k) => [k.substring(ENV_PREFIX_LENGTH), process.env[k]]),
);

const AppEnv = (global as any)._SM_Env_Context;
export default AppEnv;
