import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { HttpClient, HttpClientRequest } from "./HttpClientTypes";

export default class HttpClientImpl<P> implements HttpClient<P> {
  private requestTimeout: number;
  private axiosInstance: AxiosInstance;

  /**
   * constructor for HTTP client.
   */
  public constructor(baseURL = "", requestTimeout = 250000) {
    this.requestTimeout = requestTimeout;
    this.axiosInstance = axios.create({
      baseURL,
      timeout: this.requestTimeout,
    });

    this.axiosInstance.interceptors.response.use((response) => response);
  }

  public async post(request: HttpClientRequest): Promise<P> {
    const { endpoint, body, ...requestConfig } = request;
    return this.axiosInstance
      .post<P>(endpoint, body, requestConfig)
      .then((response) => response.data);
  }

  public async put(request: HttpClientRequest): Promise<P> {
    const { endpoint, body, ...requestConfig } = request;
    return this.axiosInstance
      .put<P>(endpoint, body, requestConfig)
      .then((response) => response.data);
  }

  public async patch(request: HttpClientRequest): Promise<P> {
    const { endpoint, body, ...requestConfig } = request;
    return this.axiosInstance
      .patch<P>(endpoint, body, requestConfig)
      .then((response) => response.data);
  }

  public async get(request: HttpClientRequest): Promise<P> {
    const requestConfig: AxiosRequestConfig = {
      headers: request.headers,
    };
    const { endpoint } = request;

    if (request.params) {
      requestConfig.params = request.params;
    }

    return this.axiosInstance
      .get<P>(endpoint, requestConfig)
      .then((response) => response.data);
  }
  public async delete(request: HttpClientRequest): Promise<P> {
    const requestConfig: AxiosRequestConfig = {
      headers: request.headers,
    };
    const { endpoint } = request;

    if (request.params) {
      requestConfig.params = request.params;
    }

    return this.axiosInstance
      .delete<P>(endpoint, requestConfig)
      .then((response) => response.data);
  }
}
