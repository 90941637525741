import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../store/AppState";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import ProductAlertsList from "./ProductAlertsList";
import CustomDialog from "../../common/DialogContainer";
import { setModalState } from "../../../store/modal/ModalActions";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { loadProductAlertsList } from "../../../store/product-alerts-modal/ProductAlertsListAction";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../../store/app-config/AppConfigSelectors";
import ButtonComponent from "components/common/ButtonComponent";
import { classes, StyledDiv } from "./ProductAlertsListContainerStyles";

function ProductAlertsListContainer() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ProductAlertsListModal"),
  );

  const selectedCallerAccountId = useSelector(selectCallerAccountId);
  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );

  useEffect(() => {
    if (selectedCallerAccountId && isProductAlertsEnabled === "true") {
      dispatch(loadProductAlertsList());
    }
  }, [dispatch, selectedCallerAccountId, isProductAlertsEnabled]);

  const handleClose = () => {
    dispatch(setModalState("ProductAlertsListModal", false));
  };

  const getProductAlersModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "productAlertsModal.title",
          defaultMessage: "Product Alerts",
        })}
      </Typography>
    );
  };

  const productAlertListActions = () => {
    return (
      <StyledDiv className={classes.divActionBtn}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "itemManagementModal.closeActionLabel",
            defaultMessage: "Close",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return isModalOpen && isProductAlertsEnabled === "true" ? (
    <CustomDialog
      open={isModalOpen}
      title={getProductAlersModalTitle()}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      actions={productAlertListActions()}
    >
      <ProductAlertsList />
    </CustomDialog>
  ) : null;
}

export default ProductAlertsListContainer;
