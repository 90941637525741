import { styled } from "@mui/material";

export const classes = {
  divActionBtn: "divActionBtn",
};

export const StyledDiv = styled("div")((theme) => ({
  [`&.${classes.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px",
  },
}));
