import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { useCallback } from "react";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";

import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    dialogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px",
    },
  }),
);

const ClipBoardActionModal = (props: {
  handleAdd: () => void;
  currentAction?: string | undefined | null;
  invalidProductCodes?: string[];
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ClipBoardConfirmParNumbersModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("ClipBoardConfirmParNumbersModal", false));
  };

  const getCurrentAction = useCallback(() => {
    switch (props.currentAction) {
      case "AddProductsModal":
        return "add";
      case "RemoveProductsModal":
        return "remove";
      case "ReplaceProductsModal":
        return "replace";
      case "MultipleStoreModal":
        return "publish/unpublish";
      default:
        return "";
    }
  }, [props.currentAction]);

  const clipBoardActionModalTitle = () => {
    return <Typography variant="inherit"></Typography>;
  };

  const clipBoardActionModalContent = () => {
    return (
      <div className={classes.dialogAction}>
        <Typography variant="subtitle1">
          {intl.formatMessage(
            {
              id: "confirmPartNumber.instruction",
              defaultMessage:
                "Invalid Part Number(s): {commaSeparatedListOfInvalidPartnumbers}.  Click proceed to {action} the rest of the part numbers?",
            },
            {
              commaSeparatedListOfInvalidPartnumbers:
                props.invalidProductCodes?.toString(),
              action: getCurrentAction(),
            },
          )}
        </Typography>
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            props.handleAdd();
            handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.Proceed",
            defaultMessage: "Proceed",
          })}
        </ButtonComponent>
      </>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={clipBoardActionModalTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
    >
      {clipBoardActionModalContent()}
    </CustomDialog>
  );
};

export default ClipBoardActionModal;
