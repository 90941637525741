export const styles = (theme) => {
  return {
    btnGroup: {
      display: "flex",
      margin: 20,
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
    },
  };
};
