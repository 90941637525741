export const SET_LOADER_STATE = "SET_LOADER_STATE";

export interface setLoaderStateAction {
  payload: Loader;
  type: string;
}

interface Loader {
  isLoading: boolean;
  loaderType: string;
}
