import React, { ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
}

const state = {
  2: {
    column: 2,
    productListFZ: ".85rem",
    productActionFZ: "2.4em",
    productActionDimensions: "40px",
    badgePositionRight: "-24px",
    badgePositionTop: "-32px",
    badgeFZ: "22px",
    badgeDimensions: "50px",
  },
  3: {
    column: 3,
    productListFZ: ".8rem",
    productActionFZ: "2.2em",
    productActionDimensions: "35px",
    badgePositionRight: "-18px",
    badgePositionTop: "-25px",
    badgeFZ: "20px",
    badgeDimensions: "40px",
  },
  4: {
    column: 4,
    productListFZ: ".75rem",
    productActionFZ: "2em",
    productActionDimensions: "30px",
    badgePositionRight: "-17px",
    badgePositionTop: "-22px",
    badgeFZ: "18px",
    badgeDimensions: "35px",
  },
  5: {
    column: 5,
    productListFZ: ".7rem",
    productActionFZ: "1.8em",
    productActionDimensions: "25px",
    badgePositionRight: "-15px",
    badgePositionTop: "-18px",
    badgeFZ: "16px",
    badgeDimensions: "30px",
  },
  6: {
    column: 6,
    productListFZ: ".65rem",
    productActionFZ: "1.6em",
    productActionDimensions: "20px",
    badgePositionRight: "-12px",
    badgePositionTop: "-15px",
    badgeFZ: "14px",
    badgeDimensions: "25px",
  },
};

export type ProductListColumn = 2 | 3 | 4 | 5 | 6;

export const ViewContext = React.createContext({
  styles: state[3],
  onChangeStyles: (num: ProductListColumn) => {},
});

const ViewContextProvider: React.FC<Props> = ({ children }) => {
  const [styles, setStyles] = useState(state[3]);

  const changeStylesHandler = (value: ProductListColumn) => {
    setStyles(state[value]);
  };
  return (
    <ViewContext.Provider
      value={{ styles, onChangeStyles: changeStylesHandler }}
    >
      {children}
    </ViewContext.Provider>
  );
};

export default ViewContextProvider;
