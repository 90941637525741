import AppState from "../AppState";
import {
  Store,
  StoreInfo,
  StoreWithInfo,
  LocaleToStoresMap,
  StoreWithLocaleCode,
  StoreInfoNode,
} from "./StoreListTypes";

export const selectStoreList = (state: AppState): Store[] => {
  return state.stores.store;
};

export const selectStoreListIds = (state: AppState): Store[] => {
  return state.stores.store.map((store) => store);
};

export const selectAllStoreInfo = (state: AppState): StoreInfoNode => {
  return state.stores.storeInfo;
};

export const selectStoreInfoById = (
  state: AppState,
  storeId: string,
): StoreInfo | null => {
  return state.stores.storeInfo.hasOwnProperty(storeId)
    ? state.stores.storeInfo[storeId]
    : null;
};

export const selectStoreListWithInfo = (state: AppState): StoreWithInfo[] => {
  return state.stores.store.map((store) => ({
    storeId: store.storeId,
    ...selectStoreInfoById(state, store.storeId),
  }));
};

export const selectLocaleToStoresMap = (state: AppState): LocaleToStoresMap => {
  const storeList = selectStoreListWithInfo(state);
  let supportedLocaleToStoreMap: { [key: string]: string[] } = {};
  storeList.forEach((storeInfo) => {
    if (storeInfo && storeInfo.localeCodes) {
      storeInfo.localeCodes.forEach((locale) => {
        if (supportedLocaleToStoreMap[locale.localeCode]) {
          supportedLocaleToStoreMap[locale.localeCode].push(storeInfo.storeId);
        } else {
          supportedLocaleToStoreMap = {
            ...supportedLocaleToStoreMap,
            [locale.localeCode]: [storeInfo.storeId],
          };
        }
      });
    }
  });
  return supportedLocaleToStoreMap;
};

export const selectCurrentStoreId = (state: AppState) => {
  return state.stores.currentStoreId;
};

export const selectStoreListWithLocalCode = (
  state: AppState,
): StoreWithLocaleCode[] => {
  return state.stores.store.map((store) => ({
    storeId: store.storeId,
    localeCodes: selectStoreInfoById(state, store.storeId)?.localeCodes.map(
      (e) => e.localeCode,
    ),
  }));
};

export const selectUniqueLocaleCodes = (state: AppState) => {
  const mergedLocaleCodes: string[] = selectStoreListWithLocalCode(
    state,
  ).flatMap((store) => store?.localeCodes ?? []);
  return [...new Set(mergedLocaleCodes)];
};

export const selectCurrentLocale = (state: AppState) => {
  return state.stores.currentLocaleCode;
};

export const selectAllStoreAttributes = (state: AppState) => {
  return state.stores.attributes;
};

export const selectAllStoreNonEnumAttributes = (state: AppState) => {
  return state.stores.nonEnumAttributes;
};

export const selectCurrencyCode = (state: AppState): string => {
  return state.stores.currentCurrencyCode;
};
export const selectStoresLoadedState = (state: AppState): boolean => {
  return state.stores.isStoresLoaded;
};
