import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  DialogActions,
  IconButton,
} from "@mui/material";
import { styles } from "./DialogContainer.styles";
import CloseIcon from "@mui/icons-material/Close";

interface IDialogProps {
  open: boolean;
  onClose: () => void;
  title: JSX.Element;
  children: JSX.Element;
  actions?: React.ReactNode;
  [otherProps: string]: any;
}
const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  actions,
  ...otherProps
}: IDialogProps) => {
  const theme = useTheme();
  const { dialogTitle, dialogPaper, dialogContent, dialogActions } =
    styles(theme);
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      {...otherProps}
      sx={dialogPaper}
    >
      <>
        <DialogTitle sx={dialogTitle}>
          {title}{" "}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={dialogContent}>{children}</DialogContent>
        {actions && <DialogActions sx={dialogActions}>{actions}</DialogActions>}
      </>
    </Dialog>
  );
};

export default CustomDialog;
