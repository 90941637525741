import { getToken } from "./../services/UserService";
import {
  take,
  call,
  cancelled,
  put,
  fork,
  takeEvery,
  cancel,
  spawn,
  all,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import AppEnv from "../utils/AppEnv";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CLOSE_SUBSCRIPTION = "CLOSE_SUBSCRIPTION";
export const CLOSE_ALL_SUBSCRIPTIONS = "CLOSE_ALL_SUBSCRIPTIONS";
const WEBSOCKET_CHANNEL_CREATED = "WEBSOCKET_CHANNEL_CREATED";
const CLOSE_WEBSOCKET_CHANNEL = "CLOSE_WEBSOCKET_CHANNEL";
const CANCEL_WEBSOCKET_CHANNEL_SAGA = "CANCEL_WEBSOCKET_CHANNEL_SAGA";
const RECONNECT = "RECONNECT";

interface Subscription {
  id: string;
  type: string;
  payload?: object;
}

interface PayLoad {
  id: string;
  operationName: string;
  query: string;
  variables?: object;
}

export interface SubscriptionAction {
  type: string;
  payload: PayLoad;
}

const GQL = {
  CONNECTION_INIT: "connection_init",
  CONNECTION_ACK: "connection_ack",
  CONNECTION_ERROR: "connection_error",
  CONNECTION_KEEP_ALIVE: "ka",
  START: "start",
  STOP: "stop",
  CONNECTION_TERMINATE: "connection_terminate",
  DATA: "data",
  ERROR: "error",
  COMPLETE: "complete",
};

let socket: WebSocket;
let listOfSubscriptions: Subscription[] = [];

function createSocketChannel(socket: WebSocket) {
  return eventChannel((emit: (input: string | END) => void) => {
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      switch (data.type) {
        case GQL.CONNECTION_ACK: {
          console.log("WebSocket: connection ack");
          break;
        }
        case GQL.CONNECTION_ERROR: {
          console.error(data.payload);
          break;
        }
        case GQL.CONNECTION_KEEP_ALIVE: {
          break;
        }
        case GQL.DATA: {
          emit(data);
          break;
        }
        case GQL.ERROR: {
          emit(data);
          break;
        }
        case GQL.COMPLETE: {
          console.log("WebSocket: completed", data.id);
          break;
        }
      }
    };

    socket.onclose = (e: CloseEvent) => {
      switch (e.code) {
        case 1000: // CLOSE_NORMAL
          console.log("WebSocket: closed");
          emit(END);
          break;
        default:
          // Abnormal closure
          console.log("WebSocket: reconnect");
          setTimeout(() => {
            emit(RECONNECT);
          }, 5000);
          break;
      }
    };

    // this will be invoked when the saga calls `socketChannel.close` method
    const unsubscribe = () => {
      console.log("socketChannel: closed");
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

export function* initializeWebSocketChannel(): any {
  let socketChannel;
  try {
    if (!socket || socket.readyState === socket.CLOSED) {
      const endpoint = AppEnv.GRAPHQL_WEBSOCKET_ENDPOINT;
      if (endpoint === undefined) {
        throw new Error("GraphQL websocket endpoint undefined");
      }
      const protocol = "graphql-ws";
      socket = new WebSocket(endpoint, protocol);
      socket.onopen = async function () {
        const token = await getToken();
        const headers = token ? { Authorization: "Bearer " + token } : {};
        const data = JSON.stringify({
          type: GQL.CONNECTION_INIT,
          payload: {
            headers,
          },
        });
        socket.send(data);
      };
      listOfSubscriptions.forEach((data) => {
        socket.addEventListener("open", () => {
          socket.send(JSON.stringify(data));
        });
      });
      yield put({ type: WEBSOCKET_CHANNEL_CREATED });
    }

    socketChannel = yield call(createSocketChannel, socket);

    while (true) {
      // wait for a message from the channel
      const response = yield take(socketChannel);
      if (response === RECONNECT) {
        socketChannel.close();
        yield put({ type: CANCEL_WEBSOCKET_CHANNEL_SAGA });
        yield spawn(watchWebSocketChannel);
        break;
      }
      // when we have multiple concurrent subscriptions for the same type of resources
      // i.e. productCount for different categoryId/catalogId
      // we append catalogId and categoryId in the id to differentiate
      // i.e. FETCH_PRODUCT_COUNT_REQUEST__catalogId__{CATALOGID}__categoryId__{CATEGORYID}
      // note: variables are separated by double underscore lines "__".
      // In contrast, single underscore "_" is used in action type(FETCH_PRODUCT_COUNT_REQUEST)
      const typeVariables = response.id.split("__");
      if (typeVariables.length > 1) {
        let variables: {
          [key: string]: string;
        } = {};
        for (let i = 1; i + 1 < typeVariables.length; i += 2) {
          variables[typeVariables[i]] = typeVariables[i + 1];
        }
        const payload = {
          ...response.payload,
          variables,
        };
        const request = typeVariables[0];
        if (response.type === "error") {
          yield put({
            type: `${request.replace("REQUEST", "FAILURE")}`,
            payload,
          });
        } else {
          yield put({
            type: `${request.replace("REQUEST", "SUCCESS")}`,
            payload,
          });
        }
      } else {
        if (response.type === "error") {
          yield put({
            type: `${response.id.replace("REQUEST", "FAILURE")}`,
            payload: response.payload,
          });
        } else {
          yield put({
            type: `${response.id.replace("REQUEST", "SUCCESS")}`,
            payload: response.payload,
          });
        }
      }
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    if (yield cancelled()) {
      // close the channel
      socketChannel.close();

      // close the WebSocket connection
      if (socket.readyState === socket.OPEN) {
        socket.send(
          JSON.stringify({
            type: GQL.CONNECTION_TERMINATE,
          }),
        );
      }
      socket.close();
    }
  }
}

export function* createSubscription(action: SubscriptionAction) {
  const { payload } = action;

  try {
    if (
      !socket ||
      socket.readyState === socket.CLOSED ||
      socket.readyState === socket.CLOSING
    ) {
      while (yield take(WEBSOCKET_CHANNEL_CREATED)) {
        break;
      }
    }
    const data = {
      id: payload.id,
      type: GQL.START,
      payload,
    };
    listOfSubscriptions.push(data);
    if (socket.readyState === socket.OPEN) {
      socket.send(JSON.stringify(data));
    } else if (socket.readyState === socket.CONNECTING) {
      socket.addEventListener("open", () => {
        socket.send(JSON.stringify(data));
      });
    }
  } catch (error) {
    console.log("error", error);
  }
}

export function* closeSubscription(action: SubscriptionAction) {
  const { payload } = action;
  try {
    if (
      !socket ||
      socket.readyState === socket.CLOSED ||
      socket.readyState === socket.CLOSING
    ) {
      while (yield take(WEBSOCKET_CHANNEL_CREATED)) {
        break;
      }
    }
    const data = {
      type: GQL.STOP,
      id: payload.id,
    };
    listOfSubscriptions = listOfSubscriptions.filter(
      (subscription) => subscription.id !== payload.id,
    );
    if (socket.readyState === socket.OPEN) {
      socket.send(JSON.stringify(data));
    } else if (socket.readyState === socket.CONNECTING) {
      socket.addEventListener("open", () => {
        socket.send(JSON.stringify(data));
      });
    }
  } catch (error) {
    console.log("error", error);
  }
}

export function* closeAllSubscriptions() {
  try {
    if (
      !socket ||
      socket.readyState === socket.CLOSED ||
      socket.readyState === socket.CLOSING
    ) {
      while (yield take(WEBSOCKET_CHANNEL_CREATED)) {
        break;
      }
    }
    listOfSubscriptions.forEach((subscription) => {
      const data = {
        type: GQL.STOP,
        id: subscription.id,
      };
      if (socket.readyState === socket.OPEN) {
        socket.send(JSON.stringify(data));
      } else if (socket.readyState === socket.CONNECTING) {
        socket.addEventListener("open", () => {
          socket.send(JSON.stringify(data));
        });
      }
    });
    listOfSubscriptions = [];
  } catch (error) {
    console.log("error", error);
  }
}

export function* watchCreateSubscription() {
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}

export function* watchCloseSubscription() {
  yield takeEvery(CLOSE_SUBSCRIPTION, closeSubscription);
}

export function* watchCloseAllSubscription() {
  yield takeEvery(CLOSE_ALL_SUBSCRIPTIONS, closeAllSubscriptions);
}

export function* watchWebSocketChannel() {
  const socketChannelSaga = yield fork(initializeWebSocketChannel);
  const action = yield take([
    CLOSE_WEBSOCKET_CHANNEL,
    CANCEL_WEBSOCKET_CHANNEL_SAGA,
  ]);
  if (action.type === CLOSE_WEBSOCKET_CHANNEL) {
    yield cancel(socketChannelSaga);
  }
}

export function* initWebSocketChannel() {
  yield all(
    [
      watchCreateSubscription,
      watchCloseSubscription,
      watchCloseAllSubscription,
      watchWebSocketChannel,
    ].map(fork),
  );
}
