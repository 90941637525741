import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Tooltip from "./common/ToolTip";
import { useSelector } from "react-redux";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import AppState from "../store/AppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    productSwatch: {
      cursor: "pointer",
      border: "2px solid transparent",
      padding: "1px",
      transition: "transform 0.4s",
      "& img": {
        border: "1px solid #ebeced",
        borderRadius: " 5px",
        width: "18px",
        height: "18px",
      },
      "&:hover": {
        transform: "scale(1.3)",
      },
    },
    swatchImageWrapper: {
      height: " 22px",
      width: " 22px",
      borderRadius: " 5px",
      border: (isSelected) =>
        `2px solid ${isSelected ? theme.palette.primary.main : "transparent"}`,
    },
  }),
);

interface Props {
  color: string;
  swatchImage: string;
  productImage: string;
  attributeValueId: string;
  isSelected: boolean;
  onSwatchClick: (image: string, attrValId: string) => void;
}

const ProductSwatch: React.FC<Props> = (props) => {
  const classes = useStyles(props.isSelected);
  const imagePrefix = useSelector((state: AppState) =>
    selectConfigValue(state, "productImagePrefix"),
  );
  const swatchClickHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    props.onSwatchClick(props.productImage, props.attributeValueId);
  };
  return (
    <div className={classes.productSwatch} onClick={swatchClickHandler}>
      <Tooltip tooltipTitle={props.color} placement="top">
        <div className={classes.swatchImageWrapper}>
          <img src={`${imagePrefix}${props.swatchImage}`} alt={props.color} />
        </div>
      </Tooltip>
    </div>
  );
};

export default ProductSwatch;
