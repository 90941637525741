import React, { useContext, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  selectPublishedProductList,
  selectIsProductListsFetched,
} from "../../store/product-list/ProductListSelectors";
import Box from "@material-ui/core/Box";
import { ViewContext } from "../../context/view-context";
import { Typography } from "@mui/material";
import { makeStyles, Toolbar } from "@material-ui/core";
import ProductTile from "../ProductTile";
import ProductListViewSelect from "../ProductListViewSelect";
import { selectProductIdsToDisplay } from "../../store/facets/FacetSelectors";
import BreadcrumbSequence from "../BreadcrumbSequence";

const useStyles = makeStyles({
  root: {},
  toolbar: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    minHeight: "50px",
    padding: 0,
  },
  productListContainer: {
    height: "100%",
    overflow: "auto",
  },
  productList: {
    display: "grid",
    gridTemplateColumns: (props: StyleProps) => `repeat(${props.column}, 1fr)`,
    gridGap: "0.7rem",
    padding: 10,
    width: "100%",
    fontSize: (props: StyleProps) => props.fontSize,
  },
  productCount: {
    position: "absolute",
    left: 0,
  },
});

interface StyleProps {
  column: number;
  fontSize: string;
}

const FacetsProductListContainer = () => {
  const intl = useIntl();
  const viewContext = useContext(ViewContext);
  const styleProps: StyleProps = {
    column: viewContext.styles.column,
    fontSize: viewContext.styles.productListFZ,
  };
  const classes = useStyles(styleProps);
  const products = useSelector(selectPublishedProductList);
  const productIdsToDisplay = useSelector(selectProductIdsToDisplay);
  const isProductListsFetched = useSelector(selectIsProductListsFetched);
  let displayCount = 0;
  products.forEach((product) => {
    if (
      productIdsToDisplay.length === 0 ||
      productIdsToDisplay.includes(product.productId)
    ) {
      displayCount += 1;
    }
  });

  const selectionChange = useCallback(
    (itemId: string, cmdKeyActive: boolean, shiftKeyActive: boolean) => {
      return;
    },
    [],
  );
  const OnQuickMoveCompleteAction = useCallback(
    (productId: string, moveTo: number) => {
      return;
    },
    [],
  );

  const productPanel = useMemo(() => {
    return (
      <>
        <Toolbar className={classes.toolbar}>
          {isProductListsFetched && (
            <Typography
              variant="heading3_regular"
              noWrap
              color="primary"
              className={classes.productCount}
            >
              {intl.formatMessage(
                {
                  id: "facetsProductListContainer.publishedProductsText",
                  defaultMessage: "{displayCount} published products",
                },
                {
                  displayCount,
                },
              )}
            </Typography>
          )}
          <ProductListViewSelect />
          {/* <FacetTypeSelect /> */}
        </Toolbar>
      </>
    );
  }, [
    isProductListsFetched,
    classes.toolbar,
    classes.productCount,
    displayCount,
    intl,
  ]);
  return (
    <>
      <BreadcrumbSequence />
      {productPanel}
      {isProductListsFetched && products.length === 0 ? (
        <Box position="absolute" top="50%" left="50%">
          <Typography variant="h4">
            {intl.formatMessage({
              id: "facetsProductListContainer.noProductsFoundText",
              defaultMessage: "No Products Available",
            })}
          </Typography>
        </Box>
      ) : (
        <div className={classes.productListContainer}>
          <div className={classes.productList}>
            {products.map((product, index) =>
              productIdsToDisplay.length === 0 ||
              productIdsToDisplay.includes(product.productId) ? (
                <ProductTile
                  key={product.productId}
                  product={product}
                  index={index}
                  onSelectionChange={selectionChange}
                  isSelected={false}
                  hasProductsSelectedFromList={false}
                  OnQuickMoveCompleteAction={OnQuickMoveCompleteAction}
                  productView={false}
                />
              ) : (
                <React.Fragment key={product.productId} />
              ),
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(FacetsProductListContainer);
