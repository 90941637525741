import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";

const ConfirmationLeadingColor = (props: {
  setIsConfirm: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ConfirmationLeadingColor"),
  );

  const handleClose = () => {
    dispatch(setModalState("ConfirmationLeadingColor", false));
  };

  const confirmRemoveLeadingImage = () => {
    return (
      <Typography variant="h6">
        {intl.formatMessage({
          id: "confirmRemoveLeadingImage.modalHead",
          defaultMessage: "Remove Leading Image",
        })}
      </Typography>
    );
  };

  const confirmRemoveLeadingImageContent = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "confirmRemoveLeadingImage.instruction",
            defaultMessage:
              "Warning:  Leading Color will remove an existing Leading Image assignment.",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={() => {
            props.setIsConfirm(true);
            handleClose();
          }}
        >
          {intl.formatMessage({
            id: "general.continue",
            defaultMessage: "Continue",
          })}
        </ButtonComponent>
      </>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={confirmRemoveLeadingImage()}
      actions={dialogActions()}
      maxWidth={"sm"}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
      }}
    >
      {confirmRemoveLeadingImageContent()}
    </CustomDialog>
  );
};

export default ConfirmationLeadingColor;
