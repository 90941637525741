import type { Components } from "@mui/material";

export const MuiRadio: Components["MuiRadio"] = {
  styleOverrides: {
    root: {
      "&.Mui-checked": {
        color: "#f18308",
      },
    },
  },
};
