import { GetProductsFromCategoryResponsePayload } from "../product-list/ProductListTypes";

export const UPDATE_VARIATION_GROUP_BY_GROUP_ID = {
  REQUEST: "UPDATE_VARIATION_GROUP_BY_GROUP_ID_REQUEST",
  SUCCESS: "UPDATE_VARIATION_GROUP_BY_GROUP_ID_SUCCESS",
  FAILURE: "UPDATE_VARIATION_GROUP_BY_GROUP_ID_FAILURE",
};

export const UPDATE_VARIATION_GROUP = "UPDATE_VARIATION_GROUP";

export const ADD_VARIATION_GROUP_BY_GROUP_ID = {
  REQUEST: "ADD_VARIATION_GROUP_BY_GROUP_ID_REQUEST",
  SUCCESS: "ADD_VARIATION_GROUP_BY_GROUP_ID_SUCCESS",
  FAILURE: "ADD_VARIATION_GROUP_BY_GROUP_ID_FAILURE",
};

export const ADD_VARIATION_GROUP = "ADD_VARIATION_GROUP";

export interface UpdateVariationGroupRequestPayload {
  variationValues: object;
}
export interface UpdateVariationGroupPayload {
  baseProductId: string;
  variationGroupId: string;
  variationValues: object;
  isUpdate: boolean;
}
export interface UpdateVariantGroupAction {
  payload: UpdateVariationGroupPayload;
  type: typeof UPDATE_VARIATION_GROUP;
}

export const DELETE_VARIATION_GROUP_BY_GROUP_ID = {
  REQUEST: "DELETE_VARIATION_GROUP_BY_GROUP_ID_REQUEST",
  SUCCESS: "DELETE_VARIATION_GROUP_BY_GROUP_ID_SUCCESS",
  FAILURE: "DELETE_VARIATION_GROUP_BY_GROUP_ID_FAILURE",
};

export const DELETE_VARIATION_GROUP = "DELETE_VARIATION_GROUP";

export interface DeleteVariationGroupPayload {
  baseProductId: string;
  variationGroupId: string;
  parentId?: string;
}
export interface DeleteVariantGroupAction {
  payload: DeleteVariationGroupPayload;
  type: typeof DELETE_VARIATION_GROUP;
}

export const FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID = {
  REQUEST: "FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID_REQUEST",
  SUCCESS: "FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID_SUCCESS",
  FAILURE: "FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID_FAILURE",
};

export const FETCH_VARIATION_GROUP_DETAILS = "FETCH_VARIATION_GROUP_DETAILS";

export interface GetVariationGroupDetailPayload {
  baseProductId: string;
  variationGroupId: string;
}
export interface GetVariantionGroupDetailAction {
  payload: GetVariationGroupDetailPayload;
  type: typeof FETCH_VARIATION_GROUP_DETAILS;
}

export const FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID = {
  REQUEST: "FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_REQUEST",
  SUCCESS: "FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_SUCCESS",
  FAILURE: "FETCH_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_FAILURE",
};

export const FETCH_VARIATION_ATTRIBUTES = "FETCH_VARIATION_ATTRIBUTES";
export const LOAD_VARIATION_PRODUCT_LIST = "LOAD_VARIATION_PRODUCT_LIST";

export const UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG = {
  REQUEST: "UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG_REQUEST",
  SUCCESS: "UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG_SUCCESS",
  FAILURE: "UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG_FAILURE",
};

export const UPDATE_PUBLISH_FLAG_COMPLETE = "UPDATE_PUBLISH_FLAG_COMPLETE";

export interface GetVariationAttributesPayload {
  baseProductId: string;
}
export interface GetVariantAttributesAction {
  payload: GetVariationAttributesPayload;
  type: typeof FETCH_VARIATION_ATTRIBUTES;
}

export const FETCH_VARIATION_GROUP_IDS = {
  REQUEST: "FETCH_VARIATION_GROUP_IDS_REQUEST",
  SUCCESS: "FETCH_VARIATION_GROUP_IDS_SUCCESS",
  FAILURE: "FETCH_VARIATION_GROUP_IDS_FAILURE",
};

export const FETCH_VARIATION_GROUP = "FETCH_VARIATION_GROUP";

export const RESET_VARIATION_GROUP_ID_DELETE_STATE =
  "RESET_VARIATION_GROUP_ID_DELETE_STATE";
export const RESET_VARIATION_GROUP_ID_DELETE =
  "RESET_VARIATION_GROUP_ID_DELETE";

export const RESET_VARIATION_GROUP_ID_ADD_STATE =
  "RESET_VARIATION_GROUP_ID_ADD_STATE";
export const RESET_VARIATION_GROUP_ID_ADD = "RESET_VARIATION_GROUP_ID_ADD";

export const RESET_VARIATION_GROUP_IDS_STATE =
  "RESET_VARIATION_GROUP_IDS_STATE";
export const RESET_VARIATION_GROUP_DETAILS = "RESET_VARIATION_GROUP_DETAILS";

export const RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE =
  "RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID_STATE";
export const RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID =
  "RESET_VARIATION_GROUP_DETAILS_BY_GROUP_ID";

export const FETCH_VARIATION_GROUP_PRODUCT_LIST = {
  REQUEST: "FETCH_VARIATION_GROUP_PRODUCT_LIST_REQUEST",
  SUCCESS: "FETCH_VARIATION_GROUP_PRODUCT_LIST_SUCCESS",
  FAILURE: "FETCH_VARIATION_GROUP_PRODUCT_LIST_FAILURE",
};

export const FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES = {
  REQUEST: "FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES_REQUEST",
  SUCCESS: "FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES_SUCCESS",
  FAILURE: "FETCH_VARIATION_ATTRIBUTES_ASSIGNED_VALUES_FAILURE",
};

export const RESET_VARIATION_GROUP_PRODUCT_LIST_STATE =
  "RESET_VARIATION_GROUP_PRODUCT_LIST_STATE";
export const RESET_VARIATION_GROUP_PRODUCT_LIST =
  "RESET_VARIATION_GROUP_PRODUCT_LIST";

export interface GetVariationGroupPayload {
  baseProductId: string;
}
export interface GetVariantGroupAction {
  payload: GetVariationGroupPayload;
  type: typeof FETCH_VARIATION_GROUP;
}

export interface VariationAttributes {
  productId: string;
  variationAttributes: [
    {
      attributeId: string;
      name: string;
      translations: [
        {
          localeCode: string;
          name: string;
        },
      ];
      values: [
        {
          name: string;
          value: string;
          translations: [
            {
              localeCode: string;
              name: string;
            },
          ];
          isAssigned: boolean;
        },
      ];
    },
  ];
}

export interface VariationGroup {
  productId: string;
  variationValues: {
    [key: string]: string;
  };
}

export interface UpdatePublishFlagVariationProductPayload {
  productId: string;
  storeId: string;
  isPublished: boolean | null;
}

export interface LoadVariationProductsListResponseAction {
  payload: GetProductsFromCategoryResponsePayload;
  type: typeof FETCH_VARIATION_GROUP_PRODUCT_LIST.SUCCESS;
}

export interface LoadVariationAtrributeValues {
  attributes: [{ attrName: string; attrValue: string }];
  attrId: string;
}

export interface fetchAssignedAndUnAssignedAttributesPayload {
  assignedAttrValues: LoadVariationAtrributeValues[];
  unAssignedAttrValues: LoadVariationAtrributeValues[];
}

export const VARIATION_BASE_PRODUCT = "Variation Base Product";
