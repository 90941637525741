import { SET_MODAL_STATE, setModalStateAction } from "./ModalTypes";

export interface ModalState {
  [key: string]: { isOpen: boolean; [key: string]: any };
}

const initialState: ModalState = {};

export const ModalReducer = (
  state = initialState,
  action: setModalStateAction,
) => {
  switch (action.type) {
    case SET_MODAL_STATE:
      const { modalId, ...restPayload } = action.payload;
      return {
        ...state,
        [action.payload.modalId]: restPayload,
      };
    default:
      return state;
  }
};
