import React, { useEffect } from "react";
import { FormControlLabel, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { loadCategoriesByProductsId } from "../../store/remove-product-categories/RemoveProductCategoriesActions";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { useIntl } from "react-intl";
import CheckboxComponent from "../common/Checkbox";
import { selectValidProductIds } from "store/clipboard/ClipBoardSelectors";

interface CategoryList {
  name: string;
  categoryId: string;
}
interface Props {
  selectedIds: string[];
  categoryList: CategoryList[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const RemoveProductCategorySelection: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { selectedIds, setSelectedIds, categoryList } = props;
  const currentStoreId = useSelector(selectCurrentStoreId);
  const currentLocale = useSelector(selectCurrentLocale);
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const productIds = useSelector(selectValidProductIds);
  const currentCategoryId = useSelector(selectCurrentCategory);
  const payload = {
    currentStoreId,
    currentCatalogId,
    productIds,
    currentLocale,
  };

  useEffect(() => {
    dispatch(loadCategoriesByProductsId(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (categoryId: string) => {
    const copiedIds = selectedIds.slice();
    const index = copiedIds.findIndex((id) => id === categoryId);
    if (index >= 0) copiedIds.splice(index, 1);
    else copiedIds.push(categoryId);
    setSelectedIds(copiedIds);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {categoryList.map((cat, idx) => (
        <FormControlLabel
          key={idx}
          control={
            <CheckboxComponent
              checkboxId="RemoveProductCategorySelection"
              checked={selectedIds.includes(cat.categoryId)}
              handleChange={handleClick}
              handleParam={cat.categoryId}
            />
          }
          label={
            <Typography
              variant="sidebarTreeTitle"
              style={
                currentCategoryId === cat.categoryId
                  ? { fontWeight: "bold" }
                  : {}
              }
            >
              {currentCategoryId === cat.categoryId
                ? `${cat.name} ${intl.formatMessage({
                    id: "removeProductsModal.currentCategory",
                    defaultMessage: "current category",
                  })}`
                : cat.name}
            </Typography>
          }
        />
      ))}
    </div>
  );
};

export default RemoveProductCategorySelection;
