import React from "react";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import { BorderLinearProgress } from "../../styles/themes/classic";

const ProgressBar: React.FC<LinearProgressProps & { value?: number }> = (
  props,
) => {
  return <BorderLinearProgress {...props} />;
};

export default ProgressBar;
