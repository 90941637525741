import { ProductSequenceData } from "../product-list/ProductListTypes";
import {
  LOAD_PRODUCT_DETAILS,
  LOAD_CHILDREN_ITEMS,
  LOAD_CATEGORY_BY_PRODUCT_ID,
  CHANGE_PRODUCT_PUBLISH_STATE,
  CHANGE_PRODUCT_DELETE_STATE,
  LoadProductDetailsAction,
  GetProductDetailsPayload,
  LoadChildrenItemsAction,
  GetChildrenItemsPayload,
  UpdateProductPublishStatePayload,
  UpdateProductPublishStateAction,
  UpdateProductDeleteStatePayload,
  UpdateProductDeleteStateAction,
  LoadProductAnalyticsAction,
  LoadProductAnalyticsPayload,
  LOAD_PRODUCT_ANALYTICS,
  LoadCategoryByProductIdPayload,
  LoadCategoryByProductIdAction,
  UpdatedSelectedSwatchesPayload,
  UpdatedSelectedSwatchesAction,
  UPDATED_SELECTED_SWATCHES,
  LOAD_PRODUCT_ATTRIBUTEVALUES,
  LoadProductAttributeValuesPayload,
  LoadProductAttributeValuesAction,
  UpdateProductAttributePayload,
  UpdateProductAttributeAction,
  CHANGE_PRODUCT_ATTRIBUTE,
  UPDATE_SELECTED_PRODUCTS_CODE,
  UpdateSelectedProductsCodePayload,
  UpdateSelectedProductsCodeAction,
  CHANGE_LOCALE_PRODUCT_PUBLISH,
  UpdateLocaleProductPublishPayload,
  UpdateLocaleProductPublishAction,
  GET_VALIDATION_PRODUCT_CODES,
  CheckValidationProductCodesPayload,
  CheckValidationProductCodesAction,
  UpdateProductColorsPayload,
  UpdateProductColorsAction,
  CHANGE_PRODUCT_COLORS,
  UpdateProductPublishByDefaultStatePayload,
  CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  UpdateProductPublishByDefaultStateAction,
  UpdateProductPublishStateByStorePayload,
  UpdateProductPublishStateByStoreAction,
  CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
  UpdateSingleProductDeleteStatePayload,
  LOAD_CLIP_CODES,
  LoadClipCodesPayload,
  LoadClipCodesAction,
  UpdateSingleProductDeleteStateAction,
  CHANGE_SINGLE_PRODUCT_DELETE_STATE,
  UpdateChildrenItemsPayload,
  UpdateChildrenItemsAction,
  UPDATE_CHILDREN_ITEMS,
  // RESET_PRODUCT_DETAILS,
  // ResetProductDetails,
  SET_SEARCH_TERM,
  SetSearchTermAction,
  UPDATE_PRODUCT_LIST_BY_SEARCH,
  UpdateProductListBySearchAction,
  LoadChildProductAttributesPayload,
  LoadChildProductAttributesAction,
  LOAD_CHILD_PRODUCT_ATTRIBUTES,
  LoadChildProductVariantAttributesByParentIdPayload,
  LoadChildProductVariantAttributesByParentIdAction,
  LOAD_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID,
  FETCH_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID,
  closeChildProductVariantAttributesByParentIdSubscriptionAction,
  LoadInventoryOvelrayAttributesPayload,
  LoadInventoryOvelrayAttributesAction,
  LOAD_INVENTORY_OVERLAY_ATTRIBUTES,
  LoadLowInventoryProductsPayload,
  LoadLowInventoryProductsAction,
  LOAD_LOW_INVENTORY_PRODUCTS,
  RESET_PRODUCTS,
  ResetProductsAction,
  LOAD_PRODUCT_DETAILS_FOR_REPORT,
  LoadProductDetailsForReportAction,
  SetDatePickerRangeAction,
  SET_DATE_PICKER_RANGE,
  FetchProductsVariantsPayload,
  FetchProductsVariantsAction,
  GET_PRODUCTS_VARIANTS,
  UpdateProductVariantPublishStatePayload,
  UpdateProductVariantPublishStateAction,
  PUT_PRODUCT_VARIANT_PUBLISH_STATE,
  RESET_PRODUCTS_VARIANTS_STATE,
  // RESET_TEMPORARY_DATA,
  UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE,
  ADD_MIGRATED_CATEGORIES_NEW,
  UpdateProductPublishFlagPayload,
  UpdateProductPublishFlagAction,
  UPDATE_PRODUCT_ISPUBLISHED,
  RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE,
  ADD_PRODUCT_IN_CATEGORY,
  REMOVE_PRODUCT_IN_CATEGORY,
  fetchSingleProductPayload,
  loadSingleProductAnalyticsAction,
  FETCH_SINGLE_PRODUCT_ANALYTICS,
  singleProductAnlayticsPayload,
  RESET_SINGLE_PRODUCT_ANALYTICS_VIEW,
  RESET_ALL_PRODUCT_ANALYTICS_VIEW,
  resetSingleProductAnalyticAction,
  ANALYTICS_DATA_LOADER,
} from "./ProductTypes";
import { CLOSE_SUBSCRIPTION } from "../Subscription";

export const loadProductDetails = (
  payload: GetProductDetailsPayload,
): LoadProductDetailsAction => {
  return {
    payload,
    type: LOAD_PRODUCT_DETAILS,
  };
};

export const loadProductDetailsForReport = (
  payload: GetProductDetailsPayload,
): LoadProductDetailsForReportAction => {
  return {
    payload,
    type: LOAD_PRODUCT_DETAILS_FOR_REPORT,
  };
};

export const loadChildrenItems = (
  payload: GetChildrenItemsPayload,
): LoadChildrenItemsAction => {
  return {
    payload,
    type: LOAD_CHILDREN_ITEMS,
  };
};
export const updateChildrenItems = (
  payload: UpdateChildrenItemsPayload,
): UpdateChildrenItemsAction => {
  return {
    payload,
    type: UPDATE_CHILDREN_ITEMS,
  };
};

export const updateProductPublishState = (
  payload: UpdateProductPublishStatePayload,
): UpdateProductPublishStateAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_PUBLISH_STATE,
  };
};

export const updateProductPublishByDefaultState = (
  payload: UpdateProductPublishByDefaultStatePayload,
): UpdateProductPublishByDefaultStateAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_PUBLISH_BY_DEFAULT_STATE,
  };
};

export const updateProductPublishStateByStore = (
  payload: UpdateProductPublishStateByStorePayload,
): UpdateProductPublishStateByStoreAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_PUBLISH_STATE_BY_STORE,
  };
};

export const updateProductDeleteState = (
  payload: UpdateProductDeleteStatePayload,
): UpdateProductDeleteStateAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_DELETE_STATE,
  };
};

export const updateSingleProductDeleteState = (
  payload: UpdateSingleProductDeleteStatePayload,
): UpdateSingleProductDeleteStateAction => {
  return {
    payload,
    type: CHANGE_SINGLE_PRODUCT_DELETE_STATE,
  };
};

export const updatedSelectedSwatches = (
  payload: UpdatedSelectedSwatchesPayload,
): UpdatedSelectedSwatchesAction => ({
  payload,
  type: UPDATED_SELECTED_SWATCHES,
});

export const updateProductColors = (
  payload: UpdateProductColorsPayload,
): UpdateProductColorsAction => ({
  payload,
  type: CHANGE_PRODUCT_COLORS,
});

export const updateLocaleProductPublish = (
  payload: UpdateLocaleProductPublishPayload,
): UpdateLocaleProductPublishAction => {
  return {
    payload,
    type: CHANGE_LOCALE_PRODUCT_PUBLISH,
  };
};

export const checkValidationProductCodes = (
  payload: CheckValidationProductCodesPayload,
): CheckValidationProductCodesAction => {
  return {
    payload,
    type: GET_VALIDATION_PRODUCT_CODES,
  };
};

export const loadProductAnalytics = (
  payload: LoadProductAnalyticsPayload,
): LoadProductAnalyticsAction => {
  return {
    payload,
    type: LOAD_PRODUCT_ANALYTICS,
  };
};

export const loadProductAttributeValues = (
  payload: LoadProductAttributeValuesPayload,
): LoadProductAttributeValuesAction => {
  return {
    payload,
    type: LOAD_PRODUCT_ATTRIBUTEVALUES,
  };
};

export const loadCategoryByProductId = (
  payload: LoadCategoryByProductIdPayload,
): LoadCategoryByProductIdAction => {
  return {
    payload,
    type: LOAD_CATEGORY_BY_PRODUCT_ID,
  };
};

export const loadClipCodes = (
  payload: LoadClipCodesPayload,
): LoadClipCodesAction => {
  return {
    payload,
    type: LOAD_CLIP_CODES,
  };
};

export const updateProductAttribute = (
  payload: UpdateProductAttributePayload,
): UpdateProductAttributeAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_ATTRIBUTE,
  };
};

// refactor code
export const updateSelectedProductsCode = (
  payload: UpdateSelectedProductsCodePayload,
): UpdateSelectedProductsCodeAction => {
  return {
    payload,
    type: UPDATE_SELECTED_PRODUCTS_CODE,
  };
};

// export const updateSelectedProducts = (payload: ProductSequenceData[]) => {
//   return {
//     payload,
//     type: UPDATE_SELECTED_PRODUCTS,
//   };
// };

export const setSearchTerm = (searchTerm: string): SetSearchTermAction => {
  return {
    payload: { searchTerm },
    type: SET_SEARCH_TERM,
  };
};

export const updateProductListBySearch = (
  products: ProductSequenceData[],
): UpdateProductListBySearchAction => {
  return {
    payload: { products },
    type: UPDATE_PRODUCT_LIST_BY_SEARCH,
  };
};

export const loadChildProductAttributes = (
  payload: LoadChildProductAttributesPayload,
): LoadChildProductAttributesAction => {
  return {
    payload,
    type: LOAD_CHILD_PRODUCT_ATTRIBUTES,
  };
};

export const loadChildProductVariantAttributesByParentId = (
  payload: LoadChildProductVariantAttributesByParentIdPayload,
): LoadChildProductVariantAttributesByParentIdAction => {
  return {
    payload,
    type: LOAD_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID,
  };
};

export const closeChildProductVariantAttributesByParentIdSubscription = (
  productId: string,
): closeChildProductVariantAttributesByParentIdSubscriptionAction => {
  return {
    payload: {
      id: `${FETCH_CHILD_PRODUCT_VARIANT_ATTRIBUTES_BY_PARENTID.REQUEST}__productId__${productId}`,
    },
    type: CLOSE_SUBSCRIPTION,
  };
};

export const loadInventoryOverlayAttributes = (
  payload: LoadInventoryOvelrayAttributesPayload,
): LoadInventoryOvelrayAttributesAction => {
  return {
    payload,
    type: LOAD_INVENTORY_OVERLAY_ATTRIBUTES,
  };
};

export const loadLowInventoryProducts = (
  payload: LoadLowInventoryProductsPayload,
): LoadLowInventoryProductsAction => {
  return {
    payload,
    type: LOAD_LOW_INVENTORY_PRODUCTS,
  };
};

export const resetProducts = (): ResetProductsAction => {
  return {
    type: RESET_PRODUCTS,
  };
};

export const setDatePickerRange = (
  startDate: string,
  endDate: string,
): SetDatePickerRangeAction => {
  return {
    payload: {
      startDate,
      endDate,
    },
    type: SET_DATE_PICKER_RANGE,
  };
};

export const FetchProductsVariants = (
  payload: FetchProductsVariantsPayload,
): FetchProductsVariantsAction => {
  return {
    payload,
    type: GET_PRODUCTS_VARIANTS,
  };
};

export const UpdateProductsVariantPublishState = (
  payload: UpdateProductVariantPublishStatePayload,
): UpdateProductVariantPublishStateAction => {
  return {
    payload,
    type: PUT_PRODUCT_VARIANT_PUBLISH_STATE,
  };
};

export const ResetProductVariantsFetchedState = () => {
  return {
    type: RESET_PRODUCTS_VARIANTS_STATE,
  };
};
// export const resetTemporaryData = () => {
//   return {
//     type: RESET_TEMPORARY_DATA,
//   };
// };

export const addMigratedCategoriesNew = (payload, categoryIds) => {
  return {
    type: ADD_MIGRATED_CATEGORIES_NEW,
    payload,
    categoryIds,
  };
};

export const updateRecentlyAddedProductsAfterSave = (payload) => {
  return {
    type: UPDATE_RECENTLY_ADDED_PRODUCTS_AFTER_SAVE,
    payload,
  };
};

export const UpdateProductPublishedState = (
  payload: UpdateProductPublishFlagPayload,
): UpdateProductPublishFlagAction => {
  return {
    payload,
    type: UPDATE_PRODUCT_ISPUBLISHED,
  };
};

export const resetProductIsPublishedState = () => {
  return {
    type: RESET_UPDATE_PRODUCT_ISPUBLISHED_STATE,
  };
};

export const updateProductAddCategoryRequestAction = (
  payload,
  fetchProductPayload: fetchSingleProductPayload,
) => {
  return {
    type: ADD_PRODUCT_IN_CATEGORY.REQUEST,
    payload,
    fetchProductPayload,
  };
};

export const updateProductRemoveCategoryRequestAction = (payload) => {
  return {
    type: REMOVE_PRODUCT_IN_CATEGORY.REQUEST,
    payload,
  };
};

export const loadSingleProductAnalytics = (
  payload: singleProductAnlayticsPayload,
): loadSingleProductAnalyticsAction => {
  return {
    type: FETCH_SINGLE_PRODUCT_ANALYTICS,
    payload,
  };
};

export const resetSingleProducAnalyticView = (
  productId: string,
): resetSingleProductAnalyticAction => {
  return {
    type: RESET_SINGLE_PRODUCT_ANALYTICS_VIEW,
    productId,
  };
};

export const resetAllProducAnalyticsView = () => {
  return {
    type: RESET_ALL_PRODUCT_ANALYTICS_VIEW,
  };
};

export const analyticsDataLoader = (productId: string) => {
  return {
    type: ANALYTICS_DATA_LOADER,
    analyticsProductId: productId,
  };
};
