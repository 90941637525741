import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProductAddCategoryRequestAction,
  updateProductRemoveCategoryRequestAction,
} from "../../store/product/ProductActions";
import {
  getVariationGroupDetails,
  resetVariationDetailsByGroupId,
} from "../../store/variation-management/VariationManagementActions";
import { Product } from "../../store/product/ProductTypes";
import {
  componentClasses,
  StyledCard,
  StyledDiv,
  StyledTypography,
  StyledEditIcon,
} from "./VariationManagementModalStyles";
import {
  selectChildCategories,
  selectCurrentCategory,
} from "../../store/category/CategorySelectors";
import { selectGetProductIds } from "../../store/product-list/ProductListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { selectTopCategories } from "../../store/category/CategorySelectors";
import {
  UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG,
  VARIATION_BASE_PRODUCT,
} from "../../store/variation-management/VariationManagementTypes";
import { getProductLeadingColorImageSFCC } from "../../utils/ProductUtil";
import PublishControl from "../PublishControl";
import CheckboxComponent from "../common/Checkbox";
import Tooltip from "../common/ToolTip";
import AppState from "../../store/AppState";
import { PLATFORM_DEFAULTS } from "../../utils/DefaultConfigs";
import { AdapterInfoObject } from "../../utils/AppConfigUtils";
import { selectAdapterInfo } from "../../store/adapter-config/AdapterConfigSelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";

const StyledProductDetail = styled.div<{
  selectedOverlay: string;
}>`
  //padding: 15px;
  overflow: hidden;
  width: 100%;
`;

const ImageContainerBase = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the container */
  align-items: center;
  overflow: hidden;
  margin: auto;
  width: 155px;
  height: 158px;
  & .image {
    width: 135px;
    height: 138px;
    object-fit: cover;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the container */
  align-items: center;
  overflow: hidden;
  margin: auto;
  width: 89px;
  height: 91px;
  & .image {
    width: 69px;
    height: 70px;
    object-fit: cover; // Ensures the image covers the container
    object-position: center; // Ensures the image is centered within the container
  }
`;

const StyledLabel = styled("p")(({ theme }) => ({
  fontSize: "0.75rem", // Update the font size here
}));

const StyledFormControlLabel = styled(FormControlLabel)`
  ${({ theme }) => `
    &.MuiFormControlLabel-root {
      margin-left: 7px !important;
      margin-bottom:10px !important;
      margin-right:5px !important;
      height: 25px !important;
    }
  `}
`;

interface Props {
  product?: Product | null;
  code?: string;
  variationGroupId?: string;
  baseProductId?: string;
  selectedOverlay: string;
  height?: string;
  isColumn: boolean;
  isEditIcon: boolean;
  setEditForm: (value: boolean) => void;
  setCancelClick: (value: boolean) => void;
  setVariationGroup?: (id: string) => void;
  setAddForm?: (value: boolean) => void;
  categoryId: string;
  storeId: string;
  isPublished?: boolean;
  productName?: string;
}
//Might necessary for future
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     editIcon: {
//       cursor: "pointer",
//       fontSize: "0.875rem",
//     },
//     publishedFlag: {
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//     },
//     publishedContainer: {
//       margin: "2px",
//       padding: "1px",
//     },
//     checkBox: {
//       margin: "5px",
//       padding: "5px 8px",
//     },
//     deleteButton: {
//       padding: "5px",
//       marginRight: "10px",
//     },
//     updateButton: {
//       marginRight: "5px",
//     },
//     storeForm: {
//       margin: "10px",
//     },
//     storeInput: {
//       width: "115px",
//     },
//     disableLink: {
//       color: "grey",
//     },
//     cursor: {
//       cursor: "not-allowed",
//       textDecoration: "none",
//     },
//     closeIcon: {
//       width: 21,
//       height: 21,
//       border: 2,
//       color: theme.palette.text.primary,
//     },
//   }),
// );

const VariationProduct: React.FC<Props> = (props) => {
  const {
    product,
    code,
    variationGroupId,
    baseProductId,
    selectedOverlay,
    isColumn,
    isEditIcon,
    setEditForm,
    setCancelClick,
    setVariationGroup,
    setAddForm,
    storeId,
    isPublished,
    productName,
  } = props;

  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(isPublished);
  const [isCategory, setIsCategory] = useState(false);

  const handleEditClick = () => {
    setAddForm && setAddForm(false);
    setEditForm(true);
    setCancelClick(false);
    if (variationGroupId && baseProductId)
      dispatch(getVariationGroupDetails({ baseProductId, variationGroupId }));
    dispatch(resetVariationDetailsByGroupId());
    setVariationGroup && setVariationGroup(variationGroupId ?? "");
  };
  const topCategories = useSelector(selectTopCategories);
  const childCategories = useSelector(selectChildCategories);
  const catalogId = useSelector(selectCurrentCatalogId);
  const localeCode = useSelector(selectCurrentLocale);
  const currentStoreId = useSelector(selectCurrentStoreId);
  const currentCategoryId = useSelector(selectCurrentCategory) || "";
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[ecomPlatformType];
  const storeAndAccountInventory = useSelector((state: AppState) =>
    selectConfigValue(state, "inventoryThreshold"),
  );
  const inventoryThreshold = parseInt(
    storeAndAccountInventory !== ""
      ? storeAndAccountInventory
      : platformDefaults?.inventoryThreshold ?? "",
  );

  const addRemoveFromCurrentCategory = (
    productId: string | undefined,
    categoryId: string,
    e: any,
  ) => {
    const tc = topCategories.find((e) => e.categoryId === categoryId);
    const ccFilter = Object.entries(childCategories).find((e) =>
      e[1].childCategories.find((el) => el.categoryId === categoryId),
    );

    const payload = {
      sequence: 0,
      productId,
      categoryId,
      storeId: currentStoreId,
      parentId: !tc && ccFilter ? ccFilter[0] : null,
      isPublished: e.target.checked && isChecked,
      isFromCategory: 1,
    };
    setIsCategory(e.target.checked);
    if (e.target.checked) {
      let fetchProductPayload = {
        productId,
        catalogId,
        localeCode,
        storeId: currentStoreId,
      };
      dispatch(
        updateProductAddCategoryRequestAction(payload, fetchProductPayload),
      );
    } else {
      dispatch(updateProductRemoveCategoryRequestAction(payload));
    }
  };
  const productIds = useSelector(selectGetProductIds);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isProductPresentInCategory = () => {
    const currentProductId = code || variationGroupId;

    return productIds.findIndex((id) => id === currentProductId) !== -1;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsCategory(isProductPresentInCategory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductPresentInCategory]);

  const updatePublishFlag = (checked: boolean) => {
    const tc = topCategories.find((e) => e.categoryId === props.categoryId);
    const ccFilter = Object.entries(childCategories).find((e) =>
      e[1].childCategories.find((el) => el.categoryId === props.categoryId),
    );
    const payload = {
      productId: product?.productId as string,
      storeId: storeId,
      isPublished: checked,
      parentId: !tc && ccFilter ? ccFilter[0] : null,
      isProductPresentInCategory: checked && isCategory,
    };
    dispatch({
      type: UPDATE_VARIATION_MANAGEMENT_PUBLISH_FLAG.REQUEST,
      payload,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    updatePublishFlag(checked);
  };

  const productLeadingColorImage = React.useMemo(() => {
    return product?.typeCode === VARIATION_BASE_PRODUCT &&
      product?.categorySpecific?.length > 0
      ? getProductLeadingColorImageSFCC(product, currentCategoryId)
      : "";
  }, [product, currentCategoryId]);

  const productImage = productLeadingColorImage || product?.thumbnail;

  const renderImageContainer = () => {
    const ImageWrapper = isEditIcon ? ImageContainer : ImageContainerBase;

    return (
      <ImageWrapper>
        <img
          className="image"
          src={productImage}
          alt={product?.name}
          draggable={false}
        />
      </ImageWrapper>
    );
  };

  const renderProductCodeDiv = () => {
    switch (isEditIcon) {
      case true:
        return componentClasses.imageProductCodeDiv;
      case false:
        return componentClasses.imageProductCodeBaseDiv;
      default:
        return "";
    }
  };

  const renderCardClass = () => {
    return isEditIcon ? componentClasses.cardList : componentClasses.card;
  };
  return (
    <StyledCard variant="outlined" className={renderCardClass()}>
      <StyledProductDetail selectedOverlay={selectedOverlay}>
        {!isEditIcon && (
          <StyledTypography className={componentClasses.stockBase}>
            INV: {product?.stock}
          </StyledTypography>
        )}

        {isEditIcon && (
          <StyledDiv
            className={componentClasses.cardTileDiv}
            isEditIcon={isEditIcon}
            stock={Number(product?.stock)}
            inventoryThreshold={inventoryThreshold}
          >
            <StyledTypography className={componentClasses.stock}>
              INV: {product?.stock}
            </StyledTypography>
            {isEditIcon && (
              <StyledDiv>
                <StyledEditIcon onClick={handleEditClick} />
              </StyledDiv>
            )}
          </StyledDiv>
        )}
        <StyledDiv className={renderProductCodeDiv()}>
          {renderImageContainer()}
          {productName && (
            <Tooltip tooltipTitle={productName} placement="bottom">
              <StyledTypography
                className={componentClasses.productNames}
                isEditIcon={isEditIcon}
              >
                {productName}
              </StyledTypography>
            </Tooltip>
          )}
          <Tooltip
            tooltipTitle={code || variationGroupId || ""}
            placement="bottom"
          >
            <StyledTypography
              className={componentClasses.productCodes}
              isEditIcon={isEditIcon}
            >
              {code || variationGroupId}
            </StyledTypography>
          </Tooltip>
        </StyledDiv>
      </StyledProductDetail>
      <StyledDiv className={componentClasses.checkboxDiv}>
        {!isColumn && (
          <PublishControl
            product={product}
            onChange={handleCheckboxChange}
            flag={isChecked}
            isVariation={true}
          />
        )}
        {isColumn && (
          <PublishControl
            product={product}
            onChange={handleCheckboxChange}
            flag={isChecked}
            isVariation={true}
          />
        )}
        <StyledFormControlLabel
          control={
            <CheckboxComponent
              checkboxId="VariationProduct"
              checked={isCategory}
              value={code || variationGroupId}
              addRemoveFromCurrentCategory={addRemoveFromCurrentCategory}
              handleParam={props.categoryId}
            />
          }
          label={<StyledLabel>In Category</StyledLabel>}
        />
      </StyledDiv>
    </StyledCard>
  );
};

export default VariationProduct;
