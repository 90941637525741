import { OVERLAY_TABS } from "../../utils/Constants";
import {
  UPDATE_SELECTED_OVERLAY,
  OverlayActions,
  updateSelectedOverlayAction,
  UPDATE_SELECTED_INVENTORY_ATTRIBUTE,
  UPDATE_SELECTED_ATTRIBUTE,
  updateSelectedInventoryAttributeAction,
  updateSelectedAttributeAction,
} from "./OverlayTypes";

export interface OverlayState {
  overlay: string;
  attribute: string;
  inventoryAttribute: string;
}

const initialState: OverlayState = {
  overlay: OVERLAY_TABS.PRODUCTS,
  attribute: "",
  inventoryAttribute: "",
};

export const OverlayReducer = (
  state = initialState,
  action: OverlayActions,
) => {
  switch (action.type) {
    case UPDATE_SELECTED_OVERLAY:
      const overlayAction = action as updateSelectedOverlayAction;
      return {
        ...state,
        overlay: overlayAction.overlay,
      };
    case UPDATE_SELECTED_INVENTORY_ATTRIBUTE:
      const inventoryAction = action as updateSelectedInventoryAttributeAction;
      return {
        ...state,
        inventoryAttribute: inventoryAction.invAttribute,
      };
    case UPDATE_SELECTED_ATTRIBUTE:
      const attributeAction = action as updateSelectedAttributeAction;
      return {
        ...state,
        attribute: attributeAction.attribute,
      };
    default:
      return state;
  }
};
