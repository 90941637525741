import { Theme, styled, IconButton } from "@mui/material";
import Modal from "@material-ui/core/Modal";
import { ResizableBox } from "react-resizable";
import CloseIcon from "@material-ui/icons/Close";

export const styles = (theme: Theme) => {
  return {
    dialogTitle: {
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight: 700,
      color: theme.palette.text.primary,
      marginBottom: 1,
    },
    dialogPaper: {
      maxHeight: "100vh",
      border: "1px solid #828282",
      paddingTop: 2,
      paddingBottom: 3,
    },
    dialogContent: {
      padding: "20px 24px 10px 24px",
      fontSize: "18px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    dialogActions: {
      display: "flex",
      justifyContent: "center",
    },
  };
};

export const commonClasses = {
  closeButton: "closeButton",
  modal: "modal",
  resizable: "resizable",
  closeIcon: "closeIcon",
};
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${commonClasses.closeButton}`]: {
    position: "absolute",
    right: 20,
    color: "rgba(0, 0, 0, 0.54)",
    padding: "12px",
    overflow: "visible",
    fontSize: "1.5rem",
    borderRadius: "50%",
  },
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
  [`&.${commonClasses.modal}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
}));

export const StyledResizableBox = styled(ResizableBox)(({ theme }) => ({
  [`&.${commonClasses.resizable}`]: {
    position: "relative",
    "& .react-resizable-handle": {
      position: "absolute",
      width: 20,
      height: 20,
      bottom: 0,
      right: 0,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      "background-position": "bottom right",
      padding: "0 3px 3px 0",
      "background-repeat": "no-repeat",
      "background-origin": "content-box",
      "box-sizing": "border-box",
      cursor: "se-resize",
    },
  },
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  [`&.${commonClasses.closeIcon}`]: {
    width: 21,
    height: 21,
    border: 2,
    color: theme.palette.text.primary,
  },
}));
