import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { BUSINESS_RULES_NAVIGATION_TABS } from "../../../utils/Constants";
import {
  useLocation,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import SortingRules from "./SortingRules/SortingRules";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAutomationRulesList,
  loadUnifiedSortingRulesList,
} from "../../../store/businessRules-list/BusinessRulesListActions";
import { setLoaderState } from "../../../store/loader/LoaderActions";
import AutomationRules from "./AutomationRules/AutomationRules";
import { selectCallerAccountId } from "../../../store/app-config/AppConfigSelectors";
import { useQuery } from "../../../hooks/useQueryParams";
import TabsComponent from "../../common/Tabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      minHeight: "45px",
      backgroundColor: "#F2F3F4",
      alignItems: "center",
      color: "black",
      "& .MuiTab-root": {
        padding: 0,
        minHeight: "35px",
      },
    },
    select: {
      marginLeft: "0.5rem",
      minWidth: "100px",
    },
    selectArea: {
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: "75%",
        width: "100%",
        backgroundColor: "rgb(112, 155, 177)",
      },
    },
  }),
);
const routes = [
  {
    name: { id: "businessRulesTabs.sorting", defaultMessage: "Rules" },
    link: `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES}`,
    activeIndex: 0,
  },
  {
    name: {
      id: "businessRulesTabs.schedules",
      defaultMessage: "Schedules",
    },
    link: `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES}`,
    activeIndex: 0,
  },
];
const BusinessRulesContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const currentTab =
    routes.find((route) => route.link.includes(location.pathname))
      ?.activeIndex ?? 0;
  const [value, setValue] = useState<number>(currentTab);
  const accountIdQuery = useQuery().get("accountId") ?? "";
  let userAccountId = useSelector(selectCallerAccountId) || accountIdQuery;

  useEffect(() => {
    const path = location.pathname;
    if (path.includes(BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES)) {
      setValue(0);
      dispatch(setLoaderState("LIST_BUSINESS_RULES_LOADER", true));
      dispatch(loadUnifiedSortingRulesList());
      return;
    } else if (path.includes(BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES)) {
      setValue(1);
      dispatch(setLoaderState("LIST_AUTOMATION_RULES_LOADER", true));
      dispatch(loadAutomationRulesList());
      return;
    } else {
      setValue(0);
      return;
    }
  }, [location.pathname, dispatch]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue) => {
    setValue(newValue);
  };

  return (
    <Box style={{ padding: 30, height: "100vh", overflow: "scroll" }}>
      <Box>
        <TabsComponent
          tabsId="BusinessRulesContainer"
          classes={classes}
          value={value}
          handleChange={handleChange}
          routes={routes}
          userAccountId={userAccountId}
        />
      </Box>
      <Switch>
        <Route path={`${url}/${BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES}`}>
          <Box>
            <SortingRules />
          </Box>
        </Route>
        <Route
          path={`${url}/${BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES}`}
        >
          <Box>
            <AutomationRules />
          </Box>
        </Route>
        <Redirect
          to={`${url}/${BUSINESS_RULES_NAVIGATION_TABS.SORTING_RULES}`}
        />
      </Switch>
    </Box>
  );
};

export default BusinessRulesContainer;
