import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import {
  selectModalVariable,
  selectIsModalOpen,
} from "../../store/modal/ModalSelectors";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { setModalState } from "../../store/modal/ModalActions";
import {
  selectUniqueLocaleCodes,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import { selectAttributeById } from "../../store/facets/FacetSelectors";
import {
  AttributeValue,
  AttributeValueDescription,
} from "../../store/category/CategoryTypes";
import {
  updateAttributeValue,
  addAttributeValue,
} from "../../store/attributes/AttributeActions";
import { useIntl } from "react-intl";
import CustomDialog from "../common/DialogContainer";
import TableComponent from "../common/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      position: "sticky",
      top: 0,
      backgroundColor: "#f18308",
    },
    firstColum: {
      position: "sticky",
      left: 0,
      backgroundColor: "#fafafa",
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      zIndex: 3,
    },
    firstCell: {
      zIndex: 4,
    },
    table: {
      maxHeight: "60vh",
      "& .MuiTableCell-head": {
        minWidth: "11vw",
      },
      "&::-webkit-scrollbar": {
        webkitAppearance: "none",
        width: 6,
      },
      "&::-webkit-scrollbar:vertical": {
        width: 6,
      },
      "&::-webkit-scrollbar:horizontal": {
        height: 6,
      },
      "&::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: 10,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
        border: "1px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
    textarea: {
      borderRadius: 4,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      padding: "2px 5px",
      width: "100%",
      resize: "none",
    },
  }),
);

export const editAttributeValuesModalId = "editAttributeValuesModal";

const EditAttributeValuesModal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [newFacetValue, setNewFacetValue] = useState("");

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, editAttributeValuesModalId),
  );
  const attributeId = useSelector((state: AppState) =>
    selectModalVariable(state, editAttributeValuesModalId, "attributeId"),
  );
  const attributeValueId = useSelector((state: AppState) =>
    selectModalVariable(state, editAttributeValuesModalId, "attributeValueId"),
  );
  const attributeById = useSelector((state: AppState) =>
    selectAttributeById(state, attributeId),
  );
  const currentLocale = useSelector(selectCurrentLocale);
  const dispatch = useDispatch();
  let localeCodes = useSelector(selectUniqueLocaleCodes);

  const handleClose = () => {
    dispatch(setModalState(editAttributeValuesModalId, false));
  };
  if (localeCodes.length === 0) {
    const valueLocales = new Set<string>();
    if (attributeById) {
      attributeById.values.forEach((attributeValue) => {
        if (
          attributeValueId &&
          attributeValueId !== attributeValue.attributeValueId
        ) {
          // do nothing
        } else {
          attributeValue.descriptions.forEach((desc) => {
            valueLocales.add(desc.localeCode);
          });
        }
      });
    }
    if (valueLocales.size > 0) localeCodes = Array.from(valueLocales);
    else if (currentLocale) localeCodes = [currentLocale];
  }

  const keyDownHandler = useCallback(
    (
      value: AttributeValue,
      localeCode: string,
      localeValue: AttributeValueDescription | undefined,
    ) => {
      const trimmedValue = newFacetValue.trim();
      if (!localeValue) {
        const descriptions: AttributeValueDescription[] = [
          {
            displayValue: trimmedValue,
            localeCode: localeCode ? localeCode : "",
            sequence: 0,
          },
        ];

        const payload = {
          attributeValueObject: {
            attributeValueId: value.attributeValueId,
            attributeId,
            code: trimmedValue,
            sequence: 0,
            descriptions:
              descriptions.length > 0
                ? {
                    data: descriptions[0],
                    on_conflict: {
                      constraint: "AttributeValueDescription_pkey",
                      update_columns: "displayValue",
                    },
                  }
                : null,
          },
        };
        dispatch(addAttributeValue(payload));
      } else {
        if (localeValue.displayValue !== trimmedValue) {
          const payload = {
            localeCode,
            attributeValueId: value.attributeValueId,
            attributeValueDisplayValue: trimmedValue,
          };
          dispatch(updateAttributeValue(payload));
        }
      }
      setNewFacetValue("");
    },
    [newFacetValue, dispatch, attributeId],
  );

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewFacetValue(event.target.value.trim());
  };

  const editAttributeValuesModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "editAttributeValuesModal.header",
          defaultMessage: "Edit attribute values for multiple languages",
        })}
      </Typography>
    );
  };

  const editAttributeValuesModalContent = () => {
    return (
      <TableComponent
        tableId="EditAttributeValuesModalTable"
        classes={classes}
        localeCodes={localeCodes}
        attributeById={attributeById}
        attributeValueId={attributeValueId}
        keyDownHandler={keyDownHandler}
        setNewFacetValue={setNewFacetValue}
        changeHandler={changeHandler}
      />
    );
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={editAttributeValuesModalTitle()}
      maxWidth={"sm"}
      fullWidth
    >
      {editAttributeValuesModalContent()}
    </CustomDialog>
  );
};

export default React.memo(EditAttributeValuesModal);
