export const FETCH_PRODUCT_SOCIAL_ANALYTICS = {
  REQUEST: "FETCH_PRODUCT_SOCIAL_ANALYTICS_REQUEST",
  SUCCESS: "FETCH_PRODUCT_SOCIAL_ANALYTICS_SUCCESS",
  FAILURE: "FETCH_PRODUCT_SOCIAL_ANALYTICS_FAILURE",
};

export const FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS =
  "FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS";
export const LOAD_SOCIAL_ANALYTICS_SERVICE_URL =
  "LOAD_SOCIAL_ANALYTICS_SERVICE_URL";

export interface ProductSocialAnalyticsPayload {
  productCodes: string[];
  isRefreshingAllProducts?: boolean;
}

export interface ProductSocialAnalyticsAction {
  payload: ProductSocialAnalyticsPayload;
  type: typeof FETCH_PRODUCT_SOCIAL_ANALYTICS;
}

export interface AnalyticsData {
  cachedAt: string;
  products: Products[];
}

export interface Products {
  productCode: string;
  rating: number;
  reviewCount: number;
}
