import React from "react";
import { useDragLayer, DragLayerMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";
import styled from "styled-components";

import ItemsDragPreview from "./ItemsDragPreview";
import { DragItem } from "./ProductTile";

const StyledItemDragLayer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 1500;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const getItemStyles = (currentOffset: XYCoord | null) => {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
    filter: "drop-shadow(0 2px 12px rgba(0,0,0,0.45))",
  };
};

const ItemDragLayer: React.FC = () => {
  // const classes = useStyles();

  const { item, itemType, currentOffset, isDragging } = useDragLayer(
    (monitor: DragLayerMonitor) => {
      return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
      };
    },
  );
  const renderItem = (type: string | symbol | null, item: DragItem) => {
    switch (type) {
      case "PRODUCT":
        return (
          <ItemsDragPreview
            items={item.itemsDragStack}
            width={item.clientWidth}
            height={item.clientHeight}
          />
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <StyledItemDragLayer>
      <div style={getItemStyles(currentOffset)}>
        {renderItem(itemType, item)}
      </div>
    </StyledItemDragLayer>
  );
};

export default React.memo(ItemDragLayer);
