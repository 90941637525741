import React, { forwardRef, CSSProperties } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Typography, TypographyProps } from "@mui/material";

// Define a type for the styling props
interface ButtonStyleProps {
  style?: CSSProperties;
  padding?: string;
  justifyContent?: CSSProperties["justifyContent"];
  width?: string;
}

interface ButtonComponentProps {
  variant?: "outlined" | "contained" | "pickerVariant" | "menu";
  color?:
    | "primary"
    | "secondary"
    | "secondaryButtonColorCTABlue"
    | "tertiary"
    | "error";
  disabled?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startIcon?: React.ReactNode;
  style?: CSSProperties;
  padding?: string;
  justifyContent?: CSSProperties["justifyContent"];
  autoFocus?: boolean;
  type?: "button" | "submit" | "reset";
  width?: string;
}

// Function to generate styles based on props
const generateRootStyle = ({
  style = {},
  padding = "8px 15px",
  justifyContent = "flex-start",
  fullWidth,
}: ButtonStyleProps & { fullWidth?: boolean }): CSSProperties => ({
  margin: "5px 0px",
  textAlign: "left",
  fontSize: "10px",
  lineHeight: "1.1",
  alignItems: "center",
  boxShadow: "none",
  width: fullWidth ? "100%" : "auto", // Conditionally apply width
  ...style,
  padding,
  justifyContent,
  verticalAlign: "middle",
});

const CustomButton = styled(Button)<ButtonComponentProps>(
  ({ color, theme }) => ({
    ...(color === "tertiary" && {
      backgroundColor: theme.palette.tertiary.main,
      "&:hover": {
        backgroundColor: theme.palette.tertiary.hoverColor,
      },
    }),
  }),
);
// Correctly typed StyledTypography component
const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  display: "flex",
  paddingTop: "1.7px",
  alignItems: "center",
  lineHeight: "inherit",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
}));

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    {
      variant = "outlined",
      color = "primary",
      disabled = false,
      fullWidth = false,
      children,
      onClick,
      startIcon = null,
      style = {},
      padding = "8px 15px",
      justifyContent = "flex-start",
      autoFocus = false,
      type = "button",
    },
    ref,
  ) => {
    const rootStyle = generateRootStyle({
      style,
      padding,
      justifyContent,
      fullWidth,
    });
    return (
      <CustomButton
        variant={variant}
        color={color as any}
        autoFocus={autoFocus}
        disabled={disabled}
        onClick={onClick}
        fullWidth={fullWidth}
        startIcon={startIcon}
        style={rootStyle}
        type={type}
        ref={ref}
        disableRipple
      >
        <StyledTypography component="span">{children}</StyledTypography>
      </CustomButton>
    );
  },
);

export default ButtonComponent;
