import React from "react";
import { Box, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Label } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { FormikProps } from "formik";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrp: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "space-between",
      "&.Mui-checked": {
        color: theme.palette?.primary?.main, // Active color for checked state
      },
    },
    radio: {
      "&.Mui-checked": {
        color: theme.palette?.primary.main, // Apply the active color from the theme
      },
    },
  }),
);

interface RadioComponentProps {
  radioId: string;
  classes?: ClassNameMap;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setEndValue?: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  endValue?: string;
  formik?: FormikProps<{}>;
  IAutomationEndDuration?: {
    ON: string;
    NEVER: string;
  };
  data?: { [key: string]: string };
  children?: JSX.Element;
}

const RadioComponent: React.FC<RadioComponentProps> = (props) => {
  const {
    radioId,
    classes,
    setValue,
    setEndValue,
    value,
    formik,
    endValue,
    IAutomationEndDuration,
    data,
    children,
  } = props;
  const intl = useIntl();
  const radioclass = useStyles();

  switch (radioId) {
    case "AutomationRulesModal":
      if (classes && setValue && value) {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setValue((event.target as HTMLInputElement).value);
        };
        return (
          <RadioGroup
            onChange={(e) => {
              handleChange(e);
            }}
            value={value}
          >
            <Box className={radioclass.formGrp}>
              <Label className={classes.label}>
                {intl.formatMessage({
                  id: "automationRulesModal.repeat",
                  defaultMessage: "Repeat",
                })}
              </Label>
              <FormControlLabel
                label="One Time"
                control={<Radio className={radioclass.radio} />}
                value="One Time"
              />
              <FormControlLabel
                label="Repeat"
                control={<Radio className={radioclass.radio} />}
                value="Repeat"
              />
            </Box>
          </RadioGroup>
        );
      }
      break;
    case "AutomationRulesModalRepeat":
      if (
        classes &&
        setEndValue &&
        formik &&
        endValue &&
        IAutomationEndDuration
      ) {
        const handleEndValue = (
          event: React.ChangeEvent<HTMLInputElement>,
          formik,
        ) => {
          setEndValue((event.target as HTMLInputElement).value);
        };
        return (
          <RadioGroup
            onChange={(e) => {
              handleEndValue(e, formik);
            }}
            value={endValue}
          >
            <Box className={radioclass.formGrp}>
              <Label className={classes.label}>
                {intl.formatMessage({
                  id: "automationRulesModal.ends",
                  defaultMessage: "Ends",
                })}
              </Label>
              <FormControlLabel
                label={intl.formatMessage({
                  id: "automationRulesModal.never",
                  defaultMessage: "Never",
                })}
                control={<Radio className={radioclass.radio} />}
                value={IAutomationEndDuration.NEVER}
              />
              <FormControlLabel
                label={intl.formatMessage({
                  id: "automationRulesModal.on",
                  defaultMessage: "On",
                })}
                control={<Radio className={radioclass.radio} />}
                value={IAutomationEndDuration.ON}
              />
            </Box>
          </RadioGroup>
        );
      }
      break;
    default:
      if (data)
        return (
          <RadioGroup name="radio-buttons-group">
            <Box className={radioclass.formGrp}>
              {Object.values(data).map((e) => {
                return (
                  <>
                    <FormControlLabel
                      value={e}
                      control={<Radio className={radioclass.radio} />}
                      label={e}
                    />
                  </>
                );
              })}
            </Box>
          </RadioGroup>
        );
      if (children)
        return (
          <RadioGroup name="radio-buttons-group">
            <Box className={radioclass.formGrp}>{children}</Box>
          </RadioGroup>
        );
  }
  return <></>;
};

export default RadioComponent;
