import { ThemeOptions } from "@mui/material";

export const zIndex: ThemeOptions["zIndex"] = {
  mobileStepper: 1,
  fab: 2,
  speedDial: 2,
  appBar: 3,
  drawer: 4,
  modal: 5,
  snackbar: 6,
  tooltip: 7,
};
