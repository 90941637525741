import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { selectUserLocale } from "./store/user/UserSelectors";

interface Props {
  children: React.ReactNode;
}

function LocaleInitializer({ children }: Props) {
  const currentLocaleCode = useSelector(selectUserLocale);
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState({});
  useEffect(() => {
    if (currentLocaleCode && currentLocaleCode.length > 0) {
      fetch(`lang/${currentLocaleCode}.json`)
        .then((data) => data.json())
        .then((json) => {
          setMessages(json);
          setLocale(currentLocaleCode.split("-")[0]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [currentLocaleCode]);

  if (Object.keys(messages).length === 0) {
    return <React.Fragment />;
  }
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export default React.memo(LocaleInitializer);
