import AppState from "../AppState";

export const selectCatalogList = (state: AppState, storeId: string) => {
  return state.catalog.catalogList[storeId] ?? [];
};

export const selectAllCatalogs = (state: AppState) => {
  return state.catalog.allCatalogs;
};

export const selectCatalogListNew = (state: AppState) => {
  return state.catalog.catalogList;
};

export const selectCurrentCatalogId = (state: AppState) => {
  return state.catalog.currentCatalogId;
};
export const selectIsCatalogsLoadedState = (state: AppState) => {
  return state.catalog.isCatalogsLoaded;
};

export const selectIsCatalogsLoading = (state: AppState) => {
  return state.catalog.isProgessing;
};
