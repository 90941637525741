import {
  FETCH_PRODUCT_ALERTS_LIST,
  loadProductAlertsListAction,
} from "./ProductAlertsListType";

export const loadProductAlertsList = (): loadProductAlertsListAction => {
  return {
    type: FETCH_PRODUCT_ALERTS_LIST.REQUEST,
  };
};
