import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { classicTheme } from "../../../styles/themes/classic";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    stack: { padding: "10px" },
    errorMessage: {
      padding: "10px",
      marginBottom: "50px",
    },
    accountContent: {
      border: `2px solid ${theme.palette.common.black}`,
      "&:hover": {
        backgroundColor: classicTheme.palette.primaryHoverColor?.hoverLight,
      },
    },
    accountActiveContent: {
      border: `2px solid ${theme.palette.common.black}`,
      "&:hover": {
        backgroundColor: classicTheme.palette.primaryHoverColor?.hoverLight,
      },
    },
    activeAccountContent: {
      backgroundColor: classicTheme.palette.primaryHoverColor?.hoverLight,
      border: `2px solid ${classicTheme.palette.primary.main}`,
    },
    accountText: {
      fontWeight: "normal", // Default font weight
    },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
  }),
);
