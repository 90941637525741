import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  FETCH_COLOR_MANAGEMENT_PRODUCT_LIST,
  UPDATE_PRODUCT_COLOR_IDS_SEQUENCE,
  UPDATE_PRODUCT_COLOR_SEQUENCE,
  UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG,
  UPDATE_COLOR_PUBLISH_FLAG_COMPLETE,
  FETCH_COLOR_MANAGEMENT_LIST,
  UPDATE_LEADING_COLOR,
  UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT,
  RESET_PRODUCT_LEADING_COLOR,
  FETCH_COLOR_VARIATION_ATTRIBUTES,
  GetVariantAttributesAction,
  FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
  FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
  UpdateProductColorAction,
} from "./ColorManagementTypes";
import { GetColorManagementProductListAction } from "./ColorManagementTypes";
import { callApi } from "../../utils/SagaUtils";
import { putData, getData } from "../../services/ApiService";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../catalog/CatalogSelectors";
import { UpdateLeadingColorProductAction } from "./ColorManagementTypes";
import {
  createBaseProductLeadingColorImageAction,
  createResetProdutctUpdateLeadingColorAction,
} from "./ColorManagementActions";
import {
  refreshProductColorFromList,
  refreshProductFromList,
} from "../product-list/ProductListActions";

function* fetchColorManagementProductList(
  action: GetColorManagementProductListAction,
) {
  yield put({
    type: FETCH_COLOR_MANAGEMENT_PRODUCT_LIST.SUCCESS,
    payload: { productList: action.payload.productList },
  });
}

function* updateColorSequence(action: UpdateProductColorAction) {
  let actionType = UPDATE_PRODUCT_COLOR_IDS_SEQUENCE;
  try {
    const productId = action.payload.productId;
    const colorIds = action.payload.colorIds;
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const localeCode = yield select(selectCurrentLocale);
    const constName = Object.keys({
      UPDATE_PRODUCT_COLOR_IDS_SEQUENCE,
    })[0].toString();

    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
      "x-locale-code": localeCode || "default",
      // "x-request-id": "req-x",
    };
    const optionsObj = {
      showGenericError: false,
    };

    const payload = {
      colorIds: colorIds,
    };
    const endpoint = acquireEndpoint(constName, productId);
    const result = yield call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
      headersObj,
      optionsObj,
    );

    if (result && result.type === actionType.SUCCESS) {
      yield put(
        refreshProductColorFromList({
          productId,
          localeCode,
          storeId,
        }),
      );
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "colorManagementModal.updateColorSequenceSuccess",
                defaultMessage:
                  "Updated color sequence value(s) of {productId}",
              },
              variables: {
                productId,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "colorManagementModal.updateColorSequenceFail",
                defaultMessage:
                  "Unable to update color sequence value(s) of {productId}",
              },
              variables: {
                productId,
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updatePublishFlagInColorModal(action) {
  try {
    const storeId = action.payload.storeId;
    const localeCode = action.payload.localeId;
    const catalogId = yield select(selectCurrentCatalogId);
    const productId = action.payload.productId;
    const colorId = action.payload.colorId;
    const isPublished = action.payload.isPublished;
    const actionType = UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG;
    const constName = Object.keys({
      UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, productId, colorId);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const optionsObj = {
      showGenericError: false,
    };
    const payloadData: any = {
      isPublished,
    };
    const result = yield call(
      callApi,
      actionType,
      putData,
      payloadData,
      endpoint,
      headersObj,
      optionsObj,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield put(
        refreshProductColorFromList({
          productId,
          localeCode,
          storeId,
        }),
      );
      yield put(
        refreshProductFromList({
          productId,
          catalogId,
          localeCode,
          storeId,
        }),
      );
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "colorManagementModal.updatePublishFlagSuccess",
                defaultMessage:
                  "Updated published value of {productId} color {colorId} for {storeId} store",
              },
              variables: {
                productId,
                storeId,
                colorId,
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
      yield put({
        type: UPDATE_COLOR_PUBLISH_FLAG_COMPLETE,
        payload: { productId, isPublished, storeId, colorId },
      });
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "colorManagementModal.updatePublishFlagFailure",
                defaultMessage: "Unable to update published value",
              },
              variables: {
                productId,
                storeId,
                colorId,
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (error) {
    yield put({ type: UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG.FAILURE });
  }
}

function* getVariationAttributes(action: GetVariantAttributesAction) {
  try {
    const baseProductId = action.payload.baseProductId;
    const actionType = FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID;
    const constName = Object.keys({
      FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, baseProductId);
    const result = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
    );
    if (result?.type === actionType.SUCCESS) {
      yield put({
        type: FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
        payload: result.payload?.variationAttributes,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID.FAILURE,
      message: e.message,
    });
  }
}

function* updateLeadingColorForProduct(
  action: UpdateLeadingColorProductAction,
) {
  try {
    const storeId = yield select(selectCurrentStoreId);
    const localeCode = yield select(selectCurrentLocale);
    const catalogId = yield select(selectCurrentCatalogId);
    const categoryId = action.payload.categoryId;
    const productId = action.payload.productId;
    const leadingColorId = action.payload.leadingColorId;
    const actionType = UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT;
    const constName = Object.keys({
      UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT,
    })[0].toString();
    const endpoint = acquireEndpoint(constName, categoryId, productId);
    const headersObj = {
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    const payloadData: any = {
      leadingColorId: leadingColorId,
    };
    const result = yield call(
      callApi,
      actionType,
      putData,
      payloadData,
      endpoint,
      headersObj,
    );
    if (result?.type === actionType.SUCCESS) {
      yield put(
        createBaseProductLeadingColorImageAction({
          leadingColorId,
          thumbnail: action.payload.thumbnail,
          productId,
          categoryId,
        }),
      );
      yield put(
        refreshProductColorFromList({
          productId,
          localeCode,
          storeId,
        }),
      );
    }
  } catch (e: any) {
    console.error(e);
    yield put({
      type: UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT.FAILURE,
      message: e.message,
    });
  }
}

function* resetUpdateLeadingColorState() {
  try {
    yield put(createResetProdutctUpdateLeadingColorAction());
  } catch (e: any) {
    console.error(e);
  }
}

export function* watchUpdatePublishFlagInColorModal() {
  yield takeEvery(
    UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG.REQUEST,
    updatePublishFlagInColorModal,
  );
}

export function* watchGetColorVariationAttributes() {
  yield takeEvery(FETCH_COLOR_VARIATION_ATTRIBUTES, getVariationAttributes);
}

export function* watchUpdateProductColorIdSequence() {
  yield takeEvery(UPDATE_PRODUCT_COLOR_SEQUENCE, updateColorSequence);
}

export function* watchFetchColorManagementProductList() {
  yield takeEvery(FETCH_COLOR_MANAGEMENT_LIST, fetchColorManagementProductList);
}

export function* watchUpdateLeadingColorForProduct() {
  yield takeEvery(UPDATE_LEADING_COLOR, updateLeadingColorForProduct);
}

export function* watchResetUpdateLeadingColorState() {
  yield takeEvery(RESET_PRODUCT_LEADING_COLOR, resetUpdateLeadingColorState);
}
