import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";

import { REPORT_TYPE_NAVIGATION_TABS } from "../../utils/Constants";
import { setReportType } from "../../store/report/ReportActions";
import { selectReportType } from "../../store/report/ReportSelectors";
import TabsComponent from "../common/Tabs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      justifyContent: "center",
      gridTemplateColumns: "repeat(3,1fr)",
      backgroundColor: "#F2F3F4",
      minHeight: "fit-content",
    },
    tabs: {
      minHeight: "35px",
      backgroundColor: "#F2F3F4",
      "& .MuiTab-root": {
        padding: 0,
        minHeight: "35px",
      },
    },
  }),
);

const ReportTypeNavigationTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();
  const search = location.search ? location.search : "";
  const dispatch = useDispatch();
  const reportType = useSelector(selectReportType);

  useEffect(() => {
    const path = location.pathname;
    if (
      path.includes(REPORT_TYPE_NAVIGATION_TABS.PRODUCT) &&
      reportType !== REPORT_TYPE_NAVIGATION_TABS.PRODUCT
    ) {
      dispatch(setReportType(REPORT_TYPE_NAVIGATION_TABS.PRODUCT));
    } else if (
      path.includes(REPORT_TYPE_NAVIGATION_TABS.CATEGORY) &&
      reportType !== REPORT_TYPE_NAVIGATION_TABS.CATEGORY
    ) {
      dispatch(setReportType(REPORT_TYPE_NAVIGATION_TABS.CATEGORY));
    }
  }, [reportType, location, dispatch]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    dispatch(setReportType(newValue));
    history.replace(`${url}/${newValue}${search}`);
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Paper variant="elevation" elevation={0}>
          <TabsComponent
            tabsId="ReportTypeNavigationTabs"
            classes={classes}
            reportType={reportType}
            handleChange={handleChange}
          />
        </Paper>
      </Toolbar>
      <Divider />
    </>
  );
};

export default ReportTypeNavigationTabs;
