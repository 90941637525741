import { Backdrop } from "@mui/material";

import { makeStyles } from "hooks";

interface IFrameViewerProps {
  open: boolean;
  onBackDropClick: () => void;
  src: string;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  iframe: {
    width: "80%",
    height: "80%",
  },
}));

export function IframeViewer(props: IFrameViewerProps) {
  const { src, title = "Iframe View", open, onBackDropClick } = props;
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={onBackDropClick}
    >
      <iframe
        loading="lazy"
        className={classes.iframe}
        src={src}
        title={title}
        referrerPolicy="no-referrer"
      />
    </Backdrop>
  );
}
