import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import ButtonComponent from "../components/common/ButtonComponent";
import TopPanelSaveButton from "./TopPanelSaveButton";
import { updateProductListSequenceMode } from "../store/product-list/ProductListActions";
import { useDispatch, useSelector } from "react-redux";
import { SEQUENCE_MODE_TYPE } from "../utils/Constants";
import {
  selectProductListSequenceMode,
  shouldUpdateEditSequenceList,
  selectPinnedProducts,
  selectNumberOfPinnedProducts,
} from "../store/product-list/ProductListSelectors";
import { selectCurrentCategory } from "../store/category/CategorySelectors";
import { selectCurrentCatalogId } from "../store/catalog/CatalogSelectors";

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {},
    buttonsRoot: {
      marginLeft: "auto",
      display: "flex",
    },
  }),
);

interface Props {
  setProgress: Function;
}

const ProductListActionBtn: React.FC<Props> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const sequenceMode = useSelector(selectProductListSequenceMode);
  const shouldUpdateEditSequenceListFlag = useSelector(
    shouldUpdateEditSequenceList,
  );
  const catalogId = useSelector(selectCurrentCatalogId);
  const categoryId = useSelector(selectCurrentCategory);
  const numberOfPinnedProducts = useSelector(selectNumberOfPinnedProducts);
  const pinnedProducts = useSelector(selectPinnedProducts);
  const showUnimplemented =
    window.localStorage.getItem("showUnimplemented") === "true";

  const renderSaveButton = sequenceMode === SEQUENCE_MODE_TYPE.EDIT;
  useEffect(() => {
    if (sequenceMode === SEQUENCE_MODE_TYPE.VIEW) {
      if (shouldUpdateEditSequenceListFlag) {
        dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
      } else if (
        !shouldUpdateEditSequenceListFlag &&
        numberOfPinnedProducts !== pinnedProducts.length
      ) {
        dispatch(updateProductListSequenceMode(SEQUENCE_MODE_TYPE.EDIT));
      }
    }
  }, [
    shouldUpdateEditSequenceListFlag,
    numberOfPinnedProducts,
    pinnedProducts,
    sequenceMode,
    intl,
    dispatch,
  ]);

  return (
    <>
      <div className={classes.buttonsRoot}>
        <TopPanelSaveButton
          categoryId={categoryId}
          catalogId={catalogId}
          setProgress={props.setProgress}
          enableSaveMode={renderSaveButton}
        />
        {showUnimplemented && (
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
          >
            {intl.formatMessage({
              id: "productListActionBtn.syncActionLabel",
              defaultMessage: "Sync",
            })}
          </ButtonComponent>
        )}
      </div>
    </>
  );
};

export default ProductListActionBtn;
