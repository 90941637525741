export const FETCH_PRODUCT_ALERTS_SCHEDULE_DATA = {
  REQUEST: "FETCH_PRODUCT_ALERTS_SCHEDULE_DATA_REQUEST",
  SUCCESS: "FETCH_PRODUCT_ALERTS_SCHEDULE_DATA_SUCCESS",
  FAILURE: "FETCH_PRODUCT_ALERTS_SCHEDULE_DATA_FAILURE",
};

export const UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO = {
  REQUEST: "UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO_REQUEST",
  SUCCESS: "UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO_SUCCESS",
  FAILURE: "UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO_FAILURE",
};

export const DELETE_PRODUCT_ALERTS_SCHEDULE_INFO = {
  REQUEST: "DELETE_PRODUCT_ALERTS_SCHEDULE_INFO_REQUEST",
  SUCCESS: "DELETE_PRODUCT_ALERTS_SCHEDULE_INFO_SUCCESS",
  FAILURE: "DELETE_PRODUCT_ALERTS_SCHEDULE_INFO_FAILURE",
};

export const PRODUCT_ALERTS_SCHEDULE_NOT_FOUND =
  "PRODUCT_ALERTS_SCHEDULE_NOT_FOUND";

export interface ProductAlertsScheduleInfo {
  hour: Number | null;
  minute: Number | null;
  timezone: string;
}

export interface loadProductAlertsScheduleData {
  type: typeof FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.REQUEST;
}

export interface ProductAlertsSchedulePayload {
  hour: Number;
  minute: Number;
  timezone: string;
}

export interface UpdateProductAlertsAction {
  type: typeof UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST;
  payload: ProductAlertsSchedulePayload;
}
