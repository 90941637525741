import React, { MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import ProductActions from "./ProductActions";
import ProductTileContent from "./product-title-content/ProductTitleContent";
import {
  selectProductAnalyticsData,
  selectProductFetched,
} from "../store/product/ProductSelectors";
import AppState from "../store/AppState";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import { PLATFORM_DEFAULTS } from "../utils/DefaultConfigs";
import { selectProductAttribute } from "../store/category/CategorySelectors";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import { AdapterInfoObject } from "../utils/AppConfigUtils";
import { selectAdapterInfo } from "../store/adapter-config/AdapterConfigSelectors";
import { selectSocialAnalyticsForProducts } from "../store/product-social-analytics/SocialAnalyticsSelectors";
import { OVERLAY_TABS } from "utils/Constants";
import ProductListLoading from "../components/ProductList/components/ProductListLoading";
import { selectProgressLoadingPercentage } from "store/product-list/ProductListSelectors";

const StyledProductTile = styled.div<{
  selectedOverlay: string;
  inventoryBorderColor: string | null;
  isMultiValueAttribute: boolean;
  productView: boolean;
  typeCode: string;
}>`
  display: flex;
  flex-direction: column;
  align-content: ${({ typeCode }) =>
    typeCode === "ProductBundle" || typeCode === "ProductSet" ? "center" : ""};
  height: 100%;
  padding: 0.8em;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  border-top: ${({ inventoryBorderColor }) =>
    inventoryBorderColor
      ? "13px solid " + inventoryBorderColor + "!important"
      : ""};
  padding-top: ${({ inventoryBorderColor }) =>
    inventoryBorderColor ? "5px" : "15px"};
`;

const StyledProductSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2 !important;
  background-color: #599dbb;
  margin: 5px;
`;

interface Props {
  product: ProductSequenceData;
  index: number;
  onSelectionChange: (
    itemId: string,
    cmdKeyActive: boolean,
    shiftKeyActive: boolean,
  ) => void;
  isSelected: boolean;
  hasProductsSelectedFromList: boolean;
  OnQuickMoveCompleteAction: (productId: string, moveTo: number) => void;
  productView: boolean;
}

export interface DragItem {
  draggedItem: ProductSequenceData;
  items: ProductSequenceData[];
  itemsDragStack: ProductSequenceData[];
  type: string;
  clientWidth: number;
  clientHeight: number;
}

const ProductTile = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    product,
    index,
    onSelectionChange,
    isSelected,
    hasProductsSelectedFromList,
    OnQuickMoveCompleteAction,
    productView,
  } = props;
  let inventoryBorderColor: string | null = null;
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[ecomPlatformType];
  const storeAndAccountInventory = useSelector((state: AppState) =>
    selectConfigValue(state, "inventoryThreshold"),
  );
  const inventoryThreshold = parseInt(
    storeAndAccountInventory !== ""
      ? storeAndAccountInventory
      : platformDefaults?.inventoryThreshold ?? "",
  );

  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, product.productId),
  );
  const socialAnalytics = useSelector(selectSocialAnalyticsForProducts);
  const productIndex = fetchedProduct?.productId ?? "";
  const rating = socialAnalytics[productIndex]?.rating ?? -1;
  const reviewCount = socialAnalytics[productIndex]?.reviewCount ?? -1;
  const productInventory = fetchedProduct?.stock;
  const progressValue = useSelector(selectProgressLoadingPercentage);

  if (productInventory === 0) inventoryBorderColor = "#D50000";
  else if (productInventory && inventoryThreshold) {
    if (productInventory <= inventoryThreshold)
      inventoryBorderColor = "#F8A510";
    else inventoryBorderColor = null;
  }

  const selectedOverlay = useSelector(selectOverlay);
  const attribute = useSelector(selectProductAttribute);

  const showSingleProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, product.productId),
  )?.showAnalytics;

  const [isAnalyticsDisplayed, setIsAnalyticsDisplayed] =
    useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const selectionHandler = (e: MouseEvent<HTMLDivElement>) => {
    if (!e.isDefaultPrevented())
      onSelectionChange(product.productId, e.metaKey || e.ctrlKey, e.shiftKey);
  };

  const mouseOverHandler = () => {
    if (productView) setHover(true);
  };
  const mouseOutHandler = () => {
    if (productView) setHover(false);
  };
  useEffect(() => {
    if (
      selectedOverlay === OVERLAY_TABS.ANALYTICS_OVERLAY &&
      showSingleProductAnalytics === undefined
    ) {
      setIsAnalyticsDisplayed(true);
    } else if (!showSingleProductAnalytics) {
      setIsAnalyticsDisplayed(false);
    }
  }, [showSingleProductAnalytics, selectedOverlay]);

  if (
    !fetchedProduct ||
    (fetchedProduct && Object.entries(fetchedProduct).length === 0) ||
    progressValue < 10
  ) {
    return (
      <div>
        <ProductListLoading />
      </div>
    );
  } else {
    return (
      fetchedProduct && (
        <StyledProductTile
          onClick={selectionHandler}
          onMouseEnter={mouseOverHandler}
          onMouseLeave={mouseOutHandler}
          ref={ref}
          selectedOverlay={selectedOverlay}
          inventoryBorderColor={inventoryBorderColor}
          isMultiValueAttribute={attribute ? attribute.isMultiValue : false}
          productView={productView}
          typeCode={fetchedProduct.typeCode}
        >
          {isSelected && <StyledProductSelectedOverlay />}
          <ProductTileContent
            index={index}
            rating={rating}
            reviewCount={reviewCount}
            product={fetchedProduct}
            sequence={product.sequence}
            productView={productView}
            isDragging={false}
            isRecentlyAdded={
              product.hasOwnProperty("isRecentlyAdded") ? true : false
            }
          />
          {productView && hover && (
            <ProductActions
              productId={product.productId}
              position={index}
              isSelected={isSelected}
              hasProductsSelectedFromList={hasProductsSelectedFromList}
              onQuickMoveCompleteAction={OnQuickMoveCompleteAction}
              setIsAnalyticsDisplayed={setIsAnalyticsDisplayed}
              isAnalyticsDisplayed={isAnalyticsDisplayed}
            />
          )}
        </StyledProductTile>
      )
    );
  }
});

export default React.memo(ProductTile);
