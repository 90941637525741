import {
  GetTopCategoriesPayLoad,
  GetChildCategoriesPayLoad,
  GetProductCountPayLoad,
  Translations,
} from "../category/CategoryTypes";
import {
  ColorData,
  ProductImageGroups,
  categorySpecificData,
} from "../product/ProductTypes";

export const LOAD_FIND_PRODUCTS_TOP_CATEGORIES =
  "LOAD_FIND_PRODUCTS_TOP_CATEGORIES";
export const LOAD_FIND_PRODUCTS_CHILD_CATEGORIES =
  "LOAD_FIND_PRODUCTS_CHILD_CATEGORIES";
export const LOAD_FIND_PRODUCTS_PRODUCT_COUNT =
  "LOAD_FIND_PRODUCTS_PRODUCT_COUNT";
export const LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY =
  "LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY";
export const CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS =
  "CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS";
export const RESET_FIND_PRODUCTS = "RESET_FIND_PRODUCTS";
export const RESET_FIND_PRODUCTS_PRODUCTS_LIST =
  "RESET_FIND_PRODUCTS_PRODUCTS_LIST";
export const LOAD_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS =
  "LOAD_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS";
export const CHANGE_PRODUCTS_CATEGORY = "CHANGE_PRODUCTS_CATEGORY";

export const FETCH_FIND_PRODUCTS_FROM_CATEGORY = {
  REQUEST: "FETCH_FIND_PRODUCTS_FROM_CATEGORY_REQUEST",
  SUCCESS: "FETCH_FIND_PRODUCTS_FROM_CATEGORY_SUCCESS",
  FAILURE: "FETCH_FIND_PRODUCTS_FROM_CATEGORY_FAILURE",
};

export const FETCH_FIND_PRODUCTS_TOP_CATEGORIES = {
  REQUEST: "FETCH_FIND_PRODUCTS_TOP_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_FIND_PRODUCTS_TOP_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_FIND_PRODUCTS_TOP_CATEGORIES_FAILURE",
};

export const FETCH_FIND_PRODUCTS_CHILD_CATEGORIES = {
  REQUEST: "FETCH_FIND_PRODUCTS_CHILD_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_FIND_PRODUCTS_CHILD_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_FIND_PRODUCTS_CHILD_CATEGORIES_FAILURE",
};

export const FETCH_FIND_PRODUCTS_PRODUCT_COUNT = {
  REQUEST: "FETCH_FIND_PRODUCTS_PRODUCT_COUNT_REQUEST",
  SUCCESS: "FETCH_FIND_PRODUCTS_PRODUCT_COUNT_SUCCESS",
  FAILURE: "FETCH_FIND_PRODUCTS_PRODUCT_COUNT_FAILURE",
};

export const FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY = {
  REQUEST: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY_REQUEST",
  SUCCESS: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY_SUCCESS",
  FAILURE: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY_FAILURE",
};

export const FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS = {
  REQUEST: "FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS_FAILURE",
};

export const UPDATE_PRODUCTS_CATEGORY = {
  REQUEST: "UPDATE_PRODUCTS_CATEGORY_REQUEST",
  SUCCESS: "UPDATE_PRODUCTS_CATEGORY_SUCCESS",
  FAILURE: "UPDATE_PRODUCTS_CATEGORY_FAILURE",
};

export const INSERT_PRODUCTS_TO_CATEGORY = {
  REQUEST: "INSERT_PRODUCTS_TO_CATEGORY_REQUEST",
  SUCCESS: "INSERT_PRODUCTS_TO_CATEGORY_SUCCESS",
  FAILURE: "INSERT_PRODUCTS_TO_CATEGORY_FAILURE",
};

export const DELETE_PRODUCTS_TO_CATEGORY = {
  REQUEST: "DELETE_PRODUCTS_TO_CATEGORY_REQUEST",
  SUCCESS: "DELETE_PRODUCTS_TO_CATEGORY_SUCCESS",
  FAILURE: "DELETE_PRODUCTS_TO_CATEGORY_FAILURE",
};

export const FETCH_PRODUCTS_FOR_FIND_PRODUCTS = {
  REQUEST: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_REQUEST",
  SUCCESS: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_SUCCESS",
  FAILURE: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_FAILURE",
  COMPLETE: "FETCH_PRODUCTS_FOR_FIND_PRODUCTS_COMPLETE",
};

export const FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY = {
  REQUEST: "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_REQUEST",
  SUCCESS: "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_SUCCESS",
  FAILURE: "FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY_FAILURE",
};

export interface LoadFindProductsTopCategoriesAction {
  payload: GetTopCategoriesPayLoad;
  type: typeof LOAD_FIND_PRODUCTS_TOP_CATEGORIES;
}

export interface LoadFindProductsChildCategoriesAction {
  payload: GetChildCategoriesPayLoad;
  type: typeof LOAD_FIND_PRODUCTS_CHILD_CATEGORIES;
}

export interface LoadFindProductsProductCountAction {
  payload: GetProductCountPayLoad;
  type: typeof LOAD_FIND_PRODUCTS_PRODUCT_COUNT;
}

export interface CloseAllFindProductsCategorySubscriptionsAction {
  type: typeof CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS;
  payload: {
    catalog: string;
  };
}

export interface ChildCategory {
  [key: string]: {
    childCategories: {
      categoryId: string;
      childCategoryCount: number;
      translations: Translations[];
      productCount: number;
      code: string;
      name: string;
      isPublished: boolean;
    }[];
  };
}

interface toCategories {
  categoryId: string;
}

export interface description {
  name: string;
  thumbnail: string;
  localeCode: string;
}
export interface ProductByCategory {
  productId: string;
  product: {
    descriptions: description[];
    toCategories: toCategories[];
  };
}

export interface ProductBySearch {
  descriptions: description[];
  productId: string;
  toCategories: toCategories[];
}

// ProductNodes = ProductByCategory || ProductBySearch
export type ProductNodes = ProductByCategory | ProductBySearch;

export interface LoadProductsFromCategoryForFindProductsCategoryAction {
  payload: LoadProductsFromCategoryForFindProductsCategoryActionPayload;
  type: typeof LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY;
}

export interface LoadProductsFromCategoryForFindProductsCategoryActionPayload {
  catalogId: string;
  categoryId: string;
  localeId: string;
  storeId: string;
  orderBy?: string;
}

export interface LoadProductsFromCategoryForFindProductsCategoryResponse {
  payload: {
    data: {
      CategoryToProduct: ProductByCategory[];
    };
  };
  type: typeof FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.SUCCESS;
}

export interface SearchProductsForFindProductsAction {
  payload: {
    localeId: string;
    storeId: string;
    searchTerm: string;
    orderBy?: string;
  };
  type: typeof LOAD_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS;
}

export interface SearchProductsForFindProductsResponse {
  payload: {
    data: {
      Product: ProductBySearch[];
    };
  };
  type: typeof FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.SUCCESS;
}

export interface UpdateProductsCategoryAction {
  payload: UpdateProductsCategoryPayload;
  type: typeof CHANGE_PRODUCTS_CATEGORY;
}

export interface UpdateProductsCategoryResponse {
  payload: {
    data: UpdateProductsCategoryResponseData;
  };
  type: typeof UPDATE_PRODUCTS_CATEGORY.SUCCESS;
}

export interface UpdateProductsCategoryResponseData {
  insert_CategoryToProduct?: AffectedRows;
  delete_CategoryToProduct?: AffectedRows;
  insert_SyncLog: AffectedRows;
}

export interface AffectedRows {
  affected_rows: number;
}

export interface UpdateProductsCategoryPayload {
  productsToBeAdded: UpdateProductData;
  productsToBeRemoved: UpdateProductData;
  catalogId: string;
  categoryId: string;
}

export interface UpdateProductData {
  [name: string]: UpdateProductObject;
}

export interface UpdateProductObject {
  productId: string;
  name: string;
}

export interface InsertSyncLogPayload {
  keyName1?: string;
  keyName2?: string;
  keyName3?: string;
  keyName4?: string;
  keyName5?: string;
  newKeyValue1?: string;
  newKeyValue2?: string;
  newKeyValue3?: string;
  newKeyValue4?: string;
  newKeyValue5?: string;
  oldKeyValue1?: string;
  oldKeyValue2?: string;
  oldKeyValue3?: string;
  oldKeyValue4?: string;
  oldKeyValue5?: string;
  operationType: string;
  tableName: string;
}

export interface GetProductListPayload {
  catalogId: string;
  categoryId: string;
  localeCode: string | null;
  storeId: string;
  next?: string;
  count?: number;
  productIds?: string[];
  totalPagesFetched?: Number;
}

export interface loadProductsForFindProductsAction {
  payload: GetProductListPayload;
  type: typeof FETCH_PRODUCTS_FOR_FIND_PRODUCTS;
}

export interface LoadProductsFromCategoryResponseAction {
  payload: GetProductsFromCategoryResponsePayload;
  type: typeof FETCH_PRODUCTS_FOR_FIND_PRODUCTS.SUCCESS;
}

export interface GetProductsFromCategoryResponsePayload {
  results: CategoryToProductData[];
  categoryId?: string;
}

export interface CategoryToProductData {
  productId: string;
  cachedProduct: ProductSubDetailsData;
}

export interface ProductSubDetailsData {
  cachedAt: Date;
  product: ProductInfoData;
  sequence: number;
}

export interface ProductInfoData {
  productId: string;
  code: string;
  typeCode: string;
  name: string;
  thumbnail: string;
  isPublished: boolean;
  variationBaseId: string;
  hasColors: boolean;
  hasManageableColors: boolean;
  url: string;
  stock: number;
  stockValue: number;
  listPrice: number;
  lowPrice: number;
  highPrice: number;
  colors: ColorData[];
  translations: [
    {
      localeCode: string;
      name: string;
      isPublished: string;
      thumbnail: string;
      url: string;
    },
  ];
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  categorySpecific: categorySpecificData[];
  imageGroups?: ProductImageGroups[];
}
