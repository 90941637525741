import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonComponent from "../common/ButtonComponent";
import MultipleStoreLanguageTabs from "./MultipleStoreLanguageTabs";
import AppState from "../../store/AppState";
import {
  updateLocaleProductPublish,
  updateProductColors,
} from "../../store/product/ProductActions";
import { setModalState } from "../../store/modal/ModalActions";
import {
  selectModalVariable,
  selectIsModalOpen,
} from "../../store/modal/ModalSelectors";
import { colorUpdate } from "../color-mangement-modal/ColorManagementActions";
import CustomDialog from "../common/DialogContainer";
import { styled } from "@mui/material";

const styleClasses = {
  wrapper: "wrapper",
  buttonProgress: "buttonProgress",
  message: "message",
};
const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClasses.wrapper}`]: {
    position: "relative",
  },
  [`&.${styleClasses.message}`]: {
    marginTop: "5px",
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  [`&.${styleClasses.buttonProgress}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {}

export const MultipleStoreLanguageModalId = "MultipleStoreLanguageModal";

const MultipleStoreLanguageModal: React.FC<Props> = () => {
  const [selectedLocaleCode, setSelectedLocaleCode] = useState<string[]>([]);
  const loading = useSelector((state: AppState) =>
    selectModalVariable(state, MultipleStoreLanguageModalId, "loading"),
  );
  const payload = useSelector((state: AppState) =>
    selectModalVariable(state, MultipleStoreLanguageModalId, "payload"),
  );
  const type = useSelector((state: AppState) =>
    selectModalVariable(state, MultipleStoreLanguageModalId, "type"),
  );

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, MultipleStoreLanguageModalId),
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModalState(MultipleStoreLanguageModalId, false));
  };

  const saveHandler = () => {
    dispatch(
      setModalState(MultipleStoreLanguageModalId, true, { loading: true }),
    );
    if (type && type === colorUpdate) {
      dispatch(
        updateProductColors({
          localeCodes: selectedLocaleCode,
          ...payload,
        }),
      );
    } else {
      dispatch(
        updateLocaleProductPublish({
          localeCodes: selectedLocaleCode,
          ...payload,
        }),
      );
    }
  };

  const multipleStoreLanguageModalTitle = () => {
    return (
      <StyledDiv className={styleClasses.message}>
        <Typography variant="inherit">
          Manage publish status in multi stores/languages
        </Typography>
      </StyledDiv>
    );
  };

  const multipleStoreLanguageModalContent = () => {
    return (
      <MultipleStoreLanguageTabs onSelectedLocaleCode={setSelectedLocaleCode} />
    );
  };

  const dialogActions = () => {
    return (
      <Grid container direction="row" justifyContent="center">
        <StyledDiv className={styleClasses.wrapper}>
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            disabled={loading}
            onClick={saveHandler}
          >
            Save
          </ButtonComponent>
          {loading && (
            <StyledCircularProgress
              size={24}
              className={styleClasses.buttonProgress}
            />
          )}
        </StyledDiv>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </ButtonComponent>
      </Grid>
    );
  };
  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={multipleStoreLanguageModalTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
    >
      {multipleStoreLanguageModalContent()}
    </CustomDialog>
  );
};

export default React.memo(MultipleStoreLanguageModal);
