import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import ButtonComponent from "../components/common/ButtonComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // marginTop: '10px',
      display: "grid",
    },
  }),
);

const BundleRelated: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ButtonComponent
        color="tertiary"
        variant="contained"
        startIcon={<i className="fas fa-layer-group" />}
      >
        {intl.formatMessage({
          id: "bundleMgmt.label",
          defaultMessage: "Bundle MGMT",
        })}
      </ButtonComponent>
    </div>
  );
};

export default BundleRelated;
