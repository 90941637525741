export const GET_ADAPTER_CONFIG_DETAILS = "GET_ADAPTER_CONFIG_DETAILS";

export const FETCH_ADAPTER_CONFIG = {
  REQUEST: "FETCH_ADAPTER_CONFIG_REQUEST",
  SUCCESS: "FETCH_ADAPTER_CONFIG_SUCCESS",
  FAILURE: "FETCH_ADAPTER_CONFIG_FAILURE",
};

export const RESET_ADAPTER_DETAILS = "RESET_ADAPTER_DETAILS";

export interface LoadAdapterConfigAction {
  type: typeof GET_ADAPTER_CONFIG_DETAILS;
}

export interface AdapterPayload {
  accountId: string;
}
export interface AdapterInfo {
  adapterId: string;
  operations: [];
  capabilities: [];
}
