import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import clipboard from "../assets/product-clipboard.svg";
import useClipboard from "../hooks/useClipBoard";
import ButtonComponent from "./common/ButtonComponent";
import { classicTheme } from "../styles/themes/classic";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: "10px",
    },
    textarea: {
      width: "100%",
      height: "100px ",
      overflowY: "auto",
      resize: "vertical",
      border: " 2px dashed",
      padding: "4px",
      borderColor: classicTheme.palette.tertiary.main,
      outline: "none",
      background: `url(${clipboard}) no-repeat center center `,
      "&:focus": {
        borderColor: classicTheme.palette.tertiary.hoverColor, // Use the theme's primary color on hover
      },
    },
    textareaActions: {
      display: "flex",
      //gridTemplateColumns: "repeat(2,1fr) ",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 5,
    },
  }),
);

interface Props {
  onCollapse: (isCollapse: boolean) => void;
}

const ClipboardArea: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { onCollapse } = props;
  const classes = useStyles();
  const { getClipboardProductCodes, setClipboardProductCodes, clearClipboard } =
    useClipboard();
  const productCodes = getClipboardProductCodes() ?? [];

  useEffect(() => {
    if (productCodes.length > 0) {
      onCollapse(true);
    } else {
      onCollapse(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCodes]);

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value.trim();
    if (value === "") {
      setClipboardProductCodes([]);
      onCollapse(false);
    } else {
      onCollapse(true);
      const productCodes = event.target.value.split(/\s+/);
      setClipboardProductCodes(productCodes);
    }
  };

  const clearClickHandler = () => {
    clearClipboard();
    onCollapse(false);
  };

  const filteredCodes = productCodes.filter((codes) => codes !== "");
  return (
    <div className={classes.container}>
      <textarea
        className={classes.textarea}
        value={productCodes?.join("\n")}
        onBlur={() => setClipboardProductCodes(filteredCodes)}
        aria-label="Clipboard Area"
        onChange={changeHandler}
      />
      <div className={classes.textareaActions}>
        <Typography variant="inherit">
          {intl.formatMessage(
            {
              id: "clipBoardArea.skusText",
              defaultMessage: "{skus} skus",
            },
            {
              skus: filteredCodes?.length,
            },
          )}
        </Typography>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          justifyContent="flex-end"
          padding="0px 5px"
          onClick={clearClickHandler}
        >
          {intl.formatMessage({
            id: "clipBoardArea.clearLabel",
            defaultMessage: "Clear",
          })}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default React.memo(ClipboardArea);
