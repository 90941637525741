import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsProductListsFetched,
  selectEditSequenceList,
} from "../../store/product-list/ProductListSelectors";
import {
  selectIsProductsFetched,
  selectIsProductChildrenFetched,
  selectIsProductChildrenFetching,
  selectChildrenPage,
  selectIsFetchedBySearch,
  selectProductIdsBySearch,
} from "../../store/product/ProductSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { loadChildrenItems } from "../../store/product/ProductActions";
import { PAGINATION_CHUNK_SIZE } from "../../utils/Constants";

interface Props {
  categoryId: string;
  priceListId: string;
  fulfillmentCenterId: string;
}

const ChildProductActionContainer = ({
  categoryId,
  priceListId,
  fulfillmentCenterId,
}: Props) => {
  const dispatch = useDispatch();
  const isProductListsFetched = useSelector(selectIsProductListsFetched);
  const products = useSelector(selectEditSequenceList);
  const productsBySearch = useSelector(selectProductIdsBySearch);
  const isProductDetailsFetched = useSelector(selectIsProductsFetched);
  const isProductChildrenFetched = useSelector(selectIsProductChildrenFetched);
  const isProductChildrenFetching = useSelector(
    selectIsProductChildrenFetching,
  );
  const localeId = useSelector(selectCurrentLocale);
  const storeId = useSelector(selectCurrentStoreId);
  const catalogId = useSelector(selectCurrentCatalogId);
  const childrenPage = useSelector(selectChildrenPage);
  const isFetchedBySearch = useSelector(selectIsFetchedBySearch);

  useEffect(() => {
    if (
      isProductDetailsFetched &&
      (productsBySearch.length > 0 || products.length > 0) &&
      (isProductListsFetched || isFetchedBySearch) &&
      !isProductChildrenFetching &&
      storeId
    ) {
      const start = childrenPage * PAGINATION_CHUNK_SIZE;
      let productIds: string[];

      if (isFetchedBySearch) {
        productIds = productsBySearch;
      } else {
        productIds = products.map((product) => product.productId);
      }

      const totalPages = Math.ceil(productIds.length / PAGINATION_CHUNK_SIZE);
      if (childrenPage < totalPages) {
        const payload = {
          productIds: productIds.slice(start, start + PAGINATION_CHUNK_SIZE),
          storeId: storeId,
          catalogId: catalogId,
          categoryId: categoryId,
          page: childrenPage,
          fulfillmentCenterId,
          priceListId,
          isFetchedBySearch,
        };
        dispatch(loadChildrenItems(payload));
      }
    }
  }, [
    isProductDetailsFetched,
    isProductChildrenFetched,
    products,
    localeId,
    storeId,
    catalogId,
    categoryId,
    dispatch,
    priceListId,
    fulfillmentCenterId,
    isProductListsFetched,
    isProductChildrenFetching,
    childrenPage,
    isFetchedBySearch,
    productsBySearch,
  ]);
  return <React.Fragment />;
};

export default React.memo(ChildProductActionContainer);
