import React, { useState } from "react";

import StoreSelection from "./StoreSelection";
import CatalogSelection from "./CatalogSelection";
// import SearchResultButton from './SearchResultButton';
// import ShowPublicCheckbox from './ShowPublicCheckbox';
import CategorySelection from "./CategorySelection";
import LanguageSelection from "./LanguageSelection";
// import LowInventoryButton from './LowInventoryButton';
// import UnpublishedInventoryButton from './UnpublishedInventoryButton';

interface Props {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const LeftNavigationPanel: React.FC<Props> = (props) => {
  const { selected, setSelected } = props;
  const [checked] = useState(false);
  return (
    <React.Fragment>
      <StoreSelection />
      <CatalogSelection />
      <LanguageSelection />
      {/* <SearchResultButton />
      <LowInventoryButton />
      <UnpublishedInventoryButton />
      <ShowPublicCheckbox checked={checked} setChecked={setChecked} /> */}
      <CategorySelection
        checked={checked}
        selected={selected}
        setSelected={setSelected}
      />
    </React.Fragment>
  );
};

export default LeftNavigationPanel;
