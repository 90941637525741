import {
  FETCH_PRODUCT_ALERTS_LIST,
  productAlertsList,
} from "./ProductAlertsListType";

export interface ProductAlertsListState {
  productAlertsList: productAlertsList[];
  isFetchingProductAlertsList: boolean;
}

const initialState: ProductAlertsListState = {
  productAlertsList: [],
  isFetchingProductAlertsList: false,
};

export const ProductAlertsListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_ALERTS_LIST.REQUEST: {
      return {
        ...state,
        isFetchingProductAlertsList: true,
      };
    }
    case FETCH_PRODUCT_ALERTS_LIST.SUCCESS: {
      const productAlertsList = action.payload.results as productAlertsList[];
      const sortedProductAlertsList = productAlertsList?.sort((a, b) =>
        b.jobStartTime.localeCompare(a.jobStartTime),
      );
      return {
        ...state,
        isFetchingProductAlertsList: false,
        productAlertsList: sortedProductAlertsList,
      };
    }
    case FETCH_PRODUCT_ALERTS_LIST.FAILURE: {
      return {
        ...state,
        isFetchingProductAlertsList: false,
        productAlertsList: [],
      };
    }
    default:
      return state;
  }
};
