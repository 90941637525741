import {
  CloseModalPayload,
  MODAL_CLOSED,
  SET_MODAL_STATE,
  setModalStateAction,
  Variables,
} from "./ModalTypes";

export const setModalState = (
  modalId: string,
  isOpen: boolean,
  variables?: Variables,
): setModalStateAction => {
  return {
    type: SET_MODAL_STATE,
    payload: {
      modalId,
      isOpen,
      ...variables,
    },
  };
};

export const CloseModal = (payload: CloseModalPayload) => {
  return {
    payload,
    type: MODAL_CLOSED,
  };
};
