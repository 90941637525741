import type { Components } from "@mui/material";

export const MuiSelect: Components["MuiSelect"] = {
  styleOverrides: {
    select: {
      ":focus": {
        backgroundColor: "transparent", // Just for the demo
        border: "none",
        borderRadius: 10,
      },
    },
  },
};
