import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { updateSelectedOverlay } from "../store/overlay/OverlayActions";
// import InventoryOverlayLabel from "./overlays/InventoryOverlayLabel";
// import AttributesOverlayLabel from "./overlays/AttributesOverlayLabel";
import CategoriesOverlayLabel from "./overlays/CategoriesOverlayLabel";
import AnalyticsOverlayLabel from "./overlays/AnalyticsOverlayLabel";
import { selectOverlay } from "../store/overlay/OverlaySelectors";
import { OVERLAY_TABS } from "../utils/Constants";
import RatingsOverlayLabel from "./overlays/RatingsOverlayLabel";
import { classicTheme } from "../styles/themes/classic";
export { addAttributeModalId } from "./add-attribute-modal/AddAttributeModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: " grid",
      justifyItems: "flex-start",
      height: "fit-content",
      "& .Mui-selected": {
        "&:hover": {
          color: classicTheme.palette.primary.main,
        },
      },
      "& .MuiBottomNavigationAction-root": {
        flex: "inherit",
        minWidth: 0,
        overflow: "hidden",
        fontWeight: "bold",
        "&:hover": {
          color: classicTheme.palette.primaryHoverColor?.main,
        },
        margin: 5,
      },
      "& .MuiBottomNavigationAction-wrapper": {
        display: "block",
      },
      "& .MuiBottomNavigationAction-label": {
        fontSize: "0.875rem",
        marginLeft: "8px",
      },
      "& .MuiBottomNavigationAction-label.Mui-selected": {
        fontSize: "14px",
        color: classicTheme.palette.primary.main,
        fontWeight: "bold",
      },
      "& .MuiBottomNavigationAction-root.Mui-selected": {
        color: classicTheme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: classicTheme.palette.primary.main,
      },
    },
    bottomNavigationAction: {
      transition: "padding 500ms ease-out",
    },
    bottomNavigationActionActive: {
      paddingBottom: theme.spacing(5),
      transition: "padding 500ms ease-in",
    },
  }),
);

interface Props {}

const OverlayTabs: React.FC<Props> = () => {
  const intl = useIntl();
  const selectedOverlay = useSelector(selectOverlay);
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <BottomNavigation
        value={selectedOverlay}
        className={classes.root}
        onChange={(event, newValue) => {
          if (selectedOverlay !== newValue) {
            dispatch(updateSelectedOverlay(newValue));
          }
        }}
        showLabels
      >
        <BottomNavigationAction
          label={intl.formatMessage({
            id: "overlayTabs.productsLabel",
            defaultMessage: "Products",
          })}
          disableRipple
          icon={<i className="fas fa-boxes" />}
          value={OVERLAY_TABS.PRODUCTS}
        />
        <BottomNavigationAction
          label={<AnalyticsOverlayLabel />}
          disableRipple
          icon={<i className="fas fa-chart-line" />}
          value={OVERLAY_TABS.ANALYTICS_OVERLAY}
        />
        <BottomNavigationAction
          label={<RatingsOverlayLabel />}
          disableRipple
          icon={<i className="fas fa-star" />}
          value={OVERLAY_TABS.RATINGS}
        />
        <BottomNavigationAction
          label={<CategoriesOverlayLabel />}
          disableRipple
          icon={<i className="fas fa-sitemap" />}
          value={OVERLAY_TABS.CATEGORIES_OVERLAY}
        />
        {/* <BottomNavigationAction
          label={<InventoryOverlayLabel />}
          disableRipple
          icon={<i className="fas fa-warehouse" />}
          className={
            selectedOverlay === OVERLAY_TABS.INVENTORY_OVERLAY
              ? classes.bottomNavigationActionActive
              : classes.bottomNavigationAction
          }
          value={OVERLAY_TABS.INVENTORY_OVERLAY}
        />
        <BottomNavigationAction
          label={<AttributesOverlayLabel />}
          disableRipple
          icon={<i className="fas fa-tags" />}
          className={
            selectedOverlay === OVERLAY_TABS.ATTRIBUTES_OVERLAY
              ? classes.bottomNavigationActionActive
              : classes.bottomNavigationAction
          }
          value={OVERLAY_TABS.ATTRIBUTES_OVERLAY}
        /> */}
      </BottomNavigation>
    </div>
  );
};

export default OverlayTabs;
