import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import AlertMessage from "../common/AlertMessage";
import { getGlobalAlerts } from "../../store/global-alert/GlobalAlertSelectors";
import { removeGlobalAlertState } from "../../store/global-alert/GlobalAlertActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  snackbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    top: "120px",
    minHeight: 57,
    borderRadius: 2,
    gap: 30,
  },
  alert: {
    marginTop: theme.spacing(1),
  },
  alertMessage: {
    size: 16,
  },
}));

const GlobalAlert: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const alerts = useSelector(getGlobalAlerts);

  const handleClose = (
    id: string,
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeGlobalAlertState({ id }));
  };

  if (!alerts.length) {
    return null;
  }

  alerts.forEach(function (alert) {
    setTimeout(() => {
      dispatch(removeGlobalAlertState({ id: alert.id }));
    }, 5000);
  });

  return (
    <div className={classes.root}>
      <AlertMessage
        alertMessageId="GlobalAlertMessage"
        classes={classes}
        alerts={alerts}
        handleClose={handleClose}
      />
    </div>
  );
};
export default GlobalAlert;
