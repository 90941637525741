import { Theme, styled, Box, Container, Card, Typography } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export const componentClasses = {
  root: "root",
  closeButton: "closeButton",
  modal: "modal",
  resizable: "resizable",
  overlay: "overlay",
  paper: "paper",
  productTiles: "productTiles",
  leftSection: "leftSection",
  draggable: "draggable",
  mainContent: "mainContent",
  mainSection: "mainSection",
  idNotFoundText: "idNotFoundText",
  rightSection: "rightSection",
  modalCloseButton: "modalCloseButton",
  editForm: "editForm",
  formContainer: "formContainer",
  productCodes: "productCodes",
  editIconAlignment: "editIconAlignment",
  stock: "stock",
  card: "card",
  actiondiv: "actiondiv",
  baseProductGrid: "baseProductGrid",
  addVariationGrid: "addVariationGrid",
  addVariationBtnDiv: "addVariationBtnDiv",
  gridContainer: "gridContainer",
  noProductGrid: "noProductGrid",
  cardList: "cardList",
  cardTileDiv: "cardTileDiv",
  imageProductCodeDiv: "imageProductCodeDiv",
  imageProductCodeBaseDiv: "imageProductCodeBaseDiv",
  noVariationTextDiv: "noVariationTextDiv",
  headLine: "headLine",
  productNames: "productNames",
  checkboxDiv: "checkboxDiv",
  stockBase: "stockBase",
};

interface StyledDivProps {
  isEditIcon?: boolean; // Optional prop
  stock?: number;
  inventoryThreshold?: number;
}

export const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${componentClasses.formContainer}`]: {
    padding: 0,
  },
}));

export const StyledEditIcon = styled(ModeEditIcon)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "0.80rem",
  color: "inherit",
  marginRight: theme.spacing(0.5),
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${componentClasses.card}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "178px",
    height: "324px",
    justifyContent: "flex-start",
  },
  [`&.${componentClasses.cardList}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "141px",
    height: "255px",
  },
}));

export const StyledDiv = styled("div")<StyledDivProps>(
  ({ theme, isEditIcon = false, stock = 0, inventoryThreshold = 0 }) => ({
    [`&.${componentClasses.overlay}`]: {
      position: "absolute",
      top: "50%",
      left: 50,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 99999,
    },
    [`&.${componentClasses.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      minWidth: "65%",
      minHeight: "80vh",
      height: "100%",
      position: "relative",
      display: "flex",
    },
    [`&.${componentClasses.productTiles}`]: {
      height: "100%",
      position: "sticky",
      top: 0,
    },

    [`&.${componentClasses.leftSection}`]: {
      padding: theme.spacing(0, 3.75, 3, 0.625),
    },
    [`&.${componentClasses.draggable}`]: {
      width: "60%",
    },
    [`&.${componentClasses.mainContent}`]: {
      overflow: "hidden",
      padding: theme.spacing(0, 3, 3, 4),
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      zIndex: 1000,
      backgroundColor: "white",
      width: "100%",
      minWidth: "30%",
      minHeight: "50%",
      marginTop: theme.spacing(10),

      [theme.breakpoints.only("sm")]: {
        minWidth: "50%", // Adjust minWidth when screen size reaches 'sm'
      },

      [theme.breakpoints.only("xs")]: {
        minWidth: "80%", // Adjust minWidth when screen size reaches 'sm'
      },
    },
    [`&.${componentClasses.rightSection}`]: {
      position: "sticky",
      top: 0,
      marginLeft: "auto",
      marginRight: "auto",
      minWidth: "250px",
      width: "auto",
      height: "74vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(8, 1, 1, 3),
    },
    [`&.${componentClasses.editForm}`]: {
      display: "flex",
      justifyContent: "end",
    },
    [`&.${componentClasses.editIconAlignment}`]: {
      textAlign: "right",
    },

    [`&.${componentClasses.addVariationBtnDiv}`]: {
      textAlign: "end",
    },
    [`&.${componentClasses.cardTileDiv}`]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "20px",
      backgroundColor: !isEditIcon
        ? "transparent"
        : stock <= 0
          ? theme.palette.dangerColor.main
          : stock <= inventoryThreshold
            ? theme.palette.neutral.yellow
            : theme.palette.grey[600],
      color: theme.palette.common.white,
    },
    [`&.${componentClasses.imageProductCodeDiv}`]: {
      width: "100px",
      margin: "auto",
    },
    [`&.${componentClasses.imageProductCodeBaseDiv}`]: {
      width: "155px",
      margin: "auto",
    },
    [`&.${componentClasses.checkboxDiv}`]: {
      marginTop: "5px",
    },
  }),
);
export const StyledTypography = styled(Typography)<StyledDivProps>(
  ({ theme, isEditIcon = false }) => ({
    [`&.${componentClasses.headLine}`]: {
      padding: theme.spacing(4.5, 0, 2.5, 0),
      fontSize: "1rem",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
    },
    [`&.${componentClasses.stockBase}`]: {
      textAlign: "center",
      flex: 1,
      color: theme.palette.common.black,
      fontSize: "0.875rem",
      fontWeight: 700,
      height: "36px",
      alignContent: "center",
      paddingTop: "9px",
    },
    [`&.${componentClasses.stockBase}`]: {
      textAlign: "center",
      flex: 1,
      color: theme.palette.common.black,
      fontSize: "0.875rem",
      fontWeight: 700,
      height: "36px",
      alignContent: "center",
      paddingTop: "9px",
    },
    [`&.${componentClasses.stock}`]: {
      textAlign: "center",
      flex: 1,
      color: "inherit",
      fontSize: "0.80rem",
      fontWeight: "600",
    },
    [`&.${componentClasses.productNames}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: isEditIcon ? "0.75rem" : "1rem",
      fontWeight: "bold",
      overflow: "hidden",
      textOverflow: "ellipsis", // Enable ellipsis for overflowed content
      direction: "ltr", // Make the ellipsis appear at the start
      whiteSpace: "nowrap", // Restrict text to a single line
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(0.375),
      maxWidth: "100%", // Set a maximum width for the text container
    },
    [`&.${componentClasses.productCodes}`]: {
      display: "block",
      textAlign: "center",
      color: theme.palette.common.black,
      fontSize: "0.75rem",
      fontWeight: isEditIcon ? "normal" : "400",
      overflow: "hidden",
      textOverflow: "ellipsis", // Show ellipsis when text exceeds
      direction: "ltr", // Ellipsis at the start
      whiteSpace: "nowrap", // Restrict to one line only
      paddingBottom: theme.spacing(2),
      maxWidth: "100%", // Ensure maximum width is set for text to truncate
    },
  }),
);

export const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${componentClasses.mainSection}`]: {
    display: "flex",
    flexWrap: "wrap",
    gridGap: "15px",
  },
  [`&.${componentClasses.modalCloseButton}`]: {
    display: "flex",
    margin: "auto",
    width: "80px",
    position: "absolute",
    bottom: "10px",
    left: "0px",
    right: "0px",
    justifyContent: "center",
    alignItems: "center",
  },
  [`&.${componentClasses.idNotFoundText}`]: {
    overflow: "hidden",
    padding: theme.spacing(7, 3, 3, 3),
    zIndex: 1000,
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    minWidth: "30%",
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`&.${componentClasses.actiondiv}`]: {
    textAlign: "end",
  },
  [`&.${componentClasses.noVariationTextDiv}`]: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "1.375rem",
      fontWeight: "bold",
    },
  },
}));

const useStyles = (theme: Theme) => {
  return {
    editIcon: {
      cursor: "pointer",
      fontSize: "0.80rem",
      color: "inherit",
      marginRight: theme.spacing(0.5),
    },
    publishedFlag: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    publishedContainer: {
      margin: "2px",
      padding: "1px",
    },
    checkBox: {
      margin: "5px",
      padding: "5px 8px",
    },
    deleteButton: {
      padding: "5px",
      marginRight: "10px",
    },
    updateButton: {
      marginRight: "5px",
    },
    storeForm: {
      margin: "10px",
    },
    storeInput: {
      width: "115px",
    },
    disableLink: {
      color: "grey",
    },
    cursor: {
      cursor: "not-allowed",
      textDecoration: "none",
    },
    closeIcon: {
      width: 21,
      height: 21,
      border: 2,
      color: theme.palette.text.primary,
    },
  };
};

export default useStyles;
