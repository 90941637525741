import {
  takeEvery,
  put,
  call,
  all,
  select,
  take,
  cancel,
  fork,
} from "redux-saga/effects";
import { callGraphQLApi, callApi } from "../../utils/SagaUtils";
import GraphQLService from "../../services/GraphQL";

import { productCountQuery } from "../category/CategorySchema";

import {
  searchProductsForFindProducts,
  searchProductsForFindProductsWithoutStoreId,
  orderByProductId,
  orderByProductName,
  insertCategory,
  deleteCategory,
} from "./FindProductsSchema";

import {
  LOAD_FIND_PRODUCTS_TOP_CATEGORIES,
  LOAD_FIND_PRODUCTS_CHILD_CATEGORIES,
  LOAD_FIND_PRODUCTS_PRODUCT_COUNT,
  FETCH_FIND_PRODUCTS_TOP_CATEGORIES,
  FETCH_FIND_PRODUCTS_CHILD_CATEGORIES,
  FETCH_FIND_PRODUCTS_PRODUCT_COUNT,
  LoadFindProductsTopCategoriesAction,
  LoadFindProductsProductCountAction,
  LoadFindProductsChildCategoriesAction,
  CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS,
  CloseAllFindProductsCategorySubscriptionsAction,
  RESET_FIND_PRODUCTS,
  LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
  FETCH_FIND_PRODUCTS_FROM_CATEGORY,
  LoadProductsFromCategoryForFindProductsCategoryAction,
  FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
  RESET_FIND_PRODUCTS_PRODUCTS_LIST,
  LOAD_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS,
  FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS,
  SearchProductsForFindProductsAction,
  UPDATE_PRODUCTS_CATEGORY,
  CHANGE_PRODUCTS_CATEGORY,
  UpdateProductsCategoryAction,
  INSERT_PRODUCTS_TO_CATEGORY,
  DELETE_PRODUCTS_TO_CATEGORY,
  InsertSyncLogPayload,
  UpdateProductsCategoryResponse,
  FETCH_PRODUCTS_FOR_FIND_PRODUCTS,
  loadProductsForFindProductsAction,
  FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY,
} from "./FindProductsTypes";

import { getData } from "../../services/ApiService";
import { CREATE_SUBSCRIPTION, CLOSE_SUBSCRIPTION } from "../Subscription";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import {
  selectMaxSequenceInList,
  selectProductsWithInvalidSequences,
} from "../product-list/ProductListSelectors";
import { CHANGE_PRODUCT_LIST_SEQUENCE } from "../product-list/ProductListTypes";
import { loadProductIdsFromFindProductsCategory } from "./FindProductsActions";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../AppState";
import { MODAL_CLOSED } from "store/modal/ModalTypes";
import { productLoadingCancelled } from "store/product-list/ProductListActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "store/catalog/CatalogSelectors";

function* loadFindProductsTopCategories(
  action: LoadFindProductsTopCategoriesAction,
) {
  const actionType = FETCH_FIND_PRODUCTS_TOP_CATEGORIES;
  const constName = Object.keys({
    FETCH_FIND_PRODUCTS_TOP_CATEGORIES,
  })[0].toString();
  const catalogId = yield select(selectCurrentCatalogId);
  const storeId = yield select(selectCurrentStoreId);

  const endpoint = acquireEndpoint(constName, catalogId);
  const headersObj = {
    "x-currency-code": "USD",
    "x-store-id": storeId,
    "x-catalog-id": catalogId,
  };
  try {
    const response = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    yield all(response.payload.data.CatalogToCategory);
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_FIND_PRODUCTS_TOP_CATEGORIES.FAILURE,
      message: e.message,
    });
  }
}

function* loadFindProductsChildCategories(
  action: LoadFindProductsChildCategoriesAction,
) {
  const actionType = FETCH_FIND_PRODUCTS_CHILD_CATEGORIES;
  const constName = Object.keys({
    FETCH_FIND_PRODUCTS_CHILD_CATEGORIES,
  })[0].toString();
  const categoryId = action.payload?.categoryId
    ? action.payload.categoryId
    : yield select(selectCurrentCategory);
  const storeId = yield select(selectCurrentStoreId);
  const catalogId = yield select(selectCurrentCatalogId);

  const endpoint = acquireEndpoint(constName, categoryId);
  const headersObj = {
    "x-currency-code": "USD",
    "x-store-id": storeId,
    "x-catalog-id": catalogId,
  };
  try {
    const response = yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );
    yield all(response.payload.data.CategoryToCategory_aggregate.nodes);
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_FIND_PRODUCTS_CHILD_CATEGORIES.FAILURE,
      message: e.message,
    });
  }
}

function* createProductCountSubscription(
  action: LoadFindProductsProductCountAction,
) {
  try {
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;

    const payload = {
      operationName: "ProductCount",
      query: productCountQuery,
      variables: {
        catalogId,
        categoryId,
      },
    };

    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: `${FETCH_FIND_PRODUCTS_PRODUCT_COUNT.REQUEST}__catalogId__${catalogId}__categoryId__${categoryId}`,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_FIND_PRODUCTS_PRODUCT_COUNT.FAILURE,
      message: e.message,
    });
  }
}

function* closeAllSubscriptions(
  action: CloseAllFindProductsCategorySubscriptionsAction,
) {
  try {
    yield put({ type: RESET_FIND_PRODUCTS });
  } catch (e: any) {
    console.error(e);
  }
}

function* loadProductsFromCategory(
  action: LoadProductsFromCategoryForFindProductsCategoryAction,
) {
  try {
    const actionType = FETCH_FIND_PRODUCTS_FROM_CATEGORY;
    const categoryId = action.payload?.categoryId
      ? action.payload.categoryId
      : yield select(selectCurrentCategory);
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const constName = Object.keys({
      FETCH_FIND_PRODUCTS_FROM_CATEGORY,
    })[0].toString();

    const endpoint = acquireEndpoint(constName, categoryId);
    const headersObj = {
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };
    yield call(
      callApi,
      actionType,
      getData,
      action.payload,
      endpoint,
      headersObj,
    );

    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST,
      },
    });

    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
      },
    });

    yield put({ type: RESET_FIND_PRODUCTS_PRODUCTS_LIST });

    // yield put({
    //   type: CREATE_SUBSCRIPTION,
    //   payload: {
    //     id: FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST,
    //     ...payload,
    //   },
    // });
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

function* searchProducts(action: SearchProductsForFindProductsAction) {
  try {
    const isGraphQLStoreRelationshipEnabled = yield select((state: AppState) =>
      selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
    );
    const useStoreId = isGraphQLStoreRelationshipEnabled === "true";
    const searchTerm = `%${action.payload?.searchTerm}%`;
    const storeId = yield select(selectCurrentStoreId);
    const orderBy = action.payload?.orderBy || "name";
    const variables = {
      searchTerm,
      orderBy: orderBy === "name" ? orderByProductName : orderByProductId,
    };
    const payload = {
      operationName: "SearchProductsForFindProducts",
      query: useStoreId
        ? searchProductsForFindProducts
        : searchProductsForFindProductsWithoutStoreId,
      variables: useStoreId ? { ...variables, storeId } : variables,
    };
    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST,
      },
    });

    yield put({
      type: CLOSE_SUBSCRIPTION,
      payload: {
        id: FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
      },
    });

    yield put({ type: RESET_FIND_PRODUCTS_PRODUCTS_LIST });

    yield put({
      type: CREATE_SUBSCRIPTION,
      payload: {
        id: FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
        ...payload,
      },
    });
  } catch (e: any) {
    console.error(e);
    yield put({
      type: FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* changeProductsCategory(action: UpdateProductsCategoryAction) {
  yield put({ type: UPDATE_PRODUCTS_CATEGORY.REQUEST });
  try {
    const productsToBeAdded = action.payload.productsToBeAdded;
    const productsToBeRemoved = action.payload.productsToBeRemoved;
    const productIdsToRemove = Object.keys(productsToBeRemoved);
    const catalogId = action.payload.catalogId;
    const categoryId = action.payload.categoryId;
    let maxSequence = yield select(selectMaxSequenceInList);
    let invalidSequenceProducts: string[] = yield select(
      selectProductsWithInvalidSequences,
    );
    invalidSequenceProducts = invalidSequenceProducts.filter(
      (product) => !productIdsToRemove.includes(product),
    );
    if (invalidSequenceProducts.length > 0) {
      const sequencePayload = {
        catalogId,
        categoryId,
        productSequences: invalidSequenceProducts.map((product) => {
          maxSequence += 1;
          return {
            productId: product,
            sequence: maxSequence,
          };
        }),
      };
      yield put({
        payload: sequencePayload,
        type: CHANGE_PRODUCT_LIST_SEQUENCE,
      });
    }
    // Insert
    if (Object.keys(productsToBeAdded).length > 0) {
      let prepareProductsToBeAdded: {
        catalogId: string;
        categoryId: string;
        productId: string;
        sequence: number;
      }[] = [];

      const syncLogInserts: InsertSyncLogPayload[] = [];
      Object.keys(productsToBeAdded).forEach((key) => {
        const product = productsToBeAdded[key];
        const productId = product.productId;
        prepareProductsToBeAdded.push({
          catalogId,
          categoryId,
          productId,
          sequence: maxSequence + 1,
        });
        maxSequence += 1;
        syncLogInserts.push({
          tableName: "CategoryToProduct",
          operationType: "INSERTED",
          keyName1: "catalogId",
          keyName2: "categoryId",
          keyName3: "productId",
          newKeyValue1: catalogId,
          newKeyValue2: categoryId,
          newKeyValue3: productId,
        });
      });

      const insertQuery = {
        operationName: "InsertCategoryToProduct",
        query: insertCategory,
        variables: {
          products: prepareProductsToBeAdded,
          syncLogInserts,
        },
      };
      const result: UpdateProductsCategoryResponse = yield callGraphQLApi(
        INSERT_PRODUCTS_TO_CATEGORY,
        GraphQLService.query,
        insertQuery,
      );
      /**
       * TODO : Add success and error handling
       */
      if (result && result.type === INSERT_PRODUCTS_TO_CATEGORY.SUCCESS) {
      }
    }

    //Delete
    if (Object.keys(productsToBeRemoved).length > 0) {
      let prepareProductsToBeRemoved: string[] = [];
      const syncLogInserts: InsertSyncLogPayload[] = [];
      Object.keys(productsToBeRemoved).forEach((key, index) => {
        const product = productsToBeRemoved[key];
        const productId = product.productId;
        prepareProductsToBeRemoved.push(productId);
        syncLogInserts.push({
          tableName: "CategoryToProduct",
          operationType: "DELETED",
          keyName1: "catalogId",
          keyName2: "categoryId",
          keyName3: "productId",
          oldKeyValue1: catalogId,
          oldKeyValue2: categoryId,
          oldKeyValue3: productId,
        });
      });

      const deleteQuery = {
        operationName: "DeleteCategoryToProduct",
        query: deleteCategory,
        variables: {
          productIds: prepareProductsToBeRemoved,
          catalogId,
          categoryId,
          syncLogInserts,
        },
      };
      const result = yield callGraphQLApi(
        DELETE_PRODUCTS_TO_CATEGORY,
        GraphQLService.query,
        deleteQuery,
      );
      /**
       * TODO : Add success and error handling
       */
      if (result && result.type === DELETE_PRODUCTS_TO_CATEGORY.SUCCESS) {
      }
    }
    yield put({ type: UPDATE_PRODUCTS_CATEGORY.SUCCESS });
  } catch (e: any) {
    console.log(e);
    yield put({ type: UPDATE_PRODUCTS_CATEGORY.FAILURE, message: e.message });
  }
}

function* loadProductListForFindProductsModal(
  action: loadProductsForFindProductsAction,
) {
  try {
    const { productIds } = action.payload;
    const localeCode = yield select(selectCurrentLocale);
    const storeId = yield select(selectCurrentStoreId);
    const catalogId = yield select(selectCurrentCatalogId);
    const currentCategoryInMainSequence = yield select(selectCurrentCategory);
    let updatedPayload = {
      ...action.payload,
      currentCategoryInMainSequence,
    };
    const actionType = FETCH_PRODUCTS_FOR_FIND_PRODUCTS;
    const constName = Object.keys({
      FETCH_PRODUCTS_FOR_FIND_PRODUCTS,
    })[0].toString();

    let commaSeperatedProductIds: string = "";

    if (productIds && Array.isArray(productIds) && productIds.length) {
      commaSeperatedProductIds = String(
        productIds.length > 1
          ? productIds.join(",").toString()
          : productIds.length && productIds[0],
      );
    }

    const endpoint = acquireEndpoint(constName, commaSeperatedProductIds);
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    yield call(
      callApi,
      actionType,
      getData,
      updatedPayload,
      endpoint,
      headersObj,
    );
  } catch (e: any) {
    yield put({
      type: FETCH_PRODUCTS_FOR_FIND_PRODUCTS.FAILURE,
      message: e.message,
    });
  }
}

function* getProductIdsForFindProductsFromCategory(
  action: ReturnType<typeof loadProductIdsFromFindProductsCategory>,
) {
  try {
    const actionType = FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY;
    const { categoryId, catalogId, storeId, localeCode } = action.payload;
    const constName =
      FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST.toString();
    const headersObj = {
      "x-locale-code": localeCode || "default",
      "x-currency-code": "USD",
      "x-store-id": storeId,
      "x-catalog-id": catalogId,
    };

    const endpoint = acquireEndpoint(constName, categoryId);
    const result = !action.payload.productIds
      ? yield call(
          callApi,
          actionType,
          getData,
          action.payload,
          endpoint,
          headersObj,
        )
      : null;

    if ((result && result.payload) || action.payload.productIds) {
      const productIds =
        result?.payload?.productIds || action.payload.productIds;
      const productCount = productIds.length;
      let totalPagesFetched = 0;
      let numberOfAPIcallsInchunks = Math.ceil(productCount / 4); // considering calling 4 items at a time;
      let currentIndex = 0;

      while (numberOfAPIcallsInchunks !== 0) {
        numberOfAPIcallsInchunks--;
        totalPagesFetched += 1;
        const productIdsChunk = productIds.slice(
          currentIndex,
          currentIndex + 4,
        );
        yield put({
          type: FETCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
          payload: {
            productIds: productIdsChunk,
            catalogId,
            storeId,
            localeCode,
            categoryId,
            totalPagesFetched,
          },
        });
        currentIndex = currentIndex + 4;
      }
    } else {
      yield put({ type: FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.FAILURE });
    }
  } catch (e: any) {
    yield put({
      type: FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

export function* watchLoadFindProductsTopCategories() {
  yield takeEvery(
    LOAD_FIND_PRODUCTS_TOP_CATEGORIES,
    loadFindProductsTopCategories,
  );
}

export function* watchFindProductsCreateProductCountSubscription() {
  yield takeEvery(
    LOAD_FIND_PRODUCTS_PRODUCT_COUNT,
    createProductCountSubscription,
  );
}

export function* watchCloseAllFindProductsSubscriptions() {
  yield takeEvery(CLOSE_ALL_FIND_PRODUCT_SUBSCRIPTIONS, closeAllSubscriptions);
}

export function* watchLoadFindProductsChildCategories() {
  yield takeEvery(
    LOAD_FIND_PRODUCTS_CHILD_CATEGORIES,
    loadFindProductsChildCategories,
  );
}

export function* watchLoadProductsForFindProductsCategory() {
  yield takeEvery(
    LOAD_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
    loadProductsFromCategory,
  );
}

export function* watchLoadSearchProductsForFindProducts() {
  yield takeEvery(LOAD_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS, searchProducts);
}

export function* watchUpdateProductsCategory() {
  yield takeEvery(CHANGE_PRODUCTS_CATEGORY, changeProductsCategory);
}

export function* watchLoadFindProductModalProductList() {
  yield takeEvery(
    FETCH_PRODUCTS_FOR_FIND_PRODUCTS.REQUEST,
    loadProductListForFindProductsModal,
  );
}

export function* watchProductIdsFromFindProductsCategory() {
  yield takeEvery(
    FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST,
    getProductIdsForFindProductsFromCategory,
  );
}

export function* watchCloseTopCategoriesFindProductModal() {
  let task;
  while (true) {
    const action = yield take(MODAL_CLOSED);
    if (task) {
      yield cancel(task); // Cancel previous loading task if it's inflight
      yield put(productLoadingCancelled());
    }
    task = yield fork(loadFindProductsTopCategories, action); // Start a new product loading task
  }
}
export function* watchCloseSubcategoriesFindProductModal() {
  let task;
  while (true) {
    const action = yield take(MODAL_CLOSED);
    if (task) {
      yield cancel(task); // Cancel previous loading task if it's inflight
      yield put(productLoadingCancelled());
    }
    task = yield fork(loadFindProductsChildCategories, action); // Start a new product loading task
  }
}
export function* watchCloseLoadFindProductModal() {
  let task;
  while (true) {
    const action = yield take(MODAL_CLOSED);
    if (task) {
      yield cancel(task); // Cancel previous loading task if it's inflight
      yield put(productLoadingCancelled());
    }
    task = yield fork(loadProductsFromCategory, action); // Start a new product loading task
  }
}
export function* watchCloseFindProductModal() {
  let task;
  while (true) {
    const action = yield take(MODAL_CLOSED);
    if (task) {
      yield cancel(task); // Cancel previous loading task if it's inflight
      yield put(productLoadingCancelled());
    }
    task = yield fork(loadProductListForFindProductsModal, action); // Start a new product loading task
  }
}
export function* watchCloseSearchFindProductModal() {
  let task;
  while (true) {
    const action = yield take(MODAL_CLOSED);
    if (task) {
      yield cancel(task); // Cancel previous loading task if it's inflight
      yield put(productLoadingCancelled());
    }
    task = yield fork(searchProducts, action); // Start a new product loading task
  }
}
