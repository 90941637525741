import {
  Facet,
  FacetReducerActions,
  FETCH_FACETS_BY_CATEGORY,
  GetFacetsByCategoryResponseAction,
  RESET_FACETS_STATE,
  GetFacetsToAddResponseAction,
  FETCH_FACETS_TO_ADD,
  AddAttributeValueFacetFilterAction,
  RemoveAttributeValueFacetFilterAction,
  ADD_ATTRIBUTE_VALUE_FACET_FILTER,
  REMOVE_ATTRIBUTE_VALUE_FACET_FILTER,
  FETCH_FACETS_BY_CATEGORY_SFCC,
  GetFacetsByCategorySFCCResponseAction,
  SET_FACETS_FETCHING,
} from "./FacetTypes";

export interface FacetState {
  facets: Facet[];
  facetsSFCC: Facet[];
  facetsToAdd: Facet[];
  filterValues: string[];
  isFacetsFetching: boolean;
  isFacetsFetched: boolean;
}

const initialState: FacetState = {
  facets: [],
  facetsSFCC: [],
  facetsToAdd: [],
  filterValues: [],
  isFacetsFetching: false,
  isFacetsFetched: false,
};

export const FacetReducer = (
  state = initialState,
  action: FacetReducerActions,
) => {
  switch (action.type) {
    case FETCH_FACETS_BY_CATEGORY.SUCCESS:
      const categoryFacetAction = action as GetFacetsByCategoryResponseAction;
      const facets = categoryFacetAction.payload.data.CategoryToFacet.map(
        (toFacet) => {
          toFacet.facet.sequence = toFacet.sequence;
          return toFacet.facet;
        },
      );
      return {
        ...state,
        isFacetsFetched: true,
        facets: facets,
      };
    case FETCH_FACETS_BY_CATEGORY_SFCC.SUCCESS:
      const facetAction = action as GetFacetsByCategorySFCCResponseAction;
      return {
        ...state,
        isFacetsFetched: true,
        facetsSFCC: facetAction.payload.data.Facet,
      };
    case SET_FACETS_FETCHING:
      return {
        ...state,
        isFacetsFetching: true,
      };
    case FETCH_FACETS_TO_ADD.SUCCESS:
      const facetsToAddAction = action as GetFacetsToAddResponseAction;
      return {
        ...state,
        facetsToAdd: facetsToAddAction.payload.data.Facet,
      };
    case ADD_ATTRIBUTE_VALUE_FACET_FILTER:
      const addFilterAction = action as AddAttributeValueFacetFilterAction;
      const toAddValues = state.filterValues.slice();
      addFilterAction.payload.attributeValueIds.forEach((valueId) => {
        if (!toAddValues.find((addValue) => addValue === valueId)) {
          toAddValues.push(valueId);
        }
      });
      return {
        ...state,
        filterValues: toAddValues,
      };
    case REMOVE_ATTRIBUTE_VALUE_FACET_FILTER:
      const removeFilterAction =
        action as RemoveAttributeValueFacetFilterAction;
      const toRemoveValues = state.filterValues.slice();
      return {
        ...state,
        filterValues: toRemoveValues.filter(
          (value) =>
            !removeFilterAction.payload.attributeValueIds.includes(value),
        ),
      };
    case RESET_FACETS_STATE:
      return initialState;
    default:
      return state;
  }
};
