import {
  LOAD_CATEGORIES_BY_PRODUCT_IDS,
  LoadCategoriesByProductIdsPayload,
  LoadCategoriesByProductIdsAction,
  DeleteProductByCategoriesPayload,
  DeleteProductByCategoriesAction,
  DELETE_PRODUCTS_BY_CATEGORIES_ID,
  RESET_PRODUCT_REMOVE_STATE,
} from "./RemoveProductCategoriesTypes";

export const loadCategoriesByProductsId = (
  payload: LoadCategoriesByProductIdsPayload,
): LoadCategoriesByProductIdsAction => {
  return {
    payload,
    type: LOAD_CATEGORIES_BY_PRODUCT_IDS,
  };
};

export const DeleteProductByCategories = (
  payload: DeleteProductByCategoriesPayload,
): DeleteProductByCategoriesAction => {
  return {
    payload,
    type: DELETE_PRODUCTS_BY_CATEGORIES_ID,
  };
};

export const ResetProductDeletedState = () => {
  return {
    type: RESET_PRODUCT_REMOVE_STATE,
  };
};
