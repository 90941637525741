export const loadProductsForFindProductsByCategory = `subscription LoadProductsForFindProductsByCategory($catalogId: String, $categoryId: String, $storeId: String, $orderBy: Product_order_by) {
  CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, product: {type: {isBrowsable: {_eq: true}}}}, order_by: { product: $orderBy }) {
    productId
    product {
      descriptions {
        name
        thumbnail
        localeCode
      }
      toCategories(distinct_on: categoryId) {
        categoryId
      }
    }
  }
}`;

export const orderByProductName = {
  descriptions_aggregate: {
    max: {
      name: "asc",
    },
  },
};

export const orderByProductId = {
  descriptions_aggregate: {
    max: {
      productId: "asc",
    },
  },
};

export const searchProductsForFindProducts = `subscription SearchProductsForFindProducts($searchTerm: String, $storeId: String, $orderBy: [Product_order_by!]) {
  Product(where: {toStores: {storeId: {_eq: $storeId}}, type: {isBrowsable: {_eq: true}}, _or: [{code: {_ilike: $searchTerm}}, {descriptions: {name: {_ilike: $searchTerm}}}]}, order_by: $orderBy) {
    productId
    descriptions {
      name
      thumbnail
      localeCode
    }
    toCategories(distinct_on: categoryId) {
      categoryId
    }
  }
}`;

export const searchProductsForFindProductsWithoutStoreId = `subscription SearchProductsForFindProducts($searchTerm: String, $orderBy: [Product_order_by!]) {
  Product(where: {type: {isBrowsable: {_eq: true}}, _or: [{code: {_ilike: $searchTerm}}, {descriptions: {name: {_ilike: $searchTerm}}}]}, order_by: $orderBy) {
    productId
    descriptions {
      name
      thumbnail
      localeCode
    }
    toCategories(distinct_on: categoryId) {
      categoryId
    }
  }
}`;

export const insertCategory = `mutation InsertCategoryToProduct($products: [CategoryToProduct_insert_input!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_CategoryToProduct(objects: $products) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteCategory = `mutation DeleteCategoryToProduct($catalogId: String!, $categoryId: String!, $productIds: [String!], $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, productId: {_in: $productIds}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const topCategoriesQuery = `query GetTopCategories($catalogId: String)  {
  CatalogToCategory(where: {catalogId: {_eq: $catalogId}}) {
    catalogId
    categoryId
    category {
      code
      isPublished
      toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
        aggregate {
          count
        }
      }
      descriptions {
        name
        isPublished
        localeCode
      }
    }
  }
}`;

export const childCategoriesQuery = `query ChildCategories($categoryId: String, $catalogId: String) {
  CategoryToCategory_aggregate(where: {parentCategoryId: {_eq: $categoryId}, catalogId: {_eq: $catalogId}}) {
    nodes {
      childCategoryId
      parentCategoryId
      childCategory {
        code
        isPublished
        toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
          aggregate {
            count
          }
        }
        descriptions {
          name
          isPublished
          localeCode
        }
      }
    }
  }
}`;
