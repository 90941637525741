import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import Tooltip from "../common/ToolTip";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import {
  selectCategories,
  Category,
  selectChildCategories,
} from "../../store/category/CategorySelectors";
import { LoadChildCategoriesForReport } from "../../store/category/CategoryActions";
import {
  isCategoryPublishedSFCC,
  getCategoryNameSFCC,
} from "../../utils/CategoryUtils";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import {
  selectReportStoreId,
  selectReportCatalogId,
  selectReportCategoriesToProducts,
} from "../../store/report/ReportSelectors";
import { setReportCategories } from "../../store/report/ReportActions";
import { loadProductDetailsForReport } from "../../store/product/ProductActions";
import { useQuery } from "../../hooks/useQueryParams";
import AppState from "../../store/AppState";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    overflowY: "auto",
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  rootLabel: {
    "&:focus>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
  count: {
    fontSize: ".8rem",
    marginLeft: "5px",
    marginBottom: "1px",
  },
}));

interface Props {}

const CategoryFilter: React.FC<Props> = () => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [expanded, setExpanded] = useState<string[]>([]);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const reportFieldsQuery = query.get("reportFields") ?? "";
  const dateRangeQuery = query.get("dateRange") ?? "";

  const dispatch = useDispatch();

  const childCategories = useSelector(selectChildCategories);
  const categoryTree = useSelector(selectCategories);
  const storeId = useSelector(selectReportStoreId);
  const catalogId = useSelector(selectReportCatalogId);
  const categoriesToProducts = useSelector(selectReportCategoriesToProducts);
  const userAccountId = useSelector(selectCallerAccountId);
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  useEffect(() => {
    setExpanded([]);
    setSelected({});
  }, [catalogId, storeId]);

  useEffect(() => {
    let categories: string[] = [];
    if (Object.keys(selected).length > 0) {
      Object.keys(selected).forEach((categoryId) => {
        if (selected[categoryId]) {
          categories.push(categoryId);
        }
      });
      dispatch(setReportCategories(categories));
    }
  }, [selected, dispatch]);

  const handleToggle = useCallback(
    (event: object, nodeIds: string[] | string) => {
      if (catalogId) {
        const value = nodeIds as string[];
        value.forEach((categoryId) => {
          if (!childCategories[categoryId]) {
            dispatch(
              LoadChildCategoriesForReport(catalogId, categoryId, storeId),
            );
          }
        });
        const keyLength = Object.keys(event).length;
        if (keyLength > 0) setExpanded(value);
      }
    },
    [childCategories, catalogId, dispatch, storeId],
  );

  useEffect(() => {
    if (categoryPathQuery !== "" && storeId !== "" && catalogId !== "") {
      const categories = categoryPathQuery.split(",");
      const fetchedCategories: string[] = [];
      let selectedCategories = {};
      let expandedCategories: string[] = [];
      if (Object.keys(selected).length === 0) {
        categories.forEach((category) => {
          const categoryTreeQuery = category.split(";");
          const selectedCategory =
            categoryTreeQuery[categoryTreeQuery.length - 1];
          dispatch(
            loadProductDetailsForReport({
              categoryId: selectedCategory,
              catalogId,
              storeId,
            }),
          );
          categoryTreeQuery.pop();
          expandedCategories.push(...categoryTreeQuery);
          selectedCategories = {
            ...selectedCategories,
            [selectedCategory]: true,
          };
          categoryTreeQuery.forEach((categoryId) => {
            if (!fetchedCategories.includes(categoryId)) {
              dispatch(
                LoadChildCategoriesForReport(catalogId, categoryId, storeId),
              );
              fetchedCategories.push(categoryId);
            }
          });
        });
        setSelected(selectedCategories);
        setExpanded(expandedCategories);
      }
    }
  }, [
    categoryPathQuery,
    storeId,
    catalogId,
    selected,
    expanded,
    handleToggle,
    setSelected,
    dispatch,
  ]);

  const renderLabel = useCallback(
    (node: Category) => (
      <>
        <Tooltip
          tooltipTitle={getCategoryNameSFCC(node, "", defaultLocaleId)}
          placement="right"
        >
          <Typography
            noWrap
            color={selected[node.id] ?? false ? "primary" : "initial"}
          >
            {isCategoryPublishedSFCC(node) ? "" : "(U) "}
            {getCategoryNameSFCC(node, "", defaultLocaleId)}
          </Typography>
        </Tooltip>
        {node.productCount > 0 ? (
          <span
            className={clsx(classes.count, {
              [classes.selected]: selected[node.id] ?? false,
            })}
          >
            ({node.productCount})
          </span>
        ) : (
          ""
        )}
      </>
    ),
    [classes.count, classes.selected, selected, defaultLocaleId],
  );

  const labelClickHandler = useCallback(
    (nodes: Category, e: React.MouseEvent) => {
      e.preventDefault();
      setSelected({
        ...selected,
        [nodes.id]: selected[nodes.id] ? false : true,
      });
      if (!selected[nodes.id] && !categoriesToProducts[nodes.id]) {
        dispatch(
          loadProductDetailsForReport({
            categoryId: nodes.id,
            catalogId,
            storeId,
          }),
        );
      }
      if (!selected[nodes.id]) {
        const newCategory = nodes.categoryPath
          ? `${nodes.categoryPath};${nodes.id}`
          : `${nodes.id}`;

        const categories = categoryPathQuery
          .split(",")
          .filter((cat) => cat.length > 0);
        const insertIndex = categories.findIndex(
          (category) => category > newCategory,
        );
        if (insertIndex === -1) {
          categories.push(newCategory);
        } else {
          categories.splice(insertIndex, 0, newCategory);
        }
        const newCategoryPath = categories.join(",");
        const newReportFieldsQuery =
          reportFieldsQuery.length > 0
            ? `&reportFields=${encodeURIComponent(reportFieldsQuery)}`
            : "";
        const newDateRangeQuery =
          dateRangeQuery.length > 0
            ? `&dateRange=${encodeURIComponent(dateRangeQuery)}`
            : "";
        history.replace(
          `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&catalogId=${catalogIdQuery}&categoryPath=${newCategoryPath}${newReportFieldsQuery}${newDateRangeQuery}`,
        );
      } else {
        let categoryPaths: string[] = categoryPathQuery.split(",");
        categoryPaths = categoryPaths.filter((path) => {
          const categories = path.split(";");
          return categories[categories.length - 1] !== nodes.id;
        });
        const newCategoryPath = categoryPaths.join(",");
        const newCategoryPathQuery =
          newCategoryPath.length > 0 ? `&categoryPath=${newCategoryPath}` : "";
        const newReportFieldsQuery =
          reportFieldsQuery.length > 0
            ? `&reportFields=${encodeURIComponent(reportFieldsQuery)}`
            : "";
        const newDateRangeQuery =
          dateRangeQuery.length > 0
            ? `&dateRange=${encodeURIComponent(dateRangeQuery)}`
            : "";
        history.replace(
          `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&catalogId=${catalogIdQuery}${newCategoryPathQuery}${newReportFieldsQuery}${newDateRangeQuery}`,
        );
      }
    },
    [
      dateRangeQuery,
      reportFieldsQuery,
      selected,
      catalogId,
      storeId,
      categoriesToProducts,
      catalogIdQuery,
      history,
      storeIdQuery,
      url,
      categoryPathQuery,
      dispatch,
      userAccountId,
    ],
  );

  const renderTree = useCallback(
    (nodes: Category) => {
      if (isCategoryPublishedSFCC(nodes)) {
        return (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={renderLabel(nodes)}
            onLabelClick={(e) => labelClickHandler(nodes, e)}
            classes={{
              root: classes.rootLabel,
              label: classes.label,
            }}
          >
            {Array.isArray(nodes.children) ? (
              nodes.children.map((node) => renderTree(node))
            ) : nodes.childCategoryCount > 0 ? (
              <div />
            ) : null}
          </TreeItem>
        );
      }
    },
    [classes.label, classes.rootLabel, labelClickHandler, renderLabel],
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<RemoveIcon fontSize="small" />}
      defaultExpandIcon={<AddIcon fontSize="small" />}
      expanded={expanded}
      selected={""}
      onNodeToggle={handleToggle}
    >
      {catalogId !== ""
        ? categoryTree.map((topCat) => renderTree(topCat))
        : null}
    </TreeView>
  );
};

export default CategoryFilter;
