import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import {
  loadProductDetails,
  setSearchTerm,
} from "../../store/product/ProductActions";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../hooks/useQueryParams";
import { selectCallerAccountId } from "../../store/app-config/AppConfigSelectors";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      //border: "2px solid #F2F3F4",
      "&:hover": {},
      marginRight: theme.spacing(2),
      marginLeft: "auto",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      //display: 'flex',
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 2,
      display: "none",
    },
    inputRoot: {
      color: "inherit",
      display: "none",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "400px",
      },
      display: "none",
    },
  }),
);

const SearchBox = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const searchTermQuery = query.get("searchTerm") ?? "";
  const localeCodeQuery = query.get("localeCode") ?? "";
  const localeCodeQueryStr =
    localeCodeQuery !== "" ? `&localeCode=${localeCodeQuery}` : "";

  const currentStoreId = useSelector(selectCurrentStoreId);
  const currentLocale = useSelector(selectCurrentLocale);
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const userAccountId = useSelector(selectCallerAccountId);
  const searchRef = useRef<HTMLInputElement>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchTermQuery && currentLocale && currentStoreId) {
      searchRef.current!.value = searchTermQuery;
      dispatch(setSearchTerm(searchTermQuery));
      dispatch(
        loadProductDetails({
          localeId: currentLocale,
          searchTerm: `%${searchRef.current?.value}%`,
          storeId: currentStoreId,
        }),
      );
    }
  }, [currentLocale, currentStoreId, dispatch, searchTermQuery]);

  const SearchTermHandler = () => {
    const searchTermValue = searchRef.current?.value;
    if (searchTermValue && searchTermValue !== searchTermQuery) {
      history.replace(
        `${location.pathname}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}&catalogId=${catalogIdQuery}&searchTerm=${searchTermValue}`,
      );
    }
  };

  const enterPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      SearchTermHandler();
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon} onClick={SearchTermHandler}>
        <Icons iconId="SearchIcon" />
      </div>
      <InputBase
        placeholder={intl.formatMessage({
          id: "searchBox.placeholder",
          defaultMessage: "Search...",
        })}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        inputRef={searchRef}
        onKeyDown={enterPressHandler}
        disabled={!(currentStoreId && currentCatalogId)}
      />
    </div>
  );
};

export default React.memo(SearchBox);
