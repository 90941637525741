import {
  FETCH_COLOR_MANAGEMENT_PRODUCT_LIST,
  UPDATE_PRODUCT_COLOR_SEQUENCE,
  UPDATE_PRODUCT_COLOR_IDS_SEQUENCE,
  UPDATE_COLOR_PUBLISH_FLAG_COMPLETE,
  UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG,
  UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT,
  SET_LEADING_COLOR_IMAGE,
  RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE,
  TOGGLE_SEQUENCE_EDITED,
  FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
  RESET_COLOR_MANAGEMENT_PRODUCT_LIST,
  LeadingColorImage,
  ColorData,
  SET_LEADING_COLOR_ID,
} from "./ColorManagementTypes";
import { LeadingImageVariationAttributesData } from "../leading-image/LeadingImageTypes";
import { createBaseProductLeadingColorImageAction } from "./ColorManagementActions";

export interface ColorManagementData {
  colorManagementProductList: ColorData[];
  variationAttributes: LeadingImageVariationAttributesData[];
  isFetchingColorManagementProductList: boolean;
  isSequenceUpdated: boolean;
  sequenceEdited: boolean;
  leadingColor: string;
  isUpdatingLeadingColorCompleted: boolean;
  imageAndColorByProductIdByCategoryId: Record<
    string,
    Record<string, LeadingColorImage>
  >;
  leadingColorId: string;
}

const initialState = {
  colorManagementProductList: [],
  variationAttributes: [],
  isFetchingColorManagementProductList: false,
  isSequenceUpdated: true,
  sequenceEdited: false,
  leadingColor: "",
  isUpdatingLeadingColorCompleted: false,
  imageAndColorByProductIdByCategoryId: {},
  leadingColorId: "",
};

export const ColorManagementReducer = (
  state: ColorManagementData = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_COLOR_MANAGEMENT_PRODUCT_LIST.REQUEST: {
      return {
        ...state,
        isFetchingColorManagementProductList: true,
      };
    }
    case UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG.REQUEST: {
      const updatedColorIndex = state.colorManagementProductList.findIndex(
        (e) => e.code === action.payload.colorId,
      );
      const updatedColorManagementProductList = [
        ...state.colorManagementProductList,
      ];
      updatedColorManagementProductList[
        updatedColorIndex
      ].isUpdatingPublishFlag = true;
      return {
        ...state,
        colorManagementProductList: updatedColorManagementProductList,
      };
    }
    case FETCH_COLOR_MANAGEMENT_PRODUCT_LIST.SUCCESS: {
      const productList = action.payload.productList;
      return {
        ...state,
        colorManagementProductList: productList,
        isFetchingColorManagementProductList: false,
      };
    }
    case UPDATE_PRODUCT_COLOR_IDS_SEQUENCE.FAILURE: {
      return {
        ...state,
        isSequenceUpdated: true,
        sequenceEdited: true,
      };
    }
    case UPDATE_PRODUCT_COLOR_IDS_SEQUENCE.SUCCESS: {
      return {
        ...state,
        isSequenceUpdated: true,
        sequenceEdited: false,
      };
    }
    case TOGGLE_SEQUENCE_EDITED: {
      return {
        ...state,
        sequenceEdited: true,
      };
    }
    case UPDATE_PRODUCT_COLOR_SEQUENCE: {
      return {
        ...state,
        isSequenceUpdated: false,
        sequenceEdited: false,
      };
    }
    case FETCH_COLOR_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE: {
      return {
        ...state,
        variationAttributes: action.payload,
      };
    }
    case UPDATE_COLOR_PUBLISH_FLAG_COMPLETE: {
      const updatedColor = state.colorManagementProductList.find(
        (e) => e.code === action.payload.colorId,
      );
      const updatedColorIndex = state.colorManagementProductList.findIndex(
        (e) => e.code === action.payload.colorId,
      );
      const updatedColorManagementProductList = [
        ...state.colorManagementProductList,
      ];
      if (updatedColor) {
        updatedColor.isPublished = action.payload.isPublished;
        updatedColor.isUpdatingPublishFlag = false;
        updatedColorManagementProductList[updatedColorIndex] = updatedColor;
      }
      return {
        ...state,
        colorManagementProductList: updatedColorManagementProductList,
      };
    }
    case UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT.REQUEST: {
      return {
        ...state,
        isUpdatingLeadingColor: true,
      };
    }
    case UPDATE_CATEGORY_LEADING_COLOR_FOR_PRODUCT.FAILURE: {
      return {
        ...state,
        isUpdatingLeadingColorCompleted: true,
      };
    }
    case SET_LEADING_COLOR_IMAGE: {
      const {
        payload: { productId, categoryId, thumbnail, leadingColorId },
      } = action as ReturnType<typeof createBaseProductLeadingColorImageAction>;
      return {
        ...state,
        isUpdatingLeadingColorCompleted: true,
        imageAndColorByProductIdByCategoryId: {
          ...state.imageAndColorByProductIdByCategoryId,
          [productId]: {
            ...state.imageAndColorByProductIdByCategoryId[productId],
            [categoryId]: { thumbnail, color: leadingColorId },
          },
        },
      };
    }
    case RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE: {
      return {
        ...state,
        isUpdatingLeadingColorCompleted: false,
      };
    }
    case RESET_COLOR_MANAGEMENT_PRODUCT_LIST: {
      return {
        ...state,
        colorManagementProductList: initialState.colorManagementProductList,
      };
    }
    case SET_LEADING_COLOR_ID: {
      const leadingColor = action.payload;
      return {
        ...state,
        leadingColorId: leadingColor,
      };
    }
    default:
      return state;
  }
};
