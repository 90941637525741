import {
  createStoreSetLeadingImageAction,
  getLeadingImageForProductInCategory,
  createStoreRemoveLeadingImageAction,
  setSelectedFilterValueAction,
  getLeadingImages,
} from "./LeadingImageActions";
import {
  STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  RESET_LEADING_IMAGE_LIST,
  FETCH_LEADING_IMAGE_IMAGES,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE,
  SET_FILTER_VALUES_FOR_PRODUCT,
  RESET_FILTER_FOR_PRODUCT,
  RESET_VARIATION_ATTRIBUTES_STATE,
  FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID,
  LeadingImageImagesData,
  LeadingImageVariationAttributesData,
  ImageIdByProductIdByCategoryIdData,
  FilterValueType,
  SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY,
  CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API,
} from "./LeadingImageTypes";
import { REFRESH_PRODUCT } from "../product-list/ProductListTypes";

export interface LeadingImageData {
  imageIdByProductIdByCategoryId: ImageIdByProductIdByCategoryIdData;
  images: LeadingImageImagesData[];
  isRefreshing: boolean;
  variationAttributes: LeadingImageVariationAttributesData[];
  selectedFilterValue: FilterValueType;
  isRefreshingLeadingImageModal: boolean;
  isVariationAttributesCallInvoked: boolean;
  isSettingLeadingImage: boolean;
}

const initialState: LeadingImageData = {
  imageIdByProductIdByCategoryId: {},
  images: [],
  isRefreshing: false,
  variationAttributes: [],
  selectedFilterValue: {},
  isRefreshingLeadingImageModal: true,
  isVariationAttributesCallInvoked: false,
  isSettingLeadingImage: false,
};

export const LeadingImageReducer = (
  state: LeadingImageData = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY: {
      const { payload } = action as ReturnType<
        typeof getLeadingImageForProductInCategory
      >;
      const imagesWithNoAttributes: LeadingImageImagesData[] = [];
      const uniqueImages: LeadingImageImagesData[] = [];
      if (payload.product?.imageGroups?.length) {
        for (const i of payload.product?.imageGroups) {
          const varAttributesPayload = i.variationAttributes?.map((e) => {
            const values = e.values.map((v) => {
              return {
                valueId: v.value,
              };
            });
            return {
              attributeId: e.attributeId,
              values: values,
            };
          });
          for (const e of i.images) {
            if (varAttributesPayload) {
              uniqueImages.push({
                imageId: e.imageId,
                url: e.thumbnail,
                variationAttributes: varAttributesPayload,
                isSelected: false,
              });
            } else {
              imagesWithNoAttributes.push({
                imageId: e.imageId,
                url: e.thumbnail,
                variationAttributes: varAttributesPayload,
                isSelected: false,
              });
            }
          }
        }
        imagesWithNoAttributes?.forEach((image) => {
          const isImageExists = uniqueImages?.some(
            (uniqueImg) => image.imageId === uniqueImg.imageId,
          );
          if (!isImageExists) {
            uniqueImages.push(image);
          }
        });
      }

      const search = payload.product.categorySpecific?.find(
        (e) => e.categoryId === payload.categoryId,
      );
      const imageIdByProductIdByCategoryId: ImageIdByProductIdByCategoryIdData =
        search && search?.leadingImageId
          ? {
              [payload.product.code]: {
                [payload.categoryId]: {
                  imageId: search.leadingImageId,
                  url:
                    uniqueImages.find(
                      (e) => e.imageId === search?.leadingImageId,
                    )?.url ?? "",
                },
              },
            }
          : {};
      return {
        ...state,
        imageIdByProductIdByCategoryId: {
          ...state.imageIdByProductIdByCategoryId,
          ...imageIdByProductIdByCategoryId,
        },
        images: uniqueImages,
        isVariationAttributesCallInvoked: true,
      };
    }
    case FETCH_LEADING_IMAGE_IMAGES: {
      const { payload } = action as ReturnType<typeof getLeadingImages>;
      const imagesWithNoAttributes: LeadingImageImagesData[] = [];
      const uniqueImages: LeadingImageImagesData[] = [];
      if (payload.product?.imageGroups?.length) {
        for (const i of payload.product?.imageGroups) {
          const varAttributesPayload = i.variationAttributes?.map((e) => {
            const values = e.values.map((v) => {
              return {
                valueId: v.value,
              };
            });
            return {
              attributeId: e.attributeId,
              values: values,
            };
          });
          for (const e of i.images) {
            if (varAttributesPayload) {
              uniqueImages.push({
                imageId: e.imageId,
                url: e.thumbnail,
                variationAttributes: varAttributesPayload,
                isSelected: false,
              });
            } else {
              imagesWithNoAttributes.push({
                imageId: e.imageId,
                url: e.thumbnail,
                variationAttributes: varAttributesPayload,
                isSelected: false,
              });
            }
          }
        }
        imagesWithNoAttributes?.forEach((image) => {
          const isImageExists = uniqueImages?.some(
            (uniqueImg) => image.imageId === uniqueImg.imageId,
          );
          if (!isImageExists) {
            uniqueImages.push(image);
          }
        });
      }
      return {
        ...state,
        images: uniqueImages,
        isVariationAttributesCallInvoked: true,
      };
    }
    case FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE:
      return {
        ...state,
        variationAttributes: action.payload,
        isRefreshingLeadingImageModal: false,
        isVariationAttributesCallInvoked: true,
      };
    case FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID.FAILURE:
      return {
        ...state,
        isRefreshingLeadingImageModal: false,
        isVariationAttributesCallInvoked: false,
      };
    case STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY: {
      const {
        payload: { productId, categoryId, image },
      } = action as ReturnType<typeof createStoreSetLeadingImageAction>;
      const code = productId;
      const imageIndex = state.images.findIndex(
        (e) => e.imageId === action.payload.image.imageId,
      );
      const imagesPayload = [...state.images];
      imagesPayload[imageIndex].isSelected = true;
      return {
        ...state,
        imageIdByProductIdByCategoryId: {
          ...state.imageIdByProductIdByCategoryId,
          [code]: {
            ...state.imageIdByProductIdByCategoryId[code],
            [categoryId]: image,
          },
        },
        images: imagesPayload,
      };
    }
    case RESET_LEADING_IMAGE_LIST: {
      const images = initialState.images;
      return {
        ...state,
        images: images,
      };
    }
    case STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY: {
      const {
        payload: { productId, categoryId },
      } = action as ReturnType<typeof createStoreRemoveLeadingImageAction>;

      const code = productId;

      return {
        ...state,
        imageIdByProductIdByCategoryId: {
          ...state.imageIdByProductIdByCategoryId,
          [code]: {
            ...state.imageIdByProductIdByCategoryId[code],
            [categoryId]: {
              imageId: "",
              url: "",
            },
          },
        },
      };
    }
    case REFRESH_PRODUCT.REQUEST: {
      return {
        ...state,
        isRefreshing: true,
      };
    }
    case REFRESH_PRODUCT.SUCCESS: {
      return {
        ...state,
        isRefreshing: false,
      };
    }
    case SET_FILTER_VALUES_FOR_PRODUCT: {
      const {
        payload: { attributeId, filterValue, productId, categoryId },
      } = action as ReturnType<typeof setSelectedFilterValueAction>;
      return {
        ...state,
        selectedFilterValue: {
          ...state.selectedFilterValue,
          [productId]: {
            ...state.selectedFilterValue[productId],
            [categoryId]: {
              [attributeId]: filterValue,
            },
          },
        },
      };
    }
    case RESET_FILTER_FOR_PRODUCT: {
      return {
        ...state,
        selectedFilterValue: {},
        isRefreshingLeadingImageModal: true,
      };
    }
    case RESET_VARIATION_ATTRIBUTES_STATE: {
      return {
        ...state,
        variationAttributes: [],
        isVariationAttributesCallInvoked: false,
      };
    }
    case SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY: {
      return {
        ...state,
        isSettingLeadingImage: true,
      };
    }
    case CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API.FAILURE:
    case CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API.SUCCESS: {
      return {
        ...state,
        isSettingLeadingImage: false,
      };
    }
    default: {
      return state;
    }
  }
};
