import { ProductCategoryNode } from "../product/ProductReducers";

export const LOAD_STORES = "LOAD_STORES";
export const LOAD_All_TOP_CATEGORIES = "LOAD_All_TOP_CATEGORIES";
export const FETCH_STORES_REQUEST = "FETCH_STORES_REQUEST";
export const FETCH_STORES_SUCCESS = "FETCH_STORES_SUCCESS";
export const FETCH_STORES_FAILURE = "FETCH_STORES_FAILURE";
export const POST_PRODUCTS_TO_CATEGORIES = "POST_PRODUCTS_TO_CATEGORIES";
export const ADD_PRODUCTS_TO_CATEGORIES = {
  REQUEST: "ADD_PRODUCTS_TO_CATEGORIES_REQUEST",
  SUCCESS: "ADD_PRODUCTS_TO_CATEGORIES_SUCCESS",
  FAILURE: "ADD_PRODUCTS_TO_CATEGORIES_FAILURE",
  COMPLETED: "ADD_PRODUCTS_TO_CATEGORIES_COMPLETED",
};
export const FETCH_STORES = {
  REQUEST: FETCH_STORES_REQUEST,
  SUCCESS: FETCH_STORES_SUCCESS,
  FAILURE: FETCH_STORES_FAILURE,
};

export interface StoreData {
  storeId: string;
  code: string;
  name: string;
  defaults: {
    catalogId: string;
    localeCode: string;
  };
}

export interface GetStoreListResponsePayload {
  count: number;
  total: number;
  results: StoreData[];
}

export interface LoadStoresAction {
  type: typeof LOAD_STORES;
  payload?: { storeId: string };
}

export interface LoadStoresResponseAction {
  payload: GetStoreListResponsePayload;
  type: typeof FETCH_STORES_SUCCESS;
}

export interface Store {
  storeId: string;
  name: string;
}

export const SET_CATALOG_ID = "SET_CATALOG_ID";
export const LOAD_CATALOGS = "LOAD_CATALOGS";
export const FETCH_CATALOGS = {
  REQUEST: "FETCH_CATALOGS_REQUEST",
  SUCCESS: "FETCH_CATALOGS_SUCCESS",
  FAILURE: "FETCH_CATALOGS_FAILURE",
};

export interface CatalogList {
  [key: string]: string[];
}

export interface AllCatalog {
  id: string;
  name: string;
}

export interface StorePayload {
  storeId: string;
}

export interface Catalog {
  catalogId: string;
  name: string;
}

export interface LoadCatalogPayload {
  storeId: string;
  localeId?: string;
}

export interface LoadCatalogsAction {
  payload: StorePayload;
  type: string;
}

export interface LoadCatalogsResponseAction {
  payload: GetCatalogsResponsePayload;
  type: string;
}

export interface GetCatalogsResponsePayload {
  count: number;
  total: number;
  results: Catalog[];
  storeId: string;
}

export interface AllCatalog {
  id: string;
  name: string;
}

export interface LoadAllTopCategoriesAction {
  payload: GetTopCategoriesPayLoad;
  type: typeof LOAD_All_TOP_CATEGORIES;
}

export interface LoadTopCategoriesResponseAction {
  payload: GetTopCategoriesResponsePayLoad;
  type: string;
}

export interface GetTopCategoriesPayLoad {
  catalogId: string;
  localeId: string;
  storeId: string;
}

export interface GetTopCategoriesResponsePayLoad {
  count: number;
  total: number;
  storeId: string;
  catalogId: string;
  results: TopCategoriesData[];
}

export interface TopCategoriesData {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  parentId: string;
  hasSubcategories: boolean;
  hasProducts: boolean;
  productCount: number;
  childCategoryCount: number;
}

export const FETCH_ALL_TOP_CATEGORIES = {
  REQUEST: "FETCH_TOP_ALL_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_TOP_ALL_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_TOP_ALL_CATEGORIES_FAILURE",
};

export interface LoadAllTopCategoriesResponseAction {
  payload: GetTopCategoriesResponsePayLoad;
  type: string;
}

export const LOAD_ALL_CHILD_CATEGORIES = "LOAD_ALL_CHILD_CATEGORIES";
export const RESET_PRODUCT_ADDED_STATE = "RESET_PRODUCT_ADDED_STATE";
export const RESET_PRODUCT_ADDED = "RESET_PRODUCT_ADDED";
export const FETCH_ALL_CHILD_CATEGORIES = {
  REQUEST: "FETCH_ALL_CHILD_CATEGORIES_REQUEST",
  SUCCESS: "FETCH_ALL_CHILD_CATEGORIES_SUCCESS",
  FAILURE: "FETCH_ALL_CHILD_CATEGORIES_FAILURE",
};
export interface LoadAllChildCategoriesAction {
  payload: GetAllChildCategoriesPayLoad;
  type: typeof LOAD_ALL_CHILD_CATEGORIES;
}

export interface GetAllChildCategoriesPayLoad {
  catalogId: string;
  categoryId: string;
  localeId: string;
  storeId: string;
  nodeId: string;
}

export interface LoadAllChildCategoriesResponseAction {
  payload: GetAllChildCategoriesResponsePayLoad;
  type: string;
}
export interface GetAllChildCategoriesResponsePayLoad {
  count: number;
  total: number;
  results: ChildCategoriesData[];
  catalogId: string;
  storeId: string;
  nodeId: string;
}

export interface ChildCategoriesData {
  categoryId: string;
  code: string;
  name: string;
  isPublished: boolean;
  parentId: string;
  hasSubcategories: boolean;
  hasProducts: boolean;
  productCount: number;
  childCategoryCount: number;
}

export interface AddProductsToCategoryPayload {
  selectedIds?: string[];
  products: string[];
  categoryId?: string;
  catalogId?: string;
  storeId?: string;
  localeCode: string;
  productCategories?: ProductCategoryNode;
  categoryIds?: { storeId: string; catalogId: string; categoryId?: string }[];
}

export interface AddProductsToCategoryAction {
  payload: AddProductsToCategoryPayload;
  type: string;
}

export interface AddProductByCategoriesIdSuccessAndErrorResponses {
  success: AddProductByCategoriesApiResult[];
  errors: AddProductByCategoriesApiResult[];
}
export interface AddProductByCategoriesApiResult {
  type: string;
  productId: string;
  categoryId: string;
  errorMessage?: string;
}
