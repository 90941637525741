import {
  LoadCatalogListAction,
  LoadAllCatalogsAction,
  LOAD_CATALOG_LIST,
  SetCurrentCatalogIdAction,
  SET_CATALOG_ID,
  LOAD_ALL_CATALOGS,
  LOAD_PROGRESS,
} from "./CatalogTypes";

export const LoadCatalogList = (storeId: string): LoadCatalogListAction => {
  return {
    type: LOAD_CATALOG_LIST,
    payload: {
      storeId,
    },
  };
};

export const LoadAllCatalogs = (storeId: string): LoadAllCatalogsAction => {
  return {
    payload: {
      storeId,
    },
    type: LOAD_ALL_CATALOGS,
  };
};

export const setCurrentCatalogId = (
  catalogId: string,
): SetCurrentCatalogIdAction => {
  return {
    type: SET_CATALOG_ID,
    payload: {
      catalogId,
    },
  };
};

export const setProgressing = () => {
  return {
    type: LOAD_PROGRESS,
  };
};
