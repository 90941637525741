export const FETCH_PRODUCT_ALERTS_LIST = {
  REQUEST: "FETCH_PRODUCT_ALERTS_LIST_REQUEST",
  SUCCESS: "FETCH_PRODUCT_ALERTS_LIST_SUCCESS",
  FAILURE: "FETCH_PRODUCT_ALERTS_LIST_FAILURE",
};

export interface SKU {
  storeId: string;
  productId: string;
  SKU: string;
  status: string;
  currentStock: Number;
  previousStock: Number;
}

export interface productAlertsList {
  accountId: string;
  watcher: string;
  jobStartTime: string;
  SKUs: SKU[];
}

export interface loadProductAlertsListAction {
  type: typeof FETCH_PRODUCT_ALERTS_LIST.REQUEST;
}

export interface loadProductAlertsListPayload {
  payload: productAlertsListPayload;
  type: typeof FETCH_PRODUCT_ALERTS_LIST;
}

export interface productAlertsListPayload {
  results: productAlertsList[];
}

export enum ProductStatus {
  O = "Out of stock",
  L = "Low stock",
  B = "Back in stock",
}
