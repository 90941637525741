import { LoadProductDetailsResponseAction } from "../product/ProductTypes";

export const SET_REPORT_TYPE = "SET_REPORT_TYPE";
export const SET_REPORT_STORE_ID = "SET_REPORT_STORE_ID";
export const SET_REPORT_CATALOG_ID = "SET_REPORT_CATALOG_ID";
export const SET_REPORT_CATEGORIES = "SET_REPORT_CATEGORIES";
export const SET_REPORT_FIELDS = "SET_REPORT_FIELDS";
export const SET_REPORT_DATERANGE = "SET_REPORT_DATERANGE";
export const RESET_REPORT_STATE = "RESET_REPORT_STATE";

export type ReportReducersActions =
  | SetReportTypeAction
  | SetReportStoreIdAction
  | SetReportCatalogIdAction
  | SetReportCategoriesAction
  | LoadProductDetailsResponseAction
  | ResetReportAction
  | SetReportFieldsAction
  | SetReportDateRangeAction;

export interface ResetReportAction {
  type: typeof RESET_REPORT_STATE;
}

export interface SetReportTypeAction {
  payload: ReportPayload;
  type: string;
}

export interface SetReportStoreIdAction {
  payload: ReportPayload;
  type: string;
}

export interface SetReportCatalogIdAction {
  payload: ReportPayload;
  type: string;
}

export interface SetReportCategoriesAction {
  payload: ReportPayload;
  type: string;
}

export interface SetReportFieldsAction {
  payload: ReportPayload;
  type: string;
}

export interface SetReportDateRangeAction {
  payload: ReportPayload;
  type: string;
}

interface ReportPayload {
  type?: string;
  storeId?: string;
  catalogId?: string;
  categories?: string[];
  fields?: string[];
  dateRange?: {
    startDate: string;
    endDate: string;
  };
}
