import {
  UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO,
  FETCH_PRODUCT_ALERTS_SCHEDULE_DATA,
  ProductAlertsSchedulePayload,
  loadProductAlertsScheduleData,
  DELETE_PRODUCT_ALERTS_SCHEDULE_INFO,
  UpdateProductAlertsAction,
} from "./ProductAlertsScheduleType";

export const loadProductAlertsSchedule = (): loadProductAlertsScheduleData => {
  return {
    type: FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.REQUEST,
  };
};

export const scheduleProductAlertsJob = (
  payload: ProductAlertsSchedulePayload,
): UpdateProductAlertsAction => {
  return {
    type: UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST,
    payload,
  };
};

export const cancelProductAlertsSchedule = () => {
  return {
    type: DELETE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST,
  };
};
