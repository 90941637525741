import {
  LoadChildCategoriesAction,
  LoadTopCategoriesAction,
  LoadAttributesAction,
  LOAD_TOP_CATEGORIES,
  LOAD_CHILD_CATEGORIES,
  LOAD_PARENT_CHILD_CATEGORIES,
  LOAD_PRODUCT_ATTRIBUTES_BY_CATEGORY,
  CLOSE_ALL_CATEGORY_SUBSCRIPTIONS,
  GetAttributesPayLoad,
  SetAdditionalAttributesPayload,
  SetAdditionalAttributesAction,
  SET_ADDITIONAL_ATTRIBUTES,
  SetCurrentCategoryAction,
  SET_CURRENT_CATEGORY,
  LOAD_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY,
  LoadVariantAttributesAndValuesAction,
  LoadTopCategoriesForReportAction,
  LOAD_TOP_CATEGORIES_FOR_REPORT,
  ResetCategory,
  RESET_CATEGORY,
  LoadChildCategoriesForReportAction,
  LOAD_CHILD_CATEGORIES_FOR_REPORT,
  RESET_TOP_CATEGORIES_LOADING_STATE,
  UPDATE_TOP_CATEGORIES_LOADING_STATE,
  UPDATE_CATEGORY_PRODUCT_COUNT,
  UpdateCategoryProductCountPayload,
  LoadTopCategoriesForFindProducts,
  LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
  LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
  LoadChildCategoriesActionForFindProducts,
  SET_CATEGORY_EXPAND,
  RESET_CATEGORY_EXPAND,
  SET_TOP_CATEGORIES_PROGRESS_STATE,
} from "./CategoryTypes";

export const LoadTopCategoriesByCatalogId = (
  catalogId: string,
  localeId: string,
  storeId: string,
): LoadTopCategoriesAction => {
  return {
    type: LOAD_TOP_CATEGORIES,
    payload: {
      catalogId,
      localeId,
      storeId,
    },
  };
};

export const LoadParentChildCategories = (
  catalogId: string,
  categoryId: string,
  localeId: string,
  storeId: string,
): LoadChildCategoriesAction => {
  return {
    type: LOAD_PARENT_CHILD_CATEGORIES,
    payload: {
      catalogId,
      categoryId,
      localeId,
      storeId,
    },
  };
};

export const LoadChildCategories = (
  catalogId: string,
  categoryId: string,
  localeId: string,
  storeId: string,
): LoadChildCategoriesAction => {
  return {
    type: LOAD_CHILD_CATEGORIES,
    payload: {
      catalogId,
      categoryId,
      localeId,
      storeId,
    },
  };
};

export const loadProductAttributesByCategory = (
  payload: GetAttributesPayLoad,
): LoadAttributesAction => {
  return {
    type: LOAD_PRODUCT_ATTRIBUTES_BY_CATEGORY,
    payload: {
      catalogId: payload.catalogId,
      categoryId: payload.categoryId,
      storeId: payload.storeId,
    },
  };
};

export const closeAllCategorySubscriptionsAndResetState = (
  catalog: string,
) => ({
  type: CLOSE_ALL_CATEGORY_SUBSCRIPTIONS,
  payload: {
    catalog,
  },
});

export const setAdditionalAttributes = (
  payload: SetAdditionalAttributesPayload,
): SetAdditionalAttributesAction => {
  return {
    type: SET_ADDITIONAL_ATTRIBUTES,
    payload,
  };
};

export const setCurrentCategory = (
  categoryId: string,
): SetCurrentCategoryAction => {
  return {
    type: SET_CURRENT_CATEGORY,
    payload: {
      categoryId,
    },
  };
};

export const loadVariantAttributesAndValuesByCategory = (payload: {
  categoryId: string;
}): LoadVariantAttributesAndValuesAction => {
  return {
    type: LOAD_VARIANT_ATTRIBUTES_AND_VALUES_BY_CATEGORY,
    payload,
  };
};

export const resetCategory = (): ResetCategory => {
  return {
    type: RESET_CATEGORY,
  };
};

export const LoadTopCategoriesByCatalogIdForReport = (
  catalogId: string,
  storeId: string,
  lId?: string,
): LoadTopCategoriesForReportAction => {
  const localeId = lId || "default";
  return {
    type: LOAD_TOP_CATEGORIES_FOR_REPORT,
    payload: {
      catalogId,
      storeId,
      localeId,
    },
  };
};

export const LoadChildCategoriesForReport = (
  catalogId: string,
  categoryId: string,
  storeId: string,
  lId?: string,
): LoadChildCategoriesForReportAction => {
  const localeId = lId || "default";
  return {
    type: LOAD_CHILD_CATEGORIES_FOR_REPORT,
    payload: {
      catalogId,
      categoryId,
      storeId,
      localeId,
    },
  };
};
export const resetTopCategoriesLoadingState = () => {
  return {
    type: RESET_TOP_CATEGORIES_LOADING_STATE,
  };
};
export const updateTopCategoriesLoadingState = () => {
  return {
    type: UPDATE_TOP_CATEGORIES_LOADING_STATE,
  };
};

export const updateCategoryProductCount = (
  payload: UpdateCategoryProductCountPayload,
) => {
  return {
    payload,
    type: UPDATE_CATEGORY_PRODUCT_COUNT,
  };
};

export const LoadTopCategoriesByCatalogIdForFindProducts = (
  catalogId: string,
  localeId: string,
  storeId: string,
): LoadTopCategoriesForFindProducts => {
  return {
    type: LOAD_TOP_CATEGORIES_FOR_FIND_PRODUCTS,
    payload: {
      catalogId,
      localeId,
      storeId,
    },
  };
};

export const LoadChildCategoriesForFindProducts = (
  catalogId: string,
  categoryId: string,
  localeId: string,
  storeId: string,
): LoadChildCategoriesActionForFindProducts => {
  return {
    type: LOAD_CHILD_CATEGORIES_FOR_FIND_PRODUCTS,
    payload: {
      catalogId,
      categoryId,
      localeId,
      storeId,
    },
  };
};

export const setCategoryExpandState = () => {
  return {
    type: SET_CATEGORY_EXPAND,
  };
};

export const resetCategoryExpandState = () => {
  return {
    type: RESET_CATEGORY_EXPAND,
  };
};

export const setTopCategoriesProgressState = () => {
  return {
    type: SET_TOP_CATEGORIES_PROGRESS_STATE,
  };
};
