import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import {
  selectModalVariable,
  selectIsModalOpen,
} from "../../store/modal/ModalSelectors";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { setModalState } from "../../store/modal/ModalActions";
import {
  selectUniqueLocaleCodes,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import { selectFacetById } from "../../store/facets/FacetSelectors";
import {
  addFacetBucket,
  updateFacetBucketDescription,
  updateFacetBucket,
} from "../../store/facets/FacetActions";
import {
  FacetBucketDescription,
  FacetBucket,
} from "../../store/facets/FacetTypes";
import { useIntl } from "react-intl";
import TableComponent from "../common/Table";
import CustomDialog from "../common/DialogContainer";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    valuesDialog: {
      minWidth: "65vw",
      minHeight: "65vh",
      maxHeight: "75vh",
    },
    thresholdsDialog: {
      minWidth: "45vw",
      minHeight: "60vh",
      maxHeight: "75vh",
    },
    headerRow: {
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
    },
    firstColum: {
      position: "sticky",
      left: 0,
      borderRight: "1px solid rgba(224, 224, 224, 1)",
      zIndex: 3,
    },
    firstCell: {
      zIndex: 4,
    },
    table: {
      maxHeight: "54vh",
      marginBottom: 30,
      "& .MuiTableCell-head": {
        minWidth: "11vw",
      },
      "&::-webkit-scrollbar": {
        webkitAppearance: "none",
        width: 6,
      },
      "&::-webkit-scrollbar:vertical": {
        width: 6,
      },
      "&::-webkit-scrollbar:horizontal": {
        height: 6,
      },
      "&::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: 10,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
        border: "1px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
    textarea: {
      borderRadius: 4,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.875rem",
      padding: "2px 5px",
      width: "100%",
      resize: "none",
      height: 24,
    },
    formControl: {
      margin: "5px 5px 15px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    valueColumn: {
      minWidth: 350,
      maxWidth: 600,
    },
  }),
);

export const editFacetBucketModalId = "editFacetBucketModal";

const EditFacetBucketModal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [newFacetValue, setNewFacetValue] = useState("");
  const [selectedLocale, setSelectedLocale] = useState("");
  const [bucketThreshold, setBucketThreshold] = useState("");

  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, editFacetBucketModalId),
  );
  const facetId = useSelector((state: AppState) =>
    selectModalVariable(state, editFacetBucketModalId, "facetId"),
  );
  const facetBucketId = useSelector((state: AppState) =>
    selectModalVariable(state, editFacetBucketModalId, "facetBucketId"),
  );
  const facetById = useSelector((state: AppState) =>
    selectFacetById(state, facetId),
  );
  const attributeById = facetById?.attribute;
  const currentLocale = useSelector(selectCurrentLocale);
  const dispatch = useDispatch();
  let localeCodes = useSelector(selectUniqueLocaleCodes);

  const handleClose = () => {
    dispatch(setModalState(editFacetBucketModalId, false));
  };

  if (localeCodes.length === 0) {
    const valueLocales = new Set<string>();
    if (facetById) {
      facetById.buckets.forEach((bucket) => {
        if (facetBucketId && facetBucketId !== bucket.facetBucketId) {
          // do nothing
        } else {
          bucket.descriptions.forEach((desc) => {
            valueLocales.add(desc.localeCode);
          });
        }
      });
    }
    if (valueLocales.size > 0) localeCodes = Array.from(valueLocales);
    else if (currentLocale) localeCodes = [currentLocale];
  }

  const keyDownHandler = useCallback(
    (
      value: FacetBucket,
      localeCode: string,
      localeValue: FacetBucketDescription | undefined,
    ) => {
      const trimmedValue = newFacetValue.trim();
      if (trimmedValue !== "") {
        if (!localeValue) {
          const descriptions: FacetBucketDescription[] = [
            {
              displayValue: trimmedValue,
              localeCode: localeCode ? localeCode : "",
            },
          ];

          const payload = {
            facetBucket: {
              facetBucketId: value.facetBucketId,
              facetId,
              sequence: 0,
              descriptions:
                descriptions.length > 0
                  ? {
                      data: descriptions[0],
                      on_conflict: {
                        constraint: "FacetBucketDescription_pkey",
                        update_columns: "displayValue",
                      },
                    }
                  : null,
              values: null,
            },
            facetBucketId: value.facetBucketId,
            localeCode: localeCode ? localeCode : "",
          };
          dispatch(addFacetBucket(payload));
        } else {
          const localeValue = value.descriptions.find(
            (val) => val.localeCode === localeCode,
          );
          if (!(localeValue && localeValue.displayValue === trimmedValue)) {
            const payload = {
              localeCode,
              facetBucketId: value.facetBucketId,
              displayValue: trimmedValue,
            };
            dispatch(updateFacetBucketDescription(payload));
          }
        }
      }
      setNewFacetValue("");
    },
    [newFacetValue, dispatch, facetId],
  );

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewFacetValue(event.target.value.trim());
  };

  const handleLocaleChange = (event: any) => {
    const newLocale = event.target.value as string;
    setSelectedLocale(newLocale);
  };

  useEffect(() => {
    if (!selectedLocale) setSelectedLocale(localeCodes[0]);
  }, [localeCodes, selectedLocale]);

  const bucketKeyDownHandler = useCallback(
    (facetBucket: FacetBucket) => {
      const { facetBucketId, threshold } = facetBucket;
      const trimmedValue = bucketThreshold.trim();
      if (`${threshold}` !== trimmedValue) {
        const payload = {
          facetBucketId,
          threshold: parseInt(trimmedValue),
        };
        dispatch(updateFacetBucket(payload));
      }
    },
    [bucketThreshold, dispatch],
  );

  const bucketChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setBucketThreshold(event.target.value);
  };

  /*const dialogClass =
    facetById && facetById.bucketMode === FACET_BUCKET_MODES.VALUES
      ? classes.valuesDialog
      : classes.thresholdsDialog;*/

  const editFacetBucketModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "editFacetBucketModal.header",
          defaultMessage: "Edit facet bucket for multiple languages",
        })}
      </Typography>
    );
  };

  const editFacetBucketModalContent = () => {
    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel id="facet-bucket-locale-label">
            {intl.formatMessage({
              id: "editFacetBucketModal.localeSelectLabel",
              defaultMessage: "Locale",
            })}
          </InputLabel>
          <DropdownMenu
            menuId="EditFacetBucketModal"
            classes={classes}
            changeHandler={handleLocaleChange}
            localeCodes={localeCodes}
          />
        </FormControl>
        <TableComponent
          tableId="EditFacetBucketModalTable"
          classes={classes}
          facetById={facetById}
          keyDownHandler={keyDownHandler}
          selectedLocale={selectedLocale}
          setNewFacetValue={setNewFacetValue}
          bucketKeyDownHandler={bucketKeyDownHandler}
          setBucketThreshold={setBucketThreshold}
          bucketChangeHandler={bucketChangeHandler}
          changeHandler={changeHandler}
          attributeById={attributeById}
        />
      </>
    );
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={editFacetBucketModalTitle()}
      maxWidth={"sm"}
      fullWidth
    >
      {editFacetBucketModalContent()}
    </CustomDialog>
  );
};

export default React.memo(EditFacetBucketModal);
