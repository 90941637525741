import { ProductSequence, AttrValueSequence } from "./ProductTypes";

export const productsDetailsQuery = `subscription ProductDetail($catalogId: String!, $categoryId: String!, $storeId: String!) {
  CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, product: {type: {isBrowsable: {_eq: true}}}}, order_by: {sequence: asc}) {
    product {
      code,
      productId,
      typeCode,
      isPublished,
      descriptions {
        isPublished,
        name,
        thumbnail,
        localeCode
      }
      overrides(where: {storeId: {_eq: $storeId}}) {
        isPublished
      }
    }
  }
}`;

export const productsDetailsBySearchQuery = `subscription ProductDetail($searchTerm: String, $storeId: String) {
  Product(where: {type: {isBrowsable: {_eq: true}}, _or: [{code: {_ilike: $searchTerm}}, {descriptions: {name: {_ilike: $searchTerm}}}]}) {
    code
    productId
    typeCode
    isPublished
    descriptions {
      isPublished
      name
      thumbnail
      localeCode
    }
    overrides(where: {storeId: {_eq: $storeId}}) {
      isPublished
    }
  }
}
`;

export const productsDetailsForReportQuery = `query ProductDetail($catalogId: String!, $categoryId: String!, $storeId: String!, $fulfillmentCenterId: String!) {
  CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, product: {type: {isBrowsable: {_eq: true}}}}, order_by: {sequence: asc}) {
    product {
      code,
      productId,
      typeCode,
      isPublished,
      descriptions {
        isPublished,
        name,
        thumbnail,
        localeCode
      }
      overrides(where: {storeId: {_eq: $storeId}}) {
        isPublished
      }
      inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
        quantity
      }
      toChildProducts {
        childProduct {
          inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
            quantity
          }
        }
      }
    }
  }
}`;

export const childrenItemsQuery = `subscription ChildrenItems($productIds: [String!]!, $catalogId: String!, $categoryId: String!, $storeId: String!, $fulfillmentCenterId: String!, $priceListId: String!) {
  Product(where: {productId: {_in: $productIds}}) {
    productId
    inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
      quantity
    }
    prices(where: {priceListId: {_eq: $priceListId}}) {
      amount
      currencyCode
    }
    toChildProducts(order_by: {sequence: asc, childProductId: asc}) {
      childProductId
      sequence
      childProduct {
        code
        isPublished
        isDeleted
        descriptions {
          name
          thumbnail
          isPublished
          localeCode
        }
        overrides {
          isPublished
          storeId
        }
        attributeValues(where: {isVariantOption: {_eq: true}, attributeValue: {attribute: {isColor: {_eq: true}}}}) {
          attributeValueId
          attributeValue {
            code
            sequence
            descriptions {
              thumbnail
              displayValue
              sequence
              localeCode
            }
            attribute {
              isColor
              code
              descriptions {
                displayName
                localeCode
              }
              attributeId
            }
          }
        }
        inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
          quantity
        }
        prices(where: {priceListId: {_eq: $priceListId}}) {
          amount
          currencyCode
        }
      }
      typeCode
    }
    leadingColors(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, storeId: {_eq: $storeId}}) {
      attributeValueId
      catalogId
      categoryId
      productId
      storeId
    }
  }
}`;

export const childrenItemsBySearchQuery = `subscription ChildrenItems($productIds: [String!]!, $fulfillmentCenterId: String!, $priceListId: String!) {
  Product(where: {productId: {_in: $productIds}}) {
    productId
    inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
      quantity
    }
    prices(where: {priceListId: {_eq: $priceListId}}) {
      amount
      currencyCode
    }
    toChildProducts(order_by: {sequence: asc, childProductId: asc}) {
      childProductId
      sequence
      childProduct {
        code
        isPublished
        isDeleted
        descriptions {
          name
          thumbnail
          isPublished
          localeCode
        }
        overrides {
          isPublished
          storeId
        }
        attributeValues(where: {isVariantOption: {_eq: true}, attributeValue: {attribute: {isColor: {_eq: true}}}}) {
          attributeValueId
          attributeValue {
            code
            sequence
            descriptions {
              thumbnail
              displayValue
              sequence
              localeCode
            }
            attribute {
              isColor
              code
              descriptions {
                displayName
                localeCode
              }
              attributeId
            }
          }
        }
        inventories(where: {fulfillmentCenterId: {_eq: $fulfillmentCenterId}}) {
          quantity
        }
        prices(where: {priceListId: {_eq: $priceListId}}) {
          amount
          currencyCode
        }
      }
      typeCode
    }
  }
}`;

export const childProductVariantAttributesByParentIdQuery = `subscription ChildProductVariantAttributesByParentId($productId: String!) {
  Product(where: {productId: {_eq: $productId}}) {
    productId
    toChildProducts(order_by: {sequence: asc, childProductId: asc}) {
      childProductId
      childProduct {
        attributeValues(where: {isVariantOption: {_eq: true}, attributeValue: {attribute: {isColor: {_eq: false}}}}) {
          attributeValueId
          attributeValue {
            code
            sequence
            descriptions {
              thumbnail
              displayValue
              sequence
              localeCode
            }
            attribute {
              isColor
              code
              descriptions {
                displayName
                localeCode
              }
              attributeId
            }
          }
        }
      }
    }
  }
}`;

export const childProductVariantAttributesByParentIdsQuery = `subscription ChildProductVariantAttributesByParentIds($productIds: [String!]!, $attributeCode: String!) {
  Product(where: {productId: {_in: $productIds}}) {
    productId
    toChildProducts(order_by: {sequence: asc, childProductId: asc}) {
      childProductId
      childProduct {
        attributeValues(where: {isVariantOption: {_eq: true}, attributeValue: {attribute: {isColor: {_eq: false}, code: {_eq: $attributeCode}}}}) {
          attributeValueId
          attributeValue {
            code
            sequence
            descriptions {
              thumbnail
              displayValue
              sequence
              localeCode
            }
            attribute {
              isColor
              code
              descriptions {
                displayName
                localeCode
              }
              attributeId
            }
          }
        }
      }
    }
  }
}`;

export const inventoryOverlayAttributesQuery = `subscription InventoryOverlayAttributes($productIds: [String!]!) {
  Attribute(where: {productAttributes: {isVariantOption: {_eq: true}, productId: {_in: $productIds}}, isColor: {_eq: false}}) {
    attributeId
    code
  }
}`;

export const productAttributeValuesQuery = `subscription ProductAttributeValues($catalogId: String!, $categoryId: String!, $localeCode: String!, $storeId: String!) {
  CategoryToProduct(where: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}, product: {type: {isBrowsable: {_eq: true}}}}, order_by: {sequence: asc}) {
    product {
      code
      productId
      attributeValues {
        attributeValueId
        attributeValue {
          attributeId
        }
      }
      attributeValueOverrides(where: {storeId: {_eq: $storeId}}) {
        attributeValueId
        attributeValue {
          attributeId
        }
      }
      attributeLocalizedValues(where: {localeCode: {_eq: $localeCode}}) {
        attributeValueId
        attributeValue {
          attributeId
        }
      }
    }
  }
}`;

export const updateProductPublishStateQuery = `mutation UpdateProductPublish($publishedProductIds: [String!], $unpublishedProductIds: [String!], $syncLogInserts: [SyncLog_insert_input!]!) {
  publish: update_Product(_set: {isPublished: true}, where: {productId: {_in: $publishedProductIds}}) {
    affected_rows
  }
  unpublish: update_Product(_set: {isPublished: false}, where: {productId: {_in: $unpublishedProductIds}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductPublishedByDefaultStateQuery = `mutation UpdateProductPublish($productId: String!, $isPublishedByDefault:Boolean!, $syncLogInserts: [SyncLog_insert_input!]!) {
   update_Product(_set: {isPublished: $isPublishedByDefault}, where: {productId: {_eq: $productId}}) {
    affected_rows
  }
  
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductPublishedStateByStoreQuery = `mutation UpdateProductPublish($productId: String!, $isPublishedByStore: Boolean, $storeId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_ProductOverride(objects: {isPublished: $isPublishedByStore, storeId: $storeId, productId: $productId}, on_conflict: {constraint: ProductOverride_pkey, update_columns: isPublished, where: {productId: {_eq: $productId}}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductDeleteStateQuery = `mutation UpdateProductDelete($isDeleted: Boolean!, $productIds: [String!], $syncLogInserts: [SyncLog_insert_input!]!) {
  update_Product(_set: {isDeleted: $isDeleted}, where: {productId: {_in: $productIds}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateSingleProductDeleteStateQuery = `mutation UpdateProductDelete($isDeleted: Boolean!, $productId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_Product(_set: {isDeleted: $isDeleted}, where: {productId: {_eq: $productId}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductToProductSequenceQeuryGenerator = (
  productSequences: ProductSequence[],
) => `mutation UpdateProductToProductSequence($parentProductId: String, $syncLogInserts: [SyncLog_insert_input!]!) {
  ${productSequences.map(
    (
      productSequence,
      index,
    ) => `product${index} : update_ProductToProduct(where: {childProductId: {_eq: "${productSequence.productId}"}, parentProductId: {_eq: $parentProductId}}, _set: {sequence: ${productSequence.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateLocaleProductPublishQuery = `mutation UpdateLocaleProductPublish($publishedProductIds: [String!], $unpublishedProductIds: [String!], $localeCodes: [String!], $syncLogInserts: [SyncLog_insert_input!]!) {
publish:  update_ProductDescription(_set: {isPublished: true},where: {productId: { _in: $publishedProductIds}, localeCode: {_in: $localeCodes}}) {
    affected_rows
    returning {
      product {
        code
      }
    }
  }

unpublish:  update_ProductDescription(_set: {isPublished: false},where: {productId: { _in: $unpublishedProductIds}, localeCode: {_in: $localeCodes}}) {
    affected_rows
    returning {
      product {
        code
      }
    }
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductPublishStateSFCCQuery = (
  isPublishedDefault: boolean | null,
  isPublishedOverride: boolean,
) => `mutation updateProductPublishState(
  $productIds: [String!],
  $productsOverride:[ProductOverride_insert_input!]!,
  $syncLogProductOverrideInserts: [SyncLog_insert_input!]!,
  $syncLogInserts: [SyncLog_insert_input!]!,
  ) {
${
  isPublishedOverride
    ? `insert_ProductOverride(
  objects: $productsOverride, 
  on_conflict: {update_columns: [isPublished], 
  constraint: ProductOverride_pkey}) {
    affected_rows
    returning {
      productId
    }
  }
  
  SyncLogProductOverride: insert_SyncLog(objects: $syncLogProductOverrideInserts) {
    affected_rows
  }
  
  `
    : ""
}
  
  ${
    isPublishedDefault !== null
      ? `update_Product(where: {productId: {_in: $productIds}}, _set: {isPublished: ${isPublishedDefault}}) {
    affected_rows
    returning {
      code
    }
  }

  SyncLogProduct : insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
  `
      : ""
  }
}`;
export const updateAttrValueSequenceQueryGenerator = (
  attrValueSequences: AttrValueSequence[],
) => `mutation UpdateProductAttrValueSequence($syncLogInserts: [SyncLog_insert_input!]!) {
  ${attrValueSequences.map(
    (
      attrVal,
      index,
    ) => `attrVal${index} : update_AttributeValue(where: {attributeValueId: {_eq: "${attrVal.attributeValueId}"}}, _set: {sequence: ${attrVal.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateAttrValueDescriptionSequenceQueryGenerator = (
  attrValueSequences: AttrValueSequence[],
) => `mutation UpdateProductAttrDescriptionValueSequence($localeCodes: [String!], $syncLogInserts: [SyncLog_insert_input!]!) {
  ${attrValueSequences.map(
    (
      attrVal,
      index,
    ) => `attrVal${index} : update_AttributeValueDescription(where: {attributeValueId: {_eq: "${attrVal.attributeValueId}"}, localeCode: {_in: $localeCodes}}, _set: {sequence: ${attrVal.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const insertProductLeadingColorQuery = `mutation InsertLeadingColor($storeId: String, $catalogId: String, $categoryId: String, $productId: String, $attributeValueId: String, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_ProductLeadingColor(objects: {attributeValueId: $attributeValueId, catalogId: $catalogId, categoryId: $categoryId, productId: $productId, storeId: $storeId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductLeadingColorQuery = `mutation UpdateLeadingColor($catalogId: String!, $storeId: String!, $productId: String!, $categoryId: String!, $attributeValueId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_ProductLeadingColor_by_pk(pk_columns: {catalogId: $catalogId, productId: $productId, storeId: $storeId, categoryId: $categoryId}, _set: {attributeValueId: $attributeValueId}) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteProductLeadingColorQuery = `mutation DeleteLeadingColor($catalogId: String!, $storeId: String!, $productId: String!, $categoryId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_ProductLeadingColor_by_pk(catalogId: $catalogId, productId: $productId, storeId: $storeId, categoryId: $categoryId) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const loadCategoryByProductIdQueryGenerator = (
  storeId: string | undefined,
) => `subscription LoadCategoryByProductId($catalogId: String!, ${
  storeId ? "$storeId: String!" : ""
}, $productIds: [String!], $localeCode: String!) {
  Product(where: {productId: {_in: $productIds}, ${
    storeId ? "toStores: {storeId: {_eq: $storeId}}" : ""
  }}) {
    productId
    toCategories(where: {catalogId: {_eq: $catalogId}}) {
      categoryId
      category {
        code
        descriptions {
          name
          localeCode
        }
      }
    }
  }
}`;

export const insertProductAttributeQuery = `mutation InsertProductAttribute($newAttributeValueId: String!, $productId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_ProductAttributeValue(objects: {productId: $productId, attributeValueId: $newAttributeValueId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteProductAttributeQuery = `mutation DeleteProductAttribute($oldAttributeValueId: String!, $productId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_ProductAttributeValue(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductAttributeQuery = `mutation UpdateProductAttribute($oldAttributeValueId: String!, $newAttributeValueId: String!, $productId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_ProductAttributeValue(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}}, _set: {attributeValueId: $newAttributeValueId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const insertProductLocalizedAttributeQuery = `mutation InsertProductLocalizedAttribute($newAttributeValueId: String!, $productId: String!, $localeCode: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_ProductAttributeLocalizedValue(objects: {productId: $productId, attributeValueId: $newAttributeValueId, localeCode: $localeCode}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteProductLocalizedAttributeQuery = `mutation DeleteProductLocalizedAttribute($oldAttributeValueId: String!, $productId: String!, $localeCode: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_ProductAttributeLocalizedValue(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}, localeCode: {_eq: $localeCode}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductLocalizedAttributeQuery = `mutation UpdateProductLocalizedAttribute($oldAttributeValueId: String!, $newAttributeValueId: String!, $productId: String!, $localeCode: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_ProductAttributeLocalizedValue(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}, localeCode: {_eq: $localeCode}}, _set: {attributeValueId: $newAttributeValueId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const insertProductAttributeOverrideQuery = `mutation InsertProductAttributeOverride($newAttributeValueId: String!, $productId: String!, $storeId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_ProductAttributeValueOverride(objects: {productId: $productId, attributeValueId: $newAttributeValueId, storeId: $storeId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteProductAttributeOverrideQuery = `mutation DeleteProductAttributeOverride($oldAttributeValueId: String!, $productId: String!, $storeId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_ProductAttributeValueOverride(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}, storeId: {_eq: $storeId}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateProductAttributeOverrideQuery = `mutation UpdateProductAttributeOverride($oldAttributeValueId: String!, $newAttributeValueId: String!, $productId: String!, $storeId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_ProductAttributeValueOverride(where: {productId: {_eq: $productId}, attributeValueId: {_eq: $oldAttributeValueId}, storeId: {_eq: $storeId}}, _set: {attributeValueId: $newAttributeValueId}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

//Queries

export const checkValidationCodesQuery = `query checkValidationProductCodes($productCodes: [String!]) {
  Product(where: {code: {_in: $productCodes}}) {
    code
  }
}`;

export const fetchChildProductAttributesQuery = `subscription FetchChildProductAttributes($productIds: [String!]!, $storeId: String!, $localeCode: String!) {
  Product(where: {productId: {_in: $productIds}}) {
    code
    productId
    toParentProducts {
      parentProductId
    }
    attributeValues {
      attributeValueId
      attributeValue {
        attributeId
      }
      isVariantOption
    }
    attributeValueOverrides(where: {storeId: {_eq: $storeId}}) {
      attributeValueId
      attributeValue {
        attributeId
      }
      isVariantOption
    }
    attributeLocalizedValues(where: {localeCode: {_eq: $localeCode}}) {
      attributeValueId
      attributeValue {
        attributeId
      }
      isVariantOption
    }
  }
}`;
