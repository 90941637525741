import * as Yup from "yup";
import { useIntl } from "react-intl";

export function useUtils() {
  const intl = useIntl();
  const requiredText = intl.formatMessage({
    id: "unifiedSortingRuleModal.required",
    defaultMessage: "Required",
  });
  const minimumPointsText = intl.formatMessage({
    id: "unifiedSortingRuleModal.minimumPoints",
    defaultMessage: "Minimum points should be 1",
  });
  const minimumValueText = intl.formatMessage({
    id: "unifiedSortingRuleModal.minimumValue",
    defaultMessage: "Minimum value should be 1",
  });
  const maximumValueText = intl.formatMessage({
    id: "unifiedSortingRuleModal.maximumValue",
    defaultMessage: "Maximum value should be 100",
  });

  enum SortingCriteria {
    Attribute = "AS",
    Metric = "WS",
    Multi = "MCS",
  }

  enum SortingValues {
    AS = "Attributes",
    WS = "Metrics",
  }

  const INITIAL_STATE = {
    name: "",
    description: "",
    scoringFormula: "",
    attributes: [
      {
        attr: "",
        value: "",
        points: "",
      },
    ],
    metrics: [
      {
        metric: "",
        weight: "",
        order: "",
      },
    ],
  } as const;

  const FORM_VALIDATION_ATTRIBUTES = Yup.object().shape({
    name: Yup.string().required(requiredText),
    scoringFormula: Yup.string().required(requiredText),
    attributes: Yup.array().of(
      Yup.object().shape({
        attr: Yup.string().required(requiredText),
        value: Yup.string().required(requiredText),
        points: Yup.number().required(requiredText).min(1, minimumPointsText),
      }),
    ),
  });
  const FORM_VALIDATION_METRICS = Yup.object().shape({
    name: Yup.string().required(requiredText),
    metrics: Yup.array().of(
      Yup.object().shape({
        metric: Yup.string().required(requiredText),
        weight: Yup.number()
          .required(requiredText)
          .min(1, minimumValueText)
          .max(100, maximumValueText),
        order: Yup.string().required(requiredText),
      }),
    ),
  });
  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(requiredText),
    scoringFormula: Yup.string().required(requiredText),
    attributes: Yup.array().of(
      Yup.object().shape({
        attr: Yup.string().required(requiredText),
        value: Yup.string().required(requiredText),
        points: Yup.number().required(requiredText).min(1, minimumPointsText),
      }),
    ),
    metrics: Yup.array().of(
      Yup.object().shape({
        metric: Yup.string().required(requiredText),
        weight: Yup.number()
          .required(requiredText)
          .min(1, minimumValueText)
          .max(100, maximumValueText),
        order: Yup.string().required(requiredText),
      }),
    ),
  });
  const FORM_VALIDATION_EMPTY = Yup.object().shape({
    sortingCriteria: Yup.string().required(requiredText),
    name: Yup.string().required(requiredText),
    scoringFormula: Yup.string().required(requiredText),
  });

  return {
    FORM_VALIDATION_ATTRIBUTES,
    FORM_VALIDATION_METRICS,
    FORM_VALIDATION,
    FORM_VALIDATION_EMPTY,
    INITIAL_STATE,
    SortingCriteria,
    SortingValues,
  };
}
