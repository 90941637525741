import { Category } from "../store/category/CategorySelectors";
import {
  CategorytoProductsAggregate,
  categoryToChildCategoriesAggregate,
  Translations,
} from "../store/category/CategoryTypes";
import { TreeNode } from "../components/tree-view/TreeView";

export interface FindProductsCategory {
  id: string;
  code: string;
  name: string;
  translations: Translations[];
  productCount: number;
  isPublished: boolean;
  childCategoryCount: number;
  children?: FindProductsCategory[];
}

export interface CategoryData {
  categoryId: string;
  childCategoryCount: number;
  name: string;
  isPublished: boolean;
}

export const getCategoryNameSFCC = (
  category: Category,
  localeCode: string,
  defaultLocaleCode: string,
): string => {
  const translation = category.translations.find(
    (desc) =>
      desc.localeCode === localeCode || desc.localeCode === defaultLocaleCode,
  );
  return translation?.name || category.name || category.code || category.id;
};

export const isCategoryPublished = (
  category: Category,
  localeCode: string,
): boolean => {
  const description = category.translations.find(
    (desc) => desc.localeCode === localeCode,
  );
  return description ? description.isPublished : false;
};

export const getCategoryTooltip = (category: Category | TreeNode): string => {
  return category?.name ?? category?.code ?? category?.id;
};

export const isCategoryPublishedSFCC = (
  category:
    | Category
    | CategorytoProductsAggregate
    | categoryToChildCategoriesAggregate
    | FindProductsCategory,
): boolean => {
  return category.isPublished;
};
