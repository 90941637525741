export const SET_CLIPBOARD_PRODUCT_CODES = "SET_CLIPBOARD_PRODUCT_CODES";

export interface SetClipBoardProductCodesPayload {
  productCodes: string[];
}

export interface SetClipBoardProductCodesAction {
  payload: SetClipBoardProductCodesPayload;
  type: typeof SET_CLIPBOARD_PRODUCT_CODES;
}

export const ADD_CLIPBOARD_PRODUCT_CODES = "ADD_CLIPBOARD_PRODUCT_CODES";
export const VERIFY_CLIPBOARD_PRODUCT_CODES = {
  REQUEST: "VERIFY_CLIPBOARD_PRODUCT_CODES_REQUEST",
  SUCCESS: "VERIFY_CLIPBOARD_PRODUCT_CODES_SUCCESS",
  FAILURE: "VERIFY_CLIPBOARD_PRODUCT_CODES_FAILURE",
  COMPLETE: "VERIFY_CLIPBOARD_PRODUCT_CODES_COMPLETE",
};

export interface AddClipBoardProductCodesPayload {
  productCodes: string[];
}

export interface AddClipBoardProductCodesAction {
  payload: AddClipBoardProductCodesPayload;
  type: typeof ADD_CLIPBOARD_PRODUCT_CODES;
}

export const CLEAR_CLIPBOARD = "CLEAR_CLIPBOARD";

export interface ClearClipBoard {
  type: typeof CLEAR_CLIPBOARD;
}
