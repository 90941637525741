import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import ButtonComponent from "../../components/common/ButtonComponent";
import { Typography } from "@mui/material";
import {
  componentClasses,
  StyledBox,
  StyledDiv,
  StyledTypography,
} from "./VariationManagementModalStyles";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { CloseModal, setModalState } from "../../store/modal/ModalActions";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
  selectStoreListIds,
} from "../../store/store-list/StoreListSelectors";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import { selectModalVariable } from "../../store/modal/ModalSelectors";
import {
  selectAllProductsFetched,
  selectProductFetched,
} from "../../store/product/ProductSelectors";
import VariationProduct from "./VariationProduct";
import {
  getVariationAttributes,
  getVariationGroupId,
  loadVariationProductsList,
  resetVariationDetailsByGroupId,
  resetVariationGroupIds,
  resetVariationGroupProductList,
} from "../../store/variation-management/VariationManagementActions";
import {
  selectVariationGroupIds,
  selectIsGroupIdsFetched,
  selectVariationProductList,
} from "../../store/variation-management/VariationManagementSelector";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { Product } from "../../store/product/ProductTypes";
import ConfirmationActionVariation from "./ConfirmationActionVariation";
import CustomDialog from "../common/DialogContainer";
import { VariationForm } from "./VariationForm";

export const VariationManagementModalId = "VariationManagementModal";

interface Props {
  categoryId: string;
}

const VariationManagement: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [isAddForm, setIsAddForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [isVariationFormOpen, setIsVariationFormOpen] = useState(false);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const [isCancelAction, setIsCancelAction] = useState(true);
  const [variationGroupId, setVariationGroupId] = useState("");
  const dispatch = useDispatch();
  const storeListIds = useSelector(selectStoreListIds);
  const [isCategoryChecked, setisCategoryChecked] = useState(true);
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, VariationManagementModalId, "variationBaseId"),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const vGroupIds = useSelector((state: AppState) =>
    selectVariationGroupIds(state),
  );
  const isGroupIdsFetched = useSelector((state: AppState) =>
    selectIsGroupIdsFetched(state),
  );
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, VariationManagementModalId),
  );
  const selectedOverlay = useSelector((state: AppState) =>
    selectOverlay(state),
  );
  const baseProductId = productId ?? "";
  const categoryId = useSelector(selectCurrentCategory);
  const localeCode = useSelector(selectCurrentLocale);
  const catalogId = useSelector(selectCurrentCatalogId);
  const currentStoreId = useSelector(selectCurrentStoreId);
  const variationGroupProductList = useSelector(selectVariationProductList);
  const products = useSelector(selectAllProductsFetched);

  useEffect(() => {
    const isProductChecked = (productId) => {
      return Object.keys(products).includes(productId);
    };
    // Check if any product in variationGroupProductList is checked
    const isAnyProductChecked = variationGroupProductList.some((product) => {
      return isProductChecked(product.productId);
    });
    setisCategoryChecked(isAnyProductChecked);
  }, [variationGroupProductList, products]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getVariationAttributes({ baseProductId }));
      dispatch(getVariationGroupId({ baseProductId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    if (vGroupIds && vGroupIds.length) {
      const productIds = [...vGroupIds, baseProductId];
      const storeId = currentStoreId;
      const payload = {
        catalogId,
        categoryId,
        localeCode,
        storeId: storeId as string,
        productIds,
        storeListIds,
      };
      dispatch(loadVariationProductsList(payload));
    } else if (variationGroupProductList.length === 0 && isModalOpen) {
      const payload = {
        catalogId,
        categoryId,
        localeCode,
        storeId: currentStoreId as string,
        productIds: [baseProductId],
        storeListIds,
      };
      dispatch(loadVariationProductsList(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vGroupIds, isModalOpen]);

  const handleClose = (checked = false) => {
    if (checked ? !checked : !isCategoryChecked) {
      dispatch(setModalState("ConfirmationActionVariation", true));
    } else {
      dispatch(setModalState("ConfirmationActionVariation", false));
      setIsEditForm(false);
      setIsVariationFormOpen(false);
      setIsAddForm(false);
      dispatch(setModalState(VariationManagementModalId, false));
      dispatch(CloseModal({ productId: productId }));
      dispatch(resetVariationGroupIds());
      dispatch(resetVariationDetailsByGroupId());
      dispatch(resetVariationGroupProductList());
      setVariationGroupId("");
    }
  };

  const getVariationBaseProduct = useMemo(() => {
    return variationGroupProductList.length
      ? (variationGroupProductList.find(
          (product) => product.productId === baseProductId,
        )?.product as Product)
      : null;
  }, [baseProductId, variationGroupProductList]);

  const showVariationProducts = useMemo(() => {
    return (
      vGroupIds.length > 0 &&
      variationGroupProductList.length &&
      variationGroupProductList.length > 1
    );
  }, [variationGroupProductList, vGroupIds]);

  const renderVariationProductList = useMemo(() => {
    return variationGroupProductList
      .filter((product) => product.productId !== baseProductId)
      .map((product, index) => (
        <div key={index}>
          <VariationProduct
            product={product.product as any}
            variationGroupId={product.productId ?? ""}
            baseProductId={baseProductId}
            selectedOverlay={selectedOverlay}
            isColumn={true}
            isEditIcon={true}
            setEditForm={setIsEditForm}
            setCancelClick={setIsCancelAction}
            setVariationGroup={setVariationGroupId}
            setAddForm={setIsAddForm}
            categoryId={props.categoryId}
            storeId={currentStoreId ?? ""}
            isPublished={product?.product?.isPublished}
            height={"100%"}
            productName={fetchedProduct ? fetchedProduct.name : ""}
          />
        </div>
      ));
  }, [
    variationGroupProductList,
    baseProductId,
    currentStoreId,
    props.categoryId,
    selectedOverlay,
    fetchedProduct,
  ]);

  const variationMgmtModalTitle = () => {
    return (
      <Typography variant="inherit">
        Variation Group Mgmt {fetchedProduct ? ` - ${fetchedProduct.name}` : ""}
      </Typography>
    );
  };
  const mainSectionClassName =
    isGroupIdsFetched && vGroupIds.length !== 0
      ? componentClasses.mainSection
      : "";
  const variationMgmtModalContent = () => {
    return (
      <div>
        {(!isGroupIdsFetched ||
          (variationGroupProductList.length === 0 && vGroupIds.length > 0)) && (
          <StyledDiv className={componentClasses.overlay}>
            <CircularProgress />
          </StyledDiv>
        )}
        <StyledDiv className={componentClasses.paper}>
          {variationGroupProductList.length ? (
            <>
              <StyledDiv className={componentClasses.productTiles}>
                <StyledDiv className={componentClasses.leftSection}>
                  <StyledTypography
                    variant="inherit"
                    className={componentClasses.headLine}
                  >
                    Variation Base
                  </StyledTypography>
                  <VariationProduct
                    product={getVariationBaseProduct}
                    code={getVariationBaseProduct?.code}
                    selectedOverlay={selectedOverlay}
                    isColumn={false}
                    isEditIcon={false}
                    setEditForm={setIsEditForm}
                    setCancelClick={setIsCancelAction}
                    categoryId={props.categoryId}
                    storeId={currentStoreId ?? ""}
                    isPublished={getVariationBaseProduct?.isPublished}
                    productName={fetchedProduct ? fetchedProduct.name : ""}
                  />
                </StyledDiv>
              </StyledDiv>
              <StyledDiv className={componentClasses.mainContent}>
                <StyledBox className={mainSectionClassName}>
                  {showVariationProducts ? (
                    renderVariationProductList
                  ) : (
                    <div>
                      {isGroupIdsFetched && vGroupIds.length === 0 && (
                        <StyledBox className={componentClasses.idNotFoundText}>
                          <Typography
                            variant="h5"
                            style={{ textAlign: "center" }}
                          >
                            {intl.formatMessage({
                              id: "variationManagement.noVariationGroupsFoundText",
                              defaultMessage: "No variation groups found",
                            })}
                          </Typography>
                        </StyledBox>
                      )}
                    </div>
                  )}
                </StyledBox>
              </StyledDiv>
              <StyledDiv className={componentClasses.rightSection}>
                <VariationForm
                  isCancelAction={isCancelAction}
                  isVariationFormOpen={isVariationFormOpen}
                  isEditForm={isEditForm}
                  isDeleteAction={isDeleteAction}
                  isAddForm={isAddForm}
                  setIsDeleteAction={setIsDeleteAction}
                  setIsEditForm={setIsEditForm}
                  setIsVariationFormOpen={setIsVariationFormOpen}
                  setIsCancelAction={setIsCancelAction}
                  setIsAddForm={setIsAddForm}
                  setVariationGroupId={setVariationGroupId}
                  variationGroupId={variationGroupId}
                />
                <StyledBox className={componentClasses.actiondiv}>
                  <ButtonComponent
                    color="primary"
                    variant="contained"
                    disabled={variationGroupProductList.length === 0} // Disable when array is empty
                    onClick={() => handleClose()}
                  >
                    {intl.formatMessage({
                      id: "general.close",
                      defaultMessage: "Close",
                    })}
                  </ButtonComponent>
                </StyledBox>
              </StyledDiv>
            </>
          ) : null}

          <ConfirmationActionVariation handleClose={handleClose} />
        </StyledDiv>
      </div>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={variationMgmtModalTitle()}
      fullWidth
      maxWidth={"lg"}
      onClick={(e) => e.preventDefault()}
    >
      {variationMgmtModalContent()}
    </CustomDialog>
  );
};

export default React.memo(VariationManagement);
