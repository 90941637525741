import React, { useState, useCallback, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { AttributeData } from "../../store/category/CategoryTypes";
import { FacetBucket } from "../../store/facets/FacetTypes";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  addFacetBucketValue,
  deleteFacetBucketValue,
} from "../../store/facets/FacetActions";

const useStyles = makeStyles({
  textField: {
    "& .MuiInputLabel-root": {
      fontSize: "0.875rem",
    },
    "& .MuiInput-root": {
      fontSize: "0.875rem",
    },
    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottomColor: "#000",
    },
    "& .MuiChip-root": {
      backgroundColor: "#fff",
    },
  },
  options: {
    textAlign: "left",
    fontSize: "0.75rem",
  },
});

interface Props {
  bucket: FacetBucket;
  attribute: AttributeData | undefined | null;
}

const FacetBucketValueAutocomplete = ({ bucket, attribute }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [bucketValues, setBucketValues] = useState<string[]>([]);
  const bucketId = bucket.facetBucketId;

  useEffect(() => {
    const processedValues: string[] = [];
    bucket.values.forEach((value) => {
      processedValues.push(value.bucketValue.split(":")[1]);
    });
    setBucketValues(processedValues);
  }, [bucket]);

  const handleMultipleValuesChange = useCallback(
    (values: string[]) => {
      const deletedValues: string[] = bucketValues.filter(
        (bucketValue) => !values.includes(bucketValue),
      );
      const addedValues: string[] = values.filter(
        (value) => !bucketValues.includes(value),
      );
      const payload = {
        facetBucketId: bucketId,
        bucketValues: addedValues,
        attributeId: attribute ? attribute.attributeId : "",
      };
      if (addedValues.length > 0) dispatch(addFacetBucketValue(payload));
      if (deletedValues.length > 0) {
        payload.bucketValues = deletedValues;
        dispatch(deleteFacetBucketValue(payload));
      }
      setBucketValues(values);
    },
    [bucketValues, bucketId, attribute, dispatch],
  );

  return (
    <Autocomplete
      multiple
      id={`automcomplete-${bucketId}`}
      options={
        attribute
          ? attribute.values.map(
              (value) => value.attributeValueId.split(":")[1],
            )
          : []
      }
      value={bucketValues}
      onChange={(e, value) => {
        handleMultipleValuesChange(value);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onClick={(e) => {
            e.preventDefault();
          }}
          size="small"
          className={classes.textField}
          placeholder={intl.formatMessage({
            id: "facetBucketValueAutocomplete.assignValuesPlaceholder",
            defaultMessage: "Assign values",
          })}
        />
      )}
      filterSelectedOptions
      size="small"
      renderOption={(option) => (
        <span
          className={classes.options}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {option}
        </span>
      )}
      freeSolo
    />
  );
};

export default React.memo(FacetBucketValueAutocomplete);
