import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { styled } from "@mui/material/styles";

const classes = {
  textField: "textField",
  attributeTextField: "attributeTextField",
};
const Text = styled(TextField)(({ theme }) => ({
  [`&.${classes.textField}, &.${classes.attributeTextField}`]: {
    backgroundColor: theme.palette.background.paper,
    margin: "10px 0",

    "& .MuiFormLabel-root": {
      width: "158px",
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      paddingLeft: "1px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette?.focusColor.main,
      height: "23px",
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.dangerColor.main,
    },
    "& .MuiInputBase-root.Mui-focused fieldset": {
      borderColor: theme.palette?.focusColor.main,
    },
    "& .MuiInputBase-root input, & .MuiInputBase-root textarea": {
      fontSize: "16px",
      lineHeight: "22.4px",
      fontWeight: 400,
      fontFamily: "Helvetica",
      height: 33.4,
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: 1.5,
    },
    "& .MuiInputBase-root fieldset": {
      height: 56.4,
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    "& .MuiFormHelperText-root": {
      marginBottom: "-10px !important",
      // minHeight: "20px", // Ensure consistent height for helper text
    },
  },
  [`&.${classes.textField}`]: {
    maxHeight: 50,
    width: 324,
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
  },
}));

const TextfieldWrapper = ({ name, type, variant, size, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    type: type,
  };

  return (
    <Text
      required={name === "description" ? false : true}
      size={size ?? "small"}
      className={
        otherProps.label === "Search"
          ? classes.attributeTextField
          : classes.textField
      }
      variant={variant ?? "outlined"}
      multiline={name === "description" ? true : false}
      {...configTextfield}
      error={meta.error && meta.touched ? true : false}
      helperText={meta.touched ? meta.error : ""}
      autoComplete=""
    />
  );
};

export default TextfieldWrapper;
