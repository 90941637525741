import {
  FETCH_COLOR_MANAGEMENT_LIST,
  UPDATE_LEADING_COLOR,
  SET_LEADING_COLOR_IMAGE,
  RESET_PRODUCT_LEADING_COLOR,
  RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE,
  UPDATE_PRODUCT_COLOR_SEQUENCE,
  GetColorManagementProductListPayload,
  UpdateProductColorPayload,
  TOGGLE_SEQUENCE_EDITED,
  FETCH_COLOR_VARIATION_ATTRIBUTES,
  GetVariationAttributesPayload,
  LeadingColorPayload,
  RESET_COLOR_MANAGEMENT_PRODUCT_LIST,
  ToggleSequenceEditedAction,
  GetVariantAttributesAction,
  SET_LEADING_COLOR_ID,
  leadingColorId,
} from "./ColorManagementTypes";

export const updateProductColorSequence = (
  payload: UpdateProductColorPayload,
) => {
  return {
    payload,
    type: UPDATE_PRODUCT_COLOR_SEQUENCE,
  };
};

export const getColorManagementProductList = (
  payload: GetColorManagementProductListPayload,
) => {
  return {
    payload,
    type: FETCH_COLOR_MANAGEMENT_LIST,
  };
};

export const resetColorManagementProductList = () => {
  return {
    type: RESET_COLOR_MANAGEMENT_PRODUCT_LIST,
  };
};

export const getVariationAttributes = (
  payload: GetVariationAttributesPayload,
): GetVariantAttributesAction => {
  return {
    payload,
    type: FETCH_COLOR_VARIATION_ATTRIBUTES,
  };
};

export const toggleSequenceEdited = (): ToggleSequenceEditedAction => {
  return {
    type: TOGGLE_SEQUENCE_EDITED,
  };
};

export const updateCategoryLeadingColorForProduct = (
  payload: LeadingColorPayload,
) => {
  return {
    type: UPDATE_LEADING_COLOR,
    payload,
  };
};

export function createBaseProductLeadingColorImageAction(
  payload: setLeadingColorImagePayload,
) {
  return {
    type: SET_LEADING_COLOR_IMAGE,
    payload,
  };
}

export interface setLeadingColorImagePayload {
  leadingColorId: string;
  thumbnail: string;
  productId: string;
  categoryId: string;
}

export const resetUpdatingLeadingColorCompletedState = () => {
  return {
    type: RESET_PRODUCT_LEADING_COLOR,
  };
};

export function createResetProdutctUpdateLeadingColorAction() {
  return {
    type: RESET_PRODUCT_UPDATE_LEADING_COLOR_STATE,
  };
}

export const setLeadingColorId = (payload: leadingColorId) => {
  return {
    payload,
    type: SET_LEADING_COLOR_ID,
  };
};
