import {
  fetchSingleProductPayload,
  fetchSingleProductColorPayload,
} from "../product/ProductTypes";
import {
  LOAD_PRODUCT_LIST,
  LoadProductsFromCategoryAction,
  UpdateProductListSequenceAction,
  CHANGE_PRODUCT_LIST_SEQUENCE,
  UPDATE_PRODUCT_LIST_IN_STATE,
  updateProductListInStateAction,
  UpdateSequencePayload,
  GetProductListPayload,
  updateProductListSequenceModeAction,
  UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
  UpdateProductInStatePayload,
  ResetProductList,
  RESET_PRODUCT_LIST,
  RESET_PRODUCT_SEQUENCE,
  UpdateNumberOfPinnedProductsAction,
  UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  UpdatePinnedProductsPayload,
  UpdatePinnedProductsAction,
  CHANGE_PINNED_PRODUCTS,
  GetPinnedProductsPayload,
  LoadPinnedProductsAction,
  LOAD_PINNED_PRODUCTS,
  SetPinnedProductsModeAction,
  SET_PINNED_PRODUCTS_MODE,
  SetSortingRuleAction,
  SET_SORTING_RULE,
  LOAD_SORTING_LIST,
  LoadSortingRulesListFromSortAction,
  LOAD_SORTED_PRODUCTS,
  ResetProductSequence,
  REFRESH_PRODUCT,
  FETCH_PRODUCT_IDS,
  UpdateProductSequencePayload,
  UPDATE_PRODUCT_SEQUENCE,
  UpdateProductSequenceAction,
  GetProductSequencePayload,
  GET_PRODUCT_SEQUENCE,
  GetProductSequenceAction,
  FETCH_PRODUCT,
  FETCH_PRODUCTS_COLORS,
  FETCH_PREV_PRODUCT_IDS,
  FETCH_NEXT_PRODUCT_IDS,
  productColorsDataPayload,
  REFRESH_PRODUCT_COLOR,
  productColorsData,
  FETCH_WATCHED_PRODUCT_LIST,
  ADD_PRODUCT_TO_WATCH_LIST,
  addRemoveProductInWatchListPayload,
  REMOVE_PRODUCT_FROM_WATCH_LIST,
  watchProductAction,
  unWatchProductAction,
  REVERT_PRODUCT_SEQUENCE,
  RevertProductSequence,
  CANCEL_CATALOG_PAGE_LOADING,
  CATEGORY_CHANGE,
  ChangeCategoryAction,
} from "./ProductListTypes";

export const changeCategory = (): ChangeCategoryAction => {
  return {
    type: CATEGORY_CHANGE,
  };
};

export const loadProductsFromCategory = (
  payload: GetProductListPayload,
): LoadProductsFromCategoryAction => {
  return {
    payload,
    type: LOAD_PRODUCT_LIST,
  };
};

export const loadProductIdsFromCategory = (payload: GetProductListPayload) => {
  return {
    payload,
    type: FETCH_PRODUCT_IDS.REQUEST, // the request action types should be reserved for API requests, not component events
  };
};

export const loadPreviousProductIdsFromCategory = (
  payload: GetProductListPayload,
) => {
  return {
    payload,
    type: FETCH_PREV_PRODUCT_IDS.REQUEST,
  };
};

export const loadNextProductIdsFromCategory = (
  payload: GetProductListPayload,
) => {
  return {
    payload,
    type: FETCH_NEXT_PRODUCT_IDS.REQUEST,
  };
};

export const updateProductListSequence = (
  payload: UpdateSequencePayload,
): UpdateProductListSequenceAction => {
  return {
    payload,
    type: CHANGE_PRODUCT_LIST_SEQUENCE,
  };
};
export const updateProductSequenceList = (
  payload: UpdateProductSequencePayload,
): UpdateProductSequenceAction => {
  return {
    payload,
    type: UPDATE_PRODUCT_SEQUENCE,
  };
};
export const getProductSequenceList = (
  payload: GetProductSequencePayload,
): GetProductSequenceAction => {
  return {
    payload,
    type: GET_PRODUCT_SEQUENCE,
  };
};

export const updateProductListInState = (
  payload: UpdateProductInStatePayload,
): updateProductListInStateAction => {
  return {
    payload,
    type: UPDATE_PRODUCT_LIST_IN_STATE,
  };
};

export const productLoadingCancelled = () => {
  return {
    type: CANCEL_CATALOG_PAGE_LOADING,
  };
};

export const updateProductListSequenceMode = (
  sequenceMode: number,
): updateProductListSequenceModeAction => {
  return {
    sequenceMode,
    type: UPDATE_PRODUCT_LIST_SEQUENCE_MODE,
  };
};

export const resetProductSequence = (): ResetProductSequence => {
  return {
    type: RESET_PRODUCT_SEQUENCE,
  };
};

export const resetProductList = (): ResetProductList => {
  return {
    type: RESET_PRODUCT_LIST,
  };
};

export const updateNumberOfPinnedProducts = (
  numberOfPinnedProducts: number,
): UpdateNumberOfPinnedProductsAction => {
  return {
    payload: { numberOfPinnedProducts },
    type: UPDATE_NUMBER_OF_PINNED_PRODUCTS,
  };
};

export const updatePinnedProducts = (
  payload: UpdatePinnedProductsPayload,
): UpdatePinnedProductsAction => {
  return {
    payload,
    type: CHANGE_PINNED_PRODUCTS,
  };
};

export const loadPinnedProducts = (
  payload: GetPinnedProductsPayload,
): LoadPinnedProductsAction => {
  return {
    payload,
    type: LOAD_PINNED_PRODUCTS,
  };
};

export const setPinnedProductsMode = (
  isPinnedProductsMode: boolean,
): SetPinnedProductsModeAction => {
  return {
    isPinnedProductsMode,
    type: SET_PINNED_PRODUCTS_MODE,
  };
};

export const loadSortingList = (): LoadSortingRulesListFromSortAction => {
  return {
    type: LOAD_SORTING_LIST,
  };
};

export const setSortingRule = (rule: string): SetSortingRuleAction => {
  return {
    rule,
    type: SET_SORTING_RULE,
  };
};

export const loadSortedProducts = (
  rule: string,
  selectedSortPeriod?: string,
  timeZone?: string,
) => {
  return {
    rule,
    selectedSortPeriod,
    timeZone,
    type: LOAD_SORTED_PRODUCTS,
  };
};

export const refreshProductFromList = (payload: fetchSingleProductPayload) => {
  return {
    payload,
    type: REFRESH_PRODUCT.REQUEST,
  };
};

export const fetchSingleProductData = (payload: fetchSingleProductPayload) => {
  return {
    payload,
    type: FETCH_PRODUCT.REQUEST,
  };
};

export interface loadProductsColorsDataActionPayload {
  payload: productColorsDataPayload;
  type: typeof FETCH_PRODUCTS_COLORS;
}

export const refreshProductColorFromList = (
  payload: fetchSingleProductColorPayload,
) => {
  return {
    payload,
    type: REFRESH_PRODUCT_COLOR.REQUEST,
  };
};

export function createRefreshProductColorAction(payload: productColorsData) {
  return {
    type: REFRESH_PRODUCT_COLOR.SUCCESS,
    payload,
  };
}

export const loadWatchedProductListAction = () => {
  return {
    type: FETCH_WATCHED_PRODUCT_LIST.REQUEST,
  };
};

export const addProductToWatchList = (
  payload: addRemoveProductInWatchListPayload,
): watchProductAction => {
  return {
    type: ADD_PRODUCT_TO_WATCH_LIST.REQUEST,
    payload,
  };
};

export const removeProductFromWatchList = (
  payload: addRemoveProductInWatchListPayload,
): unWatchProductAction => {
  return {
    type: REMOVE_PRODUCT_FROM_WATCH_LIST.REQUEST,
    payload,
  };
};

export const revertProductSequence = (): RevertProductSequence => {
  return {
    type: REVERT_PRODUCT_SEQUENCE,
  };
};
