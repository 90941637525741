import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import {
  selectAreAllPagesFetched,
  selectGetProductIds,
  selectGetProductListForFindProductsModal,
} from "../../store/find-products/FindProductSelectors";
import { ProductSequenceData } from "../../store/product-list/ProductListTypes";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "../../store/category/CategorySelectors";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import {
  updateProductAddCategoryRequestAction,
  updateProductRemoveCategoryRequestAction,
} from "../../store/product/ProductActions";
import { selectProductListsFetched } from "../../store/product-list/ProductListSelectors";
import CheckboxComponent from "../common/Checkbox";
import { NoSearchResultIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  formControl: {
    height: "90%",
    overflow: "auto",
    padding: "1rem",
  },
  img: {
    width: "80%",
  },
  paper: {
    // maxWidth: '200px',
    // maxHeight: '300px',
  },
  formControlLabel: {
    width: "100%",
    overflow: "hidden",
    margin: 0,
    padding: "0.85rem",
    position: "relative",
    "&>.MuiFormControlLabel-label": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "center",
      width: "100%",
    },
    "&>.MuiButtonBase-root": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  typographyBody1: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
  },
  partNumber: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
    color: theme.palette.text.primary,
    marginTop: "5px",
  },
  checkBox: {
    margin: "5px",
    padding: "5px 8px",
  },
  noResultDiv: { textAlign: "center", width: "100%" },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {
  currentModalSelectedCategory: string | null;
  productsToBeAdded: {};
  setProductsToBeAdded: React.Dispatch<React.SetStateAction<{}>>;
  productsToBeRemoved: {};
  setProductsToBeRemoved: React.Dispatch<React.SetStateAction<{}>>;
  existingAddingRemoveProductIdsOperation?: string[];
}

const StyledProductList = styled.div`
  height: 100%;
  margin-top: 10px;
  width: 100%;
`;

const productListLoading = (count) => {
  return (
    count > 0 && (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          rowGap: "10px",
        }}
      >
        {[...Array(count)].map(() => (
          <Skeleton width="33%" height={330} variant="rectangular" />
        ))}
      </div>
    )
  );
};

const FindProductsProductList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    currentModalSelectedCategory,
    existingAddingRemoveProductIdsOperation,
  } = props;
  const mainSequenceProductList = useSelector(selectProductListsFetched);
  const productList = useSelector(selectGetProductListForFindProductsModal);
  const isAllPagesFetched = useSelector(selectAreAllPagesFetched);
  const productIds = useSelector(selectGetProductIds);

  const topCategoriesMainSequence = useSelector(selectTopCategories);
  const childCategoriesMainSequence = useSelector(selectChildCategories);

  const catalogId = useSelector(selectCurrentCatalogId);
  const localeId = useSelector(selectCurrentLocale);
  const currentStoreId = useSelector(selectCurrentStoreId);
  const currentCategoryId = useSelector(selectCurrentCategory) || "";

  const [checkedProductIds, setCheckedProductIds] = useState([] as string[]);

  useEffect(() => {
    if (mainSequenceProductList.length && productList.length) {
      let productIdsInTheMainSequenceList = [] as string[];
      productList.forEach((product) => {
        if (
          mainSequenceProductList.findIndex(
            (productData) => productData.productId === product.productId,
          ) !== -1
        ) {
          productIdsInTheMainSequenceList.push(product.productId);
        }
      });
      setCheckedProductIds(productIdsInTheMainSequenceList);
    }
  }, [mainSequenceProductList, productList]);

  const dispatch = useDispatch();

  const addRemoveFromCurrentCategory = (
    productId: string | undefined,
    categoryId: string,
    e: any,
  ) => {
    const tc = topCategoriesMainSequence.find(
      (e) => e.categoryId === categoryId,
    );
    const ccFilter = Object.entries(childCategoriesMainSequence).find((e) =>
      e[1].childCategories.find((el) => el.categoryId === categoryId),
    );
    const payload = {
      sequence: 0,
      productId,
      categoryId,
      storeId: currentStoreId,
      parentId: !tc && ccFilter ? ccFilter[0] : null,
    };
    if (e.target.checked) {
      let fetchProductPayload = {
        productId,
        catalogId,
        localeCode: localeId,
        storeId: currentStoreId,
      };
      dispatch(
        updateProductAddCategoryRequestAction(payload, fetchProductPayload),
      );
    } else {
      dispatch(updateProductRemoveCategoryRequestAction(payload));
    }
  };

  const renderLabel = useCallback(
    (item: ProductSequenceData, isLoading: boolean) => {
      const productName = item.product.name;
      const thumbnail = item.product.thumbnail;
      const productId = item?.product?.code || "";

      return (
        <>
          <img src={thumbnail} alt={productName} className={classes.img} />
          <Typography
            className={classes.typographyBody1}
            classes={{
              root: classes.typographyBody1,
            }}
            noWrap
            title={productName}
          >
            {productName}
          </Typography>
          <Typography
            className={classes.partNumber}
            classes={{
              root: classes.typographyBody1,
            }}
            noWrap
            title={productId}
          >
            {productId}
          </Typography>
          {isLoading ? (
            <div>
              {" "}
              <CircularProgress />{" "}
            </div>
          ) : (
            <FormControlLabel
              className={classes.checkBox}
              control={
                <CheckboxComponent
                  checkboxId="FindProductsProductList"
                  checked={
                    checkedProductIds.findIndex((id) => id === productId) !== -1
                  }
                  value={productId}
                  setCheckedProductIds={setCheckedProductIds}
                  currentCategoryId={currentCategoryId}
                  addRemoveFromCurrentCategory={addRemoveFromCurrentCategory}
                  handleParam={item.product.code}
                />
              }
              label={<p style={{ fontSize: "0.75rem" }}>In Category</p>}
            />
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCategoryId, addRemoveFromCurrentCategory],
  );
  return (
    <StyledProductList>
      <Grid container spacing={2} direction="row">
        {productList &&
          productList.length > 0 &&
          productList.map((item, index) => {
            const currentId = item.product.code;
            const isLoadingState =
              existingAddingRemoveProductIdsOperation?.length
                ? existingAddingRemoveProductIdsOperation.findIndex(
                    (id) => id === currentId,
                  ) !== -1
                : false;

            return (
              <Grid item sm={3} key={`FindProductsProductsList_${index}`}>
                <Paper className={classes.paper}>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={<></>}
                    label={renderLabel(item, isLoadingState)}
                  />
                </Paper>
              </Grid>
            );
          })}
      </Grid>
      {!isAllPagesFetched
        ? productListLoading(
            productList.length === 0
              ? productIds.length
              : productIds.length - productList.length,
          )
        : null}
      {isAllPagesFetched &&
        productList.length === 0 &&
        currentModalSelectedCategory !== "" && (
          <div className={classes.noResultDiv}>
            {/* // <Box position="absolute" top="50%" left="45%"> */}
            <NoSearchResultIcon />
            <Typography variant="h5">
              {intl.formatMessage({
                id: "productListSwitch.noProductsFoundText",
                defaultMessage: "No Products Available",
              })}
            </Typography>
          </div>
          //  </Box>
        )}
      {productIds.length === 0 &&
        currentModalSelectedCategory !== "" &&
        !isAllPagesFetched && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
    </StyledProductList>
  );
};

export default FindProductsProductList;
