import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppState from "../../store/AppState";
import { setModalState } from "../../store/modal/ModalActions";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { selectAllStoreAttributes } from "../../store/store-list/StoreListSelectors";
import { selectAttributesForManagement } from "../../store/category/CategorySelectors";
import { setAdditionalAttributes } from "../../store/category/CategoryActions";
import CustomDialog from "../common/DialogContainer";
import CheckboxComponent from "../common/Checkbox";
import ButtonComponent from "../common/ButtonComponent";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    wrapper: {
      position: "relative",
    },
    formControlLabel: {
      "& .MuiTypography-root": {
        fontSize: 14,
      },
    },
    checkBox: {
      padding: "5px 9px",
    },
    dailogAction: {
      width: "100%",
      textAlign: "end",
      margin: "20px 20px 10px",
    },
    divMessage: {
      marginTop: "5px",
    },
  }),
);

export const addAttributeModalId = "addAttributeModal";

const AddAttributeModal = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [selectedAttributeIds, setSelectedAttributeIds] = useState<string[]>(
    [],
  );
  const open = useSelector((state: AppState) =>
    selectIsModalOpen(state, addAttributeModalId),
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModalState(addAttributeModalId, false));
  };
  const attributes = useSelector(selectAllStoreAttributes);
  const categoryAttributes = useSelector(selectAttributesForManagement);
  const saveHandler = () => {
    const additionalAttributesData = attributes.filter((attribute) =>
      selectedAttributeIds.includes(attribute.attributeId),
    );
    const payload = {
      additionalAttributesData,
    };
    dispatch(setAdditionalAttributes(payload));
    setSelectedAttributeIds([]);
    handleClose();
  };

  const handleChange = (attributeId: string) => {
    const copiedIds = selectedAttributeIds.slice();
    const index = copiedIds.findIndex((id) => id === attributeId);
    if (index >= 0) copiedIds.splice(index, 1);
    else copiedIds.push(attributeId);
    setSelectedAttributeIds(copiedIds);
  };

  const addAttributeModalTitle = () => {
    return <Typography variant="inherit">Add attributes to manage</Typography>;
  };

  const addAttributeModalContent = () => {
    return (
      <>
        <div className={classes.divMessage}>
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: "addAttributeModal.subtitle",
              defaultMessage:
                "Select attributes to manage the attribute values for the products in the category.",
            })}
          </Typography>
          <Typography variant="caption">
            {intl.formatMessage({
              id: "addAttributeModal.caption",
              defaultMessage:
                "Note - The attribute will be removed from list on refresh if no values are assigned to any product.",
            })}
          </Typography>
        </div>
        {attributes.map((attribute) => {
          const isExisting = categoryAttributes.find(
            (categoryAttr) =>
              categoryAttr.attributeId === attribute.attributeId,
          );
          if (isExisting) return <React.Fragment key={attribute.attributeId} />;
          return (
            <FormGroup row key={attribute.attributeId}>
              <FormControlLabel
                key={attribute.attributeId}
                control={
                  <CheckboxComponent
                    checkboxId="AddAttributeModal"
                    checked={selectedAttributeIds.includes(
                      attribute.attributeId,
                    )}
                    classes={classes}
                    handleParam={attribute.attributeId}
                    handleChange={handleChange}
                  />
                }
                label={attribute.code ? attribute.code : attribute.attributeId}
                className={classes.formControlLabel}
              />
            </FormGroup>
          );
        })}
      </>
    );
  };

  const dialogActions = () => {
    return (
      <div className={classes.dailogAction}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={saveHandler}
        >
          Add
        </ButtonComponent>
      </div>
    );
  };
  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      title={addAttributeModalTitle()}
      actions={dialogActions()}
      maxWidth={"sm"}
    >
      {addAttributeModalContent()}
    </CustomDialog>
  );
};

export default AddAttributeModal;
