import React, { useEffect, useState } from "react";
// import { DateRange } from "materialui-daterange-picker";
import { useDispatch, useSelector } from "react-redux";
// import {
//   makeStyles,
//   Theme,
//   createStyles,
// } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";

import { useQuery } from "../../hooks/useQueryParams";
import { loadProductAnalytics } from "../../store/product/ProductActions";
import {
  selectReportProducts,
  selectReportStoreId,
  selectReportDateRange,
  shouldLoadProductAnalytics,
} from "../../store/report/ReportSelectors";
import { setReportDateRange } from "../../store/report/ReportActions";
import {
  // formatDateForAnalytics,
  // getDateFromString,
  getDateStringByDays,
} from "../../utils/TimeUtils";
import { selectCallerAccountId } from "../../store/app-config/AppConfigSelectors";
// import AnalyticsDateRangePicker from '../AnalyticsDateRangePicker';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     datePicker: {
//       marginLeft: 0,
//     }
//   })
// );

interface Props {}
const DateFilter: React.FC<Props> = () => {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const reportFieldsQuery = query.get("reportFields") ?? "";
  const dateRangeQuery = query.get("dateRange") ?? "";
  const products = useSelector(selectReportProducts);
  const storeId = useSelector(selectReportStoreId);
  const selectedDateRange = useSelector(selectReportDateRange);
  const shouldLoadAnalytics = useSelector(shouldLoadProductAnalytics);
  const userAccountId = useSelector(selectCallerAccountId);

  useEffect(() => {
    if (
      shouldLoadAnalytics &&
      selectedDateRange.startDate !== "" &&
      selectedDateRange.startDate !== ""
    ) {
      const analyticsPayload = {
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        storeId: storeId,
        partNumber: products,
        level: "product",
      };
      dispatch(loadProductAnalytics(analyticsPayload));
    }
  }, [
    dispatch,
    products,
    storeId,
    selectedDateRange.startDate,
    selectedDateRange.endDate,
    shouldLoadAnalytics,
  ]);

  useEffect(() => {
    if (
      catalogIdQuery !== "" &&
      categoryPathQuery !== "" &&
      (selectedDateRange.startDate === "" || selectedDateRange.endDate === "")
    ) {
      const dates = dateRangeQuery.split("-");
      if (dates.length === 2) {
        dispatch(setReportDateRange(dates[0], dates[1]));
      } else {
        const defaultStartDate = getDateStringByDays(-7);
        const defaultEndDate = getDateStringByDays(-1);
        dispatch(setReportDateRange(defaultStartDate, defaultEndDate));
        const dateRangeQuery = `${defaultStartDate}-${defaultEndDate}`;
        const newReportFieldsQuery =
          reportFieldsQuery.length > 0
            ? `&reportFields=${encodeURIComponent(reportFieldsQuery)}`
            : "";
        history.replace(
          `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}&catalogId=${catalogIdQuery}&categoryPath=${categoryPathQuery}${newReportFieldsQuery}&dateRange=${dateRangeQuery}`,
        );
      }
    }
  }, [
    selectedDateRange.endDate,
    selectedDateRange.startDate,
    dateRangeQuery,
    dispatch,
    catalogIdQuery,
    categoryPathQuery,
    history,
    reportFieldsQuery,
    storeIdQuery,
    url,
    userAccountId,
  ]);

  // default date range: one week
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() - 1);
  // const startDate = selectedDateRange.startDate !== '' ? getDateFromString(selectedDateRange.startDate) : defaultStartDate;
  // const endDate = selectedDateRange.endDate !== ''  ? getDateFromString(selectedDateRange.endDate): defaultEndDate;

  // const handleDateRangeChange = (range: any /* DateRange */) => {
  //   if (range.startDate && range.endDate) {
  //     const startDate = formatDateForAnalytics(range.startDate);
  //     const endDate = formatDateForAnalytics(range.endDate);
  //     dispatch(setReportDateRange(startDate, endDate));
  //     if (catalogIdQuery !== ''
  //     && categoryPathQuery !== ''
  //     && reportFieldsQuery !== '') {
  //       const dateRangeQuery = `${startDate}-${endDate}`;
  //       const newReportFieldsQuery = reportFieldsQuery.length > 0 ? `&reportFields=${encodeURIComponent(reportFieldsQuery)}` : '';
  //       history.replace(
  //         `${url}?storeId=${storeIdQuery}&catalogId=${catalogIdQuery}&categoryPath=${categoryPathQuery}${newReportFieldsQuery}&dateRange=${dateRangeQuery}`
  //       );
  //     }
  //   }
  // }

  const [reset, setReset] = useState(false);
  useEffect(() => {
    setReset(true);
  }, [selectedDateRange.startDate, selectedDateRange.endDate]);

  if (reset) {
    setTimeout(() => {
      setReset(false);
    }, 200);
  }

  return (
    <>
      {/* <AnalyticsDateRangePicker 
      startDate={startDate}
      endDate={endDate}
      reset={reset}
      onDateRangeChange={handleDateRangeChange}
      overrideClass={classes.datePicker}
    /> */}
    </>
  );
};

export default DateFilter;
