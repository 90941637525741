import { defineMessages } from "react-intl";
import {
  FETCH_CHILDREN_ITEMS,
  FETCH_PRODUCT_ATTRIBUTEVALUES,
  FETCH_PRODUCT_DETAILS,
  UPDATE_LOCALE_PRODUCT_PUBLISH,
  UPDATE_PRODUCT_PUBLISH_STATE,
  UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE,
  UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE,
  UPDATE_ATTR_VALUE_SEQUENCE,
  UPDATE_LEADING_COLOR_BY_CATEGORY,
} from "../product/ProductTypes";

// TODO: add more error action type and message mappings
export const errorMessagesMap = defineMessages({
  [FETCH_CHILDREN_ITEMS.FAILURE]: {
    id: "globalAlertErrorMessages.fetchChildrenItemsError",
    defaultMessage: "Failed to fetch children items.",
  },
  [FETCH_PRODUCT_DETAILS.FAILURE]: {
    id: "globalAlertErrorMessages.fetchProductDetailsError",
    defaultMessage: "Failed to fetch product details.",
  },
  [FETCH_PRODUCT_ATTRIBUTEVALUES.FAILURE]: {
    id: "globalAlertErrorMessages.fetchProductAttributeValuesError",
    defaultMessage: "Failed to fetch product attribute values.",
  },
  [UPDATE_LOCALE_PRODUCT_PUBLISH.FAILURE]: {
    id: "globalAlertErrorMessages.updateLocaleProductPublishError",
    defaultMessage: "Error updating publish status",
  },
  [UPDATE_PRODUCT_PUBLISH_STATE.FAILURE]: {
    id: "globalAlertErrorMessages.updateProductPublishStateError",
    defaultMessage: "Error updating publish status",
  },
  [UPDATE_PRODUCT_TO_PRODUCT_SEQUENCE.FAILURE]: {
    id: "globalAlertErrorMessages.updateProductSequenceError",
    defaultMessage: "Error updating product sequences",
  },
  [UPDATE_ATTR_VALUE_DESCRIPTION_SEQUENCE.FAILURE]: {
    id: "globalAlertErrorMessages.updateAttributeValueDescriptionSequenceError",
    defaultMessage: "Error updating color sequences",
  },
  [UPDATE_ATTR_VALUE_SEQUENCE.FAILURE]: {
    id: "globalAlertErrorMessages.updateAttributeValueSequenceError",
    defaultMessage: "Error updating color sequences",
  },
  [UPDATE_LEADING_COLOR_BY_CATEGORY.FAILURE]: {
    id: "globalAlertErrorMessages.updateLeadingColorByCategoryError",
    defaultMessage: "Error updating leading color",
  },
});

export const ignoredErrorMessages = [
  "an operation already exists with this id",
];
