import { createSelector } from "reselect";
import AppState from "../AppState";
import {
  PLATFORM_DEFAULTS,
  PLATFORM_INDEPENDENT_DEFAULTS,
} from "../../utils/DefaultConfigs";
import { getConfigValue } from "../../utils/AppConfigUtils";
import { AppConfigDetails } from "./AppConfigTypes";
import { selectCurrentStoreId } from "../store-list/StoreListSelectors";
export const selectAppConfigurations = (state: AppState): AppConfigDetails => {
  return state.appConfig.data;
};

export const selectAppLogo = createSelector(
  [selectAppConfigurations],
  (appConfigDetails) => {
    const storeWithLogo = appConfigDetails.stores.find((s) => s.logo);
    if (storeWithLogo) {
      return storeWithLogo.logo;
    } else {
      return appConfigDetails.account?.logo || "";
    }
  },
);

export const selectConfigValue = (state: AppState, configKey: string) => {
  const platformType =
    state.adapterConfig?.AdapterInfo?.adapterId.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[platformType];
  const currentStoreId = selectCurrentStoreId(state);
  const appConfigs = state.appConfig?.storeAndAccountConfigDetails;
  const platformDefaultConfigValue = platformDefaults?.[configKey] ?? "";
  const platformIndependentConfigValue =
    PLATFORM_INDEPENDENT_DEFAULTS?.[configKey] ?? "";

  return getConfigValue(
    appConfigs,
    currentStoreId,
    configKey,
    platformDefaultConfigValue,
    platformIndependentConfigValue,
  );
};

export const selectCallerAccountId = (state: AppState) => {
  return state.appConfig?.accountId;
};

export const selectCallerAccounts = (state: AppState) => {
  return state.appConfig?.callerAccounts;
};

export const selectIsCallerAccountsInvoked = (state: AppState) => {
  return state.appConfig?.isCallerAccountsInvoked;
};

export const selectIsFetchingCallerAccountsFailed = (state: AppState) => {
  return state.appConfig?.isFetchingCallerAccountsFailed;
};

export const selectProductImagePrefix = (
  state: AppState,
  currentStoreId: string | null,
) => {
  const { appConfig } = state;
  if (!appConfig || !appConfig.data) {
    return "";
  }
  const { stores, account } = appConfig.data;
  // Find the store product image prefix
  const storeProductImagePrefix = stores?.find(
    (store) => store.storeId === currentStoreId && store.productImagePrefix,
  )?.productImagePrefix;
  // Find the account product image prefix
  const accountProductImagePrefix = account?.productImagePrefix;
  return storeProductImagePrefix ?? accountProductImagePrefix ?? "";
};
