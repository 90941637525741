import {
  ProductActionTypes,
  FETCH_CATEGORIES_BY_PRODUCT_IDS,
  LoadCategoriesByProductIdsResponseAction,
  ProductCategoryType,
  DELETE_PRODUCT_BY_CATEGORIES,
  RESET_PRODUCT_REMOVE_STATE,
} from "./RemoveProductCategoriesTypes";

export interface ProductCategories {
  categories: ProductCategoryType[];
  isProductDeleted: boolean;
  isProgressStopped: boolean;
}

const initialState: ProductCategories = {
  categories: [],
  isProductDeleted: false,
  isProgressStopped: false,
};

export const ProductCategoriesReducer = (
  state = initialState,
  action: ProductActionTypes,
): any => {
  switch (action.type) {
    case FETCH_CATEGORIES_BY_PRODUCT_IDS.SUCCESS:
      let fetchCategoryAction =
        action as LoadCategoriesByProductIdsResponseAction;
      return {
        ...state,
        categories: fetchCategoryAction.payload.products,
      };
    case DELETE_PRODUCT_BY_CATEGORIES.SUCCESS: {
      return {
        ...state,
        isProductDeleted: true,
      };
    }
    case DELETE_PRODUCT_BY_CATEGORIES.FAILURE: {
      return {
        ...state,
        isProductDeleted: false,
        isProgressStopped: true,
      };
    }
    case RESET_PRODUCT_REMOVE_STATE: {
      return {
        ...state,
        isProductDeleted: false,
      };
    }
    default:
      return state;
  }
};
