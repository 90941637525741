import {
  CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
  UpdateAttributeValueDescriptionPayload,
  UpdateAttributeValueDescriptionAction,
  UpdateAttributeValueCodePayload,
  UpdateAttributeValueCodeAction,
  CHANGE_ATTRIBUTE_VALUE_CODE,
  AddAttributeValuePayload,
  AddAttributeValueAction,
  ADD_ATTRIBUTE_VALUE,
  GET_ATTRIBUTE_DATA_BY_ID,
  GetAttributeByIdAction,
} from "./AttributeTypes";

export const updateAttributeValue = (
  payload: UpdateAttributeValueDescriptionPayload,
): UpdateAttributeValueDescriptionAction => {
  return {
    payload,
    type: CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
  };
};

export const updateAttributeValueCode = (
  payload: UpdateAttributeValueCodePayload,
): UpdateAttributeValueCodeAction => {
  return {
    payload,
    type: CHANGE_ATTRIBUTE_VALUE_CODE,
  };
};

export const addAttributeValue = (
  payload: AddAttributeValuePayload,
): AddAttributeValueAction => {
  return {
    payload,
    type: ADD_ATTRIBUTE_VALUE,
  };
};

export const getAttributeById = (
  attributeId: string,
): GetAttributeByIdAction => {
  return {
    type: GET_ATTRIBUTE_DATA_BY_ID,
    payload: {
      attributeId,
    },
  };
};
