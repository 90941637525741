import type { ThemeOptions } from "@mui/material";

import { MuiGrid } from "./MuiGrid";
import { MuiFormHelperText } from "./MuiFormHelperText";
import { MuiInputBase } from "./MuiInputBase";
import { MuiFormLabel } from "./MuiFormLabel";
import { MuiInputLabel } from "./MuiInputLabel";
import { MuiOutlinedInput } from "./MuiOutlinedInput";
import { MuiAppBar } from "./MuiAppBar";
import { MuiButton } from "./MuiButton";
import { MuiTypography } from "./MuiTypography";
import { MuiIconButton } from "./MuiIconButton";
import { MuiCheckbox } from "./MuiCheckbox";
import { MuiSelect } from "./MuiSelect";
import { MuiRadio } from "./MuiRadio";
import { MuiCircularProgress } from "./MuiCircularProgress";
import { MuiLink } from "./MuiLink";
import { MuiLinearProgress } from "./MuiLinearProgress";
import { MuiDialogTitle } from "./MuiDialogTitle";

export const components: ThemeOptions["components"] = {
  MuiFormHelperText,
  MuiInputBase,
  MuiFormLabel,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiAppBar,
  MuiButton,
  MuiTypography,
  MuiIconButton,
  MuiCheckbox,
  MuiSelect,
  MuiRadio,
  MuiCircularProgress,
  MuiLink,
  MuiLinearProgress,
  MuiGrid,
  MuiDialogTitle,
};
