import { getToken } from "./UserService";
import HttpClientImpl from "./HttpClientImpl";
import { GraphQLHttpResponse, GraphQLQuery } from "./GraphQLTypes";
import AppEnv from "../utils/AppEnv";

class GQL {
  async query(query: GraphQLQuery) {
    const endpoint = AppEnv.GRAPHQL_HTTP_ENDPOINT;
    if (endpoint === undefined) {
      throw new Error("GraphQL HTTP endpoint undefined");
    }

    const token = await getToken();
    const tokenHeader = token
      ? {
          Authorization: "Bearer " + token,
        }
      : {};

    const headers = {
      ...tokenHeader,
    };

    const httpClient = new HttpClientImpl<GraphQLHttpResponse>(endpoint);
    const request = {
      endpoint,
      body: query,
      headers,
    };
    return httpClient.post(request);
  }
}

const GraphQL = new GQL();

export default GraphQL;
