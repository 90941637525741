import React from "react";
import md5 from "md5";
import { Avatar } from "@material-ui/core";

interface props {
  name: string | undefined;
  picture: string | undefined;
  email: string | undefined;
}

const UserAvatar = ({ name, picture, email }: props) => {
  const src = picture
    ? picture
    : email
      ? "//www.gravatar.com/avatar/" + md5(email) + "?d=404"
      : undefined;
  const alt = name;
  const title = name;
  const props = { src, alt, title };

  return <Avatar {...props} />;
};

export default React.memo(UserAvatar);
