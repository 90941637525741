import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import TabsComponent from "./common/Tabs";
import { CATALOG_NAVIGATION_TABS } from "../utils/Constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      minHeight: "35px",
      backgroundColor: "#F2F3F4",
      "& .MuiTab-root": {
        padding: 0,
        minHeight: "35px",
      },
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      color: "black",
      "& > span": {
        maxWidth: "75%",
        width: "100%",
        backgroundColor: "rgb(112, 155, 177)",
      },
    },
  }),
);

const CatalogNavigationTabs = () => {
  const classes = useStyles();
  const [view, setView] = useState<string>(CATALOG_NAVIGATION_TABS.PRODUCTS);
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();
  const search = location.search ? location.search : "";

  useEffect(() => {
    const path = location.pathname;
    if (path.includes(CATALOG_NAVIGATION_TABS.SUB_CATEGORIES)) {
      setView(CATALOG_NAVIGATION_TABS.SUB_CATEGORIES);
    } else if (path.includes(CATALOG_NAVIGATION_TABS.LEAF_CATEGORIES)) {
      setView(CATALOG_NAVIGATION_TABS.LEAF_CATEGORIES);
    } else if (path.includes(CATALOG_NAVIGATION_TABS.FACETS)) {
      setView(CATALOG_NAVIGATION_TABS.FACETS);
    }
  }, [location]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setView(newValue);
    history.replace(`${url}/${newValue}${search}`);
  };

  return (
    <Paper variant="elevation" elevation={0}>
      <TabsComponent
        tabsId="CatalogNavigationTabs"
        classes={classes}
        view={view}
        handleChange={handleChange}
      />
    </Paper>
  );
};

export default CatalogNavigationTabs;
