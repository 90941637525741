import { Provider } from "react-redux";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { createStore } from "./store/Store";
import MainSequence from "./components/containers/MainSequence/MainSequence";
import ReportContainer from "./components/containers/ReportContainer";
import Header from "./components/header/Header";
import GlobalAlert from "./components/global-alert/GlobalAlert";
import LocaleInitializer from "./LocaleInitializer";
import GoogleAnalyticsWrapper from "./GoogleAnalyticsWrapper";
import BusinessRulesContainer from "./components/containers/BusinessRulesContainer/BusinessRulesContainer";
import PreferencesModal from "./components/header/PreferencesModal";
import { ThemeProvider as CustomThemeProvider } from "./themeContext";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "styled-components";
import { baseTheme as theme } from "./styles/themes/Theme";
import CallerAccountsModal from "./components/containers/account-selection/CallerAccountsModal";
import AccountSelection from "./components/containers/account-selection/AccountSelection";
import ProductAlertsScheduleModal from "./components/header/ProductAlersSchedule/ProductAlertsScheduleModal";
import ProductAlertsListContainer from "./components/header/ProductAlertsListModal/ProductAlertsListContainer";
import { DefaultLayout } from "layouts";

function App() {
  return (
    <HashRouter>
      <GoogleAnalyticsWrapper>
        <Provider store={createStore()}>
          <LocaleInitializer>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <CustomThemeProvider>
                <DefaultLayout>
                  <AccountSelection />
                  <Header />
                  <ProductAlertsListContainer />
                  <Switch>
                    <Route path="/catalog">
                      <MainSequence />
                    </Route>
                    <Route path="/reports">
                      <ReportContainer />
                    </Route>
                    <Route path="/business-rules">
                      <BusinessRulesContainer />
                    </Route>
                    <Redirect to="/catalog" />
                  </Switch>
                  <PreferencesModal />
                  <ProductAlertsScheduleModal />
                  <CallerAccountsModal />
                  <GlobalAlert />
                </DefaultLayout>
              </CustomThemeProvider>
            </MuiThemeProvider>
          </LocaleInitializer>
        </Provider>
      </GoogleAnalyticsWrapper>
    </HashRouter>
  );
}

export default App;
