import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Zoom, IconButton } from "@material-ui/core";
import { useIntl } from "react-intl";
import heatMap from "../assets/heat-map.png";
import ProductListViewSelect from "./ProductListViewSelect";
import SortProducts from "./SortProducts";
import PinnedProductsSelect from "./PinnedProductsSelect";
import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import { Tooltip } from "@mui/material";
import { styled, Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  leftMargin: number;
}
interface Props {
  products: ProductSequenceData[];
}

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const StyledRoot = styled(BottomNavigation)(({ theme }) => ({
  height: "fit-content",
  backgroundColor: "transparent",
  "& .MuiBottomNavigationAction-wrapper": {
    fontSize: "1.5em",
    padding: "5px",
    borderRadius: "5px",
    width: "100%",
  },
  "& .MuiBottomNavigationAction-root": {
    display: "flex",
    minWidth: "32px",
    height: "auto",
    padding: "0",
    marginRight: "7px",
    "&:hover": {
      borderRadius: "5px",
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  "& .MuiBottomNavigationAction-root.Mui-selected": {
    borderColor: theme.palette.primaryHoverColor?.main,
    color: "#f18308",
    borderRadius: "5px",
    backgroundColor: theme.palette.primaryHoverColor?.hoverLight,
    "&:hover": {
      borderColor: theme.palette.primaryHoverColor?.main,
      color: "#f18308",
      borderRadius: "5px",
      backgroundColor: theme.palette.primaryHoverColor?.hoverLight,
    },
  },
  "& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-wrapper":
    {
      borderColor: theme.palette.primary.main,
    },
}));

const StyledHeatMap = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: "-7px",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& img": {
    height: "44px",
    width: "44px",
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  borderRadius: "5px !important",
  border: "0.5px solid",
  fontSize: "10px !important",
  padding: "3px 12px 4px 12px",
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, leftMargin, ...other } = props;
  let styleProps: any = {
    marginLeft: `${leftMargin}px`,
    display: "inline-flex",
  };

  if (value !== index) styleProps = {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={styleProps}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function ProductListGroupBtn(props: Props) {
  const intl = useIntl();
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const showUnimplemented =
    window.localStorage.getItem("showUnimplemented") === "true";

  return (
    <StyledContainer>
      <StyledRoot
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        // className={classes.root}
      >
        <StyledTooltip
          // classes={{ tooltip: classes.tooltip }}
          TransitionComponent={Zoom}
          placement="top"
          title={intl.formatMessage({
            id: "productListGroupBtn.viewLabel",
            defaultMessage: "Change Product List View",
          })}
          arrow
        >
          <BottomNavigationAction
            icon={<i className="fas fa-th"></i>}
            disableRipple
          />
        </StyledTooltip>
        <StyledTooltip
          // classes={{ tooltip: classes.tooltip }}
          TransitionComponent={Zoom}
          placement="top"
          title={intl.formatMessage({
            id: "productListGroupBtn.sortLabel",
            defaultMessage: "Sort Products",
          })}
          arrow
        >
          <BottomNavigationAction
            disabled={props?.products.length ? false : true}
            icon={<i className="fas fa-sort-amount-down"></i>}
            disableRipple
          />
        </StyledTooltip>
        <StyledTooltip
          // classes={{ tooltip: classes.tooltip }}
          TransitionComponent={Zoom}
          placement="top"
          title={intl.formatMessage({
            id: "productListGroupBtn.pinProductsLabel",
            defaultMessage: "Pin Products",
          })}
          style={{ display: "none" }}
          arrow
        >
          <BottomNavigationAction
            icon={<i className="fas fa-thumbtack"></i>}
            disableRipple
          />
        </StyledTooltip>
      </StyledRoot>
      {showUnimplemented && (
        <Tooltip
          TransitionComponent={Zoom}
          placement="top"
          title={intl.formatMessage({
            id: "productListGroupBtn.heatMapLabel",
            defaultMessage: "Heat Map",
          })}
          arrow
        >
          <StyledHeatMap aria-label="Heat Map">
            <img src={heatMap} alt="Heat Map" height="58" />
          </StyledHeatMap>
        </Tooltip>
      )}
      <TabPanel value={value} index={0} leftMargin={100}>
        <ProductListViewSelect />
      </TabPanel>
      <TabPanel value={value} index={1} leftMargin={50}>
        <SortProducts products={props.products} />
      </TabPanel>
      <TabPanel value={value} index={2} leftMargin={100}>
        <PinnedProductsSelect />
      </TabPanel>
    </StyledContainer>
  );
}
