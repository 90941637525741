import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import {
  commonClasses,
  StyledCloseIcon,
} from "../common/DialogContainer.styles";

interface Props {
  onDone: (data: string) => void;
  OnClose: () => void;
  id: string;
}
const ConfirmationActionBox: React.FC<Props> = (props) => {
  const { onDone, OnClose, id } = props;
  return (
    <>
      <span>
        Are you sure you want to delete this product (<strong>{id}</strong>
        )?
      </span>
      <IconButton
        aria-label="done"
        color="secondary"
        onClick={() => onDone(id)}
      >
        <DoneIcon />
      </IconButton>
      <IconButton aria-label="close" color="secondary" onClick={OnClose}>
        <StyledCloseIcon className={commonClasses.closeIcon} />
      </IconButton>
    </>
  );
};
export default ConfirmationActionBox;
