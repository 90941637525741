import { SET_LOADER_STATE, setLoaderStateAction } from "./LoaderTypes";

export const setLoaderState = (
  loaderType: string,
  isLoading: boolean,
): setLoaderStateAction => {
  return {
    type: SET_LOADER_STATE,
    payload: {
      isLoading,
      loaderType,
    },
  };
};
