import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { FACET_ACTION_TYPE } from "../../utils/Constants";
import { deleteFacetFromCategory } from "../../store/facets/FacetActions";
import ButtonComponent from "../common/ButtonComponent";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWidthSm: { background: theme.palette.grey[100] },
    dialogContentText: {
      color: theme.palette.text.primary,
    },
    dialogTitle: {
      textTransform: "capitalize",
      textAlign: "center",
    },
  }),
);

export const FacetActionConfirmationModalId = "FacetActionConfirmationModal";

const FacetActionConfirmationModal = () => {
  const intl = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, FacetActionConfirmationModalId),
  );
  const actionType = useSelector((state: AppState) =>
    selectModalVariable(state, FacetActionConfirmationModalId, "actionType"),
  );
  const payload = useSelector((state: AppState) =>
    selectModalVariable(state, FacetActionConfirmationModalId, "payload"),
  );

  const handleClose = () => {
    dispatch(setModalState(FacetActionConfirmationModalId, false));
  };

  const handleContinue = () => {
    dispatch(deleteFacetFromCategory(payload));
    handleClose();
  };

  const deleteText = intl.formatMessage({
    id: "facetActionConfirmationModal.deleteActionText",
    defaultMessage:
      "Are you sure you want to remove the facet from the category",
  });

  const blockText = intl.formatMessage({
    id: "facetActionConfirmationModal.blockActionText",
    defaultMessage: "Are you sure you want to block the facet for the category",
  });

  const message =
    actionType === FACET_ACTION_TYPE.DELETE ? deleteText : blockText;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <strong>
          {intl.formatMessage({
            id: "facetActionConfirmationModal.header",
            defaultMessage: "Confirm your action",
          })}
        </strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentText}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "facetActionConfirmationModal.cancelButton",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleContinue}
          autoFocus
        >
          {intl.formatMessage({
            id: "facetActionConfirmationModal.continueButton",
            defaultMessage: "Continue",
          })}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(FacetActionConfirmationModal);
