import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "../common/ToolTip";

import AppState from "../../store/AppState";
import {
  isCategoryPublishedSFCC,
  getCategoryNameSFCC,
} from "../../utils/CategoryUtils";
import {
  selectChildCategoriesBasedOnCategorySelectionForFindProducts,
  selectCategoriesForFindProducts,
} from "../../store/find-products/FindProductSelectors";
import { loadProductIdsFromFindProductsCategory } from "../../store/find-products/FindProductsActions";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { LoadChildCategoriesForFindProducts } from "../../store/category/CategoryActions";
import {
  Category,
  selectTopCategoryFetchedForFindProducts,
} from "../../store/category/CategorySelectors";
import TreeViewComponent, { TreeNode } from "../tree-view/TreeView";

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    height: "90%",
    marginTop: "20px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  root: {
    flexGrow: 1,
    "&::-webkit-scrollbar": {},
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  rootLabel: {
    "&:focus>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: theme.palette.primary.main,
    },
  },
  selected: {
    "&>.MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent !important",
      color: theme.palette.primary.main,
    },
  },
  divCatagory: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  count: {
    fontSize: ".8rem",
    marginLeft: "5px",
    marginBottom: "1px",
  },
  progress: {
    marginLeft: "5px",
  },
  skeletonLabel: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
}));

interface Props {
  storeId: string;
  catalog: string;
  selected: string[];
  localeId: string;
  checked: boolean;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  clearSearchForm: () => void;
  orderBy: string;
  setCurrentModalSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

const FindProductsCategorySelection: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    catalog,
    localeId,
    storeId,
    selected,
    setSelected,
    setCurrentModalSelectedCategory,
  } = props;

  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const dispatch = useDispatch();

  // const topCategories = useSelector(selectTopCategoriesForFindProductsModalBasedOnCatalog)

  const childCategories = useSelector((state: AppState) =>
    selectChildCategoriesBasedOnCategorySelectionForFindProducts(state),
  );

  const categoryTree = useSelector(selectCategoriesForFindProducts);

  const isTopCategoriesFetched = useSelector(
    selectTopCategoryFetchedForFindProducts,
  );

  const handleToggle = useCallback(
    (event: object, node: Category | TreeNode) => {
      if (catalog) {
        const categoryId = node.id;
        if (!childCategories[categoryId]) {
          dispatch(
            LoadChildCategoriesForFindProducts(
              catalog,
              categoryId,
              localeId,
              storeId,
            ),
          );
        }
      }
    },
    [dispatch, childCategories, catalog, localeId, storeId],
  );

  const handleCategorySelection = useCallback(
    (categoryId: string) => {
      let payload = {
        categoryId,
        catalogId: catalog,
        localeCode: localeId,
        storeId,
      };
      setCurrentModalSelectedCategory(categoryId);
      dispatch(loadProductIdsFromFindProductsCategory({ ...payload }));
    },
    [catalog, localeId, dispatch, setCurrentModalSelectedCategory, storeId],
  );

  const labelClickHandler = useCallback(
    (node: Category | TreeNode, e: React.MouseEvent) => {
      e.preventDefault();
      setSelected([node.id]);
      handleCategorySelection(node.id);
    },
    [setSelected, handleCategorySelection],
  );

  const renderLabel = (node: Category | TreeNode) => (
    <Tooltip tooltipTitle={node.id} placement="right">
      <div className={classes.divCatagory}>
        <Typography noWrap variant="body4" title={node.id}>
          {isCategoryPublishedSFCC(node as Category) ? "" : "(U) "}
          {getCategoryNameSFCC(node as Category, localeId, defaultLocaleId)}
        </Typography>
        {node ? (
          <span className={classes.count}>
            {node?.productCount ? `(${node.productCount.toString()})` : ""}
          </span>
        ) : (
          <CircularProgress size={15} className={classes.progress} />
        )}
      </div>
    </Tooltip>
  );

  return (
    <TreeViewComponent
      nodes={!isTopCategoriesFetched ? [] : categoryTree}
      selectedNodes={selected}
      handleToggle={handleToggle}
      labelClickHandler={labelClickHandler}
      renderLabel={renderLabel}
      showInitialLoading={!isTopCategoriesFetched}
    />
  );
};

export default FindProductsCategorySelection;
