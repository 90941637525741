import AppState from "../AppState";

export const selectSocialAnalyticsForProducts = (state: AppState) => {
  const socialAnalyticsObj = {};
  const productSocialAnalytics = state.socialAnalytics.productAnalytics;
  if (productSocialAnalytics.length > 0) {
    productSocialAnalytics?.forEach((item) => {
      const product = item?.products[0];
      const productCode = product.productCode;
      socialAnalyticsObj[productCode] = product;
    });
  }
  return socialAnalyticsObj;
};
