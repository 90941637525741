import { call, takeEvery } from "redux-saga/effects";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { getData } from "../../services/ApiService";
import {
  FETCH_ADAPTER_CONFIG,
  GET_ADAPTER_CONFIG_DETAILS,
} from "./AdapterConfigTypes";
import { LOAD_PLATFORM_INDEPENDENT_URL } from "../businessRules-list/BusinessRulesListTypes";

function* getAdapterConfig() {
  try {
    const actionType = FETCH_ADAPTER_CONFIG;
    const constName = Object.keys({
      FETCH_ADAPTER_CONFIG,
    })[0].toString();
    const serviceType = LOAD_PLATFORM_INDEPENDENT_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

export function* watchGetAdapterConfig() {
  yield takeEvery(GET_ADAPTER_CONFIG_DETAILS, getAdapterConfig);
}
