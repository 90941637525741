import { SetUserLocaleAction, SET_USER_LOCALE } from "./UserTypes";

export const setUserLocale = (locale: string): SetUserLocaleAction => {
  return {
    payload: {
      locale,
    },
    type: SET_USER_LOCALE,
  };
};
