import { createSelector } from "reselect";

import AppState from "../AppState";
import { ChildCategory } from "./CategoryReducers";
import { Translations } from "./CategoryTypes";
import { selectAllStoreAttributes } from "../store-list/StoreListSelectors";
import { selectAssignedAttributeIds } from "../product/ProductSelectors";
import { selectAttribute } from "../overlay/OverlaySelectors";

export interface Category {
  id: string;
  translations: Translations[];
  code: string;
  name: string;
  productCount: number;
  childCategoryCount: number;
  children?: Category[];
  categoryPath?: string | null;
  isPublished: boolean;
  categoryIds?: string[];
}

export interface CategoryData {
  categoryId: string;
  childCategoryCount: number;
  productCount: number;
  translations: Translations[];
  code: string;
  name: string;
  isPublished: boolean;
}

export const selectTopCategories = (state: AppState) => {
  return state.category.topCategory;
};

export const selectChildCategories = (state: AppState) => {
  return state.category.childCategory;
};

export const selectProductAttributes = (state: AppState) => {
  return state.category.attributes;
};

export const selectAssignedAttributesData = createSelector(
  [selectAssignedAttributeIds, selectAllStoreAttributes],
  (attributeIds, attributes) => {
    return attributes.filter((attribute) =>
      attributeIds.includes(attribute.attributeId),
    );
  },
);

export const selectProductUnassginedAttributes = (state: AppState) => {
  return state.category.unassignedAttributes;
};

export const selectAttributesForManagement = createSelector(
  [selectAssignedAttributesData, selectProductUnassginedAttributes],
  (attributes, unassignedAttributes) => {
    const attributeIds = attributes.map((attr) => attr.attributeId);
    const additionalAttributes = unassignedAttributes.filter(
      (attr) => !attributeIds.includes(attr.attributeId),
    );
    return [...attributes, ...additionalAttributes];
  },
);

export const selectProductAttribute = createSelector(
  [selectAllStoreAttributes, selectAttribute],
  (attributes, attributeId) => {
    return attributes.find(
      (attribute) => attribute.attributeId === attributeId,
    );
  },
);

export const getCategories = (
  catArray: CategoryData[],
  childCategory: ChildCategory,
  categoryPath: string | null,
  categoryIds: string[],
): Category[] => {
  return catArray.map((Cat) => {
    if (!childCategory[Cat.categoryId]) {
      return {
        id: Cat.categoryId,
        translations: Cat.translations,
        code: Cat.code,
        name: Cat.name,
        isPublished: Cat.isPublished,
        productCount: Cat.productCount || 0,
        childCategoryCount: Cat.childCategoryCount,
        categoryPath,
        categoryIds: Object.keys(childCategory),
        //childCategoryData: Object.values(childCategory),
      };
    } else {
      const newCategoryPath =
        (categoryPath ? categoryPath + ";" : "") + Cat.name;
      const children: Category[] = getCategories(
        childCategory[Cat.categoryId].childCategories,
        childCategory,
        newCategoryPath,
        Object.keys(childCategory),
      );
      return {
        id: Cat.categoryId,
        translations: Cat.translations,
        code: Cat.code,
        name: Cat.name,
        isPublished: Cat.isPublished,
        productCount: Cat.productCount || 0,
        childCategoryCount: Cat.childCategoryCount,
        children,
        categoryPath,
        categoryIds: Object.keys(childCategory),
      };
    }
  });
};

export const selectCategories = createSelector(
  [selectTopCategories, selectChildCategories],
  (catArray, childCategory) => {
    return getCategories(catArray, childCategory, null, []);
  },
);

const findValueByKeyinNested = (
  category: Category,
  categoryId: string,
): string[] | null => {
  if (category.id === categoryId) {
    return category.categoryPath
      ? category.categoryPath.split(";").reverse()
      : [];
  }
  let result: string[] | null = null;
  if (category.children && category.children.length > 0) {
    category.children.forEach((cat) => {
      const value = findValueByKeyinNested(cat, categoryId);
      if (value) result = value;
    });
  }
  return result;
};

export const selectParentCategories = createSelector(
  [selectCategories, (state: AppState, categoryId: string) => categoryId],
  (categories, categoryId) => {
    let result: string[] = ["null"];
    categories.forEach((category) => {
      const value = findValueByKeyinNested(category, categoryId);
      if (value) result = value;
    });
    return !result.includes("null") ? result : null;
  },
);

export const selectCurrentCategory = (state: AppState) =>
  state.category.currentCategory;

export const selectVariantAttributesAndValues = (state: AppState) => {
  return state.category.variantAttributes;
};

export const selectVariantAttributesAndValuesByCategory = createSelector(
  [selectVariantAttributesAndValues],
  (variantAttributes) => {
    let result: { [code: string]: { [key: string]: any } } = {};
    variantAttributes.forEach((attribute) => {
      const { code, descriptions, values } = attribute;
      let mergedValues = result[code]?.values ?? {};
      values.forEach((value: any) => {
        const valueCode = value.code;
        const valueDescriptions = value.descriptions;
        mergedValues = {
          ...mergedValues,
          [valueCode]: {
            descriptions: valueDescriptions,
          },
        };
      });

      result = {
        ...result,
        [code]: {
          descriptions,
          values: mergedValues,
        },
      };
    });
    return result;
  },
);
export const selectTopCategoriesFetched = (state: AppState) => {
  return state.category.topCategoriesFetched;
};

export const selectTopCategoryFetchedForFindProducts = (state: AppState) => {
  return state.category.topCategoriesForFindProductsFetched;
};

export const selectIsCatalogOrStoreChanged = (state: AppState) => {
  return state.category.isCatalogOrStoreChanged;
};

export const selectCategoryProductCount = createSelector(
  [selectTopCategories, selectChildCategories, selectCurrentCategory],
  (topCategories, childCategories, category) => {
    let result = 0;
    const topCategorySearch = topCategories.find(
      (e) => e.categoryId === category,
    );

    const childCategorySearch = Object.entries(childCategories)
      .map((e) => e[1].childCategories.find((el) => el.categoryId === category))
      .filter((childCategory) => childCategory !== undefined);

    if (topCategorySearch) {
      result = topCategorySearch.productCount;
    }
    if (
      childCategorySearch &&
      childCategorySearch.length &&
      childCategorySearch[0]?.productCount
    ) {
      result = childCategorySearch[0].productCount;
    }
    return result;
  },
);

export const selectIsTopCategoriesLoading = (state: AppState) => {
  return state.category.isTopCategoriesLoading;
};
