import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { CATALOG_NAVIGATION_TABS } from "../../utils/Constants";
import {
  BUSINESS_RULES_NAVIGATION_TABS,
  REPORT_TYPE_NAVIGATION_TABS,
} from "../../utils/Constants";

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

interface TabsComponentProps {
  tabsId: string;
  classes?: ClassNameMap;
  view?: string;
  handleChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
  value?: number;
  routes?: {
    name: {
      id: string;
      defaultMessage: string;
    };
    link: string;
    activeIndex: number;
  }[];
  isSequenceUpdated?: boolean;
  userAccountId?: string;
  handleConfirmation?: (checked?: any) => void;
  reportType?: string;
  data?: { [key: string]: string };
  children?: JSX.Element;
}

const TabsComponent: React.FC<TabsComponentProps> = (props) => {
  const {
    tabsId,
    classes,
    view,
    handleChange,
    value,
    routes,
    isSequenceUpdated,
    userAccountId,
    handleConfirmation,
    reportType,
    data,
    children,
  } = props;
  const intl = useIntl();
  const tabsclass = useStyles();

  switch (tabsId) {
    case "CatalogNavigationTabs":
      if (classes && view && handleChange)
        return (
          <Tabs
            value={view}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
            TabIndicatorProps={{ children: <span /> }}
          >
            <Tab
              label={
                <Typography variant="subHeader">
                  {intl.formatMessage({
                    id: "catalogNavigationTabs.products",
                    defaultMessage: "Products",
                  })}
                </Typography>
              }
              value={CATALOG_NAVIGATION_TABS.PRODUCTS}
            />
            <Tab
              label={intl.formatMessage({
                id: "catalogNavigationTabs.facets",
                defaultMessage: "Facets",
              })}
              value={CATALOG_NAVIGATION_TABS.FACETS}
              style={{ display: "none" }}
            />
          </Tabs>
        );
      break;
    case "TabsMenu":
      if (
        classes &&
        handleChange &&
        routes &&
        userAccountId &&
        handleConfirmation &&
        children
      )
        return (
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            classes={{ indicator: classes.indicator }}
            indicatorColor="primary"
            TabIndicatorProps={{ children: <span /> }}
          >
            {routes
              .filter(
                (route) =>
                  route.link !==
                  `/business-rules/${BUSINESS_RULES_NAVIGATION_TABS.AUTOMATION_RULES}`,
              )
              .map((route, index) => (
                <Tab
                  key={`${route}-${index}`}
                  label={
                    <Typography variant="subHeader">
                      {intl.formatMessage(route.name)}
                    </Typography>
                  }
                  className={classes.tab}
                  component={!isSequenceUpdated ? Link : "button"}
                  {...(!isSequenceUpdated
                    ? {
                        to:
                          route.activeIndex === 1
                            ? route.link + `?accountId=${userAccountId}`
                            : route.link,
                      }
                    : route.name.defaultMessage !== "Catalog"
                      ? { onClick: () => handleConfirmation(true) }
                      : {})}
                  {...a11yProps}
                />
              ))}
            {children}
          </Tabs>
        );
      break;
    case "BusinessRulesContainer":
      if (classes && handleChange && routes && userAccountId)
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="disabled tabs example"
            className={classes.tabs}
            classes={{ indicator: classes.indicator }}
            TabIndicatorProps={{ children: <span /> }}
          >
            {routes.map((route, index) => (
              <Tab
                style={{ marginRight: 10, marginLeft: 10 }}
                key={`${route}-${index}`}
                label={
                  <Typography variant="subHeader">
                    {intl.formatMessage(route.name)}
                  </Typography>
                }
                className={classes.tabs}
                component={Link}
                to={route.link + `?accountId=${userAccountId}`}
                {...a11yProps}
              />
            ))}
          </Tabs>
        );
      break;
    case "ReportTypeNavigationTabs":
      if (classes && handleChange)
        return (
          <Tabs
            value={reportType}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="report type tabs"
            className={classes.tabs}
          >
            <Tab
              label={"Product"}
              value={REPORT_TYPE_NAVIGATION_TABS.PRODUCT}
            />
            <Tab
              label={"Category"}
              value={REPORT_TYPE_NAVIGATION_TABS.CATEGORY}
            />
          </Tabs>
        );
      break;
    case "MultipleStoreLanguageTabs":
      if (handleChange)
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Store" />
            <Tab label="Language" />
          </Tabs>
        );
      break;
    default:
      if (value && handleChange && data)
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={tabsclass.root}
          >
            {Object.values(data).map((e) => {
              return <Tab label={e} />;
            })}
          </Tabs>
        );
      if (value && handleChange && children)
        return (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={tabsclass.root}
          >
            {children}
          </Tabs>
        );
  }
  return <></>;
};

export default TabsComponent;
