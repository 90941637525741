import type { Components } from "@mui/material";

export const MuiInputLabel: Components["MuiInputLabel"] = {
  styleOverrides: {
    shrink: {
      transform: "translate(14px, -9px) scale(0.75) !important",
    },
    outlined: {
      transform: "translate(14px, 10.5px) scale(1)",
    },
  },
};
