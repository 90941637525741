import AppState from "../AppState";
import { ColorData } from "./ColorManagementTypes";

export const selectColorList = (state: AppState): ColorData[] => {
  return state.colorManagementProductList.colorManagementProductList;
};

export const selectisFetchingColorList = (state: AppState): boolean => {
  return state.colorManagementProductList.isFetchingColorManagementProductList;
};

export const selectisSequenceUpdated = (state: AppState): boolean => {
  return state.colorManagementProductList.isSequenceUpdated;
};

export const selectSequenceEdited = (state: AppState): boolean => {
  return state.colorManagementProductList.sequenceEdited;
};

export const selectLeadingColorForProduct = (
  state: AppState,
  productId: string,
  categoryId: string,
) =>
  state.colorManagementProductList.imageAndColorByProductIdByCategoryId[
    productId
  ]?.[categoryId]?.color;

export const selectLeadingColorImageForProductInCategory = (
  state: AppState,
  productId: string,
  categoryId: string,
) =>
  state.colorManagementProductList.imageAndColorByProductIdByCategoryId[
    productId
  ]?.[categoryId]?.thumbnail;

export const selectisIsUpdatingLeadingColor = (state: AppState): boolean => {
  return state.colorManagementProductList.isUpdatingLeadingColorCompleted;
};

export const selectVariationAttributes = (state: AppState) => {
  return state.leadingImage.variationAttributes;
};

export const selectLeadingColorId = (state: AppState) => {
  return state.colorManagementProductList.leadingColorId;
};
