import { FacetSequences, FacetBucketSequences } from "./FacetTypes";

const facetsData = `attributeId
categoryId
descriptions {
  displayName
  localeCode
}
facetId
isEnabled
isMultiSelect
isZeroDisplay
limit
sequence
sortMode
storeId
type
attribute {
  code
  descriptions {
    displayName
    localeCode
  }
  isEnum
  isLocalized
  isMultiValue
  isStoreSpecific
  values(where: {productId: {_is_null: true}}) {
    attributeValueId
    code
    sequence
    descriptions {
      displayValue
      sequence
      localeCode
    }
  }
}
bucketMode
unbucketedValuesMode
buckets(order_by: {sequence: asc}) {
  facetBucketId
  isDefault
  sequence
  threshold
  values {
    bucketValue
  }
  descriptions {
    displayValue
    localeCode
  }
}`;

export const getFacetsByCategoryQuery = `subscription FacetsByCategory($storeId: String, $categoryId: String!) {
  CategoryToFacet(where: {categoryId: {_eq: $categoryId}, storeId: {_eq: $storeId}}, order_by: {sequence: asc}) {
    facet {
      ${facetsData}
    }
    sequence
  }
}`;

export const getFacetByCategorySFCCQuery = `subscription GetFacetsByCategoryFromFacetTable($categoryIds: [String!]!) {
  Facet(where: {categoryId: {_in: $categoryIds}}, order_by: {sequence: asc}) {
    ${facetsData}
  }
}`;

export const deleteFacetFromCategoryQuery = `mutation DeleteFacetFromCategory($facetId: String!, $storeId: String, $categoryId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_CategoryToFacet(where: {facetId: {_eq: $facetId}, storeId: {_eq: $storeId}, categoryId: {_eq: $categoryId}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const addFacetToCategoryQuery = `mutation AddFacetToCategory($categoryToFacetInserts: [CategoryToFacet_insert_input!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_CategoryToFacet(objects: $categoryToFacetInserts) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const fetchApplicableFacetsForCategoryQueryGenerator = (
  storeId: string | undefined,
) => `subscription GetAllFacetsApplicableForCategory(${storeId ? "$storeId: String!, " : ""}$catalogId: String!, $categoryId: String!) {
  Facet(where: {attribute: {${storeId ? "toStores: {storeId: {_eq: $storeId}}, " : ""}values: {_or: [{productAttributeValues: {product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}, isVariantOption: {_eq: false}}}, {productAttributeValueOverrides: {isVariantOption: {_eq: false}, product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}}}, {productAttributeLocalizedValues: {isVariantOption: {_eq: false}, product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}}}]}}}) {
    attributeId
    categoryId
    descriptions {
      displayName
      localeCode
    }
    facetId
    isEnabled
    isMultiSelect
    isZeroDisplay
    limit
    sequence
    sortMode
    storeId
    type
  }
}`;

export const updateFacetSequencesQueryGenerator = (
  facetSeqs: FacetSequences[],
) => `mutation UpdateFacetSeuqences($storeId: String!, $categoryId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  ${facetSeqs.map(
    (
      seq,
      index,
    ) => `facet${index} : update_CategoryToFacet(where: {facetId: {_eq: "${seq.facetId}"}, storeId: {_eq: $storeId}, categoryId: {_eq: $categoryId}}, _set: {sequence: ${seq.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateSFCCFacetSequencesQueryGenerator = (
  facetSeqs: FacetSequences[],
) => `mutation UpdateSFCCFacetSequences($syncLogInserts: [SyncLog_insert_input!]!) {
  ${facetSeqs.map(
    (
      seq,
      index,
    ) => `facet${index} : update_Facet(where: {facetId: {_eq: "${seq.facetId}"}}, _set: {sequence: ${seq.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const addBlockInheritanceQuery = `mutation AddBlockInheritance($attributeId: String, $categoryId: String!, $facetId: String!, $type: FacetType_enum!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_Facet(objects: {attributeId: $attributeId, categoryId: $categoryId, facetId: $facetId, isEnabled: false, type: $type}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const removeBlockInheritanceQuery = `mutation RemoveBlockInheritance($facetId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_Facet_by_pk(facetId: $facetId) {
    facetId
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateFacetBucketSequencesQueryGenerator = (
  facetBucketSeqs: FacetBucketSequences[],
) => `mutation UpdateFacetBucketSequences($facetId: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  ${facetBucketSeqs.map(
    (
      seq,
      index,
    ) => `facet${index} : update_FacetBucket(where: {facetId: {_eq: $facetId}, facetBucketId: {_eq: "${seq.facetBucketId}"}}, _set: {sequence: ${seq.sequence}}) {
    affected_rows
  }`,
  )}
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateFacetBucketDescriptionQuery = `mutation UpdateFacetBucketDescription($facetBucketId: String!, $localeCode: String!, $displayValue: String!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_FacetBucketDescription_by_pk(pk_columns: {facetBucketId: $facetBucketId, localeCode: $localeCode}, _set: {displayValue: $displayValue}) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const addFacetBucketQuery = `mutation AddFacetBucket($facetBucket: [FacetBucket_insert_input!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_FacetBucket(objects: $facetBucket, on_conflict: {constraint: FacetBucket_pkey, update_columns: sequence}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const insertFacetBucketValueQuery = `mutation InsertFacetBucketValue($bucketValueInserts: [FacetBucketValue_insert_input!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  insert_FacetBucketValue(objects: $bucketValueInserts) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const deleteFacetBucketValueQuery = `mutation DeleteFacetBucketValue($facetBucketId: String!, $bucketValues: [String!]!, $syncLogInserts: [SyncLog_insert_input!]!) {
  delete_FacetBucketValue(where: {facetBucketId: {_eq: $facetBucketId}, bucketValue: {_in: $bucketValues}}) {
    affected_rows
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;

export const updateFacetBucketQuery = `mutation UpdateFacetBucket($facetBucketId: String!, $threshold: numeric!, $syncLogInserts: [SyncLog_insert_input!]!) {
  update_FacetBucket_by_pk(pk_columns: {facetBucketId: $facetBucketId}, _set: {threshold: $threshold}) {
    updatedAt
  }
  insert_SyncLog(objects: $syncLogInserts) {
    affected_rows
  }
}`;
