import {
  AddProductsToCategoryAction,
  AddProductsToCategoryPayload,
  LOAD_ALL_CHILD_CATEGORIES,
  LOAD_All_TOP_CATEGORIES,
  LOAD_CATALOGS,
  LOAD_STORES,
  LoadAllChildCategoriesAction,
  LoadAllTopCategoriesAction,
  LoadCatalogsAction,
  LoadStoresAction,
  POST_PRODUCTS_TO_CATEGORIES,
  RESET_PRODUCT_ADDED_STATE,
} from "./AddProductCategoriesTypes";
import { decodeNodeId } from "./AddProductCategoriesUtils";

export const LoadStoreList = (): LoadStoresAction => {
  return {
    type: LOAD_STORES,
  };
};

export const LoadAllCatalogs = (
  storeId: string,
  nodeId: string,
): LoadCatalogsAction => {
  return {
    payload: {
      storeId,
    },
    type: LOAD_CATALOGS,
  };
};

export const LoadAllTopCategoriesByCatalogId = (
  catalogId: string,
  localeId: string,
  storeId: string,
  nodeId: string,
): LoadAllTopCategoriesAction => {
  return {
    type: LOAD_All_TOP_CATEGORIES,
    payload: {
      catalogId,
      localeId,
      storeId,
    },
  };
};

export const LoadAllChildCategories = (
  catalogId: string,
  categoryId: string,
  localeId: string,
  storeId: string,
  nodeId: string,
): LoadAllChildCategoriesAction => {
  return {
    type: LOAD_ALL_CHILD_CATEGORIES,
    payload: {
      catalogId,
      categoryId,
      localeId,
      storeId,
      nodeId,
    },
  };
};

export const ResetProductAddedState = () => {
  return {
    type: RESET_PRODUCT_ADDED_STATE,
  };
};

export const AddProductsToCategory = (
  payload: AddProductsToCategoryPayload,
): AddProductsToCategoryAction => {
  return {
    payload,
    type: POST_PRODUCTS_TO_CATEGORIES,
  };
};

export const LoadChildNodes = (
  nodeId: string | null,
  localeId: string,
): any => {
  if (nodeId === null) {
    return LoadStoreList();
  }
  const parts = decodeNodeId(nodeId);
  if (parts.length === 1) {
    return LoadAllCatalogs(parts[0], nodeId);
  }
  if (parts.length === 2) {
    return LoadAllTopCategoriesByCatalogId(
      parts[1],
      localeId,
      parts[0],
      nodeId,
    );
  }

  return LoadAllChildCategories(
    parts[1],
    parts[parts.length - 1],
    localeId,
    parts[0],
    nodeId,
  );
};
