const CURRENT_YEAR = new Date().getFullYear();
const PRODUCT_NAME = "Smart Merchandiser";

export function CopyRight() {
  return (
    <span>
      Copyright © {CURRENT_YEAR} {PRODUCT_NAME}{" "}
    </span>
  );
}
