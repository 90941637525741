import type { Components } from "@mui/material";

export const MuiLink: Components["MuiLink"] = {
  styleOverrides: {
    root: {
      color: "#654321", // Secondary color for links
      "&:hover": {
        color: "#432109", // Darker shade for hover state
      },
    },
  },
};
