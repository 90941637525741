import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import ButtonComponent from "../common/ButtonComponent";
import ReportTable from "./ReportTable";
import DateFilter from "./DateFilter";
import FieldsFilter from "./FieldsFilter";
import { getDateFromString } from "../../utils/TimeUtils";
import { selectReportDateRange } from "../../store/report/ReportSelectors";
import InteractiveMenu from "../common/InteractiveMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: "8px 24px",
      width: "100%",
      overflow: "hidden",
    },
    col: {
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    shrinkRow: {
      display: "flex",
      flexDirection: "row",
      padding: "12px",
    },
    reportTableRow: {
      display: "flex",
      flexDirection: "row",
      padding: "12px",
      flex: 1,
      overflow: "hidden",
    },
    buttonContainer: {
      margin: "12px",
      minWidth: "200px",
    },
    formControl: {
      padding: "12px",
    },
    rightAlign: {
      marginLeft: "auto",
      margin: "12px",
    },
    textField: {
      "& .MuiInputLabel-root": {
        fontSize: "0.875rem",
      },
      "& .MuiInput-root": {
        fontSize: "0.875rem",
      },
      "& .MuiInput-underline::before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline::after": {
        borderBottomColor: "#000",
      },
      "& .MuiAutocomplete-tag": {
        display: "none",
      },
    },
    item: {
      width: "100px",
    },
    title: {
      fontWeight: 600,
    },
    breadcrumb: {
      "& .MuiAutocomplete-textSecondary": {
        color: "black",
      },
    },
  }),
);

interface Props {}
const Report: React.FC<Props> = () => {
  const classes = useStyles();
  const selectedDateRange = useSelector(selectReportDateRange);

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 7);
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() - 1);
  const fromDate =
    selectedDateRange.startDate !== ""
      ? getDateFromString(selectedDateRange.startDate)
      : defaultStartDate;
  const toDate =
    selectedDateRange.endDate !== ""
      ? getDateFromString(selectedDateRange.endDate)
      : defaultEndDate;
  const displayDate = `${fromDate.toDateString()} - ${toDate.toDateString()}`;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.buttonContainer}>
          <FieldsFilter />
        </div>
        <div className={classes.buttonContainer}>
          <DateFilter />
        </div>
        <div className={classes.buttonContainer}>
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            onClick={() => {
              console.log("SAVE");
            }}
          >
            SAVE
          </ButtonComponent>
        </div>
        <div className={classes.rightAlign}>
          <ButtonComponent
            aria-describedby={"export"}
            variant="outlined"
            color="primary"
            onClick={handleClick}
          >
            Export
          </ButtonComponent>
          <InteractiveMenu
            menuId="MenuItemWithSubItems"
            handleClose={handleClose}
            open={open}
            classes={classes}
            anchorEl={anchorEl}
          />
        </div>
      </div>
      <div className={classes.shrinkRow}>
        <Typography className={classes.title}>Report</Typography>
      </div>
      <div className={classes.shrinkRow}>
        <div className={classes.col}>
          <Typography variant="body2">Date: {displayDate}</Typography>
        </div>
      </div>
      <div className={classes.reportTableRow}>
        <ReportTable />
      </div>
    </div>
  );
};

export default Report;
