import { withStyles } from "@material-ui/core/styles";
import { Container, Theme } from "@mui/material";
import styled from "styled-components";

export const useStyles = (theme: Theme) => {
  return {
    selectedBoxStyle: {
      border: "1px solid " + theme.palette.text.primary,
      borderRadius: 3,
      width: 22,
      height: 22,
      cursor: "pointer",
    },
    defaultBorder: {
      marginRight: 2,
      width: 24,
      height: 24,
      cursor: "pointer",
    },
    displaySWatch: {
      display: "block",
    },
    hideSwatch: {
      display: "none",
    },
    icon: {
      marginRight: 0,
    },
    popoverStyles: {
      "& .MuiPaper-root": {
        maxWidth: 122,
        maxHeight: 124,
        padding: "10px !important",
        border: "1px solid" + theme.palette.secondary.main,
      },
    },
    swatchBox: {
      display: "flex",
    },
    productType: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left !important" as any,
      paddingBottom: "0px !important" as any,
      width: "75%",
    },
    productCode: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "75%",
    },
    smallFontSize: {
      fontSize: "11px",
    },
    colorSwatchImageContainer: {
      height: "20px",
      width: "20px",
      display: "inline-block",
    },
    colorSwatchImage: {
      width: "100%",
      height: "100%",
      borderRadius: "2px",
    },
    redBorder: {
      border: "1px solid red",
      borderRadius: "2px",
    },
    yellowBorder: {
      border: "1px solid" + theme.palette.neutral.yellow,
      borderRadius: "2px",
    },
    greyBorder: {
      border: "1px solid" + theme.palette.neutral.white,
      borderRadius: "2px",
    },
    ratingsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    ratingContainerMargin: {
      marginTop: "-2px",
    },
    ratingTextPadding: {
      paddingLeft: "5px",
      marginTop: "-1px",
    },
    swatchButton: {
      paddingTop: 1,
    },
    position: {
      display: "none",
    },
    swatch: {
      marginRight: 3,
    },
    inventoryColor: {
      color: "#B82A1D !important",
    },
  };
};

export const StyledProductTileContent = styled.div`
  ${({ theme }) => `
      padding-bottom: 15px;
      padding-left: 13%;
      & .catentryType {
        font-weight: bold;
      }
      & .inventory{
        color: ${theme.palette.secondary.main};
      } 
 `}
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-top: 10px;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flexwrap: wrap;
  justify-content: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCircularDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  cursor: pointer;
`;

export const StyledContainer = withStyles({
  root: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: "auto",
    margin: "5px",
    width: "98% !important",
    backdropFilter: "blur(0.5px)",
  },
})(Container);

export const StyledSwatchDiv = styled.div`
  text-align: center;
  cursor: pointer;
  padding-top: 15px;
`;
