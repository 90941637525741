import { deleteData, getData, putData } from "../../services/ApiService";
import { call, put, takeLatest } from "redux-saga/effects";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import {
  DELETE_PRODUCT_ALERTS_SCHEDULE_INFO,
  FETCH_PRODUCT_ALERTS_SCHEDULE_DATA,
  PRODUCT_ALERTS_SCHEDULE_NOT_FOUND,
  UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO,
} from "./ProductAlertsScheduleType";
import { addGlobalAlertState } from "../global-alert/GlobalAlertActions";

function* getProductAlertsScheduleData() {
  try {
    const actionType = FETCH_PRODUCT_ALERTS_SCHEDULE_DATA;
    const constName = Object.keys({
      FETCH_PRODUCT_ALERTS_SCHEDULE_DATA,
    })[0].toString();
    const endpoint = acquireEndpoint(constName);
    const optionsObj = {
      showGenericError: false,
    };
    const result = yield call(
      callApi,
      actionType,
      getData,
      null,
      endpoint,
      {},
      optionsObj,
    );
    if (
      result &&
      result.type === actionType.FAILURE &&
      result.reason !== PRODUCT_ALERTS_SCHEDULE_NOT_FOUND
    ) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productAlertScheduleOperations.getProductAlertsScheduleFailure",
                defaultMessage: "Unable to fetch Product Alerts Schedule Data",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* updateProductAlertsSchedule(action) {
  const actionType = UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO;
  try {
    const constName = Object.keys({
      UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO,
    })[0].toString();
    const endpoint = acquireEndpoint(constName);
    const result = yield call(
      callApi,
      actionType,
      putData,
      action.payload,
      endpoint,
    );
    if (result && result.type === actionType.SUCCESS) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productAlertScheduleOperations.updateProductAlertsSchedule",
                defaultMessage: "Product Alerts Schedule Created Successfully",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productAlertScheduleOperations.updateProductAlertsScheduleFailure",
                defaultMessage: "Failed to create Product Alerts Schedule",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* deleteProductAlertsSchedule() {
  let actionType = DELETE_PRODUCT_ALERTS_SCHEDULE_INFO;
  try {
    const constName = Object.keys({
      DELETE_PRODUCT_ALERTS_SCHEDULE_INFO,
    })[0].toString();
    const endpoint = acquireEndpoint(constName);

    const result = yield call(callApi, actionType, deleteData, null, endpoint);
    if (result?.type === actionType.SUCCESS) {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productAlertScheduleOperations.deleteProductAlertsSchedule",
                defaultMessage: "Product Alerts Schedule Removed Successfully",
              },
              severity: "success",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "productAlertScheduleOperations.deleteProductAlertsScheduleFailure",
                defaultMessage: "Unable to remove Product Alerts Schedule",
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } catch (e: any) {
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

export function* watchLoadProductAlertsScheduleData() {
  yield takeLatest(
    FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.REQUEST,
    getProductAlertsScheduleData,
  );
}

export function* watchUpdateProductAlertsScheduleData() {
  yield takeLatest(
    UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST,
    updateProductAlertsSchedule,
  );
}

export function* watchDeleteProductAlertsScheduleData() {
  yield takeLatest(
    DELETE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST,
    deleteProductAlertsSchedule,
  );
}
