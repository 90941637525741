import {
  GET_ADAPTER_CONFIG_DETAILS,
  LoadAdapterConfigAction,
  RESET_ADAPTER_DETAILS,
} from "./AdapterConfigTypes";

export const fetchAdapterDetails = (): LoadAdapterConfigAction => {
  return {
    type: GET_ADAPTER_CONFIG_DETAILS,
  };
};

export const resetAdapterDetails = () => {
  return {
    type: RESET_ADAPTER_DETAILS,
  };
};
