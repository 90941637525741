import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { ColorManagementProductData } from "../store/color-management/ColorManagementTypes";
import { CircularProgress } from "@mui/material";
import CheckboxComponent from "./common/Checkbox";
import styled from "styled-components";

interface Props {
  product: ColorManagementProductData | undefined | null;
  flag: boolean | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isVariation?: boolean;
}

// Styled components for the container and wrappers
const ContainerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 2px !important;
    margin-right: 10px !important;
    height: 25px !important;
  }
`;

const StyledLabel = styled.p`
  font-size: 0.75rem; // Update the font size here
`;

const PublishControl: React.FC<Props> = (props) => {
  const { product, flag, onChange, isVariation = false } = props;

  return (
    <ContainerDiv>
      {product?.isUpdatingPublishFlag && !isVariation ? (
        <SpinnerWrapper>
          <CircularProgress size="20px" />
        </SpinnerWrapper>
      ) : (
        <StyledFormControlLabel
          control={
            <CheckboxComponent
              checkboxId="PublishControl"
              checked={flag ?? false}
              onChange={onChange}
            />
          }
          label={<StyledLabel>Published</StyledLabel>}
        />
      )}
    </ContainerDiv>
  );
};

export default React.memo(PublishControl);
