export const topCategoriesQuery = `query GetTopCategories($catalogId: String)  {
  CatalogToCategory(where: {catalogId: {_eq: $catalogId}}) {
    catalogId
    categoryId
    category {
      code
      isPublished
      toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
        aggregate {
          count
        }
      }
      descriptions {
        name
        isPublished
        localeCode
      }
    }
  }
}`;

export const topCategoriesForReportQuery = `query GetTopCategories($catalogId: String)  {
  CatalogToCategory(where: {catalogId: {_eq: $catalogId}}) {
    catalogId
    categoryId
    category {
      code
      isPublished
      toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
        aggregate {
          count
        }
      }
      descriptions {
        name
        isPublished
        localeCode
      }
      toProducts_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}`;

export const childCategoriesQuery = `query ChildCategories($categoryId: String!, $catalogId: String!) {
  CategoryToCategory_aggregate(where: {parentCategoryId: {_eq: $categoryId}, catalogId: {_eq: $catalogId}}) {
    nodes {
      childCategoryId
      parentCategoryId
      childCategory {
        code
        isPublished
        toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
          aggregate {
            count
          }
        }
        descriptions {
          name
          isPublished
          localeCode
        }
      }
    }
  }
}`;

export const childCategoriesForReportQuery = `query ChildCategories($categoryId: String!, $catalogId: String!) {
  CategoryToCategory_aggregate(where: {parentCategoryId: {_eq: $categoryId}, catalogId: {_eq: $catalogId}}) {
    nodes {
      childCategoryId
      parentCategoryId
      childCategory {
        code
        isPublished
        toChildCategories_aggregate(where: {catalogId: {_eq: $catalogId}}) {
          aggregate {
            count
          }
        }
        descriptions {
          name
          isPublished
          localeCode
        }
        toProducts_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}`;

export const productCountQuery = `subscription ProductCount($catalogId: String, $categoryId: String, $localeId: String, $storeId: String) {
  CategoryToProduct_aggregate(where: {categoryId: {_eq: $categoryId}, catalogId: {_eq: $catalogId}, product: {type: {isBrowsable: {_eq: true}}}}) {
    aggregate {
      count
    }
    nodes {
      product {
        isPublished
        descriptions(where: {localeCode: {_eq: $localeId}}) {
          isPublished
        }
        overrides(where: {storeId: {_eq: $storeId}}) {
          isPublished
        }
      }
    }
  }
}`;

export const productAttributesByCategoryQueryGenerator = (
  storeId: string | undefined,
) => `subscription ProductAttributesByCategory($catalogId: String, $categoryId: String, ${storeId ? "$storeId: String" : ""}) {
  Attribute(where: {${storeId ? "toStores: {storeId: {_eq: $storeId}}, " : ""}values: {_or: [{productAttributeValues: {product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}, isVariantOption: {_eq: false}}}, {productAttributeValueOverrides: {isVariantOption: {_eq: false}, product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}}}, {productAttributeLocalizedValues: {isVariantOption: {_eq: false}, product: {toCategories: {catalogId: {_eq: $catalogId}, categoryId: {_eq: $categoryId}}}}}]}, isEnum: {_eq: true}}, order_by: {attributeId: asc}) {
    isMultiValue
    attributeId
    code
    descriptions {
      displayName
      localeCode
    }
    values(where: {productId: {_is_null: true}}, order_by: {sequence: asc, attributeValueId: asc}) {
      attributeValueId
      code
      descriptions {
        displayValue
        localeCode
      }
    }
    isStoreSpecific
    isLocalized
  }
}`;

export const variantAttributesAndValuesByCategoryQuery = `subscription VariantAttributesAndValuesByCategory($categoryId: String) {
  Attribute(where: {productAttributes: {product: {toCategories: {categoryId: {_eq: $categoryId}}}, isVariantOption: {_eq: true}}, isColor: {_eq: false}}) {
    code
    descriptions {
      displayName
      localeCode
    }
    values {
      code
      descriptions {
        localeCode
        displayValue
      }
    }
  }
}`;
