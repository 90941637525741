import {
  ReportReducersActions,
  SET_REPORT_TYPE,
  SET_REPORT_STORE_ID,
  SET_REPORT_CATALOG_ID,
  SET_REPORT_CATEGORIES,
  ResetReportAction,
  RESET_REPORT_STATE,
  SET_REPORT_FIELDS,
  SET_REPORT_DATERANGE,
} from "./ReportTypes";

export const setReportType = (type: string): ReportReducersActions => {
  return {
    payload: {
      type,
    },
    type: SET_REPORT_TYPE,
  };
};

export const setReportStoreId = (storeId: string): ReportReducersActions => {
  return {
    payload: {
      storeId,
    },
    type: SET_REPORT_STORE_ID,
  };
};

export const setReportCatalogId = (
  catalogId: string,
): ReportReducersActions => {
  return {
    payload: {
      catalogId,
    },
    type: SET_REPORT_CATALOG_ID,
  };
};

export const setReportCategories = (
  categories: string[],
): ReportReducersActions => {
  return {
    payload: {
      categories,
    },
    type: SET_REPORT_CATEGORIES,
  };
};

export const setReportFields = (fields: string[]): ReportReducersActions => {
  return {
    payload: {
      fields,
    },
    type: SET_REPORT_FIELDS,
  };
};

export const setReportDateRange = (
  startDate: string,
  endDate: string,
): ReportReducersActions => {
  return {
    payload: {
      dateRange: {
        startDate,
        endDate,
      },
    },
    type: SET_REPORT_DATERANGE,
  };
};

export const resetReportState = (): ResetReportAction => {
  return {
    type: RESET_REPORT_STATE,
  };
};
