import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import AppState from "../../store/AppState";
import { ReactComponent as PinIcon } from "../../assets/thumb-tack.svg";
import {
  selectPinnedProducts,
  selectEditSequenceList,
  selectNumberOfPinnedProducts,
  selectIsPinnedProductsMode,
} from "../../store/product-list/ProductListSelectors";
import { selectIsFetchedBySearch } from "../../store/product/ProductSelectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "right",
      margin: "5px",
    },
    borderStyle: {
      border: "3px dotted",
    },
    pinIcon: {
      width: "18px",
      height: "18px",
      margin: "1em 1em 0 0",
    },
    newPinIcon: {
      opacity: 0.4,
      width: "18px",
      height: "18px",
      margin: "1em 1em 0 0",
    },
  }),
);

interface Props {
  productId: string;
  index: number;
}

const PinnedProductOverlay: React.FC<Props> = ({ productId, index }) => {
  const classes = useStyles();

  const isFetchedBySearch = useSelector((state: AppState) =>
    selectIsFetchedBySearch(state),
  );

  const pinnedProducts = useSelector((state: AppState) =>
    selectPinnedProducts(state),
  );

  const numberOfPinnedProducts = useSelector((state: AppState) =>
    selectNumberOfPinnedProducts(state),
  );

  const productList = useSelector((state: AppState) =>
    selectEditSequenceList(state),
  );

  const isPinnedProductsMode = useSelector((state: AppState) =>
    selectIsPinnedProductsMode(state),
  );

  const newPinnedProducts = productList
    .map((product) => product.productId)
    .slice(0, numberOfPinnedProducts);

  const isPinnedProduct =
    index < newPinnedProducts.length && index < pinnedProducts.length
      ? newPinnedProducts[index] === pinnedProducts[index]
      : false;
  const isNewPinnedProduct = index < newPinnedProducts.length;

  return (
    <>
      {(isPinnedProduct || isNewPinnedProduct) && !isFetchedBySearch && (
        <div
          className={`${classes.root} ${isPinnedProductsMode && classes.borderStyle}`}
        >
          {isPinnedProduct && <PinIcon className={classes.pinIcon} />}
          {!isPinnedProduct && isNewPinnedProduct && (
            <PinIcon className={classes.newPinIcon} />
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(PinnedProductOverlay);
