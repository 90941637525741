/**
 * Checks if the leading image thumbnail matches the product image data.
 * @param {string} leadingImageThumbnail - The thumbnail of the leading image.
 * @param {Object} productImageData - The product image data.
 * @param {string} productCode - The product code.
 * @returns {boolean} - True if there is a match, false otherwise.
 */
export const matchLeadingImage = (
  leadingImageThumbnail,
  productImageData,
  productCode,
) => {
  if (
    !productImageData ||
    !productImageData.imageGroups ||
    productImageData.imageGroups.length === 0
  ) {
    return false;
  }

  return productImageData.imageGroups.some(
    (imageGroup) =>
      imageGroup.images?.some(
        (image) => image.thumbnail === leadingImageThumbnail,
      ) &&
      imageGroup.variationAttributes?.some(
        (attr) =>
          attr.attributeId === productImageData.colorVariationAttributeId &&
          attr.values?.some((attrValue) => attrValue?.value === productCode),
      ),
  );
};
