/**
 * Divides the input array into fixed sized arrays or "chunks".
 * @template T
 * @param {number} chunkSize - the max size of each chunk
 * @param {T[]} arr - the input array
 * @returns {T[][]}
 */
export const chunk = <T>(chunkSize: number, arr: T[]): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

export const sum = (arr: number[]) => arr.reduce((acc, cur) => acc + cur, 0);

export const avg = (arr: number[]) => sum(arr) / arr.length;

export const min = (arr: number[]) => Math.min(...arr);

export const max = (arr: number[]) => Math.max(...arr);
