import React from "react";
import { useSelector } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppState from "../../store/AppState";
import { selectProductInventoryBySKUAttributeMapSFCC } from "../../store/product/ProductSelectors";
import { ProductSwatchType } from "../../store/product/ProductTypes";
import { selectInventoryAttribute } from "../../store/overlay/OverlaySelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import {
  getProductSwatchColorSFCC,
  getProductSwatchImageSFCC,
} from "../../utils/ProductUtil";
import TableComponent from "../common/Table";

interface Props {
  productId: string;
  swatches: ProductSwatchType[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderTop: "1px solid",
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      height: "100px",
      zIndex: 2,
    },
    tableCell: {
      padding: 0,
      border: "none",
    },
    swatchTableCell: {
      padding: 0,
      paddingRight: theme.spacing(1),
      border: "none",
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      "& span": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "1rem",
    },
    cell: {
      fontSize: "12px",
    },
  }),
);

const ProductInventoryTable: React.FC<Props> = (props) => {
  const { productId, swatches } = props;
  const classes = useStyles();
  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const publishedSwatches = swatches.filter((swatch) => swatch.isPublished);
  let productSwatches: { [key: string]: any } = {};
  publishedSwatches.forEach((swatch) => {
    const color = getProductSwatchColorSFCC(
      swatch,
      currentLocaleId,
      defaultLocaleId,
    );
    const swatchImage = getProductSwatchImageSFCC(
      swatch,
      currentLocaleId,
      defaultLocaleId,
    );
    if (swatchImage) {
      productSwatches = {
        ...productSwatches,
        [color]: {
          color,
          swatchImage,
          attributeValueId: swatch.attributeValueId,
        },
      };
    }
  });
  const selectedInventoryAttribute = useSelector(selectInventoryAttribute);
  const productInventoryByAttributeMap = useSelector((state: AppState) =>
    selectProductInventoryBySKUAttributeMapSFCC(
      state,
      productId,
      currentLocaleId,
      defaultLocaleId,
    ),
  );

  return (
    <TableComponent
      tableId="ProductInventoryTable"
      classes={classes}
      selectedInventoryAttribute={selectedInventoryAttribute}
      productInventoryByAttributeMap={productInventoryByAttributeMap}
      productSwatches={productSwatches}
      productId={productId}
    />
  );
};

export default React.memo(ProductInventoryTable);
