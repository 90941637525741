import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "1px",
      transition: "transform 0.4s",
    },
    imgWrapper: {
      height: "22px",
      width: "22px",
      borderRadius: "5px",
      border: " 2px solid #fff",
    },
    img: {
      border: "1px solid #ebeced",
      borderRadius: "5px",
      width: "18px",
      height: "18px",
    },
  }),
);

interface Props {
  color: string;
  swatchImage: string;
  productImage: string;
  attributeValueId: string;
}

const ProductInventorySwatch: React.FC<Props> = (props) => {
  const classes = useStyles();
  const imagePrefix = useSelector((state: AppState) =>
    selectConfigValue(state, "productImagePrefix"),
  );

  return (
    <div className={classes.container}>
      <div className={classes.imgWrapper}>
        <img
          src={`${imagePrefix}${props.swatchImage}`}
          alt={props.color}
          className={classes.img}
        />
      </div>
    </div>
  );
};

export default React.memo(ProductInventorySwatch);
