import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../../../store/AppState";
import { selectIsModalOpen } from "../../../../../store/modal/ModalSelectors";
import { Box, CircularProgress } from "@material-ui/core";
import { Typography, useTheme } from "@mui/material";
import { setModalState } from "../../../../../store/modal/ModalActions";
import { useIntl } from "react-intl";
import {
  deleteAutomationRuleByJobId,
  loadAutomationRulesList,
} from "../../../../../store/businessRules-list/BusinessRulesListActions";
import { selectIsAutomationRuleDeleted } from "../../../../../store/businessRules-list/BusinessRuleslistSelectors";
import CustomDialog from "../../../../common/DialogContainer";
import ButtonComponent from "../../../../common/ButtonComponent";
import { styled, Theme } from "@mui/material";

const styleClass = {
  overlay: "overlay",
};
const StyledDiv = styled("div")(({ theme }) => ({
  [`&.${styleClass.overlay}`]: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 99999,
    cursor: "pointer",
  },
}));

const useStyles = (theme: Theme) => {
  return {
    confirmationText: {
      fontSize: 18,
      marginBottom: 10,
      fontWeight: 400,
      color: theme.palette.text.primary,
      fontFamily: "Helvetica",
    },
    btnParent: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "20px",
    },
  };
};

const AutomationRulesDeleteModal = ({ jobId, name }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();
  const [progress, setProgress] = useState(false);
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "DeleteAutomationRuleModal"),
  );
  const isDeleted = useSelector(selectIsAutomationRuleDeleted);
  React.useEffect(() => {
    if (isDeleted) {
      setProgress(false);
      dispatch(setModalState("DeleteAutomationRuleModal", false));
      dispatch(loadAutomationRulesList());
    }
  }, [isDeleted, dispatch]);
  const handleClose = () => {
    dispatch(setModalState("DeleteAutomationRuleModal", false));
  };

  const handleDeleteAutomationRuleByRuleId = (jobId: string, name: string) => {
    setProgress(true);
    dispatch(deleteAutomationRuleByJobId({ jobId, name }));
    dispatch(setModalState("DeleteAutomationRuleModal", false));
  };

  const deletScheduleModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "deleteScheduleModal.deleteHeader",
          defaultMessage: "Delete Schedule",
        })}
      </Typography>
    );
  };

  const deleteScheduleModalContent = () => {
    return (
      <>
        <Box sx={classes.confirmationText}>
          <Typography variant="subtitle1">
            {intl.formatMessage({
              id: "deleteScheduleModal.delete",
              defaultMessage: "Are you sure you want to delete the ",
            })}{" "}
            {name + " ?"}
          </Typography>
        </Box>
        <Box sx={classes.btnParent}>
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: "deleteSortingRuleModal.no",
              defaultMessage: "No",
            })}
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            variant="contained"
            justifyContent="center"
            onClick={() => handleDeleteAutomationRuleByRuleId(jobId, name)}
          >
            {intl.formatMessage({
              id: "deleteSortingRuleModal.yes",
              defaultMessage: "Yes",
            })}
          </ButtonComponent>
        </Box>
      </>
    );
  };

  return (
    <div>
      {progress && (
        <StyledDiv className={styleClass.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      <CustomDialog
        open={isModalOpen}
        onClose={handleClose}
        title={deletScheduleModalTitle()}
        fullWidth
        maxWidth={"sm"}
      >
        {deleteScheduleModalContent()}
      </CustomDialog>
    </div>
  );
};

export default AutomationRulesDeleteModal;
