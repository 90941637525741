import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import { OVERLAY_TABS } from "../../utils/Constants";
import { getProductsSocialAnalytics } from "../../store/product-social-analytics/SocialAnalyticsActions";
import { selectWorkingProductSequence } from "../../store/product-list/ProductListSelectors";

const RatingsOverlayLabel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const overlay = useSelector(selectOverlay);
  const productCodes = useSelector(selectWorkingProductSequence);

  useEffect(
    () => {
      if (overlay === OVERLAY_TABS.RATINGS && productCodes.length > 0) {
        const payload = {
          productCodes,
          isRefreshingAllProducts: false,
        };
        dispatch(getProductsSocialAnalytics(payload));
      }
    },
    // eslint-disable-next-line
    [productCodes, overlay, dispatch],
  );

  return (
    <>
      {intl.formatMessage({
        id: "overlayTabs.socialLabel",
        defaultMessage: "Ratings",
      })}
    </>
  );
};

export default React.memo(RatingsOverlayLabel);
