import AppState from "../AppState";

export const selectIsLoading = (
  state: AppState,
  loaderType: string,
): boolean => {
  return state.loader[loaderType]
    ? state.loader[loaderType]["isLoading"]
    : false;
};
