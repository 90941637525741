interface PlatformDefaults {
  bulkUpdateEnabled: string;
  colorSequenceMode: string;
  attrValSequenceLocaleSpecific: string;
  defaultLocaleId: string;
  displaySequences: string;
  advancedFindEnabled: string;
  inventoryThreshold: string;
  graphQLStoreRelationshipEnabled: string;
  showProductCountForFacets: string;
  leadingColorEnabled: string;
  enableProductAlerts: string;
}

export const PLATFORM_DEFAULTS: { [name: string]: PlatformDefaults } = {
  WCS: {
    bulkUpdateEnabled: "true",
    colorSequenceMode: "AttrVal",
    attrValSequenceLocaleSpecific: "true",
    defaultLocaleId: "en_US",
    displaySequences: "true",
    advancedFindEnabled: "false",
    inventoryThreshold: "10",
    graphQLStoreRelationshipEnabled: "true",
    showProductCountForFacets: "true",
    leadingColorEnabled: "true,",
    enableProductAlerts: "false",
  },
  SFCC: {
    bulkUpdateEnabled: "false",
    colorSequenceMode: "AttrVal",
    attrValSequenceLocaleSpecific: "false",
    defaultLocaleId: "x-default",
    displaySequences: "false",
    advancedFindEnabled: "false",
    inventoryThreshold: "10",
    graphQLStoreRelationshipEnabled: "false",
    showProductCountForFacets: "false",
    leadingColorEnabled: "false,",
    enableProductAlerts: "false",
  },
};

export const PLATFORM_INDEPENDENT_DEFAULTS = {
  bulkUpdateEnabled: "false",
  colorSequenceMode: "AttrVal",
  attrValSequenceLocaleSpecific: "false",
  displaySequences: "false",
  advancedFindEnabled: "false",
  inventoryThreshold: "10",
  graphQLStoreRelationshipEnabled: "false",
  showProductCountForFacets: "false",
  leadingColorEnabled: "false,",
  enableProductAlerts: "false",
};
