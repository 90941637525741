import { Auth0Client } from "@auth0/auth0-spa-js";
import AppEnv from "../utils/AppEnv";

let loggedIn = false;
let logoutSupported = false;
let user: any = null;
let isUserLoggedOut = false;

const CLIENT_ID = AppEnv.AUTH0_CLIENT_ID;
const DOMAIN = AppEnv.AUTH0_DOMAIN;
const AUDIENCE = AppEnv.AUTH0_AUDIENCE || "https://api.smartmerchandiser.com/";

const auth0 = new Auth0Client({
  domain: DOMAIN,
  clientId: CLIENT_ID,
});

export const initUserService = async () => {
  const auth0Redirect = getAuth0RedirectQueryParam();
  if (auth0Redirect === "signin") {
    await auth0.handleRedirectCallback();
    user = await auth0.getUser();
    window.history.replaceState(
      null,
      "",
      window.location.origin + window.location.pathname + window.location.hash,
    );
    loggedIn = true;
  } else {
    try {
      try {
        await auth0.getTokenSilently();
      } catch (error: any) {
        if (error.Error !== "login_required") {
          isUserLoggedOut = true;
          await auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: window.location.origin + "?auth0_redirect=signin",
              audience: AUDIENCE,
            },
          });
        }
      }
      user = await auth0.getUser();
      loggedIn = true;
    } catch (err) {
      console.error(err);
      loggedIn = false;
    }
  }

  logoutSupported = true;
};

const getAuth0RedirectQueryParam = () => {
  const queryString = window.location.search;
  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get("auth0_redirect");
  } else {
    return null;
  }
};

export const isLoggedIn = () => loggedIn;

export const userLoggedOut = () => isUserLoggedOut;

export const login = async () =>
  await auth0.loginWithRedirect({
    authorizationParams: {
      redirect_uri: window.location.origin + "?auth0_redirect=signin",
      audience: AUDIENCE,
    },
  });

export const logout = () => auth0.logout();

export const getUser = async () => await auth0.getUser();

export const isLogoutSupported = () => logoutSupported;

export const getUserId = () => user?.sub;

async function fetchToken() {
  return await auth0.getTokenSilently({
    authorizationParams: {
      audience: AUDIENCE,
    },
  });
}

export const getToken = async () => {
  try {
    return await fetchToken();
  } catch (error: any) {
    if (error.error === "login_required" && user?.sub) {
      await auth0.loginWithPopup({
        authorizationParams: {
          audience: "https://api.smartmerchandiser.com/",
          prompt: "login",
        },
      });
      user = await auth0.getUser();
      loggedIn = true;
      return await fetchToken();
    }
  }
};

export const getUserName = () => user?.name;

export const getUserEmail = () => user?.email;

export const getUserPicture = () => user?.picture;

export const isProfileAccessible = () => false;
