import type { Components } from "@mui/material";

export const MuiCheckbox: Components["MuiCheckbox"] = {
  styleOverrides: {
    root: {
      "&.Mui-checked": {
        color: "#f18308",
      },
      "&.MuiCheckbox-indeterminate": {
        color: "#65727c",
      },
    },
  },
};
