import {
  FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS,
  ProductSocialAnalyticsPayload,
} from "./SocialAnalyticsTypes";

export const getProductsSocialAnalytics = (
  payload: ProductSocialAnalyticsPayload,
) => {
  return {
    payload,
    type: FETCH_QUERY_PRODUCT_SOCIAL_ANALYTICS,
  };
};
