import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import ButtonComponent from "../common/ButtonComponent";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { logout } from "../../services/UserService";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWidthSm: { background: theme.palette.grey[300] },
    dialogContentText: {
      color: theme.palette.text.primary,
    },
    dialogTitle: {
      textTransform: "capitalize",
      textAlign: "center",
    },
  }),
);

export const LogoutConfirmationModalId = "LogoutConfirmationModal";

const LogoutConfirmationModal = () => {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, LogoutConfirmationModalId),
  );

  const handleClose = () => {
    dispatch(setModalState(LogoutConfirmationModalId, false));
  };

  const handleContinue = () => {
    logout();
    handleClose();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        <strong>Confirm your action</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.dialogContentText}
        >
          {intl.formatMessage({
            id: "logoutConfirmation.alertText",
            defaultMessage:
              "You have unsaved changes. Are you sure you want to logout without saving your changes?",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "logoutConfirmation.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleContinue}
          autoFocus
        >
          {intl.formatMessage({
            id: "logoutConfirmation.continue",
            defaultMessage: "Continue",
          })}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LogoutConfirmationModal);
