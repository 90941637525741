import {
  DELETE_PRODUCT_ALERTS_SCHEDULE_INFO,
  FETCH_PRODUCT_ALERTS_SCHEDULE_DATA,
  ProductAlertsScheduleInfo,
  UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO,
} from "./ProductAlertsScheduleType";

export interface ProductAlertsScheduleState {
  isFetchingProductAlertsSchedule: boolean;
  productAlertsSchedule: ProductAlertsScheduleInfo;
  isUpdatingSchedule: boolean;
}

const initialState: ProductAlertsScheduleState = {
  isFetchingProductAlertsSchedule: false,
  productAlertsSchedule: {
    hour: null,
    minute: null,
    timezone: "",
  },
  isUpdatingSchedule: false,
};

export const ProductAlertsScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.REQUEST: {
      return {
        ...state,
        isFetchingProductAlertsSchedule: true,
      };
    }
    case FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.SUCCESS: {
      const response = action.payload as ProductAlertsScheduleInfo;
      return {
        ...state,
        isFetchingProductAlertsSchedule: false,
        productAlertsSchedule: response,
      };
    }
    case FETCH_PRODUCT_ALERTS_SCHEDULE_DATA.FAILURE: {
      return {
        ...state,
        isFetchingProductAlertsSchedule: false,
      };
    }
    case UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST: {
      return {
        ...state,
        isUpdatingSchedule: true,
      };
    }
    case UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.SUCCESS: {
      const result = action.payload;
      return {
        ...state,
        isUpdatingSchedule: false,
        productAlertsSchedule: result,
      };
    }
    case UPDATE_PRODUCT_ALERTS_SCHEDULE_INFO.FAILURE: {
      return {
        ...state,
        isUpdatingSchedule: false,
      };
    }
    case DELETE_PRODUCT_ALERTS_SCHEDULE_INFO.REQUEST: {
      return {
        ...state,
        isUpdatingSchedule: true,
      };
    }
    case DELETE_PRODUCT_ALERTS_SCHEDULE_INFO.SUCCESS: {
      return {
        ...state,
        isUpdatingSchedule: false,
        productAlertsSchedule: {
          hour: null,
          minute: null,
          timezone: "",
        },
      };
    }
    case DELETE_PRODUCT_ALERTS_SCHEDULE_INFO.FAILURE: {
      return {
        ...state,
        isUpdatingSchedule: false,
      };
    }
    default:
      return state;
  }
};
