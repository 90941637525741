/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectCategories } from "../store/category/CategorySelectors";
import { selectCurrentLocale } from "../store/store-list/StoreListSelectors";
import { useQuery } from "../hooks/useQueryParams";
import AppState from "../store/AppState";
import { selectConfigValue } from "../store/app-config/AppConfigSelectors";
import BreadcrumbsComponent from "./common/Breadcrumbs";

const BreadcrumbSequence = () => {
  const intl = useIntl();
  const query = useQuery();
  //const location = useLocation();
  //const url = location.pathname;
  //const isInReportsTab = url.includes("reports");
  const categoryPathQuery = query.get("categoryPath") ?? "";
  const searchTermQuery = query.get("searchTerm") ?? "";
  const [breadcrumbPath, setBreadcrumbPath] = React.useState<string[]>([]);

  const currentLocaleCode = useSelector(selectCurrentLocale);
  const categories = useSelector(selectCategories);
  const breadcrumb: string[] = [];

  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const getFinalProductObj = (child, value) => {
    if (child.id === value) {
      const newCategoryPath = child.categoryPath.concat(`;${child.name}`);
      const newPath = newCategoryPath.split(";");
      let pathArr: string[] = [];
      newPath.forEach((data) => pathArr.push(data));
      setBreadcrumbPath(pathArr);
      return;
    }
    if (child.hasOwnProperty("children")) {
      child.children.forEach((child) => {
        getFinalProductObj(child, value);
      });
    }
  };
  const getProductsPath = (value) => {
    const category = categories.find(
      (cat: { code: string; id: string; name: string }) => cat.id === value,
    ) || { name: "" };
    if (category?.name) {
      let pathArr: string[] = [];
      pathArr.push(category.name);
      setBreadcrumbPath(pathArr);
      return;
    }
    categories.forEach((topCategory, index) => {
      if (topCategory.hasOwnProperty("children")) {
        categories[index].children?.forEach((child, index) => {
          if (child.id === value) {
            const newCategoryPath = child?.categoryPath?.concat(
              `;${child.name}`,
            );
            const newPath = newCategoryPath?.split(";") ?? [];
            let pathArr: string[] = [];
            newPath.forEach((data) => pathArr.push(data));
            setBreadcrumbPath(pathArr);
            return;
          }
          getFinalProductObj(child, value);
        });
      }
    });
  };

  React.useEffect(() => {
    if (categoryPathQuery !== "") {
      const codes = categoryPathQuery.split(";");
      const productId = codes[codes.length - 1];
      getProductsPath(productId);
    } else {
      setBreadcrumbPath([]);
    }
  }, [categoryPathQuery, categories]);

  if (searchTermQuery) {
    breadcrumb.push(
      intl.formatMessage({
        id: "breadCrumbSequence.searchResult",
        defaultMessage: "Search Result",
      }),
      searchTermQuery,
    );
  } else {
    const categoryTree = categoryPathQuery.split(";");
    let CategoriesObj = categories;
    categoryTree.forEach((id) => {
      const foundIndex = CategoriesObj?.findIndex(
        (category) => category.id === id || category.id.length === id.length,
      );
      if (foundIndex > -1) {
        const category = CategoriesObj[foundIndex];
        const localizedDesc = category.translations.find(
          (desc) =>
            desc.localeCode === currentLocaleCode ||
            desc.localeCode === defaultLocaleId,
        );

        const breadcrumbName = localizedDesc?.name || category.name;
        breadcrumb.push(breadcrumbName);
        CategoriesObj = category.children!;
      } else return;
    });
  }

  return <BreadcrumbsComponent breadcrumbPath={breadcrumbPath} />;
};

export default React.memo(BreadcrumbSequence);
