export const SET_MODAL_STATE = "SET_MODAL_STATE";
export const MODAL_CLOSED = "MODAL_CLOSED";

export interface setModalStateAction {
  payload: Modal;
  type: string;
}

interface Modal {
  modalId: string;
  isOpen: boolean;
  [key: string]: any;
}

export interface Variables {
  [key: string]: any;
}

export interface CloseModalPayload {
  productId?: string;
  catalogId?: string;
}
