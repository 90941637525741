export const styles = () => {
  return {
    bodyWrap: {
      m: 1,
      minWidth: 120,
      height: "32px",
      maxHeight: "32px",
      display: "contents",
      marginLeft: 20,
      marginRight: 10,
    },
  };
};
