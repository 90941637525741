import {
  LoadTopCategoriesResponseAction,
  LoadChildCategoriesResponseAction,
  Translations,
} from "../category/CategoryTypes";
import {
  FETCH_FIND_PRODUCTS_TOP_CATEGORIES,
  FETCH_FIND_PRODUCTS_CHILD_CATEGORIES,
  RESET_FIND_PRODUCTS,
  ChildCategory,
  ProductNodes,
  RESET_FIND_PRODUCTS_PRODUCTS_LIST,
  FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY,
  LoadProductsFromCategoryForFindProductsCategoryResponse,
  FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS,
  SearchProductsForFindProductsResponse,
  UPDATE_PRODUCTS_CATEGORY,
  FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY,
  FETCH_PRODUCTS_FOR_FIND_PRODUCTS,
  LoadProductsFromCategoryResponseAction,
} from "../find-products/FindProductsTypes";
import { ProductSequenceData } from "../product-list/ProductListTypes";

export interface FindProductsState {
  topCategory: {
    categoryId: string;
    childCategoryCount: number;
    translations: Translations[];
    productCount: number;
    code: string;
    name: string;
    isPublished: boolean;
  }[];
  childCategory: ChildCategory;
  products: ProductNodes[];
  isSubmitting: boolean;
  isSubmitted: boolean;
  productList: ProductSequenceData[];
  productIds: string[];
  isFetchingProductIds: boolean;
  isProductIdsFetched: boolean;
  allPagesFetched?: boolean;
  addingRemovingProductIdsList?: string[];
}

const initialState: FindProductsState = {
  topCategory: [],
  childCategory: {},
  products: [],
  isSubmitting: false,
  isSubmitted: false,
  productList: [],
  productIds: [],
  isFetchingProductIds: false,
  isProductIdsFetched: false,
  allPagesFetched: false,
  addingRemovingProductIdsList: [],
};

const getProductsFromResponse = (
  action: LoadProductsFromCategoryResponseAction,
): ProductSequenceData[] => {
  const productsInCategory: any = [];
  if (action?.payload) {
    for (const v of action.payload.results) {
      productsInCategory.push({
        sequence: v?.cachedProduct?.sequence,
        productId: v?.productId,
        product: {
          ...v?.cachedProduct?.product,
          storeSpecific: {
            ...v?.cachedProduct?.product?.storeSpecific,
          },
          categorySpecific: {
            ...v?.cachedProduct?.product?.categorySpecific,
          },
        },
        cachedAt: v?.cachedProduct?.cachedAt,
      });
    }
  }
  return productsInCategory;
};

export const FindProductsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_FIND_PRODUCTS_TOP_CATEGORIES.SUCCESS:
      const topCategoriesActions = action as LoadTopCategoriesResponseAction;
      return {
        ...state,
        topCategory: topCategoriesActions.payload.results.map((cat) => ({
          categoryId: cat.categoryId,
          childCategoryCount: cat.childCategoryCount,
          code: cat.code,
          name: cat.name,
          productCount: cat.productCount,
          translations: cat.translations,
          isPublished: cat.isPublished,
        })),
      };
    case FETCH_FIND_PRODUCTS_CHILD_CATEGORIES.SUCCESS:
      const childCategoriesAction = action as LoadChildCategoriesResponseAction;
      return {
        ...state,
        childCategory: {
          ...state.childCategory,
          [childCategoriesAction.payload.results[0].parentId]: {
            childCategories: childCategoriesAction.payload.results.map(
              (node) => ({
                categoryId: node.categoryId,
                childCategoryCount: node.childCategoryCount,
                code: node.code,
                name: node.name,
                productCount: node.productCount,
                translations: node.translations,
                isPublished: node.isPublished,
              }),
            ),
          },
        },
      };
    case RESET_FIND_PRODUCTS:
      return initialState;
    case RESET_FIND_PRODUCTS_PRODUCTS_LIST:
      return {
        ...state,
        products: [],
      };
    case FETCH_PRODUCTS_FOR_FIND_PRODUCTS_CATEGORY.SUCCESS:
      const loadProductsAction =
        action as LoadProductsFromCategoryForFindProductsCategoryResponse;
      const products = loadProductsAction.payload.data.CategoryToProduct;
      return {
        ...state,
        products,
      };
    case FETCH_SEARCH_PRODUCTS_FOR_FIND_PRODUCTS.SUCCESS:
      const searchProductsAction =
        action as SearchProductsForFindProductsResponse;
      const productList = searchProductsAction.payload.data.Product;
      return {
        ...state,
        products: [...productList],
      };
    case UPDATE_PRODUCTS_CATEGORY.REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
      };
    case UPDATE_PRODUCTS_CATEGORY.SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    case FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.REQUEST: {
      const payload = action.payload;
      return {
        ...state,
        productIds: payload.productIds ? state.productIds : [],
        isFetchingProductIds: payload.productIds ? false : true,
        productList: [],
        isProductIdsFetched: false,
        allPagesFetched: false,
        addingRemovingProductIdsList: [],
      };
    }
    case FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.SUCCESS: {
      const productIdsData = action.payload.productIds;
      return {
        ...state,
        productIds: productIdsData,
        isProductIdsFetched: true,
        isFetchingProductIds: false,
        allPagesFetched: productIdsData.length ? state.allPagesFetched : true,
      };
    }
    case FETCH_PRODUCT_IDS_FOR_FIND_PRODUCTS_CATEGORY.FAILURE: {
      return {
        ...state,
        isProductIdsFetched: false,
        isFetchingProductIds: false,
        productList: [],
        allPagesFetched: true,
      };
    }
    case FETCH_PRODUCTS_FOR_FIND_PRODUCTS.SUCCESS:
      let fetchAction = action as LoadProductsFromCategoryResponseAction;

      const pagesFetched = action?.payload?.totalPagesFetched;

      const productListData = getProductsFromResponse(fetchAction);
      let updatedProductList = [
        ...Object.values(state.productList),
        ...productListData,
      ];
      let updatedProductSequenceArrayBasedOnProductIds =
        [] as ProductSequenceData[];
      updatedProductList.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b.cachedAt as Date).valueOf() -
          new Date(a.cachedAt as Date).valueOf()
        );
      });

      let unique = updatedProductList.filter((obj, index) => {
        return (
          index ===
          updatedProductList.findIndex((o) => obj.productId === o.productId)
        );
      });
      // arranging the items once fetched
      if (unique.length) {
        state.productIds.forEach((productID) => {
          let index = unique.findIndex(
            ({ productId }) => productId === String(productID),
          );
          if (index !== -1) {
            updatedProductSequenceArrayBasedOnProductIds.push(
              unique.find(
                ({ productId }) => productId === String(productID),
              ) as ProductSequenceData,
            );
          }
        });
      }

      return {
        ...state,
        productList: updatedProductSequenceArrayBasedOnProductIds,
        allPagesFetched:
          pagesFetched &&
          pagesFetched === Math.ceil(state.productIds.length / 4)
            ? true
            : false,
      };
    case FETCH_PRODUCTS_FOR_FIND_PRODUCTS.FAILURE: {
      return {
        ...state,
        allPagesFetched:
          pagesFetched &&
          pagesFetched === Math.ceil(state.productIds.length / 4)
            ? true
            : false,
      };
    }
    default:
      return state;
  }
};
