import { storeAllAttributes } from "./BusinessRulesListActions";
import {
  AttributesList,
  AutomationRulesList,
  BusinessRulesListDetails,
  DELETE_BUSINESS_RULE_BY_RULE_ID,
  FETCH_ATTRIBUTES_LIST,
  FETCH_AUTOMATION_LIST,
  FETCH_BUSINESS_RULES_DETAILS_BY_ID,
  FETCH_JOBS_LIST,
  FETCH_UNIFIED_SORTING_RULES_LIST,
  SAVE_UNIFIED_BUSINESS_RULES_DETAILS,
  UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID,
  jobsList,
  loadAttributesListAction,
  loadJobsListActionPayload,
  loadAutomationRulesListPayload,
  DELETE_AUTOMATION_RULE_BY_JOB_ID,
  SAVE_AUTOMATION_RULES_DETAILS,
  FETCH_AUTOMATION_RULES_DETAILS_BY_ID,
  UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID,
  FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID,
  RESET_SELECTED_CATEGORIES_DATA,
  SET_SORTING_RULE_TYPE,
  FETCH_PENDING_JOBS_LIST,
  RESET_AUTOMATION_JOB_CREATION_STATE,
  loadPendingJobsListActionPayload,
  pendingJobsList,
  FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST,
  RESET_JOBS_LIST,
  LoadSystemGeneratedRulesResponseAction,
  RESET_BUSINESS_RULES_DETAILS,
  SET_IS_ANALYTIC,
  RESET_AUTOMATION_JOB,
  RESET_ATTRIBUTES_LOADED_FLAG,
  RESET_ATTRIBUTES_LIST,
  SET_SEARCH_VALUE,
  STORE_ALL_ATTRIBUTES,
  // saveUnifiedBusinessRulesAction,
} from "./BusinessRulesListTypes";

export interface BusinessRulesListState {
  isFetched: boolean;
  isFetchedById: boolean;
  businessRulesList: BusinessRulesListDetails[];
  systemGeneratedSortingRules: BusinessRulesListDetails[];
  attributesList: AttributesList[];
  isDetailsSaved: boolean;
  businessRulesDetailsObj: BusinessRulesListDetails;
  isDeleted: boolean;
  isRuleDeleted: boolean;
  isFetchedAttributes: boolean;
  jobsList: jobsList[];
  isJobListFetched: boolean;
  fetchFailedAutomationRulesCategoryDetails: boolean;
  automationRulesList: AutomationRulesList[];
  isAutomationRuleDeleted: boolean;
  isAutomationRuleSaved: boolean;
  automationRuleDetailsObj: AutomationRulesList;
  isFetchedByJobId: boolean;
  categoryDetails: any[];
  sortingRuleType: string;
  pendingJobsList: pendingJobsList[];
  isAutomationRulesJobFailed: boolean;
  isAutomationRulesFecthed: boolean;
  isAnalytic: boolean;
  isAttributesLoaded: boolean;
  searchValue: string;
}

const initialState: BusinessRulesListState = {
  isFetched: false,
  isFetchedById: false,
  businessRulesList: [],
  systemGeneratedSortingRules: [],
  attributesList: [],
  isDetailsSaved: false,
  isDeleted: false,
  isRuleDeleted: false,
  businessRulesDetailsObj: {
    ruleType: "",
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    ruleId: "",
    criteria: [],
    formula: [],
    sortingPointsLUT: [],
  },
  fetchFailedAutomationRulesCategoryDetails: false,
  isFetchedAttributes: false,
  jobsList: [],
  isJobListFetched: false,
  automationRulesList: [],
  isAutomationRuleDeleted: false,
  isAutomationRuleSaved: false,
  isFetchedByJobId: false,
  automationRuleDetailsObj: {
    name: "",
    description: "",
    jobId: "",
    jobType: "",
    parameters: {
      scope: [],
    },
    active: true,
    createdAt: "",
    updatedAt: "",
    startAt: "",
    endAt: "",
    timezone: "",
    retries: "",
    repeats: "",
    repeatsEvery: 0,
    repeatDays: [],
  },
  categoryDetails: [],
  sortingRuleType: "",
  pendingJobsList: [],
  isAutomationRulesJobFailed: false,
  isAutomationRulesFecthed: false,
  isAnalytic: false,
  isAttributesLoaded: false,
  searchValue: "",
};

export const BusinessRulesListReducer = (
  state: BusinessRulesListState = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_UNIFIED_SORTING_RULES_LIST.SUCCESS:
      const businessRulesList = action.payload.results;
      return {
        ...state,
        isFetched: businessRulesList?.length === 0 ? true : false,
        isFetchedById: false,
        isDetailsSaved: false,
        isDeleted: false,
        isRuleDeleted: false,
        businessRulesList,
        businessRulesDetailsObj: {
          ruleType: "",
          name: "",
          description: "",
          createdAt: "",
          updatedAt: "",
          ruleId: "",
          criteria: [],
          formula: [],
          sortingPointsLUT: [],
          isFetchedAttributes: false,
        },
      };
    case FETCH_ATTRIBUTES_LIST.SUCCESS:
      let fetchAttributesList = action as loadAttributesListAction;
      const attributesList = fetchAttributesList.payload.attributes;
      return {
        ...state,
        attributesList,
        isFetchedAttributes: true,
      };
    case SAVE_UNIFIED_BUSINESS_RULES_DETAILS.SUCCESS:
      return {
        ...state,
        isDetailsSaved: true,
      };
    case FETCH_BUSINESS_RULES_DETAILS_BY_ID.SUCCESS:
      const detailsObj = action;
      return {
        ...state,
        isFetchedById: true,
        businessRulesDetailsObj: detailsObj.payload,
        isFetchedAttributes: true,
      };
    case UPDATE_BUSINESS_RULES_DETAILS_BY_RULE_ID.SUCCESS:
      return {
        ...state,
        isDetailsSaved: true,
        businessRulesDetailsObj: {
          ruleType: "",
          name: "",
          description: "",
          createdAt: "",
          updatedAt: "",
          ruleId: "",
          criteria: [],
          formula: [],
          sortingPointsLUT: [],
        },
      };
    case DELETE_BUSINESS_RULE_BY_RULE_ID.SUCCESS:
      return {
        ...state,
        isDeleted: true,
        isRuleDeleted: true,
      };
    case DELETE_BUSINESS_RULE_BY_RULE_ID.REQUEST:
      return {
        ...state,
        isDeleted: true,
      };
    case DELETE_BUSINESS_RULE_BY_RULE_ID.DONE:
      return {
        ...state,
        isDeleted: false,
        isRuleDeleted: false,
      };
    case FETCH_JOBS_LIST.REQUEST:
      return {
        ...state,
        isJobListFetched: true,
      };
    case FETCH_JOBS_LIST.SUCCESS:
      const fetchedObj = action as loadJobsListActionPayload;
      const jobsList = fetchedObj.payload?.results || [];
      const combinedJobsList = [...state.jobsList, ...jobsList];
      return {
        ...state,
        jobsList: combinedJobsList,
        isJobListFetched: true,
      };
    case FETCH_JOBS_LIST.FAILURE:
      return {
        ...state,
        isJobListFetched: true,
      };

    case FETCH_AUTOMATION_LIST.SUCCESS:
      const fetchedList = action as loadAutomationRulesListPayload;
      const fetchedRules = fetchedList.payload.results;
      const isAutomationRules = fetchedRules.length === 0 ? true : false;
      return {
        ...state,
        automationRulesList: fetchedRules,
        isAutomationRulesFecthed: isAutomationRules,
        isAutomationRuleDeleted: false,
        isAutomationRuleSaved: false,
        automationRuleDetailsObj: {
          name: "",
          description: "",
          jobId: "",
          jobType: "",
          parameters: {
            scope: [],
          },
          active: true,
          createdAt: "",
          updatedAt: "",
          startAt: "",
          endAt: "",
          timezone: "",
          retries: "",
          repeats: "",
          repeatsEvery: 0,
          repeatDays: [],
        },
        isFetchedByJobId: false,
      };
    case DELETE_AUTOMATION_RULE_BY_JOB_ID.SUCCESS:
      return {
        ...state,
        isAutomationRuleDeleted: true,
      };
    case SAVE_AUTOMATION_RULES_DETAILS.SUCCESS:
      return {
        ...state,
        isAutomationRuleSaved: true,
      };
    case SAVE_AUTOMATION_RULES_DETAILS.FAILURE:
      return {
        ...state,
        isAutomationRuleSaved: false,
        isAutomationRulesJobFailed: true,
      };
    case FETCH_AUTOMATION_RULES_DETAILS_BY_ID.SUCCESS:
      const automationDetails = action;
      return {
        ...state,
        isFetchedByJobId: true,
        automationRuleDetailsObj: automationDetails.payload,
      };
    case FETCH_AUTOMATION_RULES_DETAILS_BY_ID.REQUEST:
      return {
        ...state,
        isFetchedByJobId: false,
      };
    case UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID.SUCCESS:
      return {
        ...state,
        isAutomationRuleSaved: true,
        automationRuleDetailsObj: {
          name: "",
          description: "",
          jobId: "",
          jobType: "",
          parameters: {
            scope: [],
          },
          active: true,
          createdAt: "",
          updatedAt: "",
          startAt: "",
          endAt: "",
          timezone: "",
          retries: "",
          repeats: "",
          repeatsEvery: 0,
          repeatDays: [],
        },
      };
    case UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID.FAILURE:
      return {
        ...state,
        isAutomationRuleSaved: false,
        isAutomationRulesJobFailed: true,
      };
    case FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID.REQUEST:
      return {
        ...state,
        fetchFailedAutomationRulesCategoryDetails: false,
      };
    case FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID.SUCCESS:
      const categoryData = [...state.categoryDetails];
      categoryData.push(action.payload);
      return {
        ...state,
        categoryDetails: categoryData,
        fetchFailedAutomationRulesCategoryDetails: false,
      };
    case FETCH_AUTOMATION_RULES_CATEGORY_DETAILS_BY_ID.FAILURE:
      return {
        ...state,
        fetchFailedAutomationRulesCategoryDetails: true,
      };
    case RESET_SELECTED_CATEGORIES_DATA:
      return {
        ...state,
        categoryDetails: [],
        fetchFailedAutomationRulesCategoryDetails: false,
      };
    case SET_SORTING_RULE_TYPE:
      const sortRuleType = action.sortRule;
      return {
        ...state,
        sortingRuleType: sortRuleType,
      };
    case FETCH_PENDING_JOBS_LIST.SUCCESS:
      const fetchPendingJobObj = action as loadPendingJobsListActionPayload;
      const pendingJobsList = fetchPendingJobObj.payload?.results || [];
      return {
        ...state,
        pendingJobsList: pendingJobsList,
      };
    case RESET_AUTOMATION_JOB_CREATION_STATE: {
      return {
        ...state,
        isAutomationRulesJobFailed: false,
      };
    }
    case RESET_JOBS_LIST: {
      return {
        ...state,
        isJobListFetched: false,
        jobsList: [],
      };
    }
    case FETCH_SYSTEM_GENERATED_SORTING_RULES_LIST.SUCCESS: {
      const sortingRulesListAction =
        action as LoadSystemGeneratedRulesResponseAction;
      const systemGerenatedRulesList = sortingRulesListAction.payload.results;
      return {
        ...state,
        systemGeneratedSortingRules: systemGerenatedRulesList,
      };
    }
    case RESET_BUSINESS_RULES_DETAILS: {
      return {
        ...state,
        isFetchedAttributes: false,
        isFetchedById: false,
        businessRulesDetailsObj: {
          ruleType: "",
          name: "",
          description: "",
          createdAt: "",
          updatedAt: "",
          ruleId: "",
          criteria: [],
          formula: [],
          sortingPointsLUT: [],
        },
      };
    }
    case SET_IS_ANALYTIC:
      const isAnalyticMetric = action.isAnalytic;
      return {
        ...state,
        isAnalytic: isAnalyticMetric,
      };
    case RESET_AUTOMATION_JOB: {
      return {
        ...state,
        isFetchedByJobId: false,
        automationRuleDetailsObj: {
          name: "",
          description: "",
          jobId: "",
          jobType: "",
          parameters: {
            scope: [],
          },
          active: true,
          createdAt: "",
          updatedAt: "",
          startAt: "",
          endAt: "",
          timezone: "",
          retries: "",
          repeats: "",
          repeatsEvery: 0,
          repeatDays: [],
        },
      };
    }
    case RESET_ATTRIBUTES_LOADED_FLAG: {
      return {
        ...state,
        isAttributesLoaded: false,
      };
    }
    case RESET_ATTRIBUTES_LIST: {
      return {
        ...state,
        attributesList: [],
        searchValue: "",
      };
    }
    case SET_SEARCH_VALUE: {
      const value = action.searchValue;
      return {
        ...state,
        searchValue: value,
      };
    }
    case STORE_ALL_ATTRIBUTES:
      const { payload } = action as ReturnType<typeof storeAllAttributes>;
      const allAttributesList = [
        ...state.attributesList,
        ...payload.attributes,
      ];
      const uniqueAttributesList = allAttributesList?.filter((obj, index) => {
        return (
          index ===
          allAttributesList?.findIndex((o) => obj.attributeId === o.attributeId)
        );
      });
      return {
        ...state,
        attributesList: uniqueAttributesList,
        isFetchedAttributes: true,
        isAttributesLoaded: true,
      };
    default:
      return state;
  }
};
