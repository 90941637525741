import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  selectAnalyticsStartDate,
  selectAnalyticsEndDate,
  selectProductCodes,
  selectDateRange,
} from "../../store/product/ProductSelectors";
import { loadProductAnalytics } from "../../store/product/ProductActions";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import { selectCurrentStoreId } from "../../store/store-list/StoreListSelectors";
import { OVERLAY_TABS } from "../../utils/Constants";
import {
  selectSortingRule,
  selectSortingRulesList,
} from "../../store/product-list/ProductListSelectors";
import { BusinessRulesListDetails } from "../../store/product-list/ProductListTypes";
import { calculateStartEndDate } from "../../utils/ProductUtil";
import { selectSystemGeneratedRules } from "../../store/businessRules-list/BusinessRuleslistSelectors";

const AnalyticsOverlayLabel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const overlay = useSelector(selectOverlay);
  const startDate = useSelector(selectAnalyticsStartDate);
  const endDate = useSelector(selectAnalyticsEndDate);
  const productCodes = useSelector(selectProductCodes);
  const storeId = useSelector(selectCurrentStoreId) || "";
  const selectedDateRange = useSelector(selectDateRange);
  const sortingRulesList = useSelector(selectSortingRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);
  const allSortingRulesList = [
    ...systemGeneratedRulesList,
    ...sortingRulesList,
  ];

  const selectedRule = useSelector(selectSortingRule);

  useEffect(
    () => {
      if (
        overlay === OVERLAY_TABS.ANALYTICS_OVERLAY &&
        productCodes.length > 0
      ) {
        const sortingRule = allSortingRulesList?.find(
          (rule) => rule.ruleId === selectedRule,
        );
        if (sortingRule) {
          const { startDate, endDate } = calculateStartEndDate(
            sortingRule as BusinessRulesListDetails,
          );
          if (startDate && endDate) {
            const analyticsPayload = {
              startDate: startDate,
              endDate: endDate,
              productCodes: productCodes,
            };
            dispatch(loadProductAnalytics(analyticsPayload));
          }
        } else {
          const fromDate = selectedDateRange.startDate;
          const toDate = selectedDateRange.endDate;
          if (fromDate !== startDate || toDate !== endDate) {
            const analyticsPayload = {
              startDate: fromDate,
              endDate: toDate,
              productCodes: productCodes,
            };
            dispatch(loadProductAnalytics(analyticsPayload));
          }
        }
      }
    },
    // eslint-disable-next-line
    [
      productCodes,
      overlay,
      startDate,
      endDate,
      dispatch,
      storeId,
      selectedDateRange,
    ],
  );

  return (
    <>
      {intl.formatMessage({
        id: "overlayTabs.analyticsLabel",
        defaultMessage: "Analytics",
      })}
    </>
  );
};

export default React.memo(AnalyticsOverlayLabel);
