import AppState from "../AppState";
import { createSelector } from "reselect";
import {
  selectProductAttributeValues,
  selectChildAttributeValues,
} from "../product/ProductSelectors";
import { attributeValue } from "../product/ProductTypes";
import {
  selectCurrentCategory,
  selectParentCategories,
} from "../category/CategorySelectors";

export const selectCategoryFacets = (state: AppState) => {
  return state.facets.facets;
};

export const selectSFCCCategoryFacets = (state: AppState) =>
  state.facets.facetsSFCC;

export const selectSFCCFacets = createSelector(
  [selectSFCCCategoryFacets, selectCurrentCategory, (state: AppState) => state],
  (facetsSFCC, categoryId, state) => {
    const currentCategoryFacets = facetsSFCC.filter(
      (facet) => facet.categoryId === categoryId,
    );
    const parentCategories = selectParentCategories(state, categoryId);
    if (parentCategories) {
      parentCategories.forEach((parentCategoryId) => {
        const tempCategoryFacets = facetsSFCC.filter(
          (facet) => facet.categoryId === parentCategoryId,
        );
        tempCategoryFacets.forEach((facet) => {
          let isAvailable = false;
          let deleteIndex = -1;
          currentCategoryFacets.forEach((parentFacet, i) => {
            if (
              (facet.type === "ATTRIBUTE" &&
                facet.attributeId === parentFacet.attributeId) ||
              facet.facetId === parentFacet.facetId ||
              (facet.type !== "ATTRIBUTE" && facet.type === parentFacet.type)
            ) {
              if (parentFacet.isEnabled) isAvailable = true;
              else {
                deleteIndex = i;
                facet.isEnabled = false;
              }
            }
          });
          if (deleteIndex > -1) currentCategoryFacets.splice(deleteIndex, 1);
          if (!isAvailable) currentCategoryFacets.splice(0, 0, facet);
        });
      });
    }
    return currentCategoryFacets;
  },
);

export const selectFacetsToAdd = (state: AppState) => {
  return state.facets.facetsToAdd;
};

export const selectAttributeById = createSelector(
  [selectSFCCFacets, (state: AppState, attributeId: string) => attributeId],
  (facets, attributeId) => {
    const facet = facets.find((facet) => facet.attributeId === attributeId);
    return facet?.attribute;
  },
);

export const selectAttributeValueFilters = (state: AppState) => {
  return state.facets.filterValues;
};

const mapAttributeValuesToProduct = (
  map: { [key: string]: string[] },
  values: attributeValue[],
  productId: string,
) => {
  values.forEach((attrValue) => {
    if (map.hasOwnProperty(attrValue.attributeValueId)) {
      if (!map[attrValue.attributeValueId].find((id) => id === productId)) {
        map[attrValue.attributeValueId].push(productId);
      }
    } else {
      map[attrValue.attributeValueId] = [productId];
    }
  });
};

export const selectAttributeValuesMap = createSelector(
  [selectProductAttributeValues, selectChildAttributeValues],
  (attributes, childAttributes) => {
    let map: { [key: string]: string[] } = {};
    Object.keys(attributes).forEach((productId) => {
      const attribute = attributes[productId];
      mapAttributeValuesToProduct(map, attribute.attributeValues, productId);
      mapAttributeValuesToProduct(
        map,
        attribute.attributeLocalizedValues,
        productId,
      );
      mapAttributeValuesToProduct(
        map,
        attribute.attributeValueOverrides,
        productId,
      );
    });
    Object.keys(childAttributes).forEach((childProductId) => {
      const attribute = childAttributes[childProductId];
      if (attribute.toParentProducts && attribute.toParentProducts.length > 0) {
        attribute.toParentProducts.forEach((parent) => {
          mapAttributeValuesToProduct(
            map,
            attribute.attributeValues,
            parent.parentProductId,
          );
          mapAttributeValuesToProduct(
            map,
            attribute.attributeLocalizedValues,
            parent.parentProductId,
          );
          mapAttributeValuesToProduct(
            map,
            attribute.attributeValueOverrides,
            parent.parentProductId,
          );
        });
      } else {
        mapAttributeValuesToProduct(
          map,
          attribute.attributeValues,
          childProductId,
        );
        mapAttributeValuesToProduct(
          map,
          attribute.attributeLocalizedValues,
          childProductId,
        );
        mapAttributeValuesToProduct(
          map,
          attribute.attributeValueOverrides,
          childProductId,
        );
      }
    });
    return map;
  },
);

export const selectProductIdsToDisplay = createSelector(
  [selectAttributeValuesMap, selectAttributeValueFilters],
  (attributeValueMap, filterValues) => {
    let productToDisplay: string[] = [];
    filterValues.forEach((value) => {
      const attrVal = Object.keys(attributeValueMap).find((key) =>
        key.includes(value),
      );
      if (attrVal) {
        if (productToDisplay.length > 0 && attributeValueMap[attrVal]) {
          productToDisplay = attributeValueMap[attrVal]
            ? attributeValueMap[attrVal].filter((id) =>
                productToDisplay.includes(id),
              )
            : productToDisplay;
        } else {
          productToDisplay = attributeValueMap[attrVal]
            ? attributeValueMap[attrVal]
            : productToDisplay;
        }
      }
    });
    return productToDisplay;
  },
);

export const selectAssignedAttributeValuesMap = createSelector(
  [selectAttributeValuesMap, selectProductIdsToDisplay],
  (attributeValueMap, productIds) => {
    const map: { [key: string]: string[] } = {};
    Object.assign(map, attributeValueMap);
    if (productIds.length > 0) {
      Object.keys(map).forEach((value) => {
        map[value] = map[value].filter((id) => productIds.includes(id));
      });
    }
    return map;
  },
);

export const selectIsFacetsFetching = (state: AppState) =>
  state.facets.isFacetsFetching;

export const selectFacetById = (state: AppState, facetId: string) => {
  return state.facets.facetsSFCC.find((facet) => facet.facetId === facetId);
};
