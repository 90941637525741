import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./RootReducer";
import rootSaga from "./RootSaga";

const composeEnhancers = composeWithDevTools || compose;

export const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createReduxStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
