import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import { setModalState } from "../store/modal/ModalActions";
import JobListModal from "./containers/jobs-list-modal/JobsListModal";
import { loadJobsList } from "../store/businessRules-list/BusinessRulesListActions";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderState } from "../store/loader/LoaderActions";
import { selectLogTimeList } from "../store/businessRules-list/BusinessRuleslistSelectors";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import ButtonComponent from "../components/common/ButtonComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
    },
  }),
);

const JobRelated: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const latestLogTime = useSelector(selectLogTimeList);

  const handleJobList = () => {
    dispatch(setLoaderState("JOB_LIST_LOADER", true));
    dispatch(setModalState("JobListModal", true));
    if (latestLogTime) {
      dispatch(loadJobsList(latestLogTime));
    } else {
      dispatch(loadJobsList());
    }
  };

  return (
    <div className={classes.container}>
      <ButtonComponent
        onClick={handleJobList}
        color="tertiary"
        variant="contained"
        fullWidth={true}
        startIcon={<ListTwoToneIcon fontSize="medium" />}
      >
        {intl.formatMessage({
          id: "jobList.label",
          defaultMessage: "JOB LIST",
        })}
      </ButtonComponent>
      <JobListModal />
    </div>
  );
};

export default JobRelated;
