import { createSelector } from "reselect";
import AppState from "../AppState";

export const selectReportType = (state: AppState): string => {
  return state.report.type;
};

export const selectReportStoreId = (state: AppState): string => {
  return state.report.storeId;
};

export const selectReportCatalogId = (state: AppState): string => {
  return state.report.catalogId;
};

export const selectReportCategories = (state: AppState): string[] => {
  return state.report.categories;
};

export const selectReportCategoriesToProducts = (
  state: AppState,
): { [key: string]: string[] } => {
  return state.report.products;
};

export const selectReportFields = (state: AppState): string[] => {
  return state.report.fields;
};

export const selectReportDateRange = (
  state: AppState,
): { startDate: string; endDate: string } => {
  return state.report.dateRange;
};

export const selectReportProducts = createSelector(
  [selectReportCategories, selectReportCategoriesToProducts],
  (categories, catToProducts) => {
    let products: string[] = [];
    categories.forEach((categoryId) => {
      if (catToProducts[categoryId]) {
        products.push(...catToProducts[categoryId]);
      }
    });
    return products;
  },
);

export const shouldLoadProductAnalytics = createSelector(
  [
    selectReportProducts,
    (state) => state.products.analytics,
    selectReportDateRange,
  ],
  (productIds, analytics, dateRange) => {
    let shouldload = false;
    productIds.forEach((productId) => {
      if (
        analytics.productAnalytics &&
        !analytics.productAnalytics[productId]
      ) {
        shouldload = true;
      }
    });
    shouldload =
      shouldload ||
      (productIds.length > 0 &&
        (dateRange.startDate !== analytics.startDate ||
          dateRange.endDate !== analytics.endDate));
    return shouldload;
  },
);
