import {
  AnalyticsData,
  FETCH_PRODUCT_SOCIAL_ANALYTICS,
} from "./SocialAnalyticsTypes";

export interface SocialAnalyticsData {
  productAnalytics: AnalyticsData[];
}

const initialState: SocialAnalyticsData = {
  productAnalytics: [],
};

export const SocialAnalyticsReducer = (
  state: SocialAnalyticsData = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_PRODUCT_SOCIAL_ANALYTICS.REQUEST: {
      return {
        ...state,
        productAnalytics: [],
      };
    }
    case FETCH_PRODUCT_SOCIAL_ANALYTICS.SUCCESS: {
      const productsSocialAnalytics = action.payload.results;
      const updatedProductAnalytics = [
        ...state.productAnalytics,
        ...productsSocialAnalytics,
      ];
      return {
        ...state,
        productAnalytics: updatedProductAnalytics,
      };
    }
    default:
      return state;
  }
};
