import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import FacetsList from "./FacetsList";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import {
  getFacetsByCategory,
  getFacetsToAdd,
  getFacetsByCategorySFCC,
} from "../../store/facets/FacetActions";
import {
  selectCurrentCategory,
  selectParentCategories,
} from "../../store/category/CategorySelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { AddFacetModalId } from "./AddFacetModal";
import { selectCurrentCatalogId } from "../../store/catalog/CatalogSelectors";
import { selectIsProductAttributesFetched } from "../../store/product/ProductSelectors";
import { loadProductAttributeValues } from "../../store/product/ProductActions";
import AppState from "../../store/AppState";
import { selectIsFacetsFetching } from "../../store/facets/FacetSelectors";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlayHeader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: " 44px",
      width: 228,
      backgroundColor: "#fafafa",
      margin: "0 5px",
      position: "absolute",
      zIndex: 2,
    },
    menuButton: {
      position: "absolute",
      left: 0,
      padding: "10px",
      height: 45,
      width: 45,
      marginRight: 5,
    },
    addButton: {
      position: "absolute",
      padding: "10px",
    },
    hide: {
      display: "none",
    },
    header: {
      fontWeight: 600,
    },
    facetsPanel: {
      width: "250px",
      flexShrink: 0,
      background: " #fafafa",
      boxShadow: "2px 1px 1px rgba(0, 0, 0, 0.1)",
      padding: "0 6px",
      paddingBottom: "5px",
      overflowX: "hidden",
    },
    list: {
      width: "100%",
      padding: theme.spacing(1, 3),
    },
  }),
);

interface Props {
  onDrawerOpen: () => void;
  isOpen: boolean;
}

const FacetsContainer = ({ onDrawerOpen, isOpen }: Props) => {
  const classes = useStyles();
  const DrawerOpenHandler = () => {
    onDrawerOpen();
  };
  const dispatch = useDispatch();
  const storeId = useSelector(selectCurrentStoreId);
  const categoryId = useSelector(selectCurrentCategory);
  const catalogId = useSelector(selectCurrentCatalogId);
  const localeCode = useSelector(selectCurrentLocale);
  const isProductAttributesFetched = useSelector(
    selectIsProductAttributesFetched,
  );
  const parentCategories = useSelector((state: AppState) =>
    selectParentCategories(state, categoryId),
  );
  const isFetching = useSelector(selectIsFacetsFetching);

  useEffect(() => {
    if (categoryId && storeId && catalogId) {
      dispatch(getFacetsByCategory({ categoryId, storeId }));
      dispatch(getFacetsToAdd({ categoryId, catalogId, storeId }));
    }
  }, [categoryId, storeId, catalogId, dispatch]);

  useEffect(() => {
    if (categoryId && parentCategories && !isFetching && storeId && catalogId) {
      const categoryIds = [categoryId, ...parentCategories];
      dispatch(getFacetsByCategorySFCC({ categoryIds }));
    }
  }, [categoryId, storeId, catalogId, dispatch, parentCategories, isFetching]);

  useEffect(() => {
    if (!isProductAttributesFetched) {
      let payload = {
        categoryId,
        catalogId,
        localeCode: localeCode ? localeCode : "",
        storeId: storeId ? storeId : "",
      };

      dispatch(loadProductAttributeValues(payload));
    }
  }, [
    dispatch,
    localeCode,
    storeId,
    catalogId,
    categoryId,
    isProductAttributesFetched,
  ]);

  const handleAddFacetIconClick = () => {
    dispatch(setModalState(AddFacetModalId, true, {}));
  };

  return (
    <>
      <div className={classes.facetsPanel}>
        <div className={classes.overlayHeader}>
          <IconButton
            aria-label="open drawer"
            onClick={DrawerOpenHandler}
            className={clsx(classes.menuButton, isOpen && classes.hide)}
          >
            <Icons iconId="RightArrowSmallIcon" />
          </IconButton>
          <IconButton
            aria-label="open drawer"
            className={classes.addButton}
            onClick={handleAddFacetIconClick}
          >
            <Icons iconId="AddCircleIcon" />
          </IconButton>
        </div>
        <FacetsList />
      </div>
    </>
  );
};

export default React.memo(FacetsContainer);
