import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import AppState from "../store/AppState";
import {
  selectPinnedProducts,
  selectNumberOfPinnedProducts,
} from "../store/product-list/ProductListSelectors";
import {
  updateNumberOfPinnedProducts,
  setPinnedProductsMode,
} from "../store/product-list/ProductListActions";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { selectIsFetchedBySearch } from "../store/product/ProductSelectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "row",
      alignItems: "center",
      "& .MuiOutlinedInput-input": {
        padding: "6px",
        width: "4em",
      },
    },
    text: {
      padding: "0 6px",
    },
    error: {
      display: "flex",
    },
  }),
);

const PinnedProductsSelect = () => {
  const intl = useIntl();
  const classes = useStyles();

  const pinnedProducts = useSelector((state: AppState) =>
    selectPinnedProducts(state),
  );

  const isFetchedBySearch = useSelector((state: AppState) =>
    selectIsFetchedBySearch(state),
  );

  const [value, setValue] = useState(pinnedProducts.length);
  const dispatch = useDispatch();

  const numberOfPinnedProducts = useSelector((state: AppState) =>
    selectNumberOfPinnedProducts(state),
  );

  useEffect(() => {
    dispatch(setPinnedProductsMode(true));
    return function cleanup() {
      dispatch(setPinnedProductsMode(false));
    };
  }, [dispatch]);

  const changeHandler = (event: React.ChangeEvent<{ value: string }>) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (numberOfPinnedProducts !== value) {
        dispatch(updateNumberOfPinnedProducts(value));
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [dispatch, numberOfPinnedProducts, value]);

  return (
    <div>
      {!isFetchedBySearch && (
        <FormControl className={classes.root}>
          <div className={classes.text}>
            <Typography>
              {intl.formatMessage({
                id: "pinnedProductsSelect.pinText",
                defaultMessage: "Pin first",
              })}
            </Typography>
          </div>
          <OutlinedInput
            autoFocus={false}
            value={value}
            type="number"
            margin="dense"
            onChange={changeHandler}
          />
          <div className={classes.text}>
            <Typography>
              {intl.formatMessage({
                id: "pinnedProductsSelect.productsText",
                defaultMessage: "Products",
              })}
            </Typography>
          </div>
        </FormControl>
      )}
      {isFetchedBySearch && (
        <>
          <i className="fa fa-exclamation-circle" />
          {intl.formatMessage({
            id: "pinnedProductsSelect.notAvailableText",
            defaultMessage:
              "Pinned products not available for searching result.",
          })}
        </>
      )}
    </div>
  );
};

export default React.memo(PinnedProductsSelect);
