import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../common/ButtonComponent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { FormControlLabel } from "@mui/material";

import {
  ChildColor,
  ProductSequence,
  AttrValueSequence,
} from "../../store/product/ProductTypes";
import { updateProductColors } from "../../store/product/ProductActions";
import { LeadingColorProp } from "./ColorManagementContent";
import { LEADING_COLOR_CHANGE_TYPE } from "../../utils/Constants";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import { MultipleStoreLanguageModalId } from "../store-language-modal/MultipleStoreLanguageModal";
import { setModalState } from "../../store/modal/ModalActions";
import { ColorManagementModalId } from "./ColorManagement";
import AppState from "../../store/AppState";
import { selectModalVariable } from "../../store/modal/ModalSelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import CheckboxComponent from "../common/Checkbox";

export const colorUpdate = "colorUpdate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: "10rem",
    },
    userNotifyText: {
      fontSize: 11,
      display: "inline",
    },
    multipleLocaleText: {
      fontSize: 14,
    },
    saveButtonWrapper: {
      display: "inline",
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

const ModalActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    position: "absolute",
    width: "100%",
    bottom: 10,
  },
}))(MuiDialogActions);

interface Props {
  productId: string;
  sequenceUpdated: boolean;
  newPublishedColors: string[];
  newUnpublishedColors: string[];
  leadingColorUpdated: boolean;
  shouldEnableSave: boolean;
  handleClose: () => void;
  leadingColor: string;
  selectedColor: string;
  colors: ChildColor[];
  leadingColorProp: LeadingColorProp;
}
const ColorManagementActions: React.FC<Props> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    productId,
    sequenceUpdated,
    newPublishedColors,
    newUnpublishedColors,
    leadingColorUpdated,
    shouldEnableSave,
    handleClose,
    leadingColor,
    selectedColor,
    colors,
    leadingColorProp,
  } = props;
  const storeId = useSelector(selectCurrentStoreId);
  const localeId = useSelector(selectCurrentLocale);
  const colorSequenceMode = useSelector((state: AppState) =>
    selectConfigValue(state, "colorSequenceMode"),
  );
  const attrValSequenceLocaleSpecific =
    useSelector((state: AppState) =>
      selectConfigValue(state, "attrValSequenceLocaleSpecific"),
    ) === "true";
  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true";
  const loading = useSelector((state: AppState) =>
    selectModalVariable(state, ColorManagementModalId, "loading"),
  );
  const [applyToMultipleLocaleChecked, setApplyToMultipleLocaleChecked] =
    useState(false);

  const dispatch = useDispatch();
  const publishStatusUpdateHandler = (
    colorIdsArray: string[],
    isPublished: boolean,
  ) => {
    const productIds: string[] = [];
    if (colorIdsArray.length > 0) {
      colorIdsArray.forEach((colorId) => {
        const index = colors.findIndex((color) => color.id === colorId);
        productIds.push(...colors[index].itemIds.map((item) => item.id));
      });
    }
    return productIds;
  };
  const handleSaveAction = () => {
    const publishedProductIds = publishStatusUpdateHandler(
      newPublishedColors,
      true,
    );
    const unpublishedProductIds = publishStatusUpdateHandler(
      newUnpublishedColors,
      false,
    );
    const productSequences: ProductSequence[] = [];
    const attrValueSequences: AttrValueSequence[] = [];
    if (sequenceUpdated) {
      let prevSequence = 0;
      colors.forEach((color, index) => {
        var nextIndex = index + 1;
        var thisSequence = Number(color.sequence);

        var nextSequence =
          colors.length !== nextIndex
            ? Number(colors[nextIndex].sequence)
            : thisSequence;

        if (prevSequence >= thisSequence || thisSequence > nextSequence) {
          var rounder = Math.pow(10, 2);
          thisSequence =
            prevSequence < nextSequence
              ? (prevSequence + nextSequence) / 2
              : prevSequence + 1;
          thisSequence = Math.round(thisSequence * rounder) / rounder;

          if (prevSequence === thisSequence) {
            thisSequence = prevSequence + 1;
          }

          if (colorSequenceMode === "AttrVal") {
            attrValueSequences.push({
              attributeValueId: color.id,
              sequence: thisSequence,
            });
          } else {
            color.itemIds.forEach((item) => {
              productSequences.push({
                productId: item.id,
                sequence: thisSequence,
                type: item.type,
              });
            });
          }
        }
        prevSequence = thisSequence;
      });
    }
    const leadingColorPayload = {
      ...leadingColorProp,
      storeId: storeId ? storeId : "",
      localeId: localeId ? localeId : "",
      productId,
      type: LEADING_COLOR_CHANGE_TYPE.NOUPDATE,
      leadingColor: selectedColor,
    };
    if (leadingColorUpdated) {
      if (leadingColor === "")
        leadingColorPayload.type = LEADING_COLOR_CHANGE_TYPE.INSERT;
      else if (selectedColor === "")
        leadingColorPayload.type = LEADING_COLOR_CHANGE_TYPE.DELETE;
      else leadingColorPayload.type = LEADING_COLOR_CHANGE_TYPE.UPDATE;
    }
    const payload = {
      productSequences,
      attrValueSequences,
      publishedProductIds,
      unpublishedProductIds,
      ...leadingColorPayload,
    };
    if (applyToMultipleLocaleChecked)
      dispatch(
        setModalState(MultipleStoreLanguageModalId, true, {
          payload,
          type: colorUpdate,
        }),
      );
    else {
      dispatch(
        setModalState(ColorManagementModalId, true, {
          productId,
          loading: true,
        }),
      );
      const localeCodes: string[] = [];
      if (attrValSequenceLocaleSpecific) {
        localeCodes.push(localeId ? localeId : "");
      }
      dispatch(
        updateProductColors({
          localeCodes,
          ...payload,
        }),
      );
    }
  };

  return (
    <ModalActions>
      <Grid container direction="column" alignItems="flex-end">
        {bulkUpdateEnabled ? (
          <FormControlLabel
            control={
              <CheckboxComponent
                checkboxId="ColorManagementActions"
                checked={applyToMultipleLocaleChecked}
                onClick={setApplyToMultipleLocaleChecked}
                handleParam={applyToMultipleLocaleChecked}
              />
            }
            label={
              <div className={classes.multipleLocaleText}>
                {intl.formatMessage({
                  id: "colorManagementActions.multipleLocaleText",
                  defaultMessage:
                    "Apply sequence and/or publish status changes to multiple stores/languages",
                })}
              </div>
            }
          />
        ) : (
          ""
        )}
        <Grid item>
          {sequenceUpdated && (
            <div className={classes.userNotifyText}>
              {intl.formatMessage({
                id: "colorManagementActions.colorSequenceUpdatedText",
                defaultMessage: "Color sequences updated.",
              })}
            </div>
          )}
          {newPublishedColors.length > 0 && (
            <div className={classes.userNotifyText}>
              {newPublishedColors.length}
              {intl.formatMessage({
                id: "colorManagementActions.colorPublishedText",
                defaultMessage: "color(s) Published.",
              })}
            </div>
          )}
          {newUnpublishedColors.length > 0 && (
            <div className={classes.userNotifyText}>
              {newUnpublishedColors.length}
              {intl.formatMessage({
                id: "colorManagementActions.colorUnpublishedText",
                defaultMessage: "color(s) unpublished.",
              })}
            </div>
          )}
          {leadingColorUpdated && (
            <div className={classes.userNotifyText}>
              {intl.formatMessage({
                id: "colorManagementActions.leadingColorUpdatedText",
                defaultMessage: "Leading color updated.",
              })}
            </div>
          )}
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: "colorManagementActions.cancelActionlabel",
              defaultMessage: "Cancel",
            })}
          </ButtonComponent>
          <div className={classes.saveButtonWrapper}>
            {shouldEnableSave && (
              <ButtonComponent
                color="primary"
                variant="contained"
                onClick={handleSaveAction}
                disabled={loading}
              >
                {intl.formatMessage({
                  id: "colorManagementActions.saveActionlabel",
                  defaultMessage: "Save",
                })}
              </ButtonComponent>
            )}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>
    </ModalActions>
  );
};

export default ColorManagementActions;
