import React, { useContext } from "react";
import styled from "styled-components";

import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import ProductTileContent from "./product-title-content/ProductTitleContent";
import { useSelector } from "react-redux";
import AppState from "../store/AppState";
import { selectProductFetched } from "../store/product/ProductSelectors";
import { ViewContext } from "../context/view-context";

const StyledItemsDragPreview = styled.div<{ fontSize: string }>`
  position: relative;
  font-size: ${({ fontSize }) => fontSize};
`;
const StyledDraggedItemsContainer = styled.div`
  position: absolute;
  transform-origin: bottom left;
`;

const StyledProductSelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  background-color: #599dbb;
`;

const StyledBadge = styled.div<{
  positionTop: string;
  positionRight: string;
  fontSize: string;
  dimensions: string;
}>`
  position: absolute;
  right: ${({ positionRight }) => positionRight};
  top: ${({ positionTop }) => positionTop};
  background-color: ${(props) => props.theme.palette.primary.main};
  border: solid 2px ${(props) => props.theme.palette.common.white};
  border-radius: 50%;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  color: ${(props) => props.theme.palette.common.white};
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: ${({ fontSize }) => fontSize};
  height: ${({ dimensions }) => dimensions};
  width: ${({ dimensions }) => dimensions};
  text-align: center;
  z-index: 20;
`;

const StyledDraggedItem = styled.div<{ width: number; height: number }>`
  display: grid;
  grid-template-rows: auto 1fr;
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  border-radius: 5px;
  background: ${(props) => props.theme.palette.background.paper};
  color: black;
  padding: 0.8em;
  position: relative;
  & > div {
    margin: 0;
  }
`;
interface Props {
  items: ProductSequenceData[];
  width: number;
  height: number;
}

const ItemsDragPreview: React.FC<Props> = ({ items, width, height }) => {
  const {
    badgeDimensions,
    badgePositionRight,
    badgePositionTop,
    badgeFZ,
    productListFZ,
  } = useContext(ViewContext).styles;
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, items[0].productId),
  );

  if (!fetchedProduct) return null;
  return (
    <StyledItemsDragPreview fontSize={productListFZ}>
      {items.slice(0, 3).map((item, i) => (
        <StyledDraggedItemsContainer key={i}>
          <StyledDraggedItem
            width={width}
            height={height}
            key={item.productId}
            style={{
              zIndex: items.length - i,
              transform: `rotateZ(${-i * 2.5}deg)`,
            }}
          >
            {items.length > 1 && i === 0 && (
              <StyledBadge
                positionTop={badgePositionTop}
                positionRight={badgePositionRight}
                fontSize={badgeFZ}
                dimensions={badgeDimensions}
              >
                {items.length}
              </StyledBadge>
            )}
            <StyledProductSelectedOverlay />
            <ProductTileContent
              product={fetchedProduct}
              index={i}
              sequence={item.sequence}
              productView={true}
              isDragging={true}
            />
          </StyledDraggedItem>
        </StyledDraggedItemsContainer>
      ))}
    </StyledItemsDragPreview>
  );
};

export default React.memo(ItemsDragPreview);
