import { AttributeData, AttributeValue } from "../store/category/CategoryTypes";

export const getAttributeDisplayName = (
  attribute: AttributeData,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    attribute.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attribute.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeDisplayName = desc
    ? desc.displayName
    : attribute.code
      ? attribute.code
      : attribute.attributeId;

  return attributeDisplayName;
};

export const getAttributeValueDisplayName = (
  attributeValue: AttributeValue,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    attributeValue.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attributeValue.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeValueDisplayName = desc
    ? desc.displayValue
    : attributeValue.code
      ? attributeValue.code
      : attributeValue.attributeValueId;

  return attributeValueDisplayName;
};
