import React, { useEffect, useState } from "react";
import SortingTools from "./SortingTools";
// import AnalyticsDateRangePicker from "./AnalyticsDateRangePicker";
import { useSelector /*, useDispatch */ } from "react-redux";
import { selectCurrentStoreId } from "../store/store-list/StoreListSelectors";
import { selectCurrentCatalogId } from "../store/catalog/CatalogSelectors";
import { selectCurrentCategory } from "../store/category/CategorySelectors";
// import { selectDateRange } from "../store/product/ProductSelectors";
// import { getDateFromString, formatDateForAnalytics } from "../utils/TimeUtils";
// import { setDatePickerRange } from "../store/product/ProductActions";
// import { updateSelectedOverlay } from "../store/overlay/OverlayActions";
import { useQuery } from "../hooks/useQueryParams";
// import { DateRange } from "materialui-daterange-picker";
import { ProductSequenceData } from "../store/product-list/ProductListTypes";
// import { OVERLAY_TABS } from "../utils/Constants";

interface Props {
  products: ProductSequenceData[];
}

const SortProducts = (props: Props) => {
  // const dispatch = useDispatch();
  // const selectedDateRange = useSelector(selectDateRange);
  // const startDate = getDateFromString(selectedDateRange.startDate);
  // const endDate = getDateFromString(selectedDateRange.endDate);

  /**
   * State to force render the material ui date range picker.
   * The library doesn't support changing date range programmatically,
   * hence need to force remove and render the component.
   */
  const [reset, setReset] = useState(false);
  const storeId = useSelector(selectCurrentStoreId);
  const catalogId = useSelector(selectCurrentCatalogId);
  const categoryId = useSelector(selectCurrentCategory);
  const query = useQuery();
  const alertType = query.get("alertType") ?? "";

  useEffect(() => {
    setReset(true);
  }, [storeId, catalogId, categoryId, alertType]);

  if (reset) {
    setTimeout(() => {
      setReset(false);
    }, 200);
  }

  // const handleDateRangeChange = (range: DateRange) => {
  //   const startDate = formatDateForAnalytics(
  //     range.startDate ? range.startDate : new Date()
  //   );
  //   const endDate = formatDateForAnalytics(
  //     range.endDate ? range.endDate : new Date()
  //   );
  //   dispatch(setDatePickerRange(startDate, endDate));
  //   dispatch(updateSelectedOverlay(OVERLAY_TABS.ANALYTICS_OVERLAY));
  // };

  return (
    <>
      <SortingTools products={props.products} />
      {/* <AnalyticsDateRangePicker
        startDate={startDate}
        endDate={endDate}
        reset={reset}
        onDateRangeChange={handleDateRangeChange}
      /> */}
    </>
  );
};

export default SortProducts;
