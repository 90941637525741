import { Typography } from "@mui/material";
import CustomDialog from "../../common/DialogContainer";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import AppState from "../../../store/AppState";
import { setModalState } from "../../../store/modal/ModalActions";
import { logout } from "../../../services/UserService";
import { useStyles } from "./CallerAccountModalStyled";
import ButtonComponent from "../../../components/common/ButtonComponent";

const CallerAccountsErrorModal = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "ErrorAccountModalId"),
  );
  const handleLogout = () => {
    dispatch(setModalState("ErrorAccountModalId", false));
    logout();
  };

  const accountErrorModalContent = () => {
    return (
      <>
        <Typography variant="header" className={classes.errorMessage}>
          {intl.formatMessage({
            id: "accountErrorModal.error",
            defaultMessage: "Access Denied",
          })}
        </Typography>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <ButtonComponent
        color="primary"
        variant="contained"
        onClick={() => handleLogout()}
      >
        {intl.formatMessage({
          id: "accountErrorModal.logout",
          defaultMessage: "Logout",
        })}
      </ButtonComponent>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => {}}
      title={<></>}
      actions={dialogActions()}
      maxWidth={"lg"}
    >
      {accountErrorModalContent()}
    </CustomDialog>
  );
};

export default CallerAccountsErrorModal;
