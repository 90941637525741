import type { TStyleObject } from "./index.d";

const STYLE_SHEET_ID = "custom-make-styles-sheet";
const CLASSNAME_PREFIX = "mui";

export function getStyleSheet() {
  let styleSheet = document.getElementById(STYLE_SHEET_ID) as HTMLStyleElement;

  if (!styleSheet) {
    styleSheet = document.createElement("style");
    styleSheet.id = STYLE_SHEET_ID;
    styleSheet.appendChild(document.createTextNode("")); // WebKit hack
    document.head.appendChild(styleSheet);
  }

  const sheet = styleSheet.sheet as CSSStyleSheet;

  return sheet;
}

export function muiStylesToCSS(styles: TStyleObject) {
  return Object.entries(styles)
    .map(([key, value]) => {
      const cssKey = key.replace(/([A-Z])/g, "-$1").toLowerCase();

      return `${cssKey}: ${value};`;
    })
    .join("\n");
}

export function getUniqueClassName(key: string, css: string) {
  return `${CLASSNAME_PREFIX}-${key}-${btoa(css).substring(0, 9)}`;
}
