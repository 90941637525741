import { createTheme as createMuiTheme } from "@material-ui/core";

// Extend the PaletteOptions interface to include 'tableHeaderRow'
declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    tableHeaderRow?: PaletteOptions["primary"];
    tableRowHover?: PaletteOptions["primary"];
    unifiedSortingRuleModalMenu?: PaletteOptions["primary"];
    unifiedSortingRuleModalMenuItem?: PaletteOptions["primary"];
  }

  interface Palette {
    tableHeaderRow: Palette["primary"];
    tableRowHover: Palette["primary"];
    unifiedSortingRuleModalMenu: Palette["primary"];
    unifiedSortingRuleModalMenuItem: Palette["primary"];
  }
}

declare module "@material-ui/core/styles/createTypography" {
  interface TypographyOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    sideBarTitle?: React.CSSProperties;
  }
  interface Typography {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    sideBarTitle: React.CSSProperties;
  }
}

const navBarBackground = "#fff";
// const newPrimaryColor = "#ff679";
const primaryColor = "#D50000";
//const newPrimaryColor = "#ff679";
// const newSecondaryColor = "#7F8C8D";
//const newSecondaryColor = "#7F8C8D";
// const secondaryColor = "#0C61A0";
const secondaryColor = "#0C61A0";
const legacyTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    tableHeaderRow: {
      main: "#f18308",
      contrastText: "#fff",
    },
    tableRowHover: {
      main: "#fedfc3",
    },
    unifiedSortingRuleModalMenu: {
      main: "#F18221",
      contrastText: "#fff",
    },
    unifiedSortingRuleModalMenuItem: {
      main: "#D50000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Helvetica",
    h1: {
      fontSize: "22px",
      fontWeight: 400 / 700,
    },
    h2: {
      fontSize: "18px",
      fontWeight: 400 / 700,
    },
    h3: {
      fontSize: "16px",
      fontWeight: 400 / 700,
    },
    h4: {
      fontSize: "14px",
      fontWeight: 400 / 700,
    },
    h5: {
      fontSize: "12px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400 / 700,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400 / 700,
    },
    body3: {
      fontSize: "12px",
      fontWeight: 400 / 700,
    },
    body4: {
      fontSize: "9px",
      fontWeight: 400 / 700,
    },
    sideBarTitle: {
      color: "#404041",
      display: "block",
    },
  },
});

const baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#fff",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#fff",
    },
    tableHeaderRow: {
      main: "#f18308",
      contrastText: "#fff",
    },
    tableRowHover: {
      main: "#fedfc3",
    },
    unifiedSortingRuleModalMenu: {
      main: "#F18221",
      contrastText: "#fff",
    },
    unifiedSortingRuleModalMenuItem: {
      main: "#D50000",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: navBarBackground,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
        },
        body: {
          overflow: "hidden",
          minWidth: "1280px",
          height: "100%",
        },
      },
    },
  },
});
export { baseTheme, legacyTheme };
