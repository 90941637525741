import {
  SET_REPORT_TYPE,
  SET_REPORT_STORE_ID,
  SET_REPORT_CATALOG_ID,
  SET_REPORT_CATEGORIES,
  RESET_REPORT_STATE,
  SetReportTypeAction,
  SetReportStoreIdAction,
  SetReportCatalogIdAction,
  SetReportCategoriesAction,
  ReportReducersActions,
  SET_REPORT_FIELDS,
  SetReportFieldsAction,
  SetReportDateRangeAction,
  SET_REPORT_DATERANGE,
} from "./ReportTypes";
import { REPORT_TYPE_NAVIGATION_TABS } from "../../utils/Constants";
import {
  FETCH_PRODUCT_DETAILS_FOR_REPORT,
  LoadProductDetailsForReportResponseAction,
} from "../product/ProductTypes";

export interface ReportState {
  type: string;
  storeId: string;
  catalogId: string;
  categories: string[];
  products: { [key: string]: string[] };
  fields: string[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

const initialState: ReportState = {
  type: REPORT_TYPE_NAVIGATION_TABS.PRODUCT,
  storeId: "",
  catalogId: "",
  categories: [],
  products: {},
  fields: [],
  dateRange: {
    startDate: "",
    endDate: "",
  },
};

export const ReportReducer = (
  state = initialState,
  action: ReportReducersActions,
) => {
  switch (action.type) {
    case SET_REPORT_CATEGORIES: {
      const reportCatalogIdAction = action as SetReportCategoriesAction;
      return {
        ...state,
        categories: reportCatalogIdAction.payload.categories ?? [],
      };
    }
    case SET_REPORT_CATALOG_ID: {
      const reportCatalogIdAction = action as SetReportCatalogIdAction;
      return {
        ...state,
        catalogId: reportCatalogIdAction.payload.catalogId ?? "",
      };
    }
    case SET_REPORT_STORE_ID: {
      const reportStoreIdAction = action as SetReportStoreIdAction;
      return {
        ...state,
        storeId: reportStoreIdAction.payload.storeId ?? "",
      };
    }
    case SET_REPORT_TYPE: {
      const reportTypeAction = action as SetReportTypeAction;
      return {
        ...state,
        type: reportTypeAction.payload.type ?? "",
      };
    }
    case SET_REPORT_FIELDS: {
      const reportFieldsAction = action as SetReportFieldsAction;
      return {
        ...state,
        fields: reportFieldsAction.payload.fields ?? [],
      };
    }
    case SET_REPORT_DATERANGE: {
      const reportDateRangeAction = action as SetReportDateRangeAction;
      return {
        ...state,
        dateRange:
          reportDateRangeAction.payload.dateRange ?? initialState.dateRange,
      };
    }
    case FETCH_PRODUCT_DETAILS_FOR_REPORT.SUCCESS: {
      let fetchAction = action as LoadProductDetailsForReportResponseAction;
      const products =
        fetchAction.payload?.results.map(
          (productData) => productData.cachedProduct.product.productId,
        ) ?? [];
      const categoryId = "";
      return {
        ...state,
        products: {
          ...state.products,
          [categoryId]: products,
        },
      };
    }
    case RESET_REPORT_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};
