import React from "react";
import { Checkbox } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { AttributeData } from "../../store/category/CategoryTypes";
import { classicTheme } from "../../styles/themes/classic";
import { styled } from "@mui/system";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked": {
    color: classicTheme.palette.neutral.blue,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&.MuiCheckbox-root": {
    padding: "4px",
  },
}));

interface CheckboxComponentProps {
  checkboxId: string;
  classes?: ClassNameMap;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.Dispatch<React.SetStateAction<boolean>>;
  togglePublishStatus?: (colorId: string) => void;
  handleNodeSelect?: (event: any, nodeId: any) => void;
  handleCheck?: (
    event: React.MouseEvent<unknown>,
    SKU: string,
    key: string,
  ) => void;
  addRemoveFromCurrentCategory?: (
    productId: string | undefined,
    categoryId: string,
    e: any,
  ) => void;
  setCheckedProductIds?: (value: React.SetStateAction<string[]>) => void;
  currentCategoryId?: string;
  value?: string;
  flag?: boolean | undefined;
  selectedAttributeIds?: string[];
  attribute?: AttributeData;
  disabled?: boolean;
  handleParam?: any;
  handleChange?: ([key]: string) => void;
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = (props) => {
  const {
    checkboxId,
    classes,
    onChange,
    handleParam,
    onClick,
    togglePublishStatus,
    handleNodeSelect,
    addRemoveFromCurrentCategory,
    handleCheck,
    setCheckedProductIds,
    currentCategoryId,
    value,
    disabled,
    checked,
    handleChange,
  } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      categoryCheckbox: {
        padding: "0px",
        marginRight: 1,
      },
      productCategorySelectionCheckbox: { padding: "0px 9px 0px" },
    }),
  );
  const checkboxclass = useStyles();

  switch (checkboxId) {
    case "PublishControl":
      if (onChange)
        return (
          <StyledCheckbox
            size="small"
            color="primary"
            checked={checked}
            onChange={onChange}
            disabled={disabled ?? false}
          />
        );
      break;
    case "AddAttributeModal":
      if (classes && handleParam && handleChange)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={(e) => handleChange(handleParam)}
            size="small"
            className={classes.checkBox}
            disabled={disabled ?? false}
          />
        );
      break;
    case "ProductCategorySelection":
      if (handleParam && handleChange)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={(e) => handleChange(handleParam)}
            size="small"
            className={checkboxclass.productCategorySelectionCheckbox}
            disabled={disabled ?? false}
          />
        );
      return (
        <StyledCheckbox
          checked={checked}
          onClick={(e) => handleChange}
          size="small"
          className={checkboxclass.productCategorySelectionCheckbox}
          disabled={disabled ?? false}
        />
      );
    case "ColorManagementActions":
      if (classes && handleParam && onClick)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={(e) => {
              onClick(!handleParam);
            }}
            disabled={disabled ?? false}
          />
        );
      break;
    case "ColorTile":
      if (classes && handleParam && togglePublishStatus)
        return (
          <StyledCheckbox
            checked={checked}
            className={classes.checkBox}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            onClick={(e) => {
              e.preventDefault();
              togglePublishStatus(handleParam);
            }}
            disabled={disabled ?? false}
          />
        );
      break;
    case "CategoryTreeModal":
      if (handleParam && handleNodeSelect)
        return (
          <StyledCheckbox
            className={checkboxclass.categoryCheckbox}
            checked={checked}
            tabIndex={-1}
            disableRipple
            onClick={(event) => {
              handleNodeSelect(event, handleParam);
            }}
            disabled={disabled ?? false}
          />
        );
      break;
    case "CoreSizesModal":
      if (classes && onChange && value)
        return (
          <StyledCheckbox
            checked={checked}
            onChange={onChange}
            name={value}
            disabled={disabled ?? false}
          />
        );
      break;
    case "AddFacetModal":
      if (classes && onChange && handleChange && handleParam)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={(e) => handleChange(handleParam)}
            size="small"
            className={classes.checkBox}
            disabled={disabled ?? false}
          />
        );
      break;
    case "ItemManagementTable":
      if (handleCheck && handleParam)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={(e) => handleCheck(e, handleParam[0], handleParam[1])}
            disabled={disabled ?? false}
          />
        );
      break;
    case "ShowPublicCheckbox":
      if (onChange)
        return (
          <StyledCheckbox
            checked={checked}
            onChange={onChange}
            name="showPublishedOnly"
            color="primary"
            size="small"
            disabled={disabled ?? false}
          />
        );
      break;
    case "RemoveProductCategorySelection":
      if (handleChange && handleParam)
        return (
          <StyledCheckbox
            checked={checked}
            onClick={() => handleChange(handleParam)}
            disabled={disabled ?? false}
          />
        );
      break;
    case "VariationProduct":
      if (value && addRemoveFromCurrentCategory && handleParam)
        return (
          <StyledCheckbox
            size="small"
            color="primary"
            id={value}
            checked={checked}
            onClick={(e) => addRemoveFromCurrentCategory(value, handleParam, e)}
            disabled={disabled ?? false}
          />
        );
      break;
    case "MultipleStoreLanguageTabs":
      if (value && onChange)
        return (
          <StyledCheckbox
            size="small"
            disabled={disabled ?? false}
            checked={checked}
            onChange={onChange}
            name={value}
          />
        );
      break;
    case "FindProductsProductList":
      if (
        value &&
        setCheckedProductIds &&
        currentCategoryId &&
        handleParam &&
        addRemoveFromCurrentCategory
      )
        return (
          <StyledCheckbox
            size="small"
            color="primary"
            id={value}
            checked={checked}
            onChange={(e) => {
              if (e.target.checked) {
                setCheckedProductIds((prevState: string[]) => {
                  return [...(prevState as string[]), value];
                });
              } else {
                setCheckedProductIds((prevState: string[]) => {
                  return prevState.filter((id) => id !== value);
                });
              }
              addRemoveFromCurrentCategory(handleParam, currentCategoryId, e);
            }}
            disabled={disabled ?? false}
          />
        );
      break;
    default:
      return (
        <StyledCheckbox
          size="small"
          color="primary"
          checked={checked}
          onChange={onChange}
          disabled={disabled ?? false}
        />
      );
  }
  return <></>;
};

export default CheckboxComponent;
