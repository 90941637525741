import {
  GET_APP_CONFIG_DETAILS,
  GET_CALLER_ACCOUNTS,
  LoadCallerAccountsAction,
  LoadConfigFromAppConfigAction,
  SET_ACCOUNT_ID,
  accountDetailsPayload,
  LOAD_ALL_ACCOUNT_DETAILS,
  LoadAllAccountDetailsAction,
} from "./AppConfigTypes";

export const loadAppConfigDetails = (): LoadConfigFromAppConfigAction => {
  return {
    type: GET_APP_CONFIG_DETAILS,
  };
};

export const loadCallerAccounts = (): LoadCallerAccountsAction => {
  return {
    type: GET_CALLER_ACCOUNTS,
  };
};

export const createUserAccountAction = (payload: accountDetailsPayload) => {
  return {
    type: SET_ACCOUNT_ID,
    payload,
  };
};

export const loadAllAccountDetails = (
  payload: accountDetailsPayload,
): LoadAllAccountDetailsAction => {
  return {
    type: LOAD_ALL_ACCOUNT_DETAILS,
    payload,
  };
};
