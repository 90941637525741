import type { Components } from "@mui/material";

export const MuiLinearProgress: Components["MuiLinearProgress"] = {
  styleOverrides: {
    root: {
      backgroundColor: "#D9D9D9",
    },
    bar: {
      backgroundColor: "#5CB65F",
    },
  },
};
