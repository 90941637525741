import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Drawer, Divider, IconButton, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import PrimaryToolbarSequence from "../../PrimaryToolbarSequence";
import LeftNavigationPanel from "../../left-navigation-panel/LeftNavigationPanel";
import ProductListContainer from "../ProductListContainer";
import ControlPanel from "../../ControlPanel";
import AppState from "../../../store/AppState";
import {
  selectStoreInfoById,
  selectCurrentStoreId,
} from "../../../store/store-list/StoreListSelectors";
import ItemManagementModal from "../../item-management/ItemManagementModal";
import ColorManagement from "../../color-mangement-modal/ColorManagement";
import FindProductModal from "../../find-products-modal/FindProductsModal";
import ConfirmCategorySelection from "../../left-navigation-panel/ConfirmCategorySelection";
import MultipleStoreLanguageModal from "../../store-language-modal/MultipleStoreLanguageModal";
import FacetsContainer from "../../facets/FacetsContainer";
import FacetsProductListContainer from "../../facets/FacetsProductListContainer";
import ViewContextProvider from "../../../context/view-context";
import EditAttributeValuesModal from "../../facets/EditAttributeValuesModal";
import AddAttributeModal from "../../add-attribute-modal/AddAttributeModal";
import FacetActionConfirmationModal from "../../facets/FacetActionConfirmationModal";
import AddFacetModal from "../../facets/AddFacetModal";
import ItemManagementSFCCModal from "../../item-management-SFCC/ItemManagementSFCCModal";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import ChildProductActionContainer from "../../product-display/ChildProductActionContainer";
import { CATALOG_NAVIGATION_TABS } from "../../../utils/Constants";
// import PreferencesModal from "../header/PreferencesModal";
import EditFacetBucketModal from "../../facets/EditFacetBucketModal";
import ChildProductionAttributeAction from "../../product-display/ChildProductionAttributeAction";
import CoreSizesModal from "../../core-sizes-management/CoreSizesModal";
import LogoutConfirmationModal from "../../header/LogoutConfirmationModal";
import AddProductsModal from "../../add-products-modal/AddProductsModal";
import ReplaceProductsModal from "../../replace-product-modal/ReplaceProductsModal";
import RemoveProductsModal from "../../remove-products-modal/RemoveProductsModal";
import { LinearProgressProps } from "@mui/material/LinearProgress";
import {
  selectAreAllPagesFetched,
  selectIsFetchingProductIds,
  selectProgressLoadingPercentage,
} from "../../../store/product-list/ProductListSelectors";
import LeadingImageModal from "../../leading-image-modal/LeadingImageModal";
import VariationManagement from "../../variation-management-modal/VariationManagement";
import { CategoryContextProvider } from "../../../context/CategoryContext";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../../store/app-config/AppConfigSelectors";
import { loadWatchedProductListAction } from "../../../store/product-list/ProductListActions";
import ProgressBar from "../../common/ProgressBar";
import { useStyles } from "./PersistentDrawerLeftStyles"; // Importing the styles
import Icons from "components/common/Icons";

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState([] as string[]);
  const dispatch = useDispatch();

  const { url } = useRouteMatch();

  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const storeInfo = useSelector((state: AppState) =>
    selectStoreInfoById(state, currentStoreId),
  );
  const showItemManagement = "true";
  const isFetchingProductIds = useSelector(selectIsFetchingProductIds);
  const progressValue = useSelector(selectProgressLoadingPercentage);
  const isAllPagesFetched = useSelector(selectAreAllPagesFetched);
  const selectedAccount = useSelector(selectCallerAccountId);
  const accountId = useSelector(selectCallerAccountId);

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <ProgressBar variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const bulkUpdateEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "bulkUpdateEnabled"),
    ) === "true" || showItemManagement === "true";

  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let priceListId: string = "";
  let fulfillmentCenterId: string = "";
  if (storeInfo) {
    fulfillmentCenterId =
      storeInfo.fulfillmentCenterIds.length > 0
        ? storeInfo.fulfillmentCenterIds[0]
        : "";

    storeInfo.priceLists.forEach((priceList) => {
      if (!priceListId && priceList.typeCode === "OFFER")
        priceListId = priceList.priceListId;
    });
  }

  useEffect(() => {
    if (accountId && isProductAlertsEnabled === "true") {
      dispatch(loadWatchedProductListAction());
    }
  }, [accountId, isProductAlertsEnabled, dispatch]);

  return (
    <CategoryContextProvider value={selected[0]}>
      {selectedAccount !== "" && (
        <DndProvider backend={HTML5Backend}>
          {isFetchingProductIds && <ProgressBar />}
          {progressValue !== 100 &&
          progressValue !== 0 &&
          !isFetchingProductIds &&
          !isAllPagesFetched ? (
            <LinearProgressWithLabel value={progressValue} />
          ) : null}
          <PrimaryToolbarSequence />
          <div className={classes.root}>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              PaperProps={{ style: { position: "absolute" } }}
            >
              <div className={classes.drawerHeader}>
                <IconButton
                  onClick={handleDrawerClose}
                  className={classes.drawerHeaderIcon}
                >
                  {theme.direction === "ltr" ? (
                    <Icons iconId="LeftArrowSmallIcon" type="regular" />
                  ) : (
                    <Icons iconId="RightArrowSmallIcon" type="regular" />
                  )}
                </IconButton>
              </div>
              <Divider />

              <LeftNavigationPanel
                setSelected={setSelected}
                selected={selected}
              />
            </Drawer>
            <div
              className={clsx(classes.main, {
                [classes.mainShift]: open,
              })}
            >
              <Switch>
                <Route path={`${url}/${CATALOG_NAVIGATION_TABS.PRODUCTS}`}>
                  <main
                    className={clsx(classes.content, {
                      [classes.contentShift]: open,
                    })}
                  >
                    <div className={classes.controlPanel}>
                      <ControlPanel
                        onDrawerOpen={handleDrawerOpen}
                        isOpen={open}
                        categoryId={selected[0]}
                      />
                    </div>
                    <div className={classes.list}>
                      <ProductListContainer />
                    </div>
                  </main>
                </Route>
                <Route path={`${url}/${CATALOG_NAVIGATION_TABS.FACETS}`}>
                  <main
                    className={clsx(classes.content, {
                      [classes.contentShift]: open,
                    })}
                  >
                    <FacetsContainer
                      onDrawerOpen={handleDrawerOpen}
                      isOpen={open}
                    />
                    <div className={classes.list}>
                      <ViewContextProvider>
                        <FacetsProductListContainer />
                      </ViewContextProvider>
                    </div>
                  </main>
                </Route>
                <Redirect to={`${url}/products?accountId=${selectedAccount}`} />
              </Switch>
            </div>
          </div>

          {bulkUpdateEnabled ? (
            <ItemManagementModal />
          ) : (
            <ItemManagementSFCCModal />
          )}

          <ChildProductActionContainer
            categoryId={selected[0]}
            priceListId={priceListId}
            fulfillmentCenterId={fulfillmentCenterId}
          />

          <ChildProductionAttributeAction />

          <ColorManagement categoryId={selected[0]} />
          <VariationManagement categoryId={selected[0]} />
          <FindProductModal categoryId={selected[0]} />
          <AddProductsModal />
          <ReplaceProductsModal />
          <RemoveProductsModal />
          <ConfirmCategorySelection setSelected={setSelected} />
          <MultipleStoreLanguageModal />
          <AddAttributeModal />
          <EditAttributeValuesModal />
          <FacetActionConfirmationModal />
          <AddFacetModal />
          {/* <PreferencesModal /> */}
          <EditFacetBucketModal />
          <CoreSizesModal />
          <LogoutConfirmationModal />
          <LeadingImageModal categoryId={selected[0]} />
        </DndProvider>
      )}
    </CategoryContextProvider>
  );
}
