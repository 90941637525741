import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import ReportTypeNavigationTabs from "../reports/ReportTypeNavigationTabs";
import ReportFilterPanel from "../reports/ReportFilterPanel";
import Report from "../reports/Report";

const defaultDrawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "calc(100vh - 100px)",
    },
    drawerPaper: {
      position: "relative",
    },
  }),
);

export default function ReportContainer() {
  const classes = useStyles();
  return (
    <>
      <ReportTypeNavigationTabs />
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ style: { width: defaultDrawerWidth } }}
        >
          <ReportFilterPanel />
        </Drawer>
        <Report />
      </div>
    </>
  );
}
