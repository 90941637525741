import { takeEvery, put } from "redux-saga/effects";
import {
  CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
  UpdateAttributeValueDescriptionAction,
  UPDATE_ATTRIBUTE_VALUE_DESCRIPTION,
  UpdateAttributeValueDescriptionResponse,
  UpdateAttributeValueCodeAction,
  UPDATE_ATTRIBUTE_VALUE_CODE,
  UpdateAttributeValueCodeResponse,
  AddAttributeValueAction,
  INSERT_ATTRIBUTE_VALUE,
  AddAttributeValueResponse,
  ADD_ATTRIBUTE_VALUE,
  CHANGE_ATTRIBUTE_VALUE_CODE,
  GetAttributeByIdAction,
  GET_ATTRIBUTE_BY_ID,
  GET_ATTRIBUTE_DATA_BY_ID,
} from "./AttributeTypes";
import { callGraphQLApi } from "../../utils/SagaUtils";
import GraphQLService from "../../services/GraphQL";
import {
  updateAttributeValueDescriptionQuery,
  updateAttributeValueCodeQuery,
  addAttributeValueQuery,
  getAttributeByIdQuery,
} from "./AttributeSchema";
import { SyncLogInserts } from "../../services/GraphQLTypes";

function* updateAttributeValueDescription(
  action: UpdateAttributeValueDescriptionAction,
) {
  const actionType = UPDATE_ATTRIBUTE_VALUE_DESCRIPTION;

  try {
    const attributeValueId = action.payload.attributeValueId;
    const localeCode = action.payload.localeCode;
    const attributeValueDisplayValue =
      action.payload.attributeValueDisplayValue;
    const syncLogInserts = {
      tableName: "AttributeValueDescription",
      operationType: "UPDATED",
      keyName1: "attributeValueId",
      oldKeyValue1: attributeValueId,
      newKeyValue1: attributeValueId,
      keyName2: "localeCode",
      oldKeyValue2: localeCode,
      newKeyValue2: localeCode,
    };

    const query = {
      query: updateAttributeValueDescriptionQuery,
      variables: {
        attributeValueId,
        localeCode,
        displayValue: attributeValueDisplayValue,
        syncLogInserts: [syncLogInserts],
      },
    };

    const result: UpdateAttributeValueDescriptionResponse =
      yield callGraphQLApi(actionType, GraphQLService.query, query);

    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
      }
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* updateAttributeValueCode(action: UpdateAttributeValueCodeAction) {
  const actionType = UPDATE_ATTRIBUTE_VALUE_CODE;

  try {
    const attributeValueId = action.payload.attributeValueId;
    const attributeValueDisplayValue =
      action.payload.attributeValueDisplayValue;
    const syncLogInserts = {
      tableName: "AttributeValue",
      operationType: "UPDATED",
      keyName1: "attributeValueId",
      oldKeyValue1: attributeValueId,
      newKeyValue1: attributeValueId,
    };

    const query = {
      query: updateAttributeValueCodeQuery,
      variables: {
        attributeValueId,
        displayValue: attributeValueDisplayValue,
        syncLogInserts: [syncLogInserts],
      },
    };

    const result: UpdateAttributeValueCodeResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );

    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
      }
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* addAttributeValue(action: AddAttributeValueAction) {
  const actionType = INSERT_ATTRIBUTE_VALUE;

  try {
    const attrValueObj: any = action.payload.attributeValueObject;
    const syncLogInserts: SyncLogInserts[] = [
      {
        tableName: "AttributeValue",
        operationType: "INSERTED",
        keyName1: "attributeValueId",
        newKeyValue1: attrValueObj.attributeValueId,
      },
    ];

    if (attrValueObj.descriptions) {
      syncLogInserts.push({
        tableName: "AttributeValueDescription",
        operationType: "INSERTED",
        keyName1: "attributeValueId",
        newKeyValue1: attrValueObj.attributeValueId,
        keyName2: "localeCode",
        newKeyValue2: attrValueObj.descriptions.data.localeCode,
      });
    } else {
      delete attrValueObj.descriptions;
    }

    const query = {
      query: addAttributeValueQuery,
      variables: {
        attributeValueObject: attrValueObj,
        syncLogInserts: syncLogInserts,
      },
    };

    const result: AddAttributeValueResponse = yield callGraphQLApi(
      actionType,
      GraphQLService.query,
      query,
    );

    /**
     * TODO : Add success and error handling
     */
    if (result) {
      if (result.type === actionType.SUCCESS) {
      }
    }
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

function* getAttributeById(action: GetAttributeByIdAction) {
  const actionType = GET_ATTRIBUTE_BY_ID;
  try {
    const query = {
      query: getAttributeByIdQuery,
      variables: {
        attributeId: action.payload.attributeId,
      },
    };
    yield callGraphQLApi(actionType, GraphQLService.query, query);
  } catch (e: any) {
    console.error(e);
    yield put({ type: actionType.FAILURE, message: e.message });
  }
}

export function* watchUpdateAttributeValues() {
  yield takeEvery(
    CHANGE_ATTRIBUTE_VALUE_DESCRIPTION,
    updateAttributeValueDescription,
  );
}

export function* watchUpdateAttributeValuesCode() {
  yield takeEvery(CHANGE_ATTRIBUTE_VALUE_CODE, updateAttributeValueCode);
}

export function* watchAddAttributeValue() {
  yield takeEvery(ADD_ATTRIBUTE_VALUE, addAttributeValue);
}

export function* watchGetAttributeById() {
  yield takeEvery(GET_ATTRIBUTE_DATA_BY_ID, getAttributeById);
}
