import * as Yup from "yup";
import { useIntl } from "react-intl";

export function useUtils() {
  const intl = useIntl();
  const requiredText = intl.formatMessage({
    id: "automationRules.requiredText",
    defaultMessage: "Required",
  });

  const INITIAL_STATE = {
    name: "",
    description: "",
    start: "",
    timezone: "",
    retries: "",
    repeats: "",
    repeatsEvery: 1,
    appliedRule: "",
    repeatDays: [],
    ends: "",
    scope: [],
    dateRange: "",
    showDateRange: false,
  } as const;

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(requiredText),
    retries: Yup.number().required(requiredText),
    timezone: Yup.string().required(requiredText),
    start: Yup.string().required(requiredText),
    repeats: Yup.string().required(requiredText),
    repeatsEvery: Yup.number()
      .min(1, "Repeats Frequency must be greater than or equal to 1")
      .max(30, "Repeats Frequency must be less than 30")
      .required(requiredText),
    appliedRule: Yup.string().required(requiredText),
    ends: Yup.string(),
    showDateRange: Yup.boolean(),
    dateRange: Yup.string().when("showDateRange", ([showDateRange], sch) => {
      return showDateRange ? sch.required(requiredText) : sch.notRequired();
    }),
  });

  const FORM_VALIDATION_NEW = Yup.object().shape({
    name: Yup.string().required(requiredText),
    start: Yup.string().required(requiredText),
    retries: Yup.number().required(requiredText),
    timezone: Yup.string().required(requiredText),
    appliedRule: Yup.string().required(requiredText),
    showDateRange: Yup.boolean(),
    dateRange: Yup.string().when("showDateRange", ([showDateRange], sch) => {
      return showDateRange ? sch.required(requiredText) : sch.notRequired();
    }),
  });
  return {
    FORM_VALIDATION,
    INITIAL_STATE,
    FORM_VALIDATION_NEW,
  };
}
