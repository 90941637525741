import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Item } from "../../store/product/ProductTypes";

type Order = "asc" | "desc";

interface ItemManagementTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  selected: { [key: string]: { [sku: string]: boolean } };
  rows: Item[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortIcon: {
      opacity: 0.2,
      marginRight: "0px !important",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableCell: {
      fontSize: "1rem", // Default font size is 16px, this adds 2px to make it 18px
      fontWeight: "bold", // Make the text bold
      padding: "10px",
    },
  }),
);

export default function ItemManagementTableHead(
  props: ItemManagementTableHeadProps,
) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, rows } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {rows[0] &&
          Object.keys(rows[0]).map((key, index) => {
            if (key === "Published") {
              return (
                <TableCell
                  align="center"
                  key={`${key}_${index}`}
                  className={classes.tableCell}
                >
                  {key}
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  key={key}
                  sortDirection={orderBy === key ? order : false}
                  align="center"
                  className={classes.tableCell}
                >
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : "asc"}
                    onClick={createSortHandler(key)}
                    classes={{
                      icon: classes.sortIcon, // Apply the sortIcon class here
                    }}
                  >
                    {key}
                    {orderBy === key ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              );
            }
          })}
      </TableRow>
    </TableHead>
  );
}
