import { ColorData } from "../color-management/ColorManagementTypes";
import {
  setLeadingImageForProductInCategoryPayload,
  removeLeadingImageForProductInCategoryPayload,
} from "./LeadingImageActions";

export interface LeadingImage {
  imageId: string;
  url: string;
}

interface VariationValue {
  valueId: string;
}

interface VariationAttribute {
  attributeId: string;
  values: VariationValue[];
}

export interface ImageIdByProductIdByCategoryIdData {
  [x: string]: {
    [x: string]: LeadingImage;
  };
}

export interface LeadingImageVariationAttributesData {
  attributeId: string;
  name: string;
  translations: {
    localeCode: string;
    name: string;
  }[];
  values: {
    name: string;
    value: string;
    translations: {
      localeCode: string;
      name: string;
    }[];
    isAssigned: boolean;
  }[];
  colors?: ColorData[];
}

export interface LeadingImageImagesData {
  imageId: string;
  url: string;
  variationAttributes: {
    attributeId: string;
    values: {
      valueId: string;
    }[];
  }[];
  isSelected?: boolean;
  colors?: ColorData;
}

export interface ProductImage {
  imageId: string;
  url: string;
  isSelected?: boolean;
  variationAttributes?: VariationAttribute[];
}

export interface LoadVariationAtrributeValues {
  attributes: [{ attrName: string; attrValue: string }];
  attrId: string;
}
export interface AdapterInfo {
  adapterId: string;
  operations: [];
  capabilities: [];
}

export interface LeadingImageState {
  imageIdByProductIdByCategoryId: Record<string, Record<string, LeadingImage>>;
  AdapterInfo: AdapterInfo;
}

export interface FilterValueType {
  [x: string]: {
    [x: string]: {
      [x: string]: string;
    };
  };
}

export interface PreSelectedColorType {
  [x: string]: string;
}

export const FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY =
  "FETCH_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY";
export const RESET_LEADING_IMAGE_LIST = "RESET_LEADING_IMAGE_LIST";
export const FETCH_LEADING_IMAGE_IMAGES = "FETCH_LEADING_IMAGE_IMAGES";
export const FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES =
  "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES";
export const FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE =
  "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_COMPLETE";

export const SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY =
  "SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY";

export const REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY =
  "REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY";

export const SET_FILTER_VALUES_FOR_PRODUCT = "SET_FILTER_VALUES_FOR_PRODUCT";
export const RESET_FILTER_FOR_PRODUCT = "RESET_FILTER_FOR_PRODUCT";
export const RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT =
  "RESET_VARIATION_ATTRIBUTES_FOR_PRODUCT";
export const RESET_VARIATION_ATTRIBUTES_STATE =
  "RESET_VARIATION_ATTRIBUTES_STATE";

export interface SetLeadingImageForProductInCategoryAction {
  type: typeof SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY;
  payload: setLeadingImageForProductInCategoryPayload;
}

export interface RemoveLeadingImageForProductInCategoryAction {
  type: typeof REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY;
  payload: removeLeadingImageForProductInCategoryPayload;
}

export interface StoreSetLeadingImageSuccessAction {
  type: typeof STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY;
  payload: setLeadingImageForProductInCategoryPayload;
}

export interface StoreRemoveLeadingImageSuccessAction {
  type: typeof STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY;
  payload: removeLeadingImageForProductInCategoryPayload;
}

export interface GetVariationAttributesPayload {
  baseProductId: string;
}
export interface GetVariantAttributesAction {
  type: typeof FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES;
  payload: GetVariationAttributesPayload;
}

export const FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID = {
  REQUEST:
    "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_REQUEST",
  SUCCESS:
    "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_SUCCESS",
  FAILURE:
    "FETCH_LEADING_IMAGE_VARIATION_ATTRIBUTES_BY_BASE_PRODUCT_ID_FAILURE",
};

export const CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API = {
  REQUEST: "CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_REQUEST",
  SUCCESS: "CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_SUCCESS",
  FAILURE: "CALL_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_FAILURE",
};

export const CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API = {
  REQUEST: "CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_REQUEST",
  SUCCESS: "CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_SUCCESS",
  FAILURE: "CALL_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY_API_FAILURE",
};

export const STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY =
  "STORE_SET_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY";

export const STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY =
  "STORE_REMOVE_LEADING_IMAGE_FOR_PRODUCT_IN_CATEGORY";
