import React, { useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { useHistory, useLocation } from "react-router-dom";
import { getUserName, getUserId, getUserEmail } from "./services/UserService";
import AppEnv from "./utils/AppEnv";
interface Props {
  children: React.ReactNode;
}

const GoogleAnalyticsWrapper = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const pathnameRef = useRef(location.pathname);
  const searchRef = useRef(location.search);

  useEffect(() => {
    const trackingId = AppEnv.GOOGLE_ANALYTICS_TRACKING_ID;
    ReactGA.initialize(trackingId);
    const page = `${location.pathname}${location.search}`;
    ReactGA.set({
      page,
      dimension1: getUserId(),
      dimension2: getUserEmail(),
      dimension3: getUserName(),
    });
    ReactGA.pageview(page);
  }, [location.pathname, location.search]);

  history.listen((location) => {
    if (
      pathnameRef.current !== location.pathname ||
      searchRef.current !== location.search
    ) {
      pathnameRef.current = location.pathname;
      searchRef.current = location.search;
      const page = `${location.pathname}${location.search}`;
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  });

  return <>{props.children}</>;
};

export default GoogleAnalyticsWrapper;
