import React, { useState, useCallback } from "react";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import ReportTablePagination from "./ReportTablePagination";
import { Order } from "../../utils/SortTableUtils";
import {
  selectProducts,
  selectAnalyticsDataForReport,
} from "../../store/product/ProductSelectors";
import { selectCurrentLocale } from "../../store/store-list/StoreListSelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import { Product } from "../../store/product/ProductTypes";
import { selectAnalyticsDataFieldsForReport } from "../../store/product/ProductSelectors";
import { selectReportFields } from "../../store/report/ReportSelectors";
import AppState from "../../store/AppState";
import TableComponent from "../common/Table";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
      position: "relative",
    },
    container: {
      height: "calc(100% - 100px)",
    },
    toolBar: {
      backgroundColor: theme.palette.grey[200],
      minHeight: "48px",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      marginRight: 0,
      marginLeft: "auto !important",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    tableCellText: {
      margin: "auto",
      width: "120px",
      wordWrap: "break-word",
      padding: "12px",
    },
    leftAlign: {
      textAlign: "left",
    },
  }),
);

export default function ReportTable() {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState<{ [key: string]: string | number }[]>([]);
  const [useSearchResults, setUseSearchResults] = useState(false);
  const products = useSelector(selectProducts);
  const analyticsData = useSelector(selectAnalyticsDataForReport);
  const allFields = useSelector(selectAnalyticsDataFieldsForReport);
  const selectedFields = useSelector(selectReportFields);
  const currentLocaleCode = useSelector(selectCurrentLocale);
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const findLocalizedDesc = useCallback(
    (product: Product) =>
      product &&
      (product?.descriptions.find(
        (desc) => desc.localeCode === currentLocaleCode,
      ) ||
        product?.descriptions.find(
          (desc) => desc.localeCode === defaultLocaleId,
        )),
    [currentLocaleCode, defaultLocaleId],
  );

  analyticsData.forEach((data) => {
    data.Name = findLocalizedDesc(products[data.productId])?.name || "";
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    ReactGA.event({
      category: "Report",
      action: `Sort ${property} In ${isAsc ? "desc" : "asc"} Order Action`,
    });
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newRows: { [key: string]: string | number }[] = [...analyticsData];
    newRows = newRows.filter((data) => {
      return Object.keys(data).some((key) => {
        return data[key]
          .toString()
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
    });
    setRows(newRows);
    setPage(0);
    setUseSearchResults(newRows.length !== analyticsData.length);
    if (!useSearchResults && newRows.length !== analyticsData.length) {
      ReactGA.event({
        category: "Report",
        action: "Search Action",
      });
    }
  };

  const defaultFields =
    selectedFields.length > 0 && (useSearchResults ? rows[0] : analyticsData[0])
      ? Object.keys(useSearchResults ? rows[0] : analyticsData[0]).filter(
          (key) => !allFields.includes(key),
        )
      : [];
  const analyticsFields =
    selectedFields.length > 0 && (useSearchResults ? rows[0] : analyticsData[0])
      ? Object.keys(useSearchResults ? rows[0] : analyticsData[0]).filter(
          (key) => selectedFields.includes(key),
        )
      : [];

  const row = [...defaultFields, ...analyticsFields];

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <Icons iconId="SearchIcon" />
          </div>
          <InputBase
            placeholder={"Search..."}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleSearch}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </Toolbar>
      <TableComponent
        tableId="ReportTable"
        classes={classes}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowsReport={rows}
        page={page}
        rowsPerPage={rowsPerPage}
        analyticsData={analyticsData}
        selectedFields={selectedFields}
        allFields={allFields}
        defaultFields={defaultFields}
        row={row}
      />
      <ReportTablePagination
        count={
          selectedFields.length > 0
            ? useSearchResults
              ? rows.length
              : analyticsData.length
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Paper>
  );
}
