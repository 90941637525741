export const fetchInventoryNotificationProductsQuery = `subscription inventoryNotificationProducts($storeId: String!, $quantity: numeric_comparison_exp!, $isPublished: Boolean!) {
  Inventory(where: {quantity: $quantity, product: {isPublished: {_eq: $isPublished}}}, limit: 20) {
    productId
    quantity
    product {
      code
      productId
      isPublished
      descriptions {
        isPublished
        name
        thumbnail
        localeCode
      }
      overrides(where: {storeId: {_eq: $storeId}}) {
        isPublished
      }
      toParentProducts {
        parentProduct {
          code
          productId
          typeCode
          isPublished
          descriptions {
            isPublished
            name
            thumbnail
            localeCode
          }
          overrides(where: {storeId: {_eq: $storeId}}) {
            isPublished
          }
        }
      }
    }
  }
}`;
