import React, { useContext, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import ProductDetail from "../ProductDetail";
import { Product } from "../../store/product/ProductTypes";
import ProductSwatches from "../ProductSwatches";
import AppState from "../../store/AppState";
import {
  makeSelectProductSwatches,
  selectProductPrice,
  selectProductLeadingColor,
  selectedProductSwatches,
  selectIsProductDetailRefereshing,
  selectProductAnalyticsData,
  selectProductLeadingImage,
} from "../../store/product/ProductSelectors";
import {
  isRefreshingAllProducts,
  selectEditSequenceList,
} from "../../store/product-list/ProductListSelectors";
import ProductInventoryTable from "../product-inventory-tile/ProductInventoryTable";
import ProductAttributeSelection from "../product-attribute-tile/ProductAttributeSelection";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import AnalyticsOverlay from "../overlays/AnalyticsOverlay";
import PinnedProductOverlay from "../overlays/PinnedProductOverlay";
import ProductCategoryOverlay from "../overlays/ProductCategoryOverlay";
import {
  selectConfigValue,
  selectProductImagePrefix,
} from "../../store/app-config/AppConfigSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { PLATFORM_DEFAULTS } from "../../utils/DefaultConfigs";
import {
  getProductLeadingColorImageSFCC,
  getProductLeadingImageOnInitialLoad,
  isAbsolutePath,
} from "../../utils/ProductUtil";
import { updatedSelectedSwatches } from "../../store/product/ProductActions";
import { OVERLAY_TABS } from "../../utils/Constants";
import Rating from "@mui/material/Rating";
import { selectLeadingColorImageForProductInCategory } from "../../store/color-management/ColorManagementSelectors";
import { selectLeadingImageUrlForProductInCategory } from "../../store/leading-image/LeadingImageSelectors";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { AdapterInfoObject } from "../../utils/AppConfigUtils";
import { selectAdapterInfo } from "../../store/adapter-config/AdapterConfigSelectors";
import { ViewContext } from "../../context/view-context";
import {
  useStyles,
  StyledProductTileContent,
  StyledLoadingContainer,
  ScrollableContainer,
  StyledContainer,
  StyledCircularDiv,
  StyledSwatchDiv,
} from "./ProductTileContentStyles";
import swatchIcon from "../../assets/color-swatch-icon.svg";
import Popover from "@mui/material/Popover";
import { ColorData } from "store/color-management/ColorManagementTypes";
import TooltipComponent from "components/common/ToolTip";

interface Props {
  product: Product;
  rating?: number;
  reviewCount?: number;
  index: number;
  sequence: number;
  productView: boolean;
  isDragging: boolean;
  isRecentlyAdded?: boolean;
}

const ProductTileContent: React.FC<Props> = (props) => {
  const intl = useIntl();
  const updatedProducts = useSelector(selectEditSequenceList);
  const {
    index,
    rating,
    reviewCount,
    product: {
      typeCode,
      code,
      productId,
      name,
      productScore,
      stock,
      colors,
      hasColors,
    },
    sequence,
    productView,
    isDragging,
    isRecentlyAdded,
  } = props;
  const currentProductFromUpdatedState = updatedProducts.find(
    (productData) => productData.productId === productId,
  );
  const [selectedThumbnailImage, setSelectedThumbnailImage] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const localeId = useSelector(selectCurrentLocale) || "";
  const currentCategoryId = useSelector(selectCurrentCategory) || "";
  const storeId = useSelector(selectCurrentStoreId) || "";
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[ecomPlatformType];
  const [colorName, setColorName] = useState<String>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const productImagePrefix = useSelector((state: AppState) =>
    selectProductImagePrefix(state, storeId),
  );
  const open = Boolean(anchorEl);
  const publishedColors = colors?.filter(({ isPublished }) => isPublished);
  const displaySequence =
    useSelector((state: AppState) =>
      selectConfigValue(state, "displaySequences"),
    ) === "true";
  const colorSequenceMode = useSelector((state: AppState) =>
    selectConfigValue(state, "colorSequenceMode"),
  );
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );
  const attrValSequenceLocaleSpecific =
    useSelector((state: AppState) =>
      selectConfigValue(state, "attrValSequenceLocaleSpecific"),
    ) === "true";
  const leadingColorEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "leadingColorEnabled"),
    ) === "true";
  const storeAndAccountInventory = useSelector((state: AppState) =>
    selectConfigValue(state, "inventoryThreshold"),
  );
  const inventoryThreshold = parseInt(
    storeAndAccountInventory !== ""
      ? storeAndAccountInventory
      : platformDefaults?.inventoryThreshold ?? "",
  );

  let type = typeCode === "ProductBean" ? "Product" : typeCode;
  const view = useContext(ViewContext);
  const columns = view.styles.column;
  const changeStyles =
    columns === 6 ? { fontSize: "0.75rem" } : { fontSize: "inherit" };
  const productTypeStyles =
    columns === 6 || columns === 5
      ? {
          display: "block",
          fontSize: "0.75rem",
        }
      : { fontSize: "inherit" };
  const selectProductSwatches = useMemo(makeSelectProductSwatches, []);
  const swatches = useSelector((state: AppState) =>
    selectProductSwatches(
      state,
      productId,
      colorSequenceMode,
      attrValSequenceLocaleSpecific,
      localeId,
      defaultLocaleId,
    ),
  );
  const productPrice = useSelector((state: AppState) =>
    selectProductPrice(state, productId),
  );
  const leadingColors = useSelector((state: AppState) =>
    selectProductLeadingColor(state, productId),
  );

  const selectedOverlay = useSelector(selectOverlay);
  const selectedSwatches = useSelector(selectedProductSwatches);
  const isProductDetailRefreshing = useSelector((state: AppState) =>
    selectIsProductDetailRefereshing(state, productId),
  );
  const showProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productId),
  )?.showAnalytics;
  const loadSingleProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productId),
  )?.loadAnalytics;
  const productLeadingImage = useSelector((state: AppState) =>
    selectProductLeadingImage(state, productId, currentCategoryId),
  );
  const isRefreshingEveryProduct = useSelector(isRefreshingAllProducts);
  const selectedSwatch = selectedSwatches[productId]?.selectedSwatch ?? "";

  let price = productPrice ? productPrice.price.toString() : "N/A";
  if (productPrice && productPrice.lowPrice < productPrice.highPrice) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    price = productPrice.lowPrice + "-" + productPrice.highPrice;
  }
  const productData = props.product;
  const updatedBaseProductLeadingColorImage = useSelector((state: AppState) =>
    selectLeadingColorImageForProductInCategory(
      state,
      productId,
      currentCategoryId,
    ),
  );

  const productLeadingImageOnInitialLoad = useMemo(() => {
    const leadingImage = getProductLeadingImageOnInitialLoad(
      productData,
      currentCategoryId,
    );
    return leadingImage;
  }, [productData, currentCategoryId]);

  const productLeadingColorImage = useMemo(() => {
    if (productData?.categorySpecific) {
      return productData?.categorySpecific?.length > 0
        ? getProductLeadingColorImageSFCC(productData, currentCategoryId)
        : "";
    } else {
      return updatedBaseProductLeadingColorImage;
    }
  }, [productData, currentCategoryId, updatedBaseProductLeadingColorImage]);

  const inventory =
    stock >= 0
      ? intl.formatMessage({
          id: "productTileContent.inventoryText",
          defaultMessage: "INV",
        }) +
        " : " +
        stock
      : "";
  let colorIndex = -1;
  if (
    leadingColorEnabled &&
    leadingColors &&
    leadingColors.length > 0 &&
    swatches
  ) {
    colorIndex = swatches.findIndex(
      (swatch) => swatch.attributeValueId === leadingColors[0].attributeValueId,
    );
  }
  if (colorIndex < 0 && swatches) {
    colorIndex = swatches.findIndex((color) => color.isPublished);
  }

  const id = open ? productId : undefined;
  const leadingImageUrl =
    useSelector((state: AppState) =>
      selectLeadingImageUrlForProductInCategory(
        state,
        productId,
        currentCategoryId,
      ),
    ) ?? "";

  const productImage = useMemo(() => {
    const image = leadingImageUrl
      ? leadingImageUrl
      : productLeadingImage
        ? productLeadingImage
        : productLeadingImageOnInitialLoad
          ? productLeadingImageOnInitialLoad
          : productLeadingColorImage
            ? productLeadingColorImage
            : colors?.find((color) => color.isPublished)?.thumbnail
              ? colors.find((color) => color.isPublished)?.thumbnail
              : productData.thumbnail;
    setSelectedThumbnailImage(image);
    return image;
  }, [
    productData,
    leadingImageUrl,
    productLeadingImage,
    productLeadingImageOnInitialLoad,
    productLeadingColorImage,
    colors,
  ]);

  const isRatingVisible = rating !== -1 && reviewCount !== -1;

  const positionOrSequenceDisplay = displaySequence
    ? intl.formatMessage({
        id: "productTileContent.sequenceText",
        defaultMessage: "Seq",
      }) +
      " : " +
      sequence
    : intl.formatMessage({
        id: "productTileContent.positionText",
        defaultMessage: "Position",
      }) +
      " : " +
      (sequence && currentProductFromUpdatedState?.isInitialPositionAvailable
        ? sequence
        : intl.formatMessage({
            id: "productTileContent.notSetText",
            defaultMessage: "Not set",
          }));

  const swatchClickHandler = useCallback(
    (image: string, attrValId: string) => {
      const swatchObj = {
        selectedSwatch: attrValId,
        productImage: image,
      };

      dispatch(
        updatedSelectedSwatches({
          swatch: swatchObj,
          productId,
        }),
      );
    },
    [dispatch, productId],
  );

  const finalProductScore = Array.isArray(productScore)
    ? productScore.reduce((partialSum, a) => partialSum + a, 0)
    : productScore;

  const handleColorSwatchName = useCallback((event, swatchImage, colorName) => {
    event.stopPropagation();
    setSelectedThumbnailImage(swatchImage);
    setColorName(colorName);
  }, []);

  const handleMultipleColorSwatches = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const isLeadingImageMatched = (selectedImage: string, color: ColorData) => {
    if (productData && productData?.imageGroups) {
      for (const imageGroup of productData.imageGroups) {
        if (
          imageGroup?.images?.some(
            (image) => image.thumbnail === selectedImage,
          ) &&
          imageGroup?.variationAttributes?.some((attr) =>
            attr.values?.find((attrValue) => color.colorId === attrValue.value),
          )
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const colorSwatches = (color: ColorData) => {
    const isSelectedImage = isLeadingImageMatched(
      selectedThumbnailImage,
      color,
    );
    if (
      (color.thumbnail === selectedThumbnailImage || isSelectedImage) &&
      color.name !== colorName
    ) {
      setColorName(color.name);
    }
    const borderClass =
      color.stock === 0
        ? classes.redBorder
        : color.stock < inventoryThreshold
          ? classes.yellowBorder
          : classes.greyBorder;

    const selectedClass =
      color.thumbnail === selectedThumbnailImage || isSelectedImage
        ? classes.selectedBoxStyle
        : classes.defaultBorder;
    const swatchClass = {
      ...classes.colorSwatchImageContainer,
      ...borderClass,
    };
    const productColorSwatch = color.swatchImage || color.thumbnail;
    const swatchImage = isAbsolutePath(productColorSwatch)
      ? productColorSwatch
      : productImagePrefix + productColorSwatch;
    return (
      <Box style={classes.swatch}>
        <div
          key={color.code}
          onClick={(event) =>
            handleColorSwatchName(event, color.thumbnail, color.name)
          }
          title={color.name}
          style={selectedClass}
        >
          <div style={swatchClass}>
            <img
              src={swatchImage}
              alt={color.code}
              style={classes.colorSwatchImage}
            />
          </div>
        </div>
      </Box>
    );
  };

  const renderColorSwatches = useMemo(() => {
    if (publishedColors && publishedColors.length > 0) {
      return (
        <ScrollableContainer>
          {publishedColors.map((color, index) => {
            //Rendering color swatches upto 4 for columns 5,6 & upto 6 for remaining columns
            if (
              color.thumbnail !== "" &&
              color.swatchImage !== "" &&
              ((index < 4 && (columns === 6 || columns === 5)) ||
                (index < 6 && columns !== 6 && columns !== 5))
            ) {
              return colorSwatches(color);
            } else {
              return null;
            }
          })}
          {/*New condition to display the swatch container button on columns 2 to 4 if there are more than 6 swatch images  
          and on columns 5,6 if there are more than 4 swatch images*/}
          {((publishedColors.length > 4 && (columns === 6 || columns === 5)) ||
            publishedColors.length > 6) && (
            <>
              <div
                id={id}
                aria-describedby={id}
                style={classes.swatchButton}
                onClick={(event) => handleMultipleColorSwatches(event)}
              >
                <img src={swatchIcon} alt="Swatch Icon" />
              </div>
              <Popover
                open={open}
                onClose={(event) => handleClose(event)}
                id={id}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                anchorEl={anchorEl}
                sx={classes.popoverStyles}
              >
                <div style={classes.swatchBox}>
                  {publishedColors.map((color, index) => {
                    //Excluding initial 4 color swatches for columns 5,6 & upto 6 for remaining columns
                    if (
                      color.thumbnail !== "" &&
                      color.swatchImage !== "" &&
                      ((index > 3 && (columns === 6 || columns === 5)) ||
                        (index > 5 && columns !== 6 && columns !== 5))
                    ) {
                      return colorSwatches(color);
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Popover>
            </>
          )}
        </ScrollableContainer>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    colors,
    inventoryThreshold,
    selectedThumbnailImage,
    open,
    anchorEl,
    publishedColors,
  ]);

  // TODO: randomly generate inv for demo. need to calculate based on core sizes
  // const coreSizesInventory = productInventory ? 'Core : ' + Math.floor(productInventory.inventory * Math.random()) : '';
  return (
    <>
      {(!productView ||
        (selectedOverlay !== OVERLAY_TABS.INVENTORY_OVERLAY &&
          selectedOverlay !== OVERLAY_TABS.ATTRIBUTES_OVERLAY)) && (
        <StyledProductTileContent>
          <Box>
            <div style={classes.position}>{positionOrSequenceDisplay}</div>
            <Box>
              <TooltipComponent
                tooltipTitle={type}
                placement="bottom"
                interactive
                enterDelay={500}
              >
                <Typography
                  style={productTypeStyles}
                  sx={classes.productType}
                  variant="subHeader"
                >
                  {type}
                </Typography>
              </TooltipComponent>
            </Box>
            <Box>
              <TooltipComponent
                tooltipTitle={code}
                interactive
                enterDelay={500}
              >
                <Typography
                  style={changeStyles}
                  sx={classes.productCode}
                  variant="subtitle2"
                >{`${code}`}</Typography>
              </TooltipComponent>
            </Box>
            <Box>
              <Typography
                sx={stock === 0 ? classes.inventoryColor : {}}
                variant="subHeader"
              >
                {inventory}
              </Typography>
            </Box>
            {finalProductScore !== undefined ? (
              <div>
                <Typography variant="highlightedText" style={changeStyles}>
                  {intl.formatMessage({
                    id: "productDetail.productScore",
                    defaultMessage: "Product Score: ",
                  })}
                </Typography>
                <Typography variant="highlightedText" style={changeStyles}>
                  {finalProductScore.toFixed(2)}
                </Typography>
              </div>
            ) : null}
          </Box>
        </StyledProductTileContent>
      )}
      {!isDragging &&
        productView &&
        selectedOverlay !== OVERLAY_TABS.INVENTORY_OVERLAY &&
        selectedOverlay !== OVERLAY_TABS.ATTRIBUTES_OVERLAY &&
        selectedOverlay !== OVERLAY_TABS.CATEGORIES_OVERLAY && (
          <PinnedProductOverlay productId={productId} index={index} />
        )}
      {(productView || loadSingleProductAnalytics || showProductAnalytics) && (
        <AnalyticsOverlay
          productId={productId}
          showProductAnalytics={showProductAnalytics}
          loadSingleProductAnalytics={loadSingleProductAnalytics}
        />
      )}
      {productView && selectedOverlay === OVERLAY_TABS.CATEGORIES_OVERLAY && (
        <ProductCategoryOverlay productId={productId} />
      )}
      {isProductDetailRefreshing || isRefreshingEveryProduct ? (
        <StyledContainer>
          <StyledCircularDiv>
            <CircularProgress />
          </StyledCircularDiv>
        </StyledContainer>
      ) : null}
      <ProductDetail
        name={name}
        price={
          props.product.listPrice !== undefined
            ? props.product.listPrice.toString()
            : ""
        }
        priceRange={
          props.product?.lowPrice && props.product?.highPrice
            ? [props.product.lowPrice, props.product.highPrice]
            : props.product?.lowPrice
              ? [props.product.lowPrice]
              : null
        }
        type={props.product.typeCode}
        image={selectedThumbnailImage ? selectedThumbnailImage : productImage}
        isRecentlyAdded={isRecentlyAdded}
      />
      {productView &&
      selectedOverlay === OVERLAY_TABS.INVENTORY_OVERLAY &&
      typeCode !== "ProductSet" &&
      typeCode !== "ProductBundle" ? (
        <ProductInventoryTable
          productId={productId}
          swatches={swatches ? swatches : []}
        />
      ) : productView &&
        selectedOverlay === OVERLAY_TABS.ATTRIBUTES_OVERLAY &&
        typeCode !== "ProductSet" &&
        typeCode !== "ProductBundle" ? (
        <ProductAttributeSelection productId={productId} />
      ) : (
        swatches &&
        swatches.length > 0 &&
        typeCode !== "ProductSet" &&
        typeCode !== "ProductBundle" && (
          <ProductSwatches
            productId={productId}
            swatches={swatches}
            selectedSwatch={selectedSwatch}
            onSwatchClick={swatchClickHandler}
          />
        )
      )}
      {selectedOverlay === "ratings"
        ? null
        : hasColors && (
            <StyledSwatchDiv>
              {colorName !== "" && (
                <Typography variant="body3">Color: {colorName}</Typography>
              )}
              {renderColorSwatches}
            </StyledSwatchDiv>
          )}
      {selectedOverlay === "ratings" ? (
        isRatingVisible ? (
          <Box ml={1} sx={classes.ratingsContainer}>
            <div style={classes.ratingContainerMargin}>
              <Rating
                name="read-only"
                value={rating}
                precision={0.5}
                readOnly
              />
            </div>
            <div style={classes.ratingTextPadding}>
              <Typography variant="inherit">
                {`${rating} (${reviewCount})`}
              </Typography>
            </div>
          </Box>
        ) : (
          <StyledLoadingContainer>Loading...</StyledLoadingContainer>
        )
      ) : null}
    </>
  );
};

export default React.memo(ProductTileContent);
