import React from "react";

import StoreFilter from "./StoreFilter";
import CatalogFilter from "./CatalogFilter";
import CategoryFilter from "./CategoryFilter";

interface Props {}

const ReportFilterPanel: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <StoreFilter />
      <CatalogFilter />
      <CategoryFilter />
    </React.Fragment>
  );
};

export default ReportFilterPanel;
