import React, { useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import {
  selectStoreListIds,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import DropdownMenu from "../common/DropdownMenu";
import Icons from "components/common/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      backgroundColor: theme.palette.grey[200],
      minHeight: "48px",
    },

    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      marginRight: 0,
      marginLeft: "auto !important",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    formControl: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      alignItems: "baseline",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "&  .MuiInput-formControl": {
        marginTop: 0,
      },
      "& label": {
        marginRight: "7px",
      },
    },
  }),
);

interface Props {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedStoreId: string;
  onSelectedStoreId: React.Dispatch<React.SetStateAction<string>>;
}

const ItemManagementToolbar: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { onSearch, selectedStoreId, onSelectedStoreId } = props;
  const classes = useStyles();
  const Stores = useSelector(selectStoreListIds);
  const currentStore = useSelector(selectCurrentStoreId) ?? "";

  useEffect(() => {
    onSelectedStoreId(currentStore);
  }, [currentStore, onSelectedStoreId]);

  const storesChangeHandler = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string;
    onSelectedStoreId(value);
  };

  return (
    <Toolbar className={classes.toolBar}>
      <FormControl className={classes.formControl}>
        <label>
          <Typography color="textPrimary">Store : </Typography>
        </label>
        <DropdownMenu
          menuId="ItemManagementToolbar"
          value={selectedStoreId}
          changeHandler={storesChangeHandler}
          stores={Stores}
        />
      </FormControl>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Icons iconId="SearchIcon" />
        </div>
        <InputBase
          placeholder={intl.formatMessage({
            id: "itemManagementToolBar.searchBoxPlaceholder",
            defaultMessage: "Search...",
          })}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={onSearch}
          inputProps={{ "aria-label": "search" }}
        />
      </div>
    </Toolbar>
  );
};

export default React.memo(ItemManagementToolbar);
