import { FC, createContext, useContext, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
//import { lightTheme as newTheme } from "./styles/themes/light";
import { classicTheme as legacyTheme, TTheme } from "styles/themes/classic";

// Define type for the context value
type ThemeContextType = {
  theme: string;
  muiTheme: TTheme;
  toggleTheme: (theme: string) => void;
};

// Provide default value for createContext
const defaultThemeContext: ThemeContextType = {
  theme: "Light",
  muiTheme: legacyTheme,
  toggleTheme: () => {},
};

// Create the context
export const CustomThemeContext =
  createContext<ThemeContextType>(defaultThemeContext);

export const ThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<string>(
    localStorage.getItem("theme") || "Light",
  );

  const toggleTheme = (theme: string) => {
    setTheme(theme);
    localStorage.setItem("theme", theme);
  };

  return (
    <CustomThemeContext.Provider
      value={{ theme, muiTheme: legacyTheme, toggleTheme }}
    >
      <MuiThemeProvider theme={legacyTheme}>{children}</MuiThemeProvider>
    </CustomThemeContext.Provider>
  );
};

// Define return type for useThemeContext
export const useThemeContext = (): ThemeContextType =>
  useContext(CustomThemeContext);
