import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

import { PRODUCT_ATTRIBUTE_CHANGE_TYPE } from "../../utils/Constants";
import { selectProductAttribute } from "../../store/category/CategorySelectors";
import {
  selectProductAttributeValueMap,
  selectIsProductAttributesFetched,
} from "../../store/product/ProductSelectors";
import { updateProductAttribute } from "../../store/product/ProductActions";
import { getAttributeValueDisplayName } from "../../utils/AttributeUtils";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AttributeValue } from "../../store/category/CategoryTypes";
import Chip from "@material-ui/core/Chip";
import { selectAttribute } from "../../store/overlay/OverlaySelectors";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";
import Tooltip from "../common/ToolTip";

interface Props {
  productId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderTop: "1px solid",
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      width: "100%",
      overflow: "auto",
      maxHeight: "25vh",
      textAlign: "center",
      "& .MuiAutocomplete-paper": {
        textAlign: "left",
        fontSize: "0.75rem",
      },
    },
    textField: {
      "& .MuiInputLabel-root": {
        fontSize: "0.875rem",
      },
      "& .MuiInput-root": {
        fontSize: "0.875rem",
      },
      "& .MuiInput-underline::before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline::after": {
        borderBottomColor: "#000",
      },
      "& .MuiAutocomplete-tag": {
        display: "none",
      },
    },
    chip: {
      margin: 2,
      width: "calc(100% - 4px)",
      border: "none",
      textAlign: "left",
      justifyContent: "left",
      "& .MuiChip-deleteIcon": {
        display: "none",
      },
      "&:hover": {
        backgroundColor: "transparent !important",
        "& .MuiChip-deleteIcon": {
          display: "block",
        },
      },
    },
    badgesContainer: {
      textAlign: "left",
      height: "calc(100% - 38px)",
      overflow: "auto",
    },
    options: {
      textAlign: "left",
      fontSize: "0.75rem",
    },
  }),
);

const findDifference = (
  oldValues: AttributeValue[],
  newValues: AttributeValue[],
): string[] => {
  const valuesSet = new Set<string>();

  oldValues.forEach((val) => {
    valuesSet.add(val.attributeValueId);
  });

  newValues.forEach((val) => {
    if (valuesSet.has(val.attributeValueId)) {
      valuesSet.delete(val.attributeValueId);
    } else {
      valuesSet.add(val.attributeValueId);
    }
  });

  return Array.from(valuesSet);
};

const ProductAttributeSelection: React.FC<Props> = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { productId } = props;
  const [value, setValue] = useState<AttributeValue | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [checkboxes, setCheckboxes] = useState<AttributeValue[] | null>(null);
  const dispatch = useDispatch();

  const localeCode = useSelector(selectCurrentLocale);
  const storeId = useSelector(selectCurrentStoreId);

  const attribute = useSelector(selectProductAttribute);
  const selectedAttribute = useSelector(selectAttribute);
  const productAttributeValueMap = useSelector(selectProductAttributeValueMap);
  const initialAttribute =
    attribute &&
    attribute.values.find(
      (attrValue) =>
        productAttributeValueMap[productId] !== undefined &&
        productAttributeValueMap[productId].includes(
          attrValue.attributeValueId,
        ),
    );
  const initialAttributeValue = initialAttribute
    ? initialAttribute.attributeValueId
    : "";
  const defaultChecked =
    attribute &&
    attribute.values.filter(
      (attrVal) =>
        productAttributeValueMap[productId] !== undefined &&
        productAttributeValueMap[productId].includes(attrVal.attributeValueId),
    );
  const isAttributeValuesFetched = useSelector(
    selectIsProductAttributesFetched,
  );
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  const multiSelectedValues = checkboxes
    ? checkboxes
    : defaultChecked
      ? defaultChecked
      : [];

  useEffect(() => {
    setValue(null);
    setCheckboxes(null);
  }, [selectedAttribute]);

  const handleMultipleValuesChange = useCallback(
    (event: React.ChangeEvent<{}>, newValues: AttributeValue[] | null) => {
      const updatedValues = newValues ? newValues : [];
      const existingValues = checkboxes
        ? checkboxes
        : defaultChecked
          ? defaultChecked
          : [];
      const changeType =
        updatedValues.length < existingValues.length
          ? PRODUCT_ATTRIBUTE_CHANGE_TYPE.DELETE
          : PRODUCT_ATTRIBUTE_CHANGE_TYPE.INSERT;

      const difference = findDifference(existingValues, updatedValues);

      difference.forEach((diff) => {
        dispatch(
          updateProductAttribute({
            productId,
            newAttributeValueId:
              changeType === PRODUCT_ATTRIBUTE_CHANGE_TYPE.INSERT ? diff : "",
            oldAttributeValueId:
              changeType === PRODUCT_ATTRIBUTE_CHANGE_TYPE.DELETE ? diff : "",
            type: changeType,
            isStoreSpecific: attribute ? attribute.isStoreSpecific : false,
            isLocalized: attribute ? attribute.isLocalized : false,
            storeId: storeId ? storeId : "",
            localeCode: localeCode ? localeCode : "",
          }),
        );
      });
      setCheckboxes(updatedValues);
    },
    [
      checkboxes,
      defaultChecked,
      productId,
      attribute,
      storeId,
      localeCode,
      dispatch,
    ],
  );

  const handleDelete = useCallback(
    (attributeValueId: string) => {
      const existingValues = checkboxes
        ? checkboxes
        : defaultChecked
          ? defaultChecked
          : [];
      const copiedSelectedValues = existingValues.slice();
      dispatch(
        updateProductAttribute({
          productId,
          newAttributeValueId: "",
          oldAttributeValueId: attributeValueId,
          type: PRODUCT_ATTRIBUTE_CHANGE_TYPE.DELETE,
          isStoreSpecific: attribute ? attribute.isStoreSpecific : false,
          isLocalized: attribute ? attribute.isLocalized : false,
          storeId: storeId ? storeId : "",
          localeCode: localeCode ? localeCode : "",
        }),
      );
      const newValues = copiedSelectedValues.filter(
        (val) => val.attributeValueId !== attributeValueId,
      );
      setCheckboxes(newValues);
    },
    [
      checkboxes,
      defaultChecked,
      productId,
      attribute,
      storeId,
      localeCode,
      dispatch,
    ],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: AttributeValue | null) => {
      const changeType = !newValue
        ? PRODUCT_ATTRIBUTE_CHANGE_TYPE.DELETE
        : initialAttributeValue !== ""
          ? PRODUCT_ATTRIBUTE_CHANGE_TYPE.UPDATE
          : PRODUCT_ATTRIBUTE_CHANGE_TYPE.INSERT;
      dispatch(
        updateProductAttribute({
          productId,
          newAttributeValueId: newValue ? newValue.attributeValueId : "",
          oldAttributeValueId: value
            ? value.attributeValueId
            : initialAttributeValue,
          type: changeType,
          isStoreSpecific: attribute ? attribute.isStoreSpecific : false,
          isLocalized: attribute ? attribute.isLocalized : false,
          storeId: storeId ? storeId : "",
          localeCode: localeCode ? localeCode : "",
        }),
      );
      setValue(newValue ? newValue : null);
    },
    [
      initialAttributeValue,
      value,
      productId,
      attribute,
      storeId,
      localeCode,
      dispatch,
    ],
  );

  if (!isAttributeValuesFetched) {
    return (
      <div className={classes.container}>
        {intl.formatMessage({
          id: "productAttributeSelection.loadingText",
          defaultMessage: "Loading...",
        })}
      </div>
    );
  }

  if (!attribute) {
    return null;
  }

  return (
    <div className={classes.container}>
      {attribute.isMultiValue ? (
        <>
          <div className={classes.badgesContainer}>
            {multiSelectedValues.map((val) => (
              <Tooltip
                tooltipTitle={getAttributeValueDisplayName(
                  val,
                  localeCode,
                  defaultLocaleId,
                )}
                interactive
                enterDelay={500}
                key={`chip-${val.attributeValueId}`}
              >
                <Chip
                  label={getAttributeValueDisplayName(
                    val,
                    localeCode,
                    defaultLocaleId,
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  onDelete={(e) => handleDelete(val.attributeValueId)}
                  variant="outlined"
                  size="small"
                  className={classes.chip}
                />
              </Tooltip>
            ))}
          </div>
          <Autocomplete
            multiple
            id={`${productId}-muliple-value`}
            options={attribute.values}
            getOptionLabel={(option) =>
              getAttributeValueDisplayName(option, localeCode, defaultLocaleId)
            }
            value={multiSelectedValues}
            onChange={handleMultipleValuesChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onClick={(e) => {
                  e.preventDefault();
                }}
                size="small"
                className={classes.textField}
                placeholder={intl.formatMessage({
                  id: "productAttributeSelection.assignValuesPlaceholder",
                  defaultMessage: "Assign values",
                })}
              />
            )}
            filterSelectedOptions
            size="small"
            renderOption={(option) => (
              <span
                className={classes.options}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {getAttributeValueDisplayName(
                  option,
                  localeCode,
                  defaultLocaleId,
                )}
              </span>
            )}
          />
        </>
      ) : (
        <Autocomplete
          value={value ? value : initialAttribute ? initialAttribute : null}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`${productId}-single-value`}
          options={attribute.values}
          getOptionLabel={(option) =>
            getAttributeValueDisplayName(option, localeCode, defaultLocaleId)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={(e) => {
                e.preventDefault();
              }}
              size="small"
              className={classes.textField}
              placeholder={intl.formatMessage({
                id: "productAttributeSelection.assignSingleValuePlaceholder",
                defaultMessage: "Assign a value",
              })}
            />
          )}
          onClick={(e) => {
            e.preventDefault();
          }}
          size="small"
          renderOption={(option) => (
            <span
              className={classes.options}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {getAttributeValueDisplayName(
                option,
                localeCode,
                defaultLocaleId,
              )}
            </span>
          )}
        />
      )}
    </div>
  );
};

export default React.memo(ProductAttributeSelection);
